import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import ServiceInputCard from './ServiceInputCard';
import Alert from '../alerts/Alert';
import InputCardsContext from '../../../context/InputCardsContext';
import { COMPANY_SERVICE_INPUT_MODE } from '../../../constant/General';
import { Service } from '../../../interfaces';

const ServiceInputCardsContainer = (props: {
  arrEditedService: Array<Service>;
  arrNewService: Array<Service>;
  arrService: Array<Service>;
  companyId: number;
  restaurantId: number;
  locationId: number;
  updateMapEditedServiceByServiceId: any;
  updateMapForNewService: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const MAX_SERVICE_PER_LOCATION = 9;
  /**
   * States for this functional component
   */
  const [arrServiceId, setArrServiceId] = useState<Array<number>>([]);

  const [isAlert, setIsAlert] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');

  /**
   * Function updates arrServiceId array with a new value to create a new ServiceInputCard. If the
   * number of services to be added already exceeds the maximum number of services allowed for a location,
   * an alert message will pop up.
   */
  const addService = () => {
    if (arrServiceId.length < MAX_SERVICE_PER_LOCATION) {
      let newServiceId = props.locationId - arrServiceId.length - 1;
      // This is to cater for the adding new service in update locationService mode, where there is actually a valid locationId
      if (
        inputCardsContext.input.inputModeIndex === COMPANY_SERVICE_INPUT_MODE.updateLocationService
      ) {
        newServiceId = -1110 - arrServiceId.length - 1;
      }
      const newArrServiceId = [...arrServiceId, newServiceId];
      setArrServiceId(newArrServiceId);
      inputCardsContext.setErrorExist(newServiceId, true, 0);
    } else {
      setArrAlertMessage(['Cannot add more than 9 services at one go!']);
      setAlertHeader('Sorry!');
      setIsAlert(true);
    }
  };

  /**
   * Function runs whenever Add Another Service button is clicked, to create another service input card
   */
  const addAnotherServiceOnClick = () => {
    addService();
  };

  /**
   * Function runs whenever Remove Service button is clicked, removes a service from arrServiceId and the mapUpdatedServiceByServiceId.
   * Also removes the service's error status and menuInputTag from the inputCardsContext's mapErrorById and mapMenuInputTagByServiceId
   * respectively.
   */
  const removeServiceOnClick = () => {
    let newArrServiceId = [...arrServiceId];
    if (newArrServiceId.length !== 0) {
      const serviceToRemoveId = newArrServiceId.pop();
      setArrServiceId(newArrServiceId);
      if (serviceToRemoveId) {
        props.updateMapForNewService({
          serviceId: serviceToRemoveId,
          locationId: props.locationId,
        });
        inputCardsContext.removeRestaurantIdMenuInputTag(serviceToRemoveId, 0); // Remove service data from context
        inputCardsContext.deleteErrorForOneId(serviceToRemoveId, 0);
      }
    }
  };

  /**
   * useEffect to set up the first service input card if the array of existing service is empty, which implies that the user is either adding new companyService, restaurantService and locationService
   */
  useEffect(() => {
    if (props.arrService.length === 0) {
      addService();
    }
  }, []);

  const isAddForEditLocationService = props.arrService.length !== 0;
  return (
    <div className="ServiceInputCardsContainer">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <ul className="flex flex-col mt-4 gap-y-4">
        <hr className="border h-2 bg-gray-400 rounded-xl lg:hidden" />
        <div className={`${isAddForEditLocationService && 'bg-amber-100 rounded-xl'}`}>
          <div className="flex justify-between gap-x-2 pt-2 pb-4 px-4">
            <div>Services Added: {arrServiceId.length}</div>
            <div className="flex justify-end gap-x-2 ">
              {((props.arrService.length !== 0 && arrServiceId.length > 0) ||
                arrServiceId.length > 1) && (
                <Button
                  type="submit"
                  color="red"
                  size="sm"
                  className="RemoveAServiceButton font-medium flex gap-x-1 pt-2 p-2"
                  onClick={removeServiceOnClick}
                >
                  <MdRemove className="self-center" />
                  <span>Remove a Service</span>
                </Button>
              )}
              <Button
                type="submit"
                color="lime"
                size="sm"
                className="AddAnotherServiceButton font-medium flex gap-x-1 pt-2 p-2"
                onClick={addAnotherServiceOnClick}
              >
                <MdAdd className="self-center" />
                <span>Add another Service</span>
              </Button>
            </div>
          </div>
          {arrServiceId.map((serviceId: number) => (
            <ServiceInputCard
              companyId={props.companyId}
              restaurantId={props.restaurantId}
              locationId={props.locationId}
              serviceId={serviceId}
              key={serviceId}
              arrService={props.arrService}
              service={undefined}
              arrUpdatedService={props.arrNewService.concat(props.arrEditedService)}
              updateMapForEditedOrNewService={props.updateMapForNewService}
            />
          ))}
        </div>
        {props.arrService!.map((service: Service) => (
          <ServiceInputCard
            companyId={props.companyId}
            restaurantId={props.restaurantId}
            locationId={props.locationId}
            serviceId={service.serviceId}
            key={service.serviceId}
            arrService={props.arrService}
            service={service}
            arrUpdatedService={props.arrEditedService.concat(props.arrNewService)}
            updateMapForEditedOrNewService={props.updateMapEditedServiceByServiceId}
          />
        ))}
      </ul>
    </div>
  );
};

export default ServiceInputCardsContainer;
