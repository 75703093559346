import axios from 'axios';
import { useContext, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';

import UserCard from './UserCard';
import UserInputCard from './UserInputCard';
import AddButton from '../Common/Buttons/AddButton';
import EditButton from '../Common/Buttons/EditButton';
import Alert from '../CompanyComponents/alerts/Alert';
import { POSITION } from '../../constant/General';
import InputCardsContext from '../../context/InputCardsContext';
import { CompanyService, User, UserRestaurantLocation, RestaurantLocation } from '../../interfaces';
import UserContext from '../../context/UserContext';

/**
 * Function checks if the location(s) to be updated (for edit user) is the same as the original ones
 * @param editedArrRestaurantLocation - Array of location(s) to be newly associated with the user, in the data structure of restaurantLocation
 * @param originalArrRestaurantLocation - Array of original location(s) associated with the user, in the data structure of restaurantLocation
 * @returns boolean - Indicates if the location(s) to be updated is the same as the original ones
 */
const isArrRestaurantLocationChanged = (
  editedArrRestaurantLocation: Array<RestaurantLocation>,
  originalArrRestaurantLocation: Array<RestaurantLocation>
) => {
  const setEditedLocationId = new Set();
  const setExistingLocationId = new Set();

  editedArrRestaurantLocation.forEach((restaurantLocation) => {
    restaurantLocation.arrLocation.forEach((location) => {
      setEditedLocationId.add(location.locationId);
    });
  });
  originalArrRestaurantLocation.forEach((restaurantLocation) => {
    restaurantLocation.arrLocation.forEach((location) => {
      setExistingLocationId.add(location.locationId);
    });
  });

  if (setEditedLocationId.size === setExistingLocationId.size) {
    let isSame = true;
    setEditedLocationId.forEach((editedLocationId) => {
      isSame = setExistingLocationId.has(editedLocationId);
    });
    if (!isSame) {
      return true;
    }
    return false;
  }
  return true;
};

const UserList = (props: { companyService: CompanyService }) => {
  const inputCardsContext = useContext(InputCardsContext);
  const userContext = useContext(UserContext);

  const [isAlert, setIsAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButtonFloating, setIsEditButtonFloating] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isSaveChangesDialogOpened, setIsSaveChangesDialogOpened] = useState<boolean>(false);
  const [arrSaveChangesDialogContent, setArrSaveChangesDialogContent] = useState<Array<string>>([]);

  const [alertHeader, setAlertHeader] = useState('');
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [mapIsErrorByUserId, setMapIsErrorByUserId] = useState<Map<number, boolean>>(new Map());

  const { user } = useAuth0();

  const [newUser, setNewUser] = useState<User>({
    datavizUserId: undefined,
    email: '',
    firstName: '',
    lastName: '',
    isEmailPrimaryRecipient: false,
    isUsingOtp: false,
    isCompany: false,
  });
  const [newArrRestaurantLocation, setNewArrRestaurantLocation] = useState<
    Array<RestaurantLocation>
  >([]);

  const [mapEditedUserByUserId, setMapEditedUserByUserId] = useState(new Map());
  const [mapEditedArrRestaurantLocationByUserId, setMapEditedArrRestaurantLocationbyUserId] =
    useState(new Map());
  /**
   * Function is called when user clicks on edit button, enables editing mode and passes isEdit state to children components to
   * enable display of edit inputs
   */
  const enableEditOnClick = (event: any) => {
    const updatedIsEdit = !isEdit;
    if (updatedIsEdit) {
      inputCardsContext.updateUserCardInputModeAndId(1, props.companyService.companyId);
    } else {
      inputCardsContext.updateUserCardInputModeAndId(0, 0);
    }
    setIsEdit(updatedIsEdit);
  };

  /**
   * Function is called when user clicks on add button, enables addition mode and passes isAdd state to children
   * components to enable display of add button inputs
   */
  const enableAddOnClick = (event: any) => {
    const updatedIsAdd = !isAdd;
    setIsAdd(!isAdd);
    if (updatedIsAdd) {
      inputCardsContext.updateUserCardInputModeAndId(2, props.companyService.companyId);
    } else {
      inputCardsContext.updateUserCardInputModeAndId(0, 0);
    }
    setIsAdd(updatedIsAdd);
  };

  /**
   * Function is called when user clicks on save button, enables addition mode and passes isAdd state to children
   * components to enable display of add button inputs
   */
  const saveAddChangesOnClick = async () => {
    setIsSave(!isSave);
    let newArrAlertMessage = [];
    let isAlertMessage = false;

    if (
      newUser.firstName.length === 0 ||
      newUser.lastName.length === 0 ||
      newUser.email.length === 0
    ) {
      newArrAlertMessage.push('Please do not leave any fields blank and check your inputs!');
      setAlertHeader('Add New User Error!');
      isAlertMessage = true;
    }

    if (newArrRestaurantLocation.length === 0) {
      newArrAlertMessage.push('Please check at least 1 location!');
      setAlertHeader('Add New User Error!');
      isAlertMessage = true;
    }
    setArrAlertMessage(newArrAlertMessage);

    if (isAlertMessage) {
      setIsAlert(true);
    } else {
      let newUserRestaurantLocationToAdd = {
        ...newUser,
        datavizUserId: undefined,
        arrRestaurantLocation: newArrRestaurantLocation,
      };
      let arrRestaurantLocation = newUserRestaurantLocationToAdd.arrRestaurantLocation;
      if (arrRestaurantLocation.length > 1) {
        newUserRestaurantLocationToAdd.isCompany = true;
      }
      for (let i = 0; i < arrRestaurantLocation.length; i++) {
        let arrLocation: any = arrRestaurantLocation[i].arrLocation;
        for (let x = 0; x < arrLocation.length; x++) {
          if (arrLocation[x].arrService) {
            delete arrLocation[x].arrService;
          }
        }
      }
      newUserRestaurantLocationToAdd.arrRestaurantLocation = arrRestaurantLocation;
      try {
        const response = await axios.post('/user-management/create-user', {
          userRestaurantLocation: newUserRestaurantLocationToAdd,
        });
        const { userRestaurantLocation, isCreatePasswordEmailSent } = response.data;
        userContext.addUser(userRestaurantLocation);
        setIsAdd(false);
        if (isCreatePasswordEmailSent) {
          setAlertHeader('Save success!');
          setArrAlertMessage([
            'Create password email has been successfully sent to:',
            newUser.email,
          ]);
        } else {
          setAlertHeader('User created, but email not sent!');
          setArrAlertMessage([
            'An error has occured when sending email to user!',
            "Kindly alert software team, and try again with 'Reset Password' later.",
          ]);
        }
        inputCardsContext.updateUserCardInputModeAndId(0, 0);
        setIsAlert(true);
      } catch (error: any) {
        setArrAlertMessage(['Please check your inputs!']);
        setAlertHeader('Add New User Error!');
        setIsAlert(true);
      }
    }
  };

  /**
   * Function is called when user clicks on save button. Before sending the array of userRestaurantLocation object(s) to the Backend,
   * the function checks if there is any invalid inputs of email, first name and last name, and also if there is at least one location
   * selected for each user to be updated. If there is any of the mentioned error, then an alert message will pop up to notify the app
   * user. If no error, the function will continue to process the data to combine the user and its restaurantLocation(s) together to
   * to form one single object, userRestaurantLocation to be sent to the Backend.
   */
  const saveEditChangesOnClick = async () => {
    setIsSave(!isSave);

    const newArrAlertMessage = [];
    mapEditedArrRestaurantLocationByUserId.forEach((editedArrRestaurantLocation, userId) => {
      if (editedArrRestaurantLocation.length === 0) {
        newArrAlertMessage.push(['Please check at least 1 location!']);
      }
    });
    if (mapIsErrorByUserId.size !== 0) {
      newArrAlertMessage.push(['Please check your inputs before saving!']);
    }

    if (newArrAlertMessage.length !== 0) {
      setArrAlertMessage(newArrAlertMessage);
      setAlertHeader('Edit User Error!');
      setIsAlert(true);
    } else {
      const mapEditedUserByUserIdCopy = new Map(mapEditedUserByUserId);
      const mapEditedArrRestaurantLocationByUserIdCopy = new Map(
        mapEditedArrRestaurantLocationByUserId
      );

      const arrUserRestaurantLocationToBeUpdated: Array<UserRestaurantLocation> = [];
      mapEditedUserByUserIdCopy.forEach((editedUser, userId) => {
        const editedUserRestaurantLocation = {
          ...editedUser,
          arrRestaurantLocation: [],
        };
        const editedArrRestaurantLocation = mapEditedArrRestaurantLocationByUserIdCopy.get(userId);
        if (!editedArrRestaurantLocation) {
          const matchedUserRestaurantLocation = arrUserRestaurantLocation.find(
            (userRestaurantLocation) => userRestaurantLocation.datavizUserId === userId
          );
          editedUserRestaurantLocation.arrRestaurantLocation =
            matchedUserRestaurantLocation?.arrRestaurantLocation;
          arrUserRestaurantLocationToBeUpdated.push(editedUserRestaurantLocation);
        } else {
          editedUserRestaurantLocation.arrRestaurantLocation = editedArrRestaurantLocation;
          if (editedUserRestaurantLocation.arrRestaurantLocation.length > 1) {
            editedUserRestaurantLocation.isCompany = true;
          } else {
            editedUserRestaurantLocation.isCompany = false;
          }
          arrUserRestaurantLocationToBeUpdated.push(editedUserRestaurantLocation);
          mapEditedArrRestaurantLocationByUserIdCopy.delete(userId);
        }
      });
      mapEditedArrRestaurantLocationByUserIdCopy.forEach((editedArrRestaurantLocation, userId) => {
        const matchedUserRestaurantLocation = arrUserRestaurantLocation.find(
          (userRestaurantLocation) => userRestaurantLocation.datavizUserId === userId
        );
        if (
          isArrRestaurantLocationChanged(
            editedArrRestaurantLocation,
            matchedUserRestaurantLocation!.arrRestaurantLocation
          )
        ) {
          const editedUserRestaurantLocation = { ...matchedUserRestaurantLocation! };
          editedUserRestaurantLocation.arrRestaurantLocation = editedArrRestaurantLocation;
          if (editedUserRestaurantLocation.arrRestaurantLocation.length > 1) {
            editedUserRestaurantLocation.isCompany = true;
          } else {
            editedUserRestaurantLocation.isCompany = false;
          }
          arrUserRestaurantLocationToBeUpdated.push(editedUserRestaurantLocation);
        }
      });
      try {
        if (arrUserRestaurantLocationToBeUpdated.length === 0) {
          setArrAlertMessage(['No changes have been made!']);
          setAlertHeader('Edit User Error!');
          setIsAlert(true);
        } else {
          await axios.post('/user-management/update-user', {
            companyId: props.companyService.companyId,
            arrUserRestaurantLocationToBeUpdated,
          });

          setAlertHeader('Save success!');
          setArrAlertMessage([]);
          setIsEdit(false);
          setMapEditedUserByUserId(new Map());
          setMapEditedArrRestaurantLocationbyUserId(new Map());
          setIsAlert(true);
          inputCardsContext.updateUserCardInputModeAndId(0, 0);
          userContext.updateUser(arrUserRestaurantLocationToBeUpdated);
        }
      } catch (error: any) {
        setArrAlertMessage(['Please check your inputs!']);
        setAlertHeader('Edit User Error!');
        setIsAlert(true);
      }
    }
  };

  const updateNewUser = (newUser: User) => {
    setNewUser(newUser);
  };

  const updateNewArrRestaurantLocation = (newArrRestaurantLocation: Array<RestaurantLocation>) => {
    setNewArrRestaurantLocation(newArrRestaurantLocation);
  };

  const updateMapEditedUserByUserId = (userId: number, editedUser: User) => {
    const updatedMapEditedUserByUserId = new Map(mapEditedUserByUserId);
    updatedMapEditedUserByUserId.set(userId, editedUser);
    setMapEditedUserByUserId(updatedMapEditedUserByUserId);
  };

  const updateMapEditedArrRestaurantLocationByUserId = (
    userId: number,
    editedArrRestaurantLocation: User
  ) => {
    const updatedMapEditedArrRestaurantLocationByUserId = new Map(
      mapEditedArrRestaurantLocationByUserId
    );
    updatedMapEditedArrRestaurantLocationByUserId.set(userId, editedArrRestaurantLocation);
    setMapEditedArrRestaurantLocationbyUserId(updatedMapEditedArrRestaurantLocationByUserId);
  };

  /**
   * Function updates after an onBlur event of the email, first name or last name input occurs. It updates
   * if the current user object has any invalid email, first name or last name.
   * @param userId - Id of the user
   * @param isError - Indicates if there is any invalid email, first name or last name
   */
  const updateMapIsErrorByUserId = (userId: number, isError: boolean) => {
    const updatedMapIsErrorByUserId = new Map(mapIsErrorByUserId);
    if (isError) {
      updatedMapIsErrorByUserId.set(userId, isError);
    } else {
      updatedMapIsErrorByUserId.delete(userId);
    }
    setMapIsErrorByUserId(updatedMapIsErrorByUserId);
  };

  /**
   * This function updates the isSaveChangesDialogOpened state. If the new isSaveChangesDialogOpened state is false,
   * then empty arrSaveChangesDialogContent is set and save changes dialog will be closed. If true, the dialog will be opened.
   * When adding users (isAdd is true), the user will require a create password email to be sent
   * For emails requiring create password emails to be sent, the dialog will display the list of emails for MC user's confirmation before
   * proceeding. The message 'Please click CONFIRM if you want to submit the changes, ...' will be displayed.
   * @param isToOpenSaveChangesDialog - Whether to open or close the save changes dialog
   */
  const openOrCloseSaveChangesDialog = (isToOpenSaveChangesDialog: boolean) => {
    setIsSaveChangesDialogOpened(isToOpenSaveChangesDialog);
    if (!isToOpenSaveChangesDialog) {
      setArrSaveChangesDialogContent([]);
      return;
    }
    const arrUpdatedDialogContent = [];
    if (mapIsErrorByUserId.size === 0) {
      const arrEmailWithPasswordChange = [];
      if (isAdd && newUser.email) {
        arrEmailWithPasswordChange.push(newUser.email);
      }
      if (arrEmailWithPasswordChange.length) {
        arrUpdatedDialogContent.push(
          'The following email addresses are to receive their create password emails:'
        );
        arrEmailWithPasswordChange.forEach((emailWithPasswordChange) => {
          arrUpdatedDialogContent.push(emailWithPasswordChange);
        });
      }
    }
    arrUpdatedDialogContent.push(
      'Please click CONFIRM if you want to submit the changes, and click CANCEL if you would like to check the changes again.'
    );
    setArrSaveChangesDialogContent(arrUpdatedDialogContent);
  };

  /**
   * This function deletes the selected datavizUser
   * The deletedBy is the admin user's email to be used for logging purposes in database-middleware.
   * Upon successful deletion, the user context values are updated to reflect changes on the UI.
   * @param datavizUserId - Id of the datavizUser to be deleted
   * @param datavizUserEmail - Email of the datavizUser to be deleted
   */
  const deleteUser = async (datavizUserId: number, datavizUserEmail: string) => {
    try {
      await axios.post('/user-management/delete-user', {
        datavizUserId,
        datavizUserEmail,
        deletedBy: user!.email,
      });
      userContext.deleteUser(props.companyService.companyId, datavizUserId, datavizUserEmail);
      setArrAlertMessage([`User ${datavizUserEmail} has been successfully deleted`]);
      setAlertHeader('User Deleted');
      setIsAlert(true);
    } catch (error: any) {
      setArrAlertMessage(['An error has occured when deleting user']);
      setAlertHeader('User Deletion Error!');
      setIsAlert(true);
    }
  };

  const arrUserRestaurantLocation =
    userContext.mapArrUserRestaurantLocationByCompanyId.get(props.companyService.companyId) || [];

  return (
    <div className="UserList">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      {isSaveChangesDialogOpened && (
        <Dialog open={true} handler={() => {}} className="SaveChangesDialog">
          <DialogHeader className="SaveChangesDialogHeader">Confirm Save Changes</DialogHeader>
          <DialogBody
            divider
            className="SaveChangesDialogBody flex flex-col font-normal text-off-black"
          >
            <>
              {arrSaveChangesDialogContent.map(
                (saveChangesDialogContent, saveChangesDialogContentIndex) => (
                  <p key={`dialogContent_${saveChangesDialogContentIndex}`} className="my-1">
                    {saveChangesDialogContent}
                  </p>
                )
              )}
            </>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={() => setIsSaveChangesDialogOpened(false)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button
              className="ConfirmSaveChangesButton"
              variant="gradient"
              color="green"
              onClick={() => {
                setIsSaveChangesDialogOpened(false);
                if (isAdd) {
                  saveAddChangesOnClick();
                } else if (isEdit) {
                  saveEditChangesOnClick();
                }
              }}
            >
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </Dialog>
      )}
      <Waypoint
        onEnter={({ previousPosition }) => {
          if (previousPosition === POSITION.above) {
            setIsEditButtonFloating(true);
          }
        }}
        onLeave={() => setIsEditButtonFloating(false)}
      >
        <div>
          <div className="mt-3 flex gap-x-1 flex-nowrap justify-between content-center">
            <h1 className="font-medium text-xl">Users: {arrUserRestaurantLocation.length}</h1>
            <div className="flex gap-x-2">
              {!isEdit && (
                <AddButton
                  isEnabled={
                    props.companyService.companyId !== inputCardsContext.userCardInputId &&
                    inputCardsContext.userCardInputId === 0
                  }
                  enableAddOnClick={enableAddOnClick}
                  saveChangesOnClick={() => openOrCloseSaveChangesDialog(true)}
                  isAdd={isAdd}
                  buttonName={'Add User'}
                  buttonClassName={'AddUserButton'}
                />
              )}
              {!isAdd && (
                <EditButton
                  isEnabled={
                    props.companyService.companyId !== inputCardsContext.userCardInputId &&
                    inputCardsContext.userCardInputId === 0
                  }
                  enableEditOnClick={enableEditOnClick}
                  saveChangesOnClick={() => openOrCloseSaveChangesDialog(true)}
                  isEdit={isEdit}
                  buttonName={
                    isEdit ? 'Save Changes' : `Edit Users for ${props.companyService.name}`
                  }
                  isButtonFloating={isEditButtonFloating}
                  setIsButtonFloating={setIsEditButtonFloating}
                />
              )}
            </div>
          </div>
          {isAdd && (
            <UserInputCard
              existingUserRestaurantLocation={null}
              arrAllRestaurantService={props.companyService.arrRestaurantService}
              updateNewUser={updateNewUser}
              updateNewArrRestaurantLocation={updateNewArrRestaurantLocation}
              updateMapEditedUserByUserId={updateMapEditedUserByUserId}
              updateMapEditedArrRestaurantLocationByUserId={
                updateMapEditedArrRestaurantLocationByUserId
              }
              updateMapIsErrorByUserId={updateMapIsErrorByUserId}
            />
          )}
          {isEdit ? (
            <div className="mt-3 grid grid-cols-1 grid-flow-row-dense-y gap-y-3 gap-x-5">
              {arrUserRestaurantLocation.map((userRestaurantLocation: UserRestaurantLocation) => (
                <UserInputCard
                  key={userRestaurantLocation.email}
                  existingUserRestaurantLocation={userRestaurantLocation}
                  arrAllRestaurantService={props.companyService.arrRestaurantService}
                  updateNewUser={updateNewUser}
                  updateNewArrRestaurantLocation={updateNewArrRestaurantLocation}
                  updateMapEditedUserByUserId={updateMapEditedUserByUserId}
                  updateMapEditedArrRestaurantLocationByUserId={
                    updateMapEditedArrRestaurantLocationByUserId
                  }
                  updateMapIsErrorByUserId={updateMapIsErrorByUserId}
                />
              ))}
            </div>
          ) : (
            <div className="mt-3 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 grid-flow-row-dense-y gap-y-3 gap-x-5">
              {arrUserRestaurantLocation.map((userRestaurantLocation: UserRestaurantLocation) => (
                <UserCard
                  userRestaurantLocation={userRestaurantLocation}
                  key={userRestaurantLocation.email}
                  deleteUser={deleteUser}
                />
              ))}
            </div>
          )}
        </div>
      </Waypoint>
    </div>
  );
};

export default UserList;
