import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Button, Input } from '@material-tailwind/react';

import { MenuItem, NewMenuItem } from '../../../interfaces';

// Enum constants used for getting and setting maps in parent/grandparent component states.
const MENU_ITEM_CONSTANT = {
  name: 'name',
  isNameValid: 'isNameValid',
  nameErrorMessage: 'nameErrorMessage',
  station: 'station',
  isStationValid: 'isStationValid',
  stationErrorMessage: 'stationErrorMessage',
  costPerKilogram: 'costPerKilogram',
  isCostPerKilogramValid: 'isCostPerKilogramValid',
  costPerKilogramErrorMessage: 'costPerKilogramErrorMessage',
};

const MenuItemInputCard = (props: {
  arrExistingMenuItem: Array<MenuItem>;
  arrNewMenuItem: Array<NewMenuItem>;
  checkCostPerKilogramError: any;
  checkNameError: any;
  checkStationError: any;
  hasOnlyOneMenuItemToBeCreated: boolean;
  mapMenuItemErrorToBeCreatedByMenuItemId: any;
  newMenuItem: NewMenuItem;
  removeMenuItemId: any;
  setMapMenuItemErrorToBeCreatedByMenuItemId: any;
  updateMapMenuItemByArrMenuItemId: any;
  updateMapMenuItemErrorByArrMenuItemId: any;
}) => {
  const [menuItemForInput, setMenuItemForInput] = useState<NewMenuItem>({ ...props.newMenuItem });

  /**
   * This function updates the parent component's mapMenuItemErrorToBeCreatedByMenuItemId's value of its corresponding key
   * @param key - String of key ('isNameValid', 'isStationValid', 'isCostPerKilogramValid', 'nameErrorMessage', 'stationErrorMessage', 'costPerKilogramErrorMessage')
   * @param value - Boolean for 'isNameValid', 'isStationValid', 'isCostPerKilogramValid' and string for 'nameErrorMessage', 'stationErrorMessage', 'costPerKilogramMessage'
   */
  const updateMapMenuItemError = (key: string, value: boolean | string) => {
    props.setMapMenuItemErrorToBeCreatedByMenuItemId(
      props.mapMenuItemErrorToBeCreatedByMenuItemId.set(props.newMenuItem.menuItemId, {
        ...props.mapMenuItemErrorToBeCreatedByMenuItemId.get(props.newMenuItem.menuItemId),
        [key]: value,
      })
    );
  };

  /**
   * This is a getter function to the boolean value of 'isNameValid', 'isStationValid', 'isCostPerKilogramValid' the parent component mapMenuItemErrorToBeCreatedByMenuItemId
   * @param key - String of key ('isNameValid', 'isStationValid', 'isCostPerKilogramValid')
   * @returns - Boolean if key searched for is valid or not
   */
  const getInputValidity = (key: string) => {
    return props.mapMenuItemErrorToBeCreatedByMenuItemId.get(props.newMenuItem.menuItemId)[key];
  };

  /**
   * This function updates menuItemForInput state with new menu name/station/costPerKilogram on change
   * @param event ChangeEvent - Contains value of Input Element as per user input
   * @param key String - Key of menuItemForInput to be updated
   */
  const updateMenuItemForInput = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const updatedMenuItemForInput = {
      ...menuItemForInput,
      [key]: event.target.value,
    };
    setMenuItemForInput(updatedMenuItemForInput);
  };

  /**
   * This function triggers on input fields on blur. It runs checks depending on name/station/costPerKilogram.
   * After checks are done, the result of errors and inputs are set for parent/grandparent component states.
   * @param type - String of type ('name', 'station', 'costPerKilogramValid') to determine which checks to invoke
   */
  const updateMenuItemErrorByInputType = (type: string) => {
    let errorMessage = '';
    let validityKeyIdentifier = '';
    let errorMessageKeyIdentifier = '';
    let isOtherInputValid = false;

    const updatedMenuItemForInput = { ...menuItemForInput };

    // Depending on type of input element triggering the function, its corresponding checks are invoked
    if (type === MENU_ITEM_CONSTANT.name) {
      validityKeyIdentifier = MENU_ITEM_CONSTANT.isNameValid;
      errorMessageKeyIdentifier = MENU_ITEM_CONSTANT.nameErrorMessage;
      isOtherInputValid =
        getInputValidity(MENU_ITEM_CONSTANT.isStationValid) &&
        getInputValidity(MENU_ITEM_CONSTANT.isCostPerKilogramValid);
      errorMessage = props.checkNameError(
        updatedMenuItemForInput.name,
        updatedMenuItemForInput.menuItemId,
        props.arrNewMenuItem
      );
    } else if (type === MENU_ITEM_CONSTANT.station) {
      validityKeyIdentifier = MENU_ITEM_CONSTANT.isStationValid;
      errorMessageKeyIdentifier = MENU_ITEM_CONSTANT.stationErrorMessage;
      isOtherInputValid =
        getInputValidity(MENU_ITEM_CONSTANT.isNameValid) &&
        getInputValidity(MENU_ITEM_CONSTANT.isCostPerKilogramValid);
      errorMessage = props.checkStationError(updatedMenuItemForInput.station);
    } else if (type === MENU_ITEM_CONSTANT.costPerKilogram) {
      validityKeyIdentifier = MENU_ITEM_CONSTANT.isCostPerKilogramValid;
      errorMessageKeyIdentifier = MENU_ITEM_CONSTANT.costPerKilogramErrorMessage;
      isOtherInputValid =
        getInputValidity(MENU_ITEM_CONSTANT.isNameValid) &&
        getInputValidity(MENU_ITEM_CONSTANT.isStationValid);
      errorMessage = props.checkCostPerKilogramError(updatedMenuItemForInput.costPerKilogram);
      if (errorMessage === '') {
        updatedMenuItemForInput.costPerKilogram = Number(updatedMenuItemForInput.costPerKilogram);
      }
      setMenuItemForInput(updatedMenuItemForInput);
    }

    // Updating of states
    const isUpdatedMenuItemFieldValid = errorMessage === '';
    updateMapMenuItemError(validityKeyIdentifier, isUpdatedMenuItemFieldValid);
    updateMapMenuItemError(errorMessageKeyIdentifier, errorMessage);
    props.updateMapMenuItemErrorByArrMenuItemId(
      [updatedMenuItemForInput.menuItemId],
      [!(isUpdatedMenuItemFieldValid && isOtherInputValid)],
      false,
      false
    );
    props.updateMapMenuItemByArrMenuItemId(
      [updatedMenuItemForInput.menuItemId],
      [updatedMenuItemForInput],
      false,
      false
    );
  };

  /**
   * This function removes a menu item from the add menu item form when user clicks on 'X' on the particular menu item to be created.
   * The particular menuItemId is removed from associated states containing menuItemId, menuItem information and menuItem errors.
   */
  const removeMenuItem = () => {
    props.removeMenuItemId(menuItemForInput.menuItemId);
    props.updateMapMenuItemErrorByArrMenuItemId([menuItemForInput.menuItemId], [true], true, false);
    props.updateMapMenuItemByArrMenuItemId([menuItemForInput.menuItemId], {}, true);
  };

  return (
    <tr className="border-t-2 h-10 border-gray-100">
      <td className="text-left w-5/12">
        <div className="flex flex-col m-1 mr-3">
          <Input
            onBlur={() => updateMenuItemErrorByInputType(MENU_ITEM_CONSTANT.name)}
            size="md"
            onChange={(event) => {
              updateMenuItemForInput(event, MENU_ITEM_CONSTANT.name);
            }}
            // label="Name"
            value={menuItemForInput.name}
            variant="static"
            className={`MenuItemNameInput text-off-black`}
            error={!getInputValidity(MENU_ITEM_CONSTANT.isNameValid)}
          />
          {!getInputValidity(MENU_ITEM_CONSTANT.isNameValid) && (
            <p className="MenuItemNameErrorMessage text-red-500 text-xs mt-2">
              {
                props.mapMenuItemErrorToBeCreatedByMenuItemId.get(props.newMenuItem.menuItemId)
                  .nameErrorMessage
              }
            </p>
          )}
        </div>
      </td>
      <td className="text-center w-3/12 mr-2">
        <div className="flex flex-col m-1">
          <Input
            onBlur={() => updateMenuItemErrorByInputType(MENU_ITEM_CONSTANT.station)}
            size="md"
            onChange={(event) => {
              updateMenuItemForInput(event, MENU_ITEM_CONSTANT.station);
            }}
            // label="Station"
            value={menuItemForInput.station}
            variant="static"
            className={`StationInput text-off-black`}
            error={!getInputValidity(MENU_ITEM_CONSTANT.isStationValid)}
          />
          {!getInputValidity(MENU_ITEM_CONSTANT.isStationValid) && (
            <p className="StationErrorMessage text-red-500 text-xs mt-2">
              {
                props.mapMenuItemErrorToBeCreatedByMenuItemId.get(props.newMenuItem.menuItemId)
                  .stationErrorMessage
              }
            </p>
          )}
        </div>
      </td>
      <td className="text-center w-3/12 mr-2">
        <div className="flex flex-col m-1">
          <Input
            onBlur={() => updateMenuItemErrorByInputType(MENU_ITEM_CONSTANT.costPerKilogram)}
            size="md"
            onChange={(event) => {
              updateMenuItemForInput(event, MENU_ITEM_CONSTANT.costPerKilogram);
            }}
            // label="Cost Per Kilogram"
            value={menuItemForInput.costPerKilogram}
            variant="static"
            className={`CostPerKilogramInput text-off-black`}
            error={!getInputValidity(MENU_ITEM_CONSTANT.isCostPerKilogramValid)}
          />
          {!getInputValidity(MENU_ITEM_CONSTANT.isCostPerKilogramValid) && (
            <p className="CostPerKilogramErrorMessage text-red-500 text-xs mt-2">
              {
                props.mapMenuItemErrorToBeCreatedByMenuItemId.get(props.newMenuItem.menuItemId)
                  .costPerKilogramErrorMessage
              }
            </p>
          )}
        </div>
      </td>
      <td className="text-center w-1/12">
        {!props.hasOnlyOneMenuItemToBeCreated && (
          <div className="flex justify-center">
            <Button
              onClick={removeMenuItem}
              size="sm"
              className="font-medium flex gap-x-1 p-2"
              color="yellow"
            >
              <MdOutlineClose className="self-center" />
            </Button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default MenuItemInputCard;
