import CompanyInputCard from './CompanyInputCard';
import RestaurantServiceInputCardsContainer from './RestaurantServiceInputCardsContainer';
import { Company, Location, Restaurant, Service } from '../../../interfaces';

const CompanyServiceInputCard = (props: {
  arrNewCompany: Array<Company>;
  arrNewRestaurant: Array<Restaurant>;
  companyId: number;
  mapMapNewLocationByLocationIdByRestaurantId: Map<number, Map<number, Location>>;
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>;
  updateMapNewCompany: any;
  updateMapMapNewRestaurantByRestaurantIdByCompanyId: any;
  updateMapMapNewLocationByLocationIdByRestaurantId: any;
  updateMapMapNewServiceByServiceIdByLocationId: any;
}) => {
  return (
    <li className="GroupServiceInputCard flex flex-col mt-2 bg-gray-200 p-4 rounded-lg gap-y-1">
      <CompanyInputCard
        arrNewCompany={props.arrNewCompany}
        companyService={undefined}
        companyId={props.companyId}
        updateCompany={props.updateMapNewCompany}
      />
      <RestaurantServiceInputCardsContainer
        arrNewRestaurant={props.arrNewRestaurant}
        companyId={props.companyId}
        mapMapNewLocationByLocationIdByRestaurantId={
          props.mapMapNewLocationByLocationIdByRestaurantId
        }
        mapMapNewServiceByServiceIdByLocationId={props.mapMapNewServiceByServiceIdByLocationId}
        updateMapForNewRestaurant={props.updateMapMapNewRestaurantByRestaurantIdByCompanyId}
        updateMapMapNewLocationByLocationIdByRestaurantId={
          props.updateMapMapNewLocationByLocationIdByRestaurantId
        }
        updateMapMapNewServiceByServiceIdByLocationId={
          props.updateMapMapNewServiceByServiceIdByLocationId
        }
      />
    </li>
  );
};

export default CompanyServiceInputCard;
