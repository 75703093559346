import Papa from 'papaparse';
import React, { useRef, useState } from 'react';
import { MdUploadFile } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import Alert from '../alerts/Alert';
import { MenuItem } from '../../../interfaces';

const expectedCSVHeaders = ['name', 'station', 'costPerKilogram'];

const MenuItemUpload = (props: { addMultipleMenuItem: any }) => {
  // File upload states and ref
  const fileUploadRef = useRef<HTMLInputElement>(null);

  // Alert states
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>(['']);
  const [alertHeader, setAlertHeader] = useState<string>('');

  /**
   * This function is invoked when a .csv file is uploaded by the user. There are 3 outcomes:
   * 1. If .csv headers are not valid, an alert will notify the user to upload a valid .csv file
   * 2. If .csv file contains duplicate menu items, an alert will notify the user to correct the duplicates
   * 3. If .csv headers are valid and no duplicates are found, file name is rendered to UI and add button is enabled
   * @param changeEvent - Takes in the ChangeEvent object passed from onChange in html file input element
   */
  const uploadCSVOnChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const csvFile = changeEvent.target.files;
    Papa.parse(csvFile![0], {
      header: true,
      complete: (results) => {
        const isValidHeaders =
          JSON.stringify(results.meta.fields) === JSON.stringify(expectedCSVHeaders);
        if (!isValidHeaders) {
          setIsAlert(true);
          setAlertHeader('Invalid .csv file');
          setArrAlertMessage(['Please check if .csv content is valid!']);
          fileUploadRef.current!.value = '';
          return;
        }
        const arrNewMenuItem = [...results.data] as Array<MenuItem>;
        arrNewMenuItem.forEach((menuItem) => {
          if (!menuItem.name) {
            menuItem.name = '';
          }
          if (!menuItem.costPerKilogram) {
            menuItem.costPerKilogram = '';
          }
          if (!menuItem.station) {
            menuItem.station = '';
          }
        });
        props.addMultipleMenuItem(arrNewMenuItem);
      },
    });
  };

  return (
    <React.Fragment>
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <Button className={'UploadMenuItemButton cursor-pointer p-0'}>
        <label className={'cursor-pointer flex font-medium flex gap-x-1 p-2'}>
          <MdUploadFile className="UploadButton self-center" />
          Upload Menu Item
          <input
            ref={fileUploadRef}
            className="MenuItemUpload"
            style={{ display: 'none' }}
            type="file"
            accept=".csv"
            onChange={uploadCSVOnChange}
          />
        </label>
      </Button>
    </React.Fragment>
  );
};

export default MenuItemUpload;
