const SLIDE_STYLE = {
  opening: 'opening',
  section: 'section',
  plain: 'plain',
  closing: 'closing',
};

const SLIDE_TYPE = {
  graph: 'graph',
  image: 'image',
  station: 'station',
  table: 'table',
};

const WEIGHT_COST_TYPE = {
  weight: 'weight',
  cost: 'cost',
};

// dynamic property refers to the requirement to multiply slides according to services in that slide category
const ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE = [
  { slideStyle: SLIDE_STYLE.opening, dynamic: false },
  {
    slideStyle: SLIDE_STYLE.section,
    dynamic: false,
    headerText: 'Food Waste Review',
    hasTextToAdd: true,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL FOOD WASTE IN TERMS OF WEIGHT (BY MONTHS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER PER COVER',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    arrSlideGraphProp: [
      {
        // slideStyle: SLIDE_STYLE.plain,
        headerText: 'LEFTOVER IN TERMS OF WEIGHT AND THROW (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        dynamic: false,
        isFullWidth: true,
      },
      {
        // slideStyle: SLIDE_STYLE.plain,
        headerText: 'LEFTOVER IN TERMS OF WEIGHT AND COVER (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        dynamic: false,
        isFullWidth: true,
      },
      {
        // slideStyle: SLIDE_STYLE.plain,
        headerText: 'LEFTOVER PER COVER (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        dynamic: false,
        isFullWidth: true,
      },
    ],
    arrSlideImageProp: [
      {
        headerText: 'TOP WASTED THROWS',
        slideType: SLIDE_TYPE.image,
      },
      {
        headerText: 'TOP 3 LEFTOVER IN TERMS OF WEIGHT',
        slideType: SLIDE_TYPE.image,
      },
    ],
    dynamic: true,
    grouped: true,
  },
  { slideStyle: SLIDE_STYLE.plain, dynamic: false, headerText: 'SUMMARY' },
  { slideStyle: SLIDE_STYLE.section, dynamic: false, headerText: 'Next Steps' },
  // {
  //   slideStyle: SLIDE_STYLE.plain,
  //   arrSlideImageProp: [
  //     {
  //       headerText: 'TOP WASTED THROWS',
  //       slideType: SLIDE_TYPE.image,
  //     },
  //     {
  //       headerText: 'TOP 3 LEFTOVER IN TERMS OF WEIGHT',
  //       slideType: SLIDE_TYPE.image,
  //     },
  //   ],
  //   dynamic: true,
  // },
  { slideStyle: SLIDE_STYLE.closing, dynamic: false },
];

// dynamic property refers to the requirement to multiply slides according to services in that slide category
const ARR_SLIDE_ORDER_TEMPLATE = [
  { slideStyle: SLIDE_STYLE.opening, dynamic: false },
  { slideStyle: SLIDE_STYLE.section, dynamic: false, headerText: 'Food Waste Review' },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL FOOD WASTE IN TERMS OF WEIGHT (BY MONTHS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL LEFTOVER PER COVER (BY MONTHS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL FOOD WASTE IN TERMS OF WEIGHT (BY LAST 8 WEEKS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL FOOD WASTE IN TERMS OF COST (BY MONTHS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'TOTAL FOOD WASTE IN TERMS OF COST (BY LAST 8 WEEKS)',
    dynamic: false,
    slideType: SLIDE_TYPE.graph,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    arrSlideProp: [
      {
        headerText: 'LEFTOVER IN TERMS OF WEIGHT AND THROW (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        isFullWidth: true,
      },
      { headerText: 'LEFTOVER IN TERMS OF WEIGHT (BY MONTHS)', slideType: SLIDE_TYPE.graph },
      { headerText: 'LEFTOVER IN TERMS OF WEIGHT (BY LAST 8 WEEKS)', slideType: SLIDE_TYPE.graph },
      {
        headerText: 'LEFTOVER IN TERMS OF WEIGHT AND COVER (BY MONTHS)',
        slideType: SLIDE_TYPE.graph,
      },
      {
        headerText: 'LEFTOVER IN TERMS OF WEIGHT AND COVER (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        isFullWidth: true,
      },
      { headerText: 'LEFTOVER PER COVER (BY MONTHS)', slideType: SLIDE_TYPE.graph },
      { headerText: 'LEFTOVER PER COVER (BY LAST 8 WEEKS)', slideType: SLIDE_TYPE.graph },
      {
        headerText: 'LEFTOVER PER COVER (BY DAYS)',
        slideType: SLIDE_TYPE.graph,
        isFullWidth: true,
      },
      { headerText: 'LEFTOVER IN TERMS OF COST (BY MONTHS)', slideType: SLIDE_TYPE.graph },
      { headerText: 'LEFTOVER IN TERMS OF COST (BY LAST 8 WEEKS)', slideType: SLIDE_TYPE.graph },
    ],
    dynamic: true,
    grouped: true,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT (GROUPED)',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER PER COVER',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER PER COVER (GROUPED)',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    headerText: 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST (GROUPED)',
    dynamic: false,
    slideType: SLIDE_TYPE.table,
  },
  {
    slideStyle: SLIDE_STYLE.plain,
    arrSlideProp: [
      {
        headerText: 'TOP 3 LEFTOVER IN TERMS OF WEIGHT (BY STATIONS)',
        slideType: SLIDE_TYPE.station,
        weightCostType: WEIGHT_COST_TYPE.weight,
      },
      {
        headerText: 'TOP 6 LEFTOVER IN TERMS OF WEIGHT',
        slideType: SLIDE_TYPE.image,
        weightCostType: WEIGHT_COST_TYPE.weight,
      },
      {
        headerText: 'TOP 6 LEFTOVER IN TERMS OF WEIGHT',
        slideType: SLIDE_TYPE.table,
        weightCostType: WEIGHT_COST_TYPE.weight,
      },
      {
        headerText: 'TOP 3 LEFTOVER IN TERMS OF COST (BY STATIONS)',
        slideType: SLIDE_TYPE.station,
        weightCostType: WEIGHT_COST_TYPE.cost,
      },
      {
        headerText: 'TOP 6 LEFTOVER IN TERMS OF COST',
        slideType: SLIDE_TYPE.image,
        weightCostType: WEIGHT_COST_TYPE.cost,
      },
      {
        headerText: 'TOP 6 LEFTOVER IN TERMS OF COST',
        slideType: SLIDE_TYPE.table,
        weightCostType: WEIGHT_COST_TYPE.cost,
      },
    ],
    dynamic: true,
  },
  { slideStyle: SLIDE_STYLE.closing, dynamic: false },
];

// This map matches the header text to graph name prefix
const MAP_GRAPH_PREFIX_BY_HEADER_TEXT = new Map([
  [
    'TOTAL FOOD WASTE IN TERMS OF WEIGHT (BY MONTHS)',
    'BarGraph_Buffet_Leftovers_ByService_ByMonth_Weight',
  ],
  [
    'TOTAL LEFTOVER PER COVER (BY MONTHS)',
    'MixedGraph_Buffet_Leftovers_ByLocation_ByMonth_Cover_WeightPerCover',
  ],
  [
    'TOTAL FOOD WASTE IN TERMS OF WEIGHT (BY LAST 8 WEEKS)',
    'BarGraph_Buffet_Leftovers_ByService_ByWeek_Weight',
  ],
  [
    'TOTAL FOOD WASTE IN TERMS OF COST (BY MONTHS)',
    'BarGraph_Buffet_Leftovers_ByService_ByMonth_Cost',
  ],
  [
    'TOTAL FOOD WASTE IN TERMS OF COST (BY LAST 8 WEEKS)',
    'BarGraph_Buffet_Leftovers_ByService_ByWeek_Cost',
  ],
  ['LEFTOVER IN TERMS OF WEIGHT AND THROW (BY DAYS)', 'MixedGraph_Throw_Weight_ByDayOfFinalMonth'],
  ['LEFTOVER IN TERMS OF WEIGHT (BY LAST 8 WEEKS)', 'BarGraph_Buffet_Leftovers_Weight_ByWeek'],
  ['LEFTOVER IN TERMS OF WEIGHT (BY MONTHS)', 'BarGraph_Buffet_Leftovers_Weight_ByMonth'],
  ['LEFTOVER IN TERMS OF WEIGHT AND COVER (BY MONTHS)', 'MixedGraph_Cover_Weight_ByMonth'],
  ['LEFTOVER IN TERMS OF WEIGHT AND COVER (BY DAYS)', 'MixedGraph_Cover_Weight_ByDayOfFinalMonth'],
  ['LEFTOVER PER COVER (BY MONTHS)', 'MixedGraph_Cover_WeightPerCover_ByMonth'],
  ['LEFTOVER PER COVER (BY LAST 8 WEEKS)', 'MixedGraph_Cover_WeightPerCover_ByWeek'],
  ['LEFTOVER PER COVER (BY DAYS)', 'MixedGraph_Cover_WeightPerCover_ByDayOfFinalMonth'],
  ['LEFTOVER IN TERMS OF COST (BY MONTHS)', 'BarGraph_Buffet_Leftovers_Cost_ByMonth'],
  ['LEFTOVER IN TERMS OF COST (BY LAST 8 WEEKS)', 'BarGraph_Buffet_Leftovers_Cost_ByWeek'],
  ['TOP 6 LEFTOVER IN TERMS OF WEIGHT', 'Top6MenuItemWaste'],
  ['TOP 6 LEFTOVER IN TERMS OF COST', 'Top6MenuItemWaste'],
  [
    'TOP 3 LEFTOVER IN TERMS OF WEIGHT (BY STATIONS)',
    'BarGraph_Buffet_Leftovers_Weight_For_Top3Stations_For_FinalMonth',
  ],
  [
    'TOP 3 LEFTOVER IN TERMS OF COST (BY STATIONS)',
    'BarGraph_Buffet_Leftovers_Cost_For_Top3Stations_For_FinalMonth',
  ],
]);

const ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT = [
  ['Summary'],
  [
    '(a)\n\n',
    'Estimated Food Waste Without Intervention\n',
    'Baseline buffet leftovers per cover multiplied by actual covers served during the period',
  ],
  [
    '(b)\n\n',
    'Actual Food Waste\n',
    'Amount of food waste that is thrown into Lumitics Insight device during the period',
  ],
  [
    '(c)\n\n',
    'Reduction / Increase in Food Waste [KG and %]\n',
    "Difference between 'Forecasted Food Waste' and 'Actual Food Waste' expressed in kg and percentage",
  ],
  [
    '(d)\n\n',
    'Number of Meals Saved\n',
    '“Food Waste Reduction” divided by 0.25 kg (1 kg of food waste is equivalent to 1 meal portion of 0.25 kg)',
  ],
  [
    '(e)\n\n',
    'CO2 Emissions Equivalent Avoided\n',
    '“Food Waste Reduction” multiplied by 2.5 kg (1 kg of food waste contributes to 2.5 kg of CO2 emissions)',
  ],
];

const ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT = [
  ['Calculation'],
  ['baseline buffet leftover per cover x actual covers served'],
  ['-'],
  ['(a) - (b)\n', '[(a) - (b)] ÷ (a) x 100 %'],
  ['(c) ÷ 0.25'],
  ['(c) x 2.5'],
];

const ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT = [
  ['Summary'],
  [
    '(a)\n\n',
    'Baseline Leftover Per Cover\n',
    'Baseline amount of food waste that is thrown into Lumitics Insight device divided by number of covers served during baseline',
  ],
  [
    '(b)\n\n',
    'Actual Leftover Per Cover\n',
    'Actual amount of food waste that is thrown into Lumitics Insight device divided by number of covers served during the period',
  ],
  [
    '(c)\n\n',
    'Reduction / Increase in Leftover Per Cover\n',
    "Difference between 'Baseline Leftover Per Cover' and 'Actual Leftover Per Cover' expressed in percentage as compared to 'Baseline Leftover Per Cover'",
  ],
];

const ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT = [
  ['Calculation'],
  ['-'],
  ['-'],
  ['(a) - (b)\n', '[(a) - (b)] ÷ (a) x 100 %'],
];

const ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT = (currency: string) => [
  ['Summary'],
  [
    '(a)\n\n',
    'Estimated Food Waste Value Without Intervention\n',
    'Baseline food waste value per cover multiplied by actual covers served during the period',
  ],
  [
    '(b)\n\n',
    'Actual Food Waste Value\n',
    'Value of the food waste that is thrown into Lumitics Insight device during the period',
  ],
  [
    '(c)\n\n',
    `Food Waste Savings [${currency} and %]\n`,
    "Difference between 'Estimated Food Waste Value Without Intervention' and 'Actual Food Waste Value' expressed in dollar value and percentage",
  ],
];

const ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT = [
  ['Calculation'],
  ['-'],
  ['-'],
  ['(a) - (b)\n', '[(a) - (b)] ÷ (a) x 100 %'],
];

const ARR_SERVICE_ORDER = [
  'all day',
  'all day leftover',
  'all day dining',
  'all day dining leftover',
  'all day buffet',
  'all day buffet leftover',
  'breakfast',
  'breakfast buffet',
  'breakfast buffet leftover',
  'breakfast buffet leftovers',
  'breakfast banquet',
  'breakfast leftovers',
  'breakfast leftover',
  'food to charity',
  'breakfast food to charity',
  'food to donation',
  'f2c breakfast',
  'f2sc breakfast',
  'food to staff canteen',
  'rework breakfast',
  'am coffee break',
  'brunch',
  'brunch buffet',
  'brunch buffet leftover',
  'sunday brunch',
  'sunday brunch leftover',
  'sunday brunch buffet leftover',
  'lunch',
  'lunch buffet',
  'lunch buffet leftover',
  'lunch banquet',
  'lunch banquet leftover',
  'lunch and dinner',
  'f2c lunch',
  'f2sc lunch',
  'rework lunch',
  'afternoon tea',
  'afternoon tea leftover',
  'pm coffee break',
  'tea',
  'dinner',
  'dinner buffet',
  'dinner buffet leftover',
  'dinner banquet',
  'dinner banquet leftover',
  'Dinner food to charity',
  'f2c dinner',
  'f2sc dinner',
  'rework dinner',
  'supper',
  'production',
  'spoilt inventory',
  'delivery supply',
];

export {
  ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT,
  ARR_SERVICE_ORDER,
  ARR_SLIDE_ORDER_TEMPLATE,
  ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE,
  SLIDE_STYLE,
  SLIDE_TYPE,
  MAP_GRAPH_PREFIX_BY_HEADER_TEXT,
  WEIGHT_COST_TYPE,
};
