import { MdLogout } from 'react-icons/md';
import { Button } from '@material-tailwind/react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button color="teal" size="sm" onClick={() => logout({ returnTo: window.location.origin })}>
      <div className="flex">
        <MdLogout className="w-4 h-4 mr-1" />
        <span>Logout</span>
      </div>
    </Button>
  );
};

export default LogoutButton;
