import { MenuItem } from '../../interfaces';

const StationCard = (props: { arrMenuItem: any }) => {
  return (
    <div className="StationCard border-2 border-gray-200 rounded-lg text-gray-800 flex flex-col">
      <p className="text-lg font-bold sticky -top-5 bg-white rounded-t-lg w-full text-left pt-3 pb-6 p-2 pl-3 pr-3 border-b-0 z-10">
        {props.arrMenuItem[0].station}
      </p>
      <div className="p-3 pt-0 pb-1 text-sm">
        <table className="table-fixed font-normal w-full">
          <thead className="sticky top-9 z-30 bg-white">
            <tr>
              <th className="text-left w-6/12">Name</th>
              <th className="text-center w-3/12">Expiry Date</th>
              <th className="text-center w-3/12">Cost Per Kilogram</th>
            </tr>
          </thead>
          <tbody className="mt-1">
            {props.arrMenuItem.map((menuItem: MenuItem, index: number) => (
              <tr className="MenuItemRow border-t-2 h-10 border-gray-100" key={index}>
                <td className="text-left w-6/12">{menuItem.name}</td>
                <td className="text-center w-3/12">
                  {menuItem.expiryDate === null ? '-' : menuItem.expiryDate}
                </td>
                <td className="text-center w-3/12">{menuItem.costPerKilogram}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StationCard;
