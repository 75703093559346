import Fuse, { FuseResult } from 'fuse.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';

import CompanyContext from '../../context/CompanyContext';
import {
  CompanyLocationOption,
  CompanyService,
  RestaurantService,
  LocationService,
} from '../../interfaces';

function getOptionClass(index: number, length: number) {
  let className = 'hover:bg-gray-200 px-4 py-2';
  if (index === 0) {
    className += ' rounded-t-lg';
  } else if (index === length - 1) {
    className += ' rounded-b-lg';
  }
  return className;
}

const fuseOptions = {
  threshold: 0.4,
  keys: [
    {
      name: 'companyName',
      weight: 0.9,
    },
    {
      name: 'restaurantName',
      weight: 0.5,
    },
    {
      name: 'locationName',
      weight: 0.3,
    },
  ],
};

const getFilteredOptionsFromSearchResult = (
  arrSearchResult: Array<FuseResult<CompanyLocationOption>>
): Array<CompanyLocationOption> => {
  return arrSearchResult.map((searchResult) => searchResult.item);
};

/**
 * To get the selection options for companyLocation for the dropdown list suggestions
 * @param arrCompanyService - Array of all existing companyService
 * @returns arrCompanyLocationOption - Array of companyLocation Object containing the ids and names
 */
const getCompanyLocationOptions = (
  arrCompanyService: Array<CompanyService>
): CompanyLocationOption[] => {
  const arrCompanyLocationOption: CompanyLocationOption[] = [];
  arrCompanyService.forEach((companyService: CompanyService) => {
    const { companyId, name: companyName, isAirline, arrRestaurantService } = companyService;
    arrRestaurantService.forEach(
      (restaurantService: RestaurantService, restaurantIndex: number) => {
        const { restaurantId, name: restaurantName, arrLocationService } = restaurantService;
        arrLocationService.forEach((locationService: LocationService, locationIndex: number) => {
          const { locationId, name: locationName } = locationService;
          arrCompanyLocationOption.push({
            companyId,
            companyName,
            isAirline,
            restaurantId,
            restaurantName,
            restaurantIndex,
            locationId,
            locationName,
            locationIndex,
          });
        });
      }
    );
  });

  return arrCompanyLocationOption;
};

const Autocomplete = (props: { updateFunction: any }) => {
  const companyContext = useContext(CompanyContext);

  const [query, setQuery] = useState<string>('');
  const [isShowOption, setIsShowOption] = useState<boolean>(false);
  const [arrCompanyLocationOption, setArrCompanyLocationOption] = useState<CompanyLocationOption[]>(
    []
  );

  const ref: any = useRef();

  const searchFuse = new Fuse(arrCompanyLocationOption, fuseOptions);

  const arrFilteredOption: Array<CompanyLocationOption> =
    query === ''
      ? arrCompanyLocationOption
      : getFilteredOptionsFromSearchResult(searchFuse.search(query));

  /**
   * Update input when there is an input change. Display the suggestions based on input if the input is not empty.
   * @param event
   */
  const updateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedInputValue = event.target.value;
    const updatedIsShowOption = updatedInputValue !== '';

    setQuery(updatedInputValue);
    setIsShowOption(updatedIsShowOption);
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current.contains(event.target)) {
        setIsShowOption(false);
      }
    };
    document.addEventListener('click', listener);
    document.addEventListener('focusin', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('focusin', listener);
    };
  }, []);

  useEffect(() => {
    setArrCompanyLocationOption(getCompanyLocationOptions(companyContext.arrCompanyService));
  }, [companyContext.arrCompanyService]);

  // Note: If the input is not valid, there will not be a dropdown list. If the input is valid but no search results is found,
  // a 'No results found!' will appear
  return (
    <div
      ref={ref}
      className="flex flex-row relative h-fit items-center rounded-3xl px-2 gap-2 border-2 focus-within:border-regal-blue focus-within:shadow-md focus-within:shadow-blue-900/40 w-96"
    >
      <CiSearch size="18px" />
      <input
        className="block w-full outline outline-0 rounded-3xl py-1 font-sans font-normal text-lg transition:all focus:ring-0 focus:outline-0 cursor-pointer"
        placeholder="Search"
        onChange={updateInputChange}
      />
      {isShowOption && (
        <ul className="absolute bg-white mt-2 shadow-lg hover:shadow w-96 rounded-2xl z-50 overflow-auto max-h-80 top-8 -translate-x-2.5 drop-shadow-lg">
          {arrFilteredOption.length !== 0 ? (
            arrFilteredOption.map((option: CompanyLocationOption, index: number) => (
              <li
                className={getOptionClass(index, arrFilteredOption.length)}
                key={`${index} ${option.companyId}`}
                onClick={(event) => {
                  props.updateFunction(event, option);
                  setIsShowOption(false);
                }}
              >
                {`${option.companyName} / ${option.restaurantName} / ${option.locationName}`}
              </li>
            ))
          ) : (
            <li className="bg-white px-4 py-2 rounded-b-lg" key="NoResultsFound">
              No results found!
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
