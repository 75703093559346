import axios from 'axios';
import Pptxgen from 'pptxgenjs';
import { DateObject } from 'react-multi-date-picker';
import { User } from '@auth0/auth0-spa-js';

import {
  ARR_SLIDE_ORDER_TEMPLATE,
  ARR_SERVICE_ORDER,
  MAP_GRAPH_PREFIX_BY_HEADER_TEXT,
  SLIDE_STYLE,
  SLIDE_TYPE,
  WEIGHT_COST_TYPE,
} from '../../constant/LocationMonthlyReviewPowerpoint';
import {
  insertGraph,
  insertImage,
  insertServiceTop6MenuItemTable,
  insertStationGraph,
  insertStationImage,
  insertTable,
  initialiseSlide,
} from '../../functions/PowerpointFunctions';
import {
  ReductionInCostSummary,
  ReductionInWeightSummary,
  TopMenuItemSummaryWithImageDetail,
  TopMenuItemSummaryWithWasteDetail,
  Service,
  Top6StationMenuItemWithImageDetail,
  WastePerCoverSummary,
} from '../../interfaces';

const NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE = 12;
const NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE = 4;

/**
 * This function sorts arrSelectedService by serviceName by the order specified above in ARR_SERVICE_ORDER
 * @param arrService - Array of all selected Service objects belonging to the location
 * @returns - Array of sorted Service objects belonging to the location
 */
const sortArrSelectedService = (arrSelectedService: Array<any>): Array<any> => {
  arrSelectedService.sort((serviceA, serviceB) => {
    const serviceAIndex = ARR_SERVICE_ORDER.indexOf(serviceA.name.toLowerCase());
    const serviceBIndex = ARR_SERVICE_ORDER.indexOf(serviceB.name.toLowerCase());
    return (
      (serviceAIndex > -1 ? serviceAIndex : Infinity) -
      (serviceBIndex > -1 ? serviceBIndex : Infinity)
    );
  });
  return arrSelectedService;
};

/**
 * This function converts an array buffer to base64 encoded string
 * Adapted from https://stackoverflow.com/questions/62048929/parse-buffer-image-to-base64-on-react-native
 * @param arrayBuffer - array buffer to be coverted to base64 encoded string
 * @returns base64String - base64 encoded string to be
 */
const convertArrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
  let binary = '';
  let bytes = new Uint8Array(arrayBuffer);
  for (let byteIndex = 0; byteIndex < bytes.byteLength; byteIndex += 1) {
    binary += String.fromCharCode(bytes[byteIndex]);
  }
  const base64String = window.btoa(binary);
  return base64String;
};

/**
 * This function holds the logic required to extract the pptData provided by the backend and plots them within
 * a predetermined slide order template structure.
 * The strategy to match the graph & waste images to the correct slide is to store imageBase64 in a map read by pathName
 * as keys:
 * 1. For Location Graphs - Retrieve the pathName using another map constant MAP_GRAPH_PREFIX_BY_HEADER_TEXT to
 * programatically craft the pathName to get the associated image.
 * 2. For Waste Image - Retrieve the pathName by crafting it from the waste detail attributes
 * (e.g. Top6MenuItemWaste/Breakfast/Weight/1) Egg 1_23 KG/01 Oct 0_12 KG [12345].jpg)
 * @param fetchGraphRes - Response from backend containing information required to be plot into the powerpoint slides
 * @param formState - Report form header values used for powerpoint slide headers
 * @param user - User provided by Auth0 SDK to place the user's email address in the closing slide
 */
const downloadLocationMonthlyReviewPowerpoint = async (
  fetchGraphRes: any,
  formState: {
    companyField: string;
    restaurantField: string;
    locationField: string;
    arrSelectedService: Array<Service>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
  },
  user: User | undefined
) => {
  const { arrImageBase64, arrImageSignedUrl, locationService, pptData, fileName } = fetchGraphRes;
  const {
    currency,
    reductionInWeightSummaryForLocation,
    wastePerCoverSummaryForLocation,
    reductionInCostSummaryForLocation,
  } = pptData;
  const { restaurantField, dateRange, arrSelectedService } = formState;
  const mapServiceGroupByGroup = new Map();
  const mapGroupByServiceName = new Map();
  arrSelectedService.forEach((service) => {
    const { name, group, serviceType } = service;
    if (group) {
      const serviceGroup = mapServiceGroupByGroup.get(group);
      if (
        !serviceGroup ||
        (serviceType.type === 'Production' && serviceGroup.serviceType.type !== 'Production')
      ) {
        mapServiceGroupByGroup.set(group, { name: group, serviceType, isGroup: true });
      }
      mapGroupByServiceName.set(name, group);
    } else {
      mapServiceGroupByGroup.set(name, { name, serviceType, isGroup: false });
    }
  });
  const mapImageBase64ByPathName: Map<string, string> = new Map(
    arrImageBase64.map((imageData: { name: string; image: string }) => [
      imageData.name,
      imageData.image,
    ])
  );
  await Promise.all(
    arrImageSignedUrl.map(async (imageSignedUrl: { name: string; signedUrl: string }) => {
      const imageResponse = await axios.get(imageSignedUrl.signedUrl, {
        responseType: 'arraybuffer',
      });
      const image = convertArrayBufferToBase64(imageResponse.data);
      mapImageBase64ByPathName.set(imageSignedUrl.name, image);
    })
  );

  // Group service(s) for waste per cover summary
  const mapWastePerCoverSummaryByServiceGroupName = new Map<string, WastePerCoverSummary>();
  pptData.wastePerCoverSummaryForLocation.arrWastePerCoverSummaryForService.forEach(
    (wastePerCoverSummary: WastePerCoverSummary) => {
      const {
        serviceName,
        weightPerCoverForBaseline,
        weightPerCoverForFinalMonth,
        weightPerCoverForWholePeriod,
        weightPerCoverForPreviousMonth,
      } = wastePerCoverSummary;
      const group = mapGroupByServiceName.get(serviceName);
      if (group) {
        const existingWastePerCoverSummary = mapWastePerCoverSummaryByServiceGroupName.get(
          `group_${group}`
        );
        if (existingWastePerCoverSummary) {
          if (
            existingWastePerCoverSummary.weightPerCoverForBaseline === '-' ||
            weightPerCoverForBaseline === '-'
          ) {
            existingWastePerCoverSummary.weightPerCoverForBaseline = '-';
          } else {
            existingWastePerCoverSummary.weightPerCoverForBaseline +=
              Math.round(weightPerCoverForBaseline);
          }
          if (
            existingWastePerCoverSummary.weightPerCoverForFinalMonth === '-' ||
            weightPerCoverForFinalMonth === '-'
          ) {
            existingWastePerCoverSummary.weightPerCoverForFinalMonth = '-';
          } else {
            existingWastePerCoverSummary.weightPerCoverForFinalMonth += Math.round(
              weightPerCoverForFinalMonth
            );
          }
          if (
            existingWastePerCoverSummary.weightPerCoverForWholePeriod === '-' ||
            weightPerCoverForWholePeriod === '-'
          ) {
            existingWastePerCoverSummary.weightPerCoverForWholePeriod = '-';
          } else {
            existingWastePerCoverSummary.weightPerCoverForWholePeriod += Math.round(
              weightPerCoverForWholePeriod
            );
          }
          if (
            existingWastePerCoverSummary.weightPerCoverForPreviousMonth === '-' ||
            weightPerCoverForPreviousMonth === '-'
          ) {
            existingWastePerCoverSummary.weightPerCoverForPreviousMonth = '-';
          } else {
            existingWastePerCoverSummary.weightPerCoverForPreviousMonth += Math.round(
              weightPerCoverForPreviousMonth
            );
          }
        } else {
          mapWastePerCoverSummaryByServiceGroupName.set(`group_${group}`, {
            serviceName: group,
            weightPerCoverForBaseline:
              weightPerCoverForBaseline === '-' ? '-' : Math.round(weightPerCoverForBaseline),
            weightPerCoverForFinalMonth:
              weightPerCoverForFinalMonth === '-' ? '-' : Math.round(weightPerCoverForFinalMonth),
            weightPerCoverForWholePeriod:
              weightPerCoverForWholePeriod === '-' ? '-' : Math.round(weightPerCoverForWholePeriod),
            weightPerCoverForPreviousMonth:
              weightPerCoverForPreviousMonth === '-'
                ? '-'
                : Math.round(weightPerCoverForPreviousMonth),
          });
        }
      } else {
        mapWastePerCoverSummaryByServiceGroupName.set(
          `service_${serviceName}`,
          wastePerCoverSummary
        );
      }
    }
  );
  const wastePerCoverSummaryForLocationByGroupedService = {
    ...wastePerCoverSummaryForLocation,
    arrWastePerCoverSummaryForService: Array.from(
      mapWastePerCoverSummaryByServiceGroupName.values()
    ),
  };

  // Group service(s) for reduction in weight summary
  const mapReductionInWeightSummaryByServiceGroupName = new Map<string, ReductionInWeightSummary>();
  pptData.reductionInWeightSummaryForLocation.arrReductionInWeightSummaryForService.forEach(
    (reductionInWeightSummary: ReductionInWeightSummary) => {
      const {
        serviceName,
        totalWeightForFinalMonth,
        totalWeightWithoutReductionForFinalMonth,
        totalWeightForWholePeriod,
        totalWeightWithoutReductionForWholePeriod,
      } = reductionInWeightSummary;
      const group = mapGroupByServiceName.get(serviceName);
      if (group) {
        const existingReductionInWeightSummary = mapReductionInWeightSummaryByServiceGroupName.get(
          `group_${group}`
        );
        if (existingReductionInWeightSummary) {
          if (
            existingReductionInWeightSummary.totalWeightForFinalMonth === '-' ||
            totalWeightForFinalMonth === '-'
          ) {
            existingReductionInWeightSummary.totalWeightForFinalMonth = '-';
          } else {
            existingReductionInWeightSummary.totalWeightForFinalMonth +=
              Math.round(totalWeightForFinalMonth);
          }
          if (
            existingReductionInWeightSummary.totalWeightWithoutReductionForFinalMonth === '-' ||
            totalWeightWithoutReductionForFinalMonth === '-'
          ) {
            existingReductionInWeightSummary.totalWeightWithoutReductionForFinalMonth = '-';
          } else {
            existingReductionInWeightSummary.totalWeightWithoutReductionForFinalMonth += Math.round(
              totalWeightWithoutReductionForFinalMonth
            );
          }
          if (
            existingReductionInWeightSummary.totalWeightForWholePeriod === '-' ||
            totalWeightForWholePeriod === '-'
          ) {
            existingReductionInWeightSummary.totalWeightForWholePeriod = '-';
          } else {
            existingReductionInWeightSummary.totalWeightForWholePeriod +=
              Math.round(totalWeightForWholePeriod);
          }
          if (
            existingReductionInWeightSummary.totalWeightWithoutReductionForWholePeriod === '-' ||
            totalWeightWithoutReductionForWholePeriod === '-'
          ) {
            existingReductionInWeightSummary.totalWeightWithoutReductionForWholePeriod = '-';
          } else {
            existingReductionInWeightSummary.totalWeightWithoutReductionForWholePeriod +=
              Math.round(totalWeightWithoutReductionForWholePeriod);
          }
        } else {
          mapReductionInWeightSummaryByServiceGroupName.set(`group_${group}`, {
            serviceName: group,
            totalWeightForFinalMonth:
              totalWeightForFinalMonth === '-' ? '-' : Math.round(totalWeightForFinalMonth),
            totalWeightWithoutReductionForFinalMonth:
              totalWeightWithoutReductionForFinalMonth === '-'
                ? '-'
                : Math.round(totalWeightWithoutReductionForFinalMonth),
            totalWeightForWholePeriod:
              totalWeightForWholePeriod === '-' ? '-' : Math.round(totalWeightForWholePeriod),
            totalWeightWithoutReductionForWholePeriod:
              totalWeightWithoutReductionForWholePeriod === '-'
                ? '-'
                : Math.round(totalWeightWithoutReductionForWholePeriod),
          });
        }
      } else {
        mapReductionInWeightSummaryByServiceGroupName.set(
          `service_${serviceName}`,
          reductionInWeightSummary
        );
      }
    }
  );
  const reductionInWeightSummaryForLocationByGroupedService = {
    ...reductionInWeightSummaryForLocation,
    arrReductionInWeightSummaryForService: Array.from(
      mapReductionInWeightSummaryByServiceGroupName.values()
    ),
  };

  // Group service(s) for reduction in cost summary
  const mapReductionInCostSummaryByServiceGroupName = new Map<string, ReductionInCostSummary>();
  pptData.reductionInCostSummaryForLocation.arrReductionInCostSummaryForService.forEach(
    (reductionInCostSummary: ReductionInCostSummary) => {
      const {
        serviceName,
        totalCostForFinalMonth,
        totalCostWithoutReductionForFinalMonth,
        totalCostForWholePeriod,
        totalCostWithoutReductionForWholePeriod,
      } = reductionInCostSummary;
      const group = mapGroupByServiceName.get(serviceName);
      if (group) {
        const existingReductionInCostSummary = mapReductionInCostSummaryByServiceGroupName.get(
          `group_${group}`
        );
        if (existingReductionInCostSummary) {
          if (
            existingReductionInCostSummary.totalCostForFinalMonth === '-' ||
            totalCostForFinalMonth === '-'
          ) {
            existingReductionInCostSummary.totalCostForFinalMonth = '-';
          } else {
            existingReductionInCostSummary.totalCostForFinalMonth +=
              Math.round(totalCostForFinalMonth);
          }
          if (
            existingReductionInCostSummary.totalCostWithoutReductionForFinalMonth === '-' ||
            totalCostWithoutReductionForFinalMonth === '-'
          ) {
            existingReductionInCostSummary.totalCostWithoutReductionForFinalMonth = '-';
          } else {
            existingReductionInCostSummary.totalCostWithoutReductionForFinalMonth += Math.round(
              totalCostWithoutReductionForFinalMonth
            );
          }
          if (
            existingReductionInCostSummary.totalCostForWholePeriod === '-' ||
            totalCostForWholePeriod === '-'
          ) {
            existingReductionInCostSummary.totalCostForWholePeriod = '-';
          } else {
            existingReductionInCostSummary.totalCostForWholePeriod +=
              Math.round(totalCostForWholePeriod);
          }
          if (
            existingReductionInCostSummary.totalCostWithoutReductionForWholePeriod === '-' ||
            totalCostWithoutReductionForWholePeriod === '-'
          ) {
            existingReductionInCostSummary.totalCostWithoutReductionForWholePeriod = '-';
          } else {
            existingReductionInCostSummary.totalCostWithoutReductionForWholePeriod += Math.round(
              totalCostWithoutReductionForWholePeriod
            );
          }
        } else {
          mapReductionInCostSummaryByServiceGroupName.set(`group_${group}`, {
            serviceName: group,
            totalCostForFinalMonth:
              totalCostForFinalMonth === '-' ? '-' : Math.round(totalCostForFinalMonth),
            totalCostWithoutReductionForFinalMonth:
              totalCostWithoutReductionForFinalMonth === '-'
                ? '-'
                : Math.round(totalCostWithoutReductionForFinalMonth),
            totalCostForWholePeriod:
              totalCostForWholePeriod === '-' ? '-' : Math.round(totalCostForWholePeriod),
            totalCostWithoutReductionForWholePeriod:
              totalCostWithoutReductionForWholePeriod === '-'
                ? '-'
                : Math.round(totalCostWithoutReductionForWholePeriod),
          });
        }
      } else {
        mapReductionInCostSummaryByServiceGroupName.set(
          `service_${serviceName}`,
          reductionInCostSummary
        );
      }
    }
  );
  const reductionInCostSummaryForLocationByGroupedService = {
    ...reductionInCostSummaryForLocation,
    arrReductionInCostSummaryForService: Array.from(
      mapReductionInCostSummaryByServiceGroupName.values()
    ),
  };

  // This variable is only used for generating SUMMARY - TOTAL LEFTOVER PER COVER table to cater for the case where all service belong to the same group,
  // and therefore all total values should be the sum of the rounded off values of each service
  // Note: The actual definition of waste per cover should always be total waste over totla cover. Hence in future, if there is a requirement to have a combined
  // waste per cover value for all services where the services have different groups, all waste per cover related values should be calculated based on total waste
  // over total cover, including for overall value where all the services are from the same group.
  const isAllServiceFromSameGroup = mapServiceGroupByGroup.size === 1;
  // Powerpoint creation
  const pres = new Pptxgen();
  ARR_SLIDE_ORDER_TEMPLATE.forEach((slideTemplate) => {
    const { slideStyle, grouped } = slideTemplate;
    // Opening Slide
    if (slideStyle === SLIDE_STYLE.opening) {
      const openingText = `${JSON.parse(restaurantField).name}\n${
        locationService.name
      }\nMonthly Review (${dateRange[1].format('MMMM-YYYY')})`;
      initialiseSlide(pres, slideStyle, openingText);
      return;
    }
    // Section Slide
    else if (slideStyle === SLIDE_STYLE.section) {
      const { headerText } = slideTemplate;
      initialiseSlide(pres, slideStyle, headerText);
      return;
    }
    // Closing Slide
    else if (slideStyle === SLIDE_STYLE.closing) {
      initialiseSlide(pres, slideStyle, user?.email);
    }
    // Plain Slide
    else if (slideStyle === SLIDE_STYLE.plain) {
      const { dynamic } = slideTemplate;
      // For slides that do not require multiple service iterations
      if (!dynamic) {
        const { headerText, slideType } = slideTemplate;
        if (slideType === SLIDE_TYPE.graph) {
          let slide = initialiseSlide(pres, slideStyle, headerText);
          const graphName = `${MAP_GRAPH_PREFIX_BY_HEADER_TEXT.get(headerText!)!}.jpg`;
          insertGraph(slide, mapImageBase64ByPathName.get(graphName)!, headerText);
        } else if (slideType === SLIDE_TYPE.table) {
          if (headerText.includes('(GROUPED)')) {
            // If there is no grouping of services, there is no need to have the summary table for the grouped service
            // as it will be a repeat of the ungrouped table
            // Note: mapGroupByServiceName is used to check if there is any grouping in the services as it contains only services with group
            if (mapGroupByServiceName.size === 0) {
              return;
            }
            let slide = initialiseSlide(pres, slideStyle, headerText);
            // For grouped service(s)
            insertTable(
              pres,
              slide,
              headerText,
              {
                currency,
                reductionInWeightSummaryForLocation:
                  reductionInWeightSummaryForLocationByGroupedService,
                wastePerCoverSummaryForLocation: wastePerCoverSummaryForLocationByGroupedService,
                reductionInCostSummaryForLocation:
                  reductionInCostSummaryForLocationByGroupedService,
              },
              isAllServiceFromSameGroup
            );
          } else {
            let slide = initialiseSlide(pres, slideStyle, headerText);
            // For individual service(s)
            insertTable(
              pres,
              slide,
              headerText,
              {
                currency,
                reductionInWeightSummaryForLocation,
                wastePerCoverSummaryForLocation,
                reductionInCostSummaryForLocation,
              },
              isAllServiceFromSameGroup
            );
          }
        }
      } else if (grouped) {
        const { arrSlideProp } = slideTemplate;
        sortArrSelectedService(Array.from(mapServiceGroupByGroup.values())).forEach(
          (serviceGroup) => {
            const { name, serviceType, isGroup } = serviceGroup;
            arrSlideProp?.forEach(
              (slideProp: {
                headerText: string;
                slideType: string;
                weightCostType?: string;
                isFullWidth?: boolean;
              }) => {
                const { headerText, slideType, isFullWidth } = slideProp;
                // If service is production, do not generate leftover per cover slides
                if (
                  serviceType.type === 'Production' &&
                  (headerText === 'LEFTOVER PER COVER (BY MONTHS)' ||
                    headerText === 'LEFTOVER PER COVER (BY LAST 8 WEEKS)')
                ) {
                  return;
                }
                let slide = initialiseSlide(
                  pres,
                  slideStyle,
                  `${name.toUpperCase()} - ${headerText}`
                );
                if (slideType === SLIDE_TYPE.graph) {
                  // SLIDE TYPE: GRAPH
                  const graphName = `${MAP_GRAPH_PREFIX_BY_HEADER_TEXT.get(
                    headerText
                  )}_For_${name}.jpg`;
                  const wastePerCoverSummaryMapKey = isGroup ? `group_${name}` : `service_${name}`;
                  const wastePerCoverSummary = mapWastePerCoverSummaryByServiceGroupName.get(
                    wastePerCoverSummaryMapKey
                  )!;
                  insertGraph(
                    slide,
                    mapImageBase64ByPathName.get(graphName)!,
                    headerText,
                    isFullWidth,
                    wastePerCoverSummary
                  );
                }
              }
            );
          }
        );
      } else {
        const { arrSlideProp } = slideTemplate;
        // For slides that require multiple service iterations
        sortArrSelectedService(arrSelectedService).forEach((validService) => {
          const { name: serviceName, serviceType } = validService;
          arrSlideProp?.forEach(
            (slideProp: {
              headerText: string;
              slideType: string;
              weightCostType?: string;
              isFullWidth?: boolean;
            }) => {
              const { headerText, slideType } = slideProp;
              // If service is production, do not generate leftover per cover slides
              if (
                serviceType.type === 'Production' &&
                (headerText === 'LEFTOVER PER COVER (BY MONTHS)' ||
                  headerText === 'LEFTOVER PER COVER (BY LAST 8 WEEKS)')
              ) {
                return;
              }
              // If service is plate waste, do not generate station slides
              if (
                serviceType.type === 'Plate Waste' &&
                (headerText === 'TOP 3 LEFTOVER IN TERMS OF WEIGHT (BY STATIONS)' ||
                  headerText === 'TOP 3 LEFTOVER IN TERMS OF COST (BY STATIONS)')
              ) {
                return;
              }
              let slide = initialiseSlide(
                pres,
                slideStyle,
                `${serviceName.toUpperCase()} - ${headerText}`
              );
              // SLIDE TYPE: IMAGE
              if (slideType === SLIDE_TYPE.image) {
                const { weightCostType } = slideProp;
                const numberOfImagesToDisplay =
                  serviceType.type === 'Plate Waste'
                    ? NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE
                    : NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE;
                const arrTopMenuItemSummaryWithImageDetail: Array<TopMenuItemSummaryWithImageDetail> =
                  [];
                if (weightCostType === WEIGHT_COST_TYPE.weight) {
                  pptData.top6MenuItemByServiceName[serviceName].arrTop6MenuItemByWeight.forEach(
                    (
                      menuItemByWeight: TopMenuItemSummaryWithWasteDetail,
                      menuItemIndex: number
                    ) => {
                      const { menuItemName, weight, cost, numberOfThrows, arrWasteDetail } =
                        menuItemByWeight;
                      const topMenuItemSummaryWithImageDetail = {
                        menuItemName,
                        weight,
                        cost,
                        numberOfThrows,
                        currency,
                        // Only top 4 waste is displayed in a row for each menu item for non-plate waste service. Top 12 waste is displayed for plate waste service, occupying the whole slide.
                        arrImageDetail: arrWasteDetail
                          .slice(0, numberOfImagesToDisplay)
                          .map((waste) => {
                            const { thrownDate, value: wasteWeight, wasteId } = waste;
                            const image = mapImageBase64ByPathName.get(
                              `Top6MenuItemWaste/${serviceName}/Weight/${
                                menuItemIndex + 1
                              }) ${menuItemName} ${weight
                                .toFixed(2)
                                .replace('.', '_')} KG/${thrownDate} ${wasteWeight
                                .toFixed(2)
                                .replace('.', '_')} KG [${wasteId}].jpg`
                            )!;
                            return {
                              thrownDate,
                              weight: wasteWeight!,
                              image,
                            };
                          }),
                      };
                      arrTopMenuItemSummaryWithImageDetail.push(topMenuItemSummaryWithImageDetail);
                    }
                  );
                } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
                  pptData.top6MenuItemByServiceName[serviceName].arrTop6MenuItemByCost.forEach(
                    (menuItemByCost: TopMenuItemSummaryWithWasteDetail, menuItemIndex: number) => {
                      const { menuItemName, weight, cost, numberOfThrows, arrWasteDetail } =
                        menuItemByCost;
                      const topMenuItemSummaryWithImageDetail = {
                        menuItemName,
                        weight,
                        cost,
                        numberOfThrows,
                        currency,
                        // Only top 4 waste is displayed in a row for each menu item for non-plate waste service. Top 12 waste is displayed for plate waste service, occupying the whole slide.
                        arrImageDetail: arrWasteDetail
                          .slice(0, numberOfImagesToDisplay)
                          .map((waste) => {
                            const { thrownDate, value: wasteCost, wasteId } = waste;
                            const image = mapImageBase64ByPathName.get(
                              `Top6MenuItemWaste/${serviceName}/Cost/${
                                menuItemIndex + 1
                              }) ${menuItemName} ${currency} ${Math.round(cost).toLocaleString(
                                'en-us'
                              )}/${thrownDate} ${currency} ${Math.round(wasteCost).toLocaleString(
                                'en-us'
                              )} [${wasteId}].jpg`
                            )!;
                            return {
                              thrownDate,
                              cost: wasteCost!,
                              image,
                            };
                          }),
                      };
                      arrTopMenuItemSummaryWithImageDetail.push(topMenuItemSummaryWithImageDetail);
                    }
                  );
                }
                insertImage(
                  pres,
                  slide,
                  arrTopMenuItemSummaryWithImageDetail!,
                  `${serviceName.toUpperCase()} - ${headerText}`
                );
              }
              // SLIDE TYPE: STATION
              else if (slideType === SLIDE_TYPE.station) {
                const { weightCostType } = slideProp;
                const graphName = `${MAP_GRAPH_PREFIX_BY_HEADER_TEXT.get(
                  headerText
                )}_For_${serviceName}.jpg`;
                insertStationGraph(slide, mapImageBase64ByPathName.get(graphName)!);
                if (weightCostType === WEIGHT_COST_TYPE.weight) {
                  const arrMenuItemDetailByStation: Array<{
                    station: string;
                    weight: number;
                    arrMenuItemDetail: Array<{
                      menuItemName: string;
                      weight: number;
                      image: string;
                    }>;
                  }> = pptData.top6StationTop6MenuItemByServiceName[
                    serviceName
                  ].arrTop6StationsByWeight
                    // Only top 3 station is displayed
                    .slice(0, 3)
                    .map(
                      (
                        top6StationsByWeight: Top6StationMenuItemWithImageDetail,
                        stationIndex: number
                      ) => {
                        const {
                          station: stationName,
                          value: stationValue,
                          arrMenuItemWithImageDetail,
                        } = top6StationsByWeight;
                        return {
                          station: stationName,
                          weight: stationValue,
                          // Only top 2 waste per station is displayed in a row
                          arrMenuItemDetail: arrMenuItemWithImageDetail.slice(0, 2).map(
                            (
                              menuItem: {
                                menuItemName: string;
                                wasteId: number;
                                value: number;
                              },
                              menuItemIndex: number
                            ) => {
                              const { menuItemName, wasteId, value: menuItemValue } = menuItem;
                              const image = mapImageBase64ByPathName.get(
                                `Top6StationWaste/${serviceName}/Weight/${
                                  stationIndex + 1
                                }) ${stationName} ${stationValue.toFixed(2).replace('.', '_')} KG/${
                                  menuItemIndex + 1
                                }) ${menuItemName} ${menuItemValue
                                  .toFixed(2)
                                  .replace('.', '_')} KG [${wasteId}].jpg`
                              );
                              return {
                                menuItemName,
                                weight: menuItemValue,
                                image,
                              };
                            }
                          ),
                        };
                      }
                    );
                  insertStationImage(slide, arrMenuItemDetailByStation);
                } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
                  const arrMenuItemDetailByStation: Array<{
                    station: string;
                    cost: number;
                    currency: string;
                    arrMenuItemDetail: Array<{
                      menuItemName: string;
                      cost: number;
                      image: string;
                    }>;
                  }> = pptData.top6StationTop6MenuItemByServiceName[
                    serviceName
                  ].arrTop6StationsByCost
                    // Only top 3 station is displayed
                    .slice(0, 3)
                    .map(
                      (
                        top6StationsByCost: Top6StationMenuItemWithImageDetail,
                        stationIndex: number
                      ) => {
                        const {
                          station: stationName,
                          value: stationValue,
                          arrMenuItemWithImageDetail,
                        } = top6StationsByCost;
                        return {
                          station: stationName,
                          cost: stationValue,
                          currency: currency,
                          // Only top 2 waste per station is displayed in a row
                          arrMenuItemDetail: arrMenuItemWithImageDetail.slice(0, 2).map(
                            (
                              menuItem: {
                                menuItemName: string;
                                wasteId: number;
                                value: number;
                              },
                              menuItemIndex: number
                            ) => {
                              const { menuItemName, wasteId, value: menuItemValue } = menuItem;
                              const image = mapImageBase64ByPathName.get(
                                `Top6StationWaste/${serviceName}/Cost/${
                                  stationIndex + 1
                                }) ${stationName} ${currency} ${Math.round(
                                  stationValue
                                ).toLocaleString('en-us')}/${
                                  menuItemIndex + 1
                                }) ${menuItemName} ${currency} ${Math.round(
                                  menuItemValue
                                ).toLocaleString('en-us')} [${wasteId}].jpg`
                              );
                              return {
                                menuItemName,
                                cost: menuItemValue,
                                image,
                              };
                            }
                          ),
                        };
                      }
                    );
                  insertStationImage(slide, arrMenuItemDetailByStation);
                }
              }
              // SLIDE TYPE: TABLE
              else if (slideType === SLIDE_TYPE.table) {
                const { weightCostType } = slideProp;
                if (weightCostType === WEIGHT_COST_TYPE.weight) {
                  insertServiceTop6MenuItemTable(
                    slide,
                    'Top 6 High Volume Items',
                    currency,
                    pptData.top6MenuItemByServiceName[serviceName].arrTop6MenuItemByWeight,
                    pptData.top6MenuItemByServiceName[serviceName]
                      .arrTop6MenuItemByWeightForPreviousMonth,
                    serviceType.type === 'Production'
                  );
                } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
                  insertServiceTop6MenuItemTable(
                    slide,
                    'Top 6 High Value Items',
                    currency,
                    pptData.top6MenuItemByServiceName[serviceName].arrTop6MenuItemByCost,
                    pptData.top6MenuItemByServiceName[serviceName]
                      .arrTop6MenuItemByCostForPreviousMonth,
                    serviceType.type === 'Production'
                  );
                }
              }
            }
          );
        });
      }
    }
  });
  await pres.writeFile({ fileName });
};

export default downloadLocationMonthlyReviewPowerpoint;
