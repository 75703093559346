import LocationInputCard from './LocationInputCard';
import ServiceInputCardsContainer from './ServiceInputCardsContainer';
import { LocationService, Location, Service } from '../../../interfaces';

const LocationServiceInputCard = (props: {
  arrEditedService: Array<Service>;
  arrLocationService: Array<LocationService>;
  arrNewLocation: Array<Location>;
  arrNewService: Array<Service>;
  companyId: number;
  locationId: number;
  locationService: LocationService | undefined;
  restaurantId: number;
  updateEditedOrNewLocation: any;
  updateMapEditedServiceByServiceId: any;
  updateMapForNewService: any;
}) => {
  return (
    <li className="LocationServiceInputCard flex flex-col mt-2 bg-gray-200 py-4 rounded-lg gap-y-1">
      <LocationInputCard
        key={props.locationId}
        arrLocationService={props.arrLocationService}
        arrNewLocation={props.arrNewLocation}
        companyId={props.companyId}
        locationId={props.locationId}
        locationService={props.locationService}
        restaurantId={props.restaurantId}
        updateEditedOrNewLocation={props.updateEditedOrNewLocation}
      />
      <ServiceInputCardsContainer
        companyId={props.companyId}
        restaurantId={props.restaurantId}
        locationId={props.locationId}
        arrService={props.locationService !== undefined ? props.locationService.arrService : []}
        arrEditedService={props.arrEditedService}
        arrNewService={props.arrNewService}
        updateMapEditedServiceByServiceId={props.updateMapEditedServiceByServiceId}
        updateMapForNewService={props.updateMapForNewService}
      />
    </li>
  );
};

export default LocationServiceInputCard;
