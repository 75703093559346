/**
 * This function checks if the name (company, restaurant, location, service, menu) passes requirement for
 * allowed characters and format. Name can only contain alphanumeric characters, and the substrings of
 * alphanumeric characters can only be separated by single space. It cannot start with a number, space or
 * underscore, and cannot end with a space or underscore.
 * @param name - Takes in input name and checks if naming convention matches with standards
 * @returns boolean - Returns true if input passes alphanumeric test, returns false if input fails
 */
const checkIsNameValidWithRegEx = (name: string): boolean => {
  const regEx = /^(?=.*[a-z])[a-z0-9]+(?:(_| )[a-z0-9]+)*$/i;
  return regEx.test(name);
};

/**
 * This function checks if the input tag passes requirement for allowed characters and format. Input tag can
 * only contain alphanumeric characters, and the substrings of alphanumeric characters can only be separated
 * by single space. It cannot start and end with a space or underscore.
 * @param inputTag - Takes in input inputTag and checks if naming convention matches with standards
 * @returns boolean - Returns true if input passes alphanumeric test, returns false if input fails
 */
const checkIsInputTagValidWithRegEx = (inputTag: string): boolean => {
  const regEx = /^([a-z0-9]+)(?:(_| )[a-z0-9]+)*$/i;
  return regEx.test(inputTag);
};

/**
 * This function checks if the user name to be added passes requirement for allowed characters and format.
 * Name can only be alphanumeric and the substrings of alphanumeric characters can only be separated by
 * single space. Spaces at the start or end of the string are not alllowed.
 * @param name - Takes in input name and checks if naming convention matches with standards
 * @returns boolean - Returns true if input passes alphanumeric test, returns false if input fails
 */
const checkIsUserNameValidWithRegEx = (name: string): boolean => {
  return /^[a-zA-Z]+((\s)[a-zA-Z]+)*$/.test(name);
};

/**
 * This function checks if the station to be added passes requirement for allowed characters and format.
 * Station can only be alphanumeric and the substrings of alphanumeric characters can only be separated by
 * single space. Spaces at the start or end of the string are not allowed. Every substring of alphanumeric
 * characters separated by the space must be proper capitalized. String cannot start with a number.
 * @param station - Takes in input station and checks if naming convention matches with standards
 * @returns boolean - Returns true if input passes alphanumeric test, returns false if input fails
 */
const checkIsStationValidWithRegEx = (station: string): boolean => {
  return /^[A-Z][a-zA-Z0-9]*((\s)[A-Z0-9][a-zA-Z0-9]*)*$/.test(station);
};

/**
 * This function checks if the menuItemName to be added passes requirement for allowed characters and format.
 * MenuItemName can contain alphanumeric characters, forward slash, apostrophe and parenthesis, and also single
 * space or hyphen in between the characters. Space and hyphen are not allowed at the start and the end of the
 * string. Every substring (separated by space or hyphen) must be proper capitalized. String cannot start with
 * a number.
 *
 * Note:
 * - The alphabet (if any) that is after a open parenthesis that followed a space/hyphen must be uppercased
 * i.e. Meat (Grilled) is accepted, but Meat (grilled) is not accepted
 * - There is no strict checking of the usage of forward slash, apostrophe and parenthesis at the moment
 * i.e. Meat((') is accepted
 * @param menuItemName - Takes in input station and checks if naming convention matches with standards
 * @returns boolean - Returns true if input passes alphanumeric test, returns false if input fails
 */
const checkIfMenuItemNameValidWithRegEx = (menuItemName: string): boolean => {
  return /^[A-Z|(][a-zA-Z0-9|()|'|/]*((\s|-)([A-Z0-9]|([(][A-Z0-9]))[a-zA-Z0-9|()|'|/]*)*$/.test(
    menuItemName
  );
};

/**
 * This function checks if the email to be added is a unique email and if it is a valid email
 * @param email - Takes in input name and checks if naming convention matches with standards
 * @param arrUser - Takes in arrUser to check email against to find duplicates
 * @returns isEmailValid - Returns true if email is unique, false if there are duplicates
 */
const isEmailValidCheck = (email: string, arrEmail: Array<string>): boolean => {
  const regexExp = /^\S+@\S+\.\S+$/;
  if (regexExp.test(email) === true) {
    for (let i = 0; i < arrEmail.length; i++) {
      let currentEmail = arrEmail[i];
      if (currentEmail === email) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export {
  checkIsInputTagValidWithRegEx,
  checkIfMenuItemNameValidWithRegEx,
  checkIsNameValidWithRegEx,
  checkIsStationValidWithRegEx,
  checkIsUserNameValidWithRegEx,
  isEmailValidCheck,
};
