import { useEffect, useState, useRef } from 'react';
import { Input } from '@material-tailwind/react';

const InputWithSearchFunction = (props: {
  className?: string;
  arrAllOption: any;
  label: string;
  value: string;
  errorMessage: string;
  onChange: (updatedValue: string) => void;
  onBlur: () => void;
}) => {
  const { className, label, value, errorMessage, arrAllOption, onChange, onBlur } = props;
  const [arrFilteredOption, setArrFilteredOption] = useState(arrAllOption);
  const [showOption, setShowOption] = useState(false);
  const ref: any = useRef();

  /**
   * Function attached to input component, takes in search input data for menu, currency, offset or type, and updated updatedService state in the ServiceInputCard.tsx.
   * Filter the arrAllOption by the search input (case insensitive).
   * Note: Currently, for menu, valid input can be manually typed or selected from the dropdown list. But for currency, offset and type, valid input must be selected
   * from the dropdown list.
   * @param event - contains input data from input html
   */
  const updateFilteredOption = (manualInput: string) => {
    const newManualInputInLowerCase = manualInput.toLowerCase();
    const arrNewFilteredOption = arrAllOption.filter((option: any) =>
      option.toLowerCase().includes(newManualInputInLowerCase)
    );

    setArrFilteredOption(arrNewFilteredOption);
    if (!showOption) {
      setShowOption(true);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current.contains(event.target)) {
        setShowOption(false);
      }
    };
    document.addEventListener('click', listener);
    document.addEventListener('focusin', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('focusin', listener);
    };
  }, []);

  return (
    <div className={`${className}`} ref={ref}>
      <Input
        error={!!errorMessage}
        label={label}
        value={value}
        onFocus={() => setShowOption(true)}
        onChange={(event) => {
          onChange(event.target.value);
          updateFilteredOption(event.target.value);
        }}
        onBlur={onBlur}
        onKeyDown={(event) => {
          if (event.code === 'Tab' && arrFilteredOption.length > 0) {
            onChange(arrFilteredOption[0]);
          }
        }}
      />
      {showOption && (
        <ul className="absolute bg-gray-100 shadow-lg hover:shadow w-full rounded-xl z-50 overflow-auto max-h-40">
          {arrFilteredOption.map((option: any, index: number) => (
            <li
              className="hover:bg-gray-200 px-4 py-2"
              key={`${index} ${option.name}`}
              onMouseDown={() => onChange(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default InputWithSearchFunction;
