import { useContext, useState } from 'react';
import { Input } from '@material-tailwind/react';

import CompanyContext from '../../../context/CompanyContext';
import InputCardsContext from '../../../context/InputCardsContext';
import {
  checkForInvalidNameInput,
  checkForInvalidSlackChannelIdInput,
} from '../../../functions/InputChecks';
import { CompanyService, RestaurantService, Restaurant } from '../../../interfaces';

const RestaurantInputCard = (props: {
  arrNewRestaurant: Array<Restaurant>;
  companyId: number;
  restaurantId: number;
  restaurantService: RestaurantService | undefined;
  updateRestaurant: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const companyContext = useContext(CompanyContext);
  // This array of restaurant slack channel id is used for input validation where slack channel id cannot
  // be duplicated across any two restaurants
  const arrRestaurantSlackChannelId = companyContext.arrCompanyService.reduce(
    (
      arrRestaurantAccumulator: Array<{ restaurantId: number; slackChannelId: string }>,
      companyService: CompanyService
    ) => {
      companyService.arrRestaurantService.forEach((restaurantService) => {
        const { slackChannelId, restaurantId } = restaurantService;
        if (slackChannelId !== null)
          arrRestaurantAccumulator.push({ restaurantId, slackChannelId });
      });
      return arrRestaurantAccumulator;
    },
    []
  );

  // If RestaurantInputCard is for editing existing restaurant, isNameValid and isSlackChannelIdValid should be true
  const isEdit = !!props.restaurantService;
  const [isNameValid, setIsNameValid] = useState(isEdit);
  const [isSlackChannelIdValid, setIsSlackChannelIdValid] = useState(true);
  const [nameErrorMessage, setNameErrorMessage] = useState(isEdit ? '' : 'Field cannot be empty!');
  const [slackChannelIdErrorMessage, setSlackChannelIdErrorMessage] = useState('');

  const [restaurantForInput, setRestaurantForInput] = useState<Restaurant>(
    isEdit && props.restaurantService !== undefined
      ? {
          restaurantId: props.restaurantService.restaurantId,
          name: '',
          companyId: props.restaurantService.companyId,
          isValid: props.restaurantService.isValid,
          slackChannelId: props.restaurantService.slackChannelId,
        }
      : {
          restaurantId: props.restaurantId,
          name: '',
          companyId: props.companyId,
          isValid: true,
          slackChannelId: null,
        }
  );

  /**
   * Function attached to input component, takes in input data for editing this restaurant's name and updates newRestaurantService state.
   * @param event - contains input data from html input element
   */
  const updateRestaurantNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRestaurantForInput = {
      ...restaurantForInput,
      name: event.target.value,
    };
    setRestaurantForInput(newRestaurantForInput);
  };

  /**
   * Function attached to input component, takes in input data for editing this restaurant's slack channel id and updates newRestaurantService state.
   * @param event - contains input data from html input element
   */
  const updateRestaurantSlackChannelIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRestaurantForInput = {
      ...restaurantForInput,
      slackChannelId: event.target.value === '' ? null : event.target.value,
    };
    setRestaurantForInput(newRestaurantForInput);
  };

  /**
   * Function runs whenever onBlur event on restaurant name Input component occurs. Validate the restaurant name
   * - Check for alphanumeric
   * - Cannot duplicate with any new or existing restaurant name within a company
   * Note: If it is for editing, empty string ('') is considered a valid input as it will be treated as no change to the
   * current name. Hence there is slight difference in the handling of input between edit and add.
   */
  const restaurantNameInputOnBlur = () => {
    if (isEdit) {
      if (
        restaurantForInput.name.length === 0 ||
        restaurantForInput.name === props.restaurantService!.name
      ) {
        const newRestaurantForInput = { ...restaurantForInput, name: '' };
        setIsNameValid(true);
        setRestaurantForInput(newRestaurantForInput);
        if (isSlackChannelIdValid)
          inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
        if (restaurantForInput.slackChannelId === props.restaurantService?.slackChannelId) {
          props.updateRestaurant({ restaurantId: restaurantForInput.restaurantId });
        } else {
          props.updateRestaurant(
            { restaurantId: restaurantForInput.restaurantId },
            restaurantForInput
          );
        }
      } else {
        const arrRestaurantService = companyContext.arrCompanyService.find(
          (companyService: CompanyService) => companyService.companyId === props.companyId
        )?.arrRestaurantService;
        const errorMessage = checkForInvalidNameInput(
          restaurantForInput.name,
          restaurantForInput.restaurantId,
          arrRestaurantService,
          [],
          'Property'
        );
        if (errorMessage.length !== 0) {
          setIsNameValid(false);
          setNameErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(restaurantForInput.restaurantId, true, 2);
        } else {
          setIsNameValid(true);
          if (isSlackChannelIdValid)
            inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
        }
        props.updateRestaurant(
          { restauarntId: restaurantForInput.restaurantId },
          restaurantForInput
        );
      }
    } else {
      props.updateRestaurant(
        { restaurantId: props.restaurantId, companyId: props.companyId },
        restaurantForInput
      );
      if (restaurantForInput.name.length === 0) {
        setIsNameValid(false);
        setNameErrorMessage('Field cannot be empty!');
        inputCardsContext.setErrorExist(props.restaurantId, true, 2);
      } else {
        const arrRestaurantService = companyContext.arrCompanyService.find(
          (companyService: CompanyService) => companyService.companyId === props.companyId
        )?.arrRestaurantService;
        const errorMessage = checkForInvalidNameInput(
          restaurantForInput.name,
          props.restaurantId,
          arrRestaurantService || [],
          props.arrNewRestaurant,
          'Property'
        );
        if (errorMessage.length !== 0) {
          setIsNameValid(false);
          setNameErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(restaurantForInput.restaurantId, true, 2);
        } else {
          setIsNameValid(true);
          if (isSlackChannelIdValid)
            inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
        }
      }
    }
  };

  /**
   * Function runs whenever onBlur event on restaurant slack channel id Input component occurs. Validate the slack channel id
   * - Check for length (only empty string or 11 characters)
   * - Cannot duplicate with any new or existing slack channel id
   */
  const restaurantSlackChannelIdInputOnBlur = () => {
    if (isEdit) {
      if (restaurantForInput.slackChannelId === props.restaurantService?.slackChannelId) {
        setIsSlackChannelIdValid(true);
        if (isNameValid) inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
        if (
          restaurantForInput.name.length === 0 ||
          restaurantForInput.name === props.restaurantService!.name
        )
          props.updateRestaurant({ restaurantId: restaurantForInput.restaurantId });
      } else {
        const errorMessage =
          restaurantForInput.slackChannelId === null
            ? ''
            : checkForInvalidSlackChannelIdInput(
                restaurantForInput.slackChannelId,
                restaurantForInput.restaurantId,
                arrRestaurantSlackChannelId
              );
        if (errorMessage.length !== 0) {
          setIsSlackChannelIdValid(false);
          setSlackChannelIdErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(restaurantForInput.restaurantId, true, 2);
        } else {
          setIsSlackChannelIdValid(true);
          if (isNameValid)
            inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
        }
        props.updateRestaurant(
          { restaurantId: restaurantForInput.restaurantId },
          {
            ...restaurantForInput,
            name:
              restaurantForInput.name === ''
                ? props.restaurantService!.name
                : restaurantForInput.name,
          }
        );
      }
    } else {
      props.updateRestaurant(
        { restaurantId: props.restaurantId, companyId: props.companyId },
        restaurantForInput
      );
      const arrToCheck = [...arrRestaurantSlackChannelId, ...props.arrNewRestaurant];
      const errorMessage =
        restaurantForInput.slackChannelId === null
          ? ''
          : checkForInvalidSlackChannelIdInput(
              restaurantForInput.slackChannelId,
              props.restaurantId,
              arrToCheck
            );
      if (errorMessage.length !== 0) {
        setIsSlackChannelIdValid(false);
        setSlackChannelIdErrorMessage(errorMessage);
        inputCardsContext.setErrorExist(restaurantForInput.restaurantId, true, 2);
      } else {
        setIsSlackChannelIdValid(true);
        if (isNameValid) inputCardsContext.deleteErrorForOneId(restaurantForInput.restaurantId, 2);
      }
    }
  };

  return (
    <div className="flex justify-between py-2 gap-x-8">
      <div className="flex flex-col gap-x-1 mt-2">
        <Input
          onBlur={restaurantNameInputOnBlur}
          size="md"
          onChange={updateRestaurantNameOnChange}
          label="Property"
          variant="static"
          value={restaurantForInput.name}
          placeholder={props.restaurantService !== undefined ? props.restaurantService.name : ''}
          className={`PropertyNameInput text-off-black`}
          error={!isNameValid}
        />
        {!isNameValid && (
          <p className="PropertyNameErrorMessage text-red-500 text-xs mt-2">{nameErrorMessage}</p>
        )}
      </div>
      <div className="flex flex-col gap-x-1 mt-2">
        <Input
          onBlur={restaurantSlackChannelIdInputOnBlur}
          size="md"
          onChange={updateRestaurantSlackChannelIdOnChange}
          label="Slack Channel Id"
          variant="static"
          value={
            restaurantForInput.slackChannelId === null ? '' : restaurantForInput.slackChannelId
          }
          className={`PropertySlackChannelIdInput text-off-black`}
          error={!isSlackChannelIdValid}
        />
        {!isSlackChannelIdValid && (
          <p className="PropertySlackChannelIdErrorMessage text-red-500 text-xs mt-2">
            {slackChannelIdErrorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default RestaurantInputCard;
