import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdCreate, MdRemove } from 'react-icons/md';
import { Waypoint } from 'react-waypoint';
import { Button } from '@material-tailwind/react';

import CompanyServiceInputCard from './CompanyServiceInputCard';
import Alert from '../alerts/Alert';
import { COMPANY_SERVICE_INPUT_MODE } from '../../../constant/General';
import CompanyContext from '../../../context/CompanyContext';
import InputCardsContext from '../../../context/InputCardsContext';
import {
  Company,
  Location,
  NewCompanyService,
  NewLocationService,
  NewRestaurantService,
  NewService,
  Restaurant,
  Service,
} from '../../../interfaces';

/**
 * To get an array of new companyService to when the fake IDs are removed
 * @param mapNewCompanyByCompanyId - Map of key: companyId and value: newCompany
 * @param mapMapNewRestaurantByRestaurantIdByCompanyId - Map of key: companyId and value: Map of key: restaurantId and value: newRestaurant
 * @param mapMapNewLocationByLocationIdByRestaurantId - Map of key: restaurantId and value: Map of key: locationId and value: newLocation
 * @param mapMapNewServiceByServiceIdByLocationId - Map of key: locationId and value: Map of key: serviceId and value: newService
 * @returns arrNewCompanyService - Array of newCompanyService with the fake IDs removed
 */
const getArrNewCompanyService = (
  mapNewCompanyByCompanyId: Map<number, Company>,
  mapMapNewRestaurantByRestaurantIdByCompanyId: Map<number, Map<number, Restaurant>>,
  mapMapNewLocationByLocationIdByRestaurantId: Map<number, Map<number, Location>>,
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>
): Array<NewCompanyService> => {
  const arrNewCompanyService: Array<NewCompanyService> = [];
  mapNewCompanyByCompanyId.forEach((newCompany: Company, companyId: number) => {
    const mapNewRestaurantByRestaurantId =
      mapMapNewRestaurantByRestaurantIdByCompanyId.get(companyId);
    const arrNewRestaurantService: Array<NewRestaurantService> = [];
    mapNewRestaurantByRestaurantId?.forEach((newRestaurant: Restaurant, restaurantId: number) => {
      const mapNewLocationByLocationId =
        mapMapNewLocationByLocationIdByRestaurantId.get(restaurantId);
      const arrNewLocationService: Array<NewLocationService> = [];
      mapNewLocationByLocationId?.forEach((newLocation: Location, locationId: number) => {
        const mapNewServiceByServiceId = mapMapNewServiceByServiceIdByLocationId.get(locationId);
        const arrNewService: Array<NewService> = [];
        mapNewServiceByServiceId?.forEach((newService: Service, serviceId: number) => {
          const newServiceWithoutfakeIds: NewService = { ...newService };
          newServiceWithoutfakeIds.serviceId = undefined;
          newServiceWithoutfakeIds.locationId = undefined;
          newServiceWithoutfakeIds.menuId = undefined;
          newServiceWithoutfakeIds.menu.menuId = undefined;
          arrNewService.push(newServiceWithoutfakeIds);
        });
        const newLocationService: NewLocationService = {
          ...newLocation,
          locationId: undefined,
          restaurantId: undefined,
          arrService: arrNewService,
        };
        arrNewLocationService.push(newLocationService);
      });
      const newRestaurantService: NewRestaurantService = {
        ...newRestaurant,
        restaurantId: undefined,
        arrLocationService: arrNewLocationService,
      };
      arrNewRestaurantService.push(newRestaurantService);
    });
    const newCompanyService: NewCompanyService = {
      ...newCompany,
      companyId: undefined,
      arrRestaurantService: arrNewRestaurantService,
    };
    arrNewCompanyService.push(newCompanyService);
  });
  return arrNewCompanyService;
};

// Note: This component is used for AddCompanyService only
const CompanyInputCardsContainer = () => {
  const inputCardsContext = useContext(InputCardsContext);
  const companyContext = useContext(CompanyContext);
  const MAX_COMPANY = 9;
  /**
   * States for this functional component
   */
  const [arrCompanyId, setArrCompanyId] = useState<Array<number>>([]); // Array stores key values of all LocationCreateCard component, for creation of new InputCards
  const [mapNewCompanyByCompanyId, setMapNewCompanyByCompanyId] = useState(new Map());
  const [
    mapMapNewRestaurantByRestaurantIdByCompanyId,
    setMapMapNewRestaurantByRestaurantIdByCompanyId,
  ] = useState(new Map());
  const [
    mapMapNewLocationByLocationIdByRestaurantId,
    setMapMapNewLocationByLocationIdByRestaurantId,
  ] = useState(new Map());
  const [mapMapNewServiceByServiceIdByLocationId, setMapMapNewServiceByServiceIdByLocationId] =
    useState(new Map());

  const [isAlert, setIsAlert] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>(['']);
  const [alertHeader, setAlertHeader] = useState<string>('');
  const [isButtonFloating, setIsButtonFloating] = useState<boolean>(false);

  /**
   * Function can add the new company to map, with the companyId as the key, or remove the key-value if the company is to be removed.
   * If the company is to be removed, remove all the new restaurant(s), location(s) and service(s) that belongs to this company.
   * @param companyId - companyId of the company
   * @param newCompany - New company object, or undefined if the company is to be removed
   */
  const updateMapNewCompanyByCompanyId = (companyId: number, newCompany?: Company) => {
    const updatedMapNewCompanyByCompanyId = new Map(mapNewCompanyByCompanyId);
    if (newCompany) {
      updatedMapNewCompanyByCompanyId.set(companyId, newCompany);
    } else {
      updatedMapNewCompanyByCompanyId.delete(companyId);

      const updatedMapMapNewRetaurantByRestaurantIdByCompanyId = new Map(
        mapMapNewRestaurantByRestaurantIdByCompanyId
      );
      updatedMapMapNewRetaurantByRestaurantIdByCompanyId.delete(companyId);

      const updatedMapMapNewLocationByLocationIdByRestaurantId = new Map(
        mapMapNewLocationByLocationIdByRestaurantId
      );
      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      const lowerLimitOfRange = companyId - 999;
      const upperLimitOfRange = companyId;
      updatedMapMapNewLocationByLocationIdByRestaurantId.forEach(
        (mapNewLocationByLocation, restaurantId) => {
          if (restaurantId >= lowerLimitOfRange && restaurantId <= upperLimitOfRange) {
            updatedMapMapNewLocationByLocationIdByRestaurantId.delete(restaurantId);
          }
        }
      );
      updatedMapMapNewServiceByServiceIdByLocationId.forEach(
        (mapNewServiceByService, locationId) => {
          if (locationId >= lowerLimitOfRange && locationId <= upperLimitOfRange) {
            updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
          }
        }
      );
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
      setMapMapNewLocationByLocationIdByRestaurantId(
        updatedMapMapNewLocationByLocationIdByRestaurantId
      );
      setMapMapNewRestaurantByRestaurantIdByCompanyId(
        updatedMapMapNewRetaurantByRestaurantIdByCompanyId
      );
    }
    setMapNewCompanyByCompanyId(updatedMapNewCompanyByCompanyId);
  };

  /**
   * Function can add the new restaurant to map, with the companyId as the first key and restaurantId as the second key, or remove the key-value
   * if the restaurant is to be removed.
   * If the restaurant is to be removed, remove all location(s) and service(s) that belongs to this restaurant.
   * @param idParameters - Object containing the restaurantId and of the restaurant
   * @param newRestaurant - New restaurant object, or undefined if the restaurant is to be removed
   */
  const updateMapMapNewRestaurantByRestaurantIdByCompanyId = (
    idParameters: { restaurantId: number; companyId: number },
    newRestaurant?: Restaurant
  ) => {
    const { restaurantId, companyId } = idParameters;
    const updatedMapMapNewRestaurantByRestaurantIdByCompanyId = new Map(
      mapMapNewRestaurantByRestaurantIdByCompanyId
    );
    if (newRestaurant) {
      if (updatedMapMapNewRestaurantByRestaurantIdByCompanyId.get(companyId)) {
        updatedMapMapNewRestaurantByRestaurantIdByCompanyId
          .get(companyId)
          .set(restaurantId, newRestaurant);
      } else {
        updatedMapMapNewRestaurantByRestaurantIdByCompanyId.set(
          companyId,
          new Map([[restaurantId, newRestaurant]])
        );
      }
    } else {
      updatedMapMapNewRestaurantByRestaurantIdByCompanyId.get(companyId)?.delete(restaurantId);

      const updatedMapMapNewLocationByLocationIdByRestaurantId = new Map(
        mapMapNewLocationByLocationIdByRestaurantId
      );
      updatedMapMapNewLocationByLocationIdByRestaurantId.delete(restaurantId);

      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      const lowerLimitOfRange = restaurantId - 999;
      const upperLimitOfRange = restaurantId;
      updatedMapMapNewServiceByServiceIdByLocationId.forEach(
        (mapNewServiceByService, locationId) => {
          if (locationId >= lowerLimitOfRange && locationId <= upperLimitOfRange) {
            updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
          }
        }
      );
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
      setMapMapNewLocationByLocationIdByRestaurantId(
        updatedMapMapNewLocationByLocationIdByRestaurantId
      );
    }
    setMapMapNewRestaurantByRestaurantIdByCompanyId(
      updatedMapMapNewRestaurantByRestaurantIdByCompanyId
    );
  };

  /**
   * Function can add the new location to map, with the restaurantId as the first key and locationId as the second key, or remove the key-value if the location is
   * to be removed.
   * If the location is to be removed, remove all the new service(s) that belongs to this location.
   * @param idParameters - Object containing the locationId and the restaurantId of the location
   * @param newLocation - New location object, or undefined if the location is to be removed
   */
  const updateMapMapNewLocationByLocationIdByRestaurantId = (
    idParameters: { locationId: number; restaurantId: number },
    newLocation?: Location
  ) => {
    const { locationId, restaurantId } = idParameters;
    const updatedMapMapNewLocationByLocationIdByRestaurantId = new Map(
      mapMapNewLocationByLocationIdByRestaurantId
    );
    if (newLocation) {
      if (updatedMapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)) {
        updatedMapMapNewLocationByLocationIdByRestaurantId
          .get(restaurantId)
          .set(locationId, newLocation);
      } else {
        updatedMapMapNewLocationByLocationIdByRestaurantId.set(
          restaurantId,
          new Map([[locationId, newLocation]])
        );
      }
    } else {
      updatedMapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)?.delete(locationId);
      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
    }
    setMapMapNewLocationByLocationIdByRestaurantId(
      updatedMapMapNewLocationByLocationIdByRestaurantId
    );
  };

  /**
   * Function can add the new service to map, with the locationId as the first key and serviceId as the second key, or remove the key-value if the service
   * is to be removed
   * @param idParameters - Object containing the serviceId and the locationId of the service
   * @param newService - New service object, or undefined if the service is to be removed
   */
  const updateMapMapNewServiceByServiceIdByLocationId = (
    idParameters: { serviceId: number; locationId: number },
    newService?: Location
  ) => {
    const { locationId, serviceId } = idParameters;
    const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
      mapMapNewServiceByServiceIdByLocationId
    );
    if (newService) {
      if (updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)) {
        updatedMapMapNewServiceByServiceIdByLocationId.get(locationId).set(serviceId, newService);
      } else {
        updatedMapMapNewServiceByServiceIdByLocationId.set(
          locationId,
          new Map([[serviceId, newService]])
        );
      }
    } else {
      updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)?.delete(serviceId);
    }
    setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
  };

  /**
   * Function runs whenever Save Company button is clicked. If there is no error in any of the company, restaurant, location
   * and service card, loop through the arrCompanyService to remove the all the fake IDs before passing to the backend to create
   * the new companyService in the database. Upon successfully update of the changes, Backend returns the array of newly created
   * companyService(s).
   */
  const saveNewCompanyServiceOnClick = async () => {
    const isErrorExist = inputCardsContext.getErrorExist();

    if (isErrorExist === true) {
      setArrAlertMessage(['Please check for any errors and for any empty fields!']);
      setAlertHeader('Save error!');
      setIsAlert(true);
    } else {
      // Save data
      try {
        const arrNewCompanyService = getArrNewCompanyService(
          mapNewCompanyByCompanyId,
          mapMapNewRestaurantByRestaurantIdByCompanyId,
          mapMapNewLocationByLocationIdByRestaurantId,
          mapMapNewServiceByServiceIdByLocationId
        );

        const response = await axios.post('/company-management/create-company-service', {
          arrCompanyService: arrNewCompanyService,
        });
        companyContext.addArrCompanyService(response.data.arrCompanyService);
        setArrAlertMessage([]);
        setAlertHeader('Save success!');
        setIsAlert(true);
        setIsSaveSuccess(true);
        // Reset changes
        setMapNewCompanyByCompanyId(new Map());
        setMapMapNewRestaurantByRestaurantIdByCompanyId(new Map());
        setMapMapNewLocationByLocationIdByRestaurantId(new Map());
        setMapMapNewServiceByServiceIdByLocationId(new Map());
      } catch (error: any) {
        setArrAlertMessage(['Could not add data!', error]);
        setAlertHeader('Save error!');
        setIsAlert(true);
      }
    }
  };

  /**
   * Function updates arrCompanyId array with a new companyId to create a new CompanyServiceInputCard. If it's the first
   * company created, it will have an ID of -1000. It will also add an error status true into the inputCardsContext's
   * mapErrorById. If the number of companys to be added already exceeds the maximum number of companys allowed, an
   * alert message will pop up.
   */
  const addCompany = () => {
    const numberOfCurrentNewCompany = arrCompanyId.length;
    if (numberOfCurrentNewCompany < MAX_COMPANY) {
      let newCompanyId = -1000;
      if (numberOfCurrentNewCompany !== 0) {
        newCompanyId = arrCompanyId[arrCompanyId.length - 1] - 1000;
      }
      const newArrCompanyId = [...arrCompanyId, newCompanyId];
      setArrCompanyId(newArrCompanyId);
      inputCardsContext.setErrorExist(newCompanyId, true, 3);
    } else {
      setArrAlertMessage(['Cannot add more than 9 companies at one go!']);
      setAlertHeader('Sorry!');
      setIsAlert(true);
    }
  };

  /**
   * Function runs whenever Add Another Company button is clicked, to create a new company card
   */
  const addAnotherCompanyOnClick = () => {
    addCompany();
  };

  /**
   * Function runs whenever Remove Company button is clicked, removes a company from arrCompanyId and mapNewCompanyServiceByCompanyId.
   * Also removes the company's error status and menuInputTag from the inputCardsContext's mapErrorById and mapMenuInputTagByServiceId
   * respectively.
   */
  const removeCompanyOnClick = () => {
    let newArrCompanyId = [...arrCompanyId];
    if (newArrCompanyId.length > 0) {
      const companyToRemoveId = newArrCompanyId.pop();
      setArrCompanyId(newArrCompanyId);
      if (companyToRemoveId) {
        updateMapNewCompanyByCompanyId(companyToRemoveId);
        inputCardsContext.removeRestaurantIdMenuInputTag(companyToRemoveId, -999); // Remove service data from context
        inputCardsContext.deleteErrorForAllIdsWithinRange(companyToRemoveId, -999);
      }
    }
  };

  const alertHandler = () => {
    if (isSaveSuccess === true) {
      setIsSaveSuccess(false);
      inputCardsContext.setInputMode(COMPANY_SERVICE_INPUT_MODE.none, '', 0);
    }
    setIsAlert(!isAlert);
  };

  /**
   * useEffect to set up the first company card
   */
  useEffect(() => {
    addCompany();
  }, []);

  return (
    <div className="CompanyInputCardsContainer">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={alertHandler}
          isExpanded={isAlert}
        />
      )}
      <div className="flex justify-between gap-x-2 mt-2 flex-col">
        <div className="text-xl font-semibold whitespace-nowrap">
          Groups Added: {arrCompanyId.length}
        </div>
        <div className="flex justify-end gap-x-2">
          {arrCompanyId.length > 1 && (
            <Button
              type="submit"
              color="red"
              className="RemoveAGroupButton font-medium flex gap-x-1 pl-3 pr-3 whitespace-nowrap"
              onClick={removeCompanyOnClick}
            >
              <MdRemove className="self-center" />
              Remove a Group
            </Button>
          )}
          <Button
            type="submit"
            color="yellow"
            className="AddAnotherGroupButton font-medium flex gap-x-1 pl-3 pr-3 whitespace-nowrap"
            onClick={addAnotherCompanyOnClick}
          >
            <MdAdd className="self-center" />
            Add another Group
          </Button>
          <div className={`${isButtonFloating && 'fixed top-24 z-10'}`}>
            <Button
              onClick={saveNewCompanyServiceOnClick}
              color="green"
              className="SaveNewGroupsButton font-medium flex gap-x-1 pl-3 pr-3"
            >
              <MdCreate className="self-center" />
              Save New Groups
            </Button>
          </div>
          <Waypoint
            onEnter={() => setIsButtonFloating(false)}
            onLeave={() => setIsButtonFloating(true)}
          />
        </div>
      </div>
      <ul className="flex flex-col w-full mt-2 gap-y-1">
        {arrCompanyId.map((companyId: number) => (
          <CompanyServiceInputCard
            key={companyId}
            arrNewCompany={Array.from(mapNewCompanyByCompanyId.values())}
            arrNewRestaurant={
              mapMapNewRestaurantByRestaurantIdByCompanyId.get(companyId)
                ? Array.from(mapMapNewRestaurantByRestaurantIdByCompanyId.get(companyId).values())
                : []
            }
            companyId={companyId}
            mapMapNewLocationByLocationIdByRestaurantId={
              mapMapNewLocationByLocationIdByRestaurantId
            }
            mapMapNewServiceByServiceIdByLocationId={mapMapNewServiceByServiceIdByLocationId}
            updateMapNewCompany={updateMapNewCompanyByCompanyId}
            updateMapMapNewRestaurantByRestaurantIdByCompanyId={
              updateMapMapNewRestaurantByRestaurantIdByCompanyId
            }
            updateMapMapNewLocationByLocationIdByRestaurantId={
              updateMapMapNewLocationByLocationIdByRestaurantId
            }
            updateMapMapNewServiceByServiceIdByLocationId={
              updateMapMapNewServiceByServiceIdByLocationId
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default CompanyInputCardsContainer;
