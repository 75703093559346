import {
  checkIfMenuItemNameValidWithRegEx,
  checkIsInputTagValidWithRegEx,
  checkIsNameValidWithRegEx,
} from './InputValidation';
import { Menu, MenuItem, NewMenuItem, Service } from '../interfaces';

/**
 * Checks a name input against an array to check against to check if name is unique.
 * Name is valid if no duplicates are found when checked against array of updated name and array of existing
 * name (excluding those that have been updated) and name has to pass requirement for allowed characters and
 * format with a regular expression.
 * @param name - Name to be checked
 * @param id - string
 * @param arrExistingElementToCheckAgainst - Array of existing name to be checked against for duplicates
 * @param arrUpdatedElementToCheckAgainst - Array of edited or new name to be checked against for duplicates
 * @returns errorMessage - String containing information on error, empty string if there are no errors
 */
const checkForInvalidNameInput = (
  name: string,
  id: number,
  arrExistingElementToCheckAgainst: Array<any>,
  arrUpdatedElementToCheckAgainst: Array<any>,
  nameOfObject: string
) => {
  let errorMessage: string = '';

  const setUpdatedElementId = new Set();
  // check if name passes alphanumeric checks
  if (checkIsNameValidWithRegEx(name)) {
    for (let i = 0; i < arrUpdatedElementToCheckAgainst.length; i++) {
      const nameToCheckAgainst = arrUpdatedElementToCheckAgainst[i].name.toLowerCase();

      if (nameOfObject === 'Service') {
        setUpdatedElementId.add(arrUpdatedElementToCheckAgainst[i].serviceId);
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrUpdatedElementToCheckAgainst[i].serviceId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same location!`;
          return errorMessage;
        }
      } else if (nameOfObject === 'Location') {
        setUpdatedElementId.add(arrUpdatedElementToCheckAgainst[i].locationId);
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrUpdatedElementToCheckAgainst[i].locationId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same property!`;
          return errorMessage;
        }
      } else if (nameOfObject === 'Property') {
        setUpdatedElementId.add(arrUpdatedElementToCheckAgainst[i].restaurantId);
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrUpdatedElementToCheckAgainst[i].restaurantId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same group!`;
          return errorMessage;
        }
      } else {
        setUpdatedElementId.add(arrUpdatedElementToCheckAgainst[i].companyId);
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrUpdatedElementToCheckAgainst[i].companyId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name!`;
          return errorMessage;
        }
      }
    }
    for (let j = 0; j < arrExistingElementToCheckAgainst.length; j++) {
      const nameToCheckAgainst = arrExistingElementToCheckAgainst[j].name.toLowerCase();

      if (nameOfObject === 'Service') {
        if (setUpdatedElementId.has(arrExistingElementToCheckAgainst[j].serviceId)) continue;
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrExistingElementToCheckAgainst[j].serviceId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same location!`;
          return errorMessage;
        }
      } else if (nameOfObject === 'Location') {
        if (setUpdatedElementId.has(arrExistingElementToCheckAgainst[j].locationId)) continue;
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrExistingElementToCheckAgainst[j].locationId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same restaurant!`;
          return errorMessage;
        }
      } else if (nameOfObject === 'Restaurant') {
        if (setUpdatedElementId.has(arrExistingElementToCheckAgainst[j].restaurantId)) continue;
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrExistingElementToCheckAgainst[j].restaurantId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name within the same company!`;
          return errorMessage;
        }
      } else {
        if (setUpdatedElementId.has(arrExistingElementToCheckAgainst[j].companyId)) continue;
        if (
          name.toLowerCase() === nameToCheckAgainst &&
          id !== arrExistingElementToCheckAgainst[j].companyId
        ) {
          errorMessage = `Duplicated ${nameOfObject} name!`;
          return errorMessage;
        }
      }
    }
  } else {
    errorMessage = `${nameOfObject} name does not pass Alphanumeric checks!`;
  }

  return errorMessage;
};

/**
 * Checks a slack channel id input against an array to check against to check if slack channel id is unique.
 * Slack channel id is valid if no duplicates are found when checked against array
 * @param slackChannelId - Slack channel id to be checked
 * @param restaurantId - Id of restaurant id slack channel id belongs to
 * @param arrRestaurantSlackChannelId - Arr slack channel id to be checked against for duplicates
 * @returns errorMessage - String containing information on error, empty string if there are no errors
 */
const checkForInvalidSlackChannelIdInput = (
  slackChannelId: string,
  restaurantId: number,
  arrRestaurantSlackChannelId: Array<{
    restaurantId: number;
    slackChannelId: string | null;
  }>
) => {
  let errorMessage: string = '';
  if (slackChannelId.length === 11) {
    for (
      let restaurantIndex = 0;
      restaurantIndex < arrRestaurantSlackChannelId.length;
      restaurantIndex += 1
    ) {
      const { slackChannelId: currentSlackChannelId } =
        arrRestaurantSlackChannelId[restaurantIndex];
      if (currentSlackChannelId === null) continue;
      const slackChannelIdToCheckAgainst = currentSlackChannelId.toLowerCase();
      if (
        slackChannelId.toLowerCase() === slackChannelIdToCheckAgainst &&
        restaurantId !== arrRestaurantSlackChannelId[restaurantIndex].restaurantId
      ) {
        errorMessage = `Duplicated slack channel id found`;
        return errorMessage;
      }
    }
  } else {
    errorMessage = 'Should be 11 characters long';
  }

  return errorMessage;
};

/**
 * Checks a inputTag input against an arrAllExistingService and arrUpdatedInputTagAndServiceId (because swapping of input tag is allowed)
 * to check if name is unique. Also checks if the inputTag passes requirement for allowed characters and format with
 * a regular expression.
 * @param updatedInputTag - InputTag to be checked if it is valid
 * @param serviceId - Id of the service that the new input tag belongs to
 * @param arrAllExistingService - Array of all existing services to be checked for duplicates
 * @param arrUpdatedInputTagAndServiceId - Array of objects containing the edited/new input tag and its serviceId
 * @returns errorMessage - Returns a string containing an error message. Returns an empty string if there are no errors.
 */
const checkForInvalidInputTag = (
  updatedInputTag: string,
  serviceId: number,
  arrAllExistingService: Array<Service>,
  arrUpdatedInputTagAndServiceId: Array<{
    serviceId: number;
    inputTag: string;
  }>
) => {
  let errorMessage: string = '';
  const setUpdatedServiceId = new Set();
  if (checkIsInputTagValidWithRegEx(updatedInputTag)) {
    for (
      let inputTagAndServiceIdIndex = 0;
      inputTagAndServiceIdIndex < arrUpdatedInputTagAndServiceId.length;
      inputTagAndServiceIdIndex++
    ) {
      if (
        updatedInputTag === arrUpdatedInputTagAndServiceId[inputTagAndServiceIdIndex].inputTag &&
        serviceId !== arrUpdatedInputTagAndServiceId[inputTagAndServiceIdIndex].serviceId
      ) {
        errorMessage = `Duplicated input tag!`;
        return errorMessage;
      }
      setUpdatedServiceId.add(arrUpdatedInputTagAndServiceId[inputTagAndServiceIdIndex].serviceId);
    }
    for (let serviceIndex = 0; serviceIndex < arrAllExistingService.length; serviceIndex++) {
      if (
        !setUpdatedServiceId.has(arrAllExistingService[serviceIndex].serviceId) &&
        arrAllExistingService[serviceIndex].inputTag === updatedInputTag &&
        arrAllExistingService[serviceIndex].serviceId !== serviceId
      ) {
        errorMessage = `Duplicated input tag!`;
        return errorMessage;
      }
    }
  } else {
    errorMessage = 'InputTag does not pass Alphanumeric checks!';
  }
  return errorMessage;
};

/**
 * Checks a name input against an array of all new menus except menus from current restaurant and an array
 * of existing services. Menu names are allowed to be duplicated within a restaurant.
 * Menu name is valid if there are no duplicate menu names found outside of current restaurant and if name
 * passes requirement for allowed characters and format with a regular expression.
 * @param newMenuName - New menu names to be checked
 * @param arrMenu - Array of unique menu that belong to the same restaurant. Will be [] for add companyService and add restaurantService.
 * @param arrAllNewMenuExceptCurrentRestaurant - Array of all new menu names to be checked for duplicates and does not include
 * menu names from the menu's own restaurant
 * @param arrAllExistingService - Array of all existing services to be checked for duplicates
 * @returns errorMessage - String containing information on error, empty string if there are no errors
 */
const checkForInvalidMenuName = (
  newMenuName: string,
  arrMenu: Array<Menu>,
  arrAllNewMenuExceptCurrentRestaurant: Array<string>,
  arrAllExistingService: Array<Service>
) => {
  let errorMessage: string = '';

  if (checkIsNameValidWithRegEx(newMenuName)) {
    // Converting arrMenu into a set object of menu name is for efficient finding of the menu name that
    // belongs to the same company as the menu name of interest
    const setMenuName = new Set();
    arrMenu.forEach((menu) => setMenuName.add(menu.name));

    for (let i = 0; i < arrAllExistingService.length; i++) {
      const service = arrAllExistingService[i];
      // This array of services includes the services that belongs to the same company as the menu name of interest,
      // and therefore an additional check to see if the new menu name is a duplicate of the ones within the same
      // company is necessary
      if (service.menu.name === newMenuName && !setMenuName.has(newMenuName)) {
        errorMessage = `Duplicated menu name between properties!`;
        return errorMessage;
      }
    }

    for (let i = 0; i < arrAllNewMenuExceptCurrentRestaurant.length; i++) {
      if (newMenuName === arrAllNewMenuExceptCurrentRestaurant[i]) {
        errorMessage = `Duplicated menu name between restaurants!`;
      }
    }
  } else {
    errorMessage = 'Menu name does not pass Alphanumeric checks!';
  }
  return errorMessage;
};

/**
 * Checks a menu item name input against an array of all new menu items (except its own) and an array of existing menu items,
 * within the same menu. Comparison is done in lower case as casing should not matter.
 * Also checks if the menu item name input passes requirement for allowed characters and format with a regular expression.
 * Note: Menu item name has to be unique within the same menu, regardless of the station and cost per kilogram.
 * @param newMenuItemName - New menu item name to be checked
 * @param newMenuItemId - New menu item id which the name is to be checked
 * @param arrAllNewMenuItem - Array of all new menu item names to be checked for duplicates
 * @param arrAllExistingMenuItem - Array of all existing menu item names to be checked for duplicates
 * @returns errorMessage - String containing information on error, empty string if there are no errors
 */
const checkForInvalidMenuItemName = (
  newMenuItemName: string,
  newMenuItemId: number,
  arrAllNewMenuItem: Array<NewMenuItem>,
  arrAllExistingMenuItem: Array<MenuItem>
) => {
  let errorMessage: string = '';

  if (checkIfMenuItemNameValidWithRegEx(newMenuItemName)) {
    const newMenuItemNameInLowerCase = newMenuItemName.toLowerCase();
    for (let i = 0; i < arrAllExistingMenuItem.length; i++) {
      const menuItem = arrAllExistingMenuItem[i];
      const menuItemNameInLowerCase = menuItem.name.toLowerCase();
      if (menuItemNameInLowerCase === newMenuItemNameInLowerCase) {
        errorMessage = `Duplicated menu item name!`;
        return errorMessage;
      }
    }

    for (let i = 0; i < arrAllNewMenuItem.length; i++) {
      const menuItem = arrAllNewMenuItem[i];
      const menuItemNameInLowerCase = menuItem.name.toLowerCase();
      if (
        menuItemNameInLowerCase === newMenuItemNameInLowerCase &&
        menuItemNameInLowerCase !== '' &&
        menuItem.menuItemId !== newMenuItemId
      ) {
        errorMessage = `Duplicated menu item name!`;
      }
    }
  } else {
    errorMessage =
      "Must be alphanumeric, '/', '()', ''', or single space/hyphen in between, and proper capitalized";
  }

  return errorMessage;
};

export {
  checkForInvalidInputTag,
  checkForInvalidMenuItemName,
  checkForInvalidMenuName,
  checkForInvalidNameInput,
  checkForInvalidSlackChannelIdInput,
};
