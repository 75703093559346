import React, { useState } from 'react';
import { MdAdd, MdClear, MdCreate } from 'react-icons/md';
import { Waypoint } from 'react-waypoint';
import { Button } from '@material-tailwind/react';

const AddButton = (props: {
  buttonClassName: string;
  buttonName: string;
  enableAddOnClick: any;
  isAdd: boolean;
  isEnabled: boolean;
  saveChangesOnClick: any;
}) => {
  const [isButtonFloating, setIsButtonFloating] = useState<boolean>(false);
  return (
    <div className="flex gap-x-2">
      {!props.isAdd && (
        <Button
          onClick={props.enableAddOnClick}
          size={props.buttonClassName === 'AddMenuItemButton' ? 'md' : 'sm'}
          className={
            props.buttonClassName +
            ' flex gap-x-1 p-2 ' +
            (props.buttonClassName === 'AddMenuItemButton'
              ? 'font-small bg-regal-blue'
              : 'font-medium bg-green-500')
          }
          disabled={!props.isEnabled}
        >
          <MdAdd className="AddButton self-center" />
          {props.buttonName}
        </Button>
      )}
      {props.isAdd && (
        <React.Fragment>
          <Button
            onClick={props.enableAddOnClick}
            size="sm"
            color="red"
            className={
              'CancelAddButton bg-red-500 flex gap-x-1 pt-2 p-2' +
              (props.buttonClassName === 'AddMenuItemButton' ? 'font-small' : 'font-medium')
            }
          >
            <MdClear className="self-center" />
            Cancel Add
          </Button>
          <div className={`${isButtonFloating && 'fixed top-24 z-10'}`}>
            <Button
              onClick={props.saveChangesOnClick}
              size="sm"
              color="green"
              className={
                'SaveChangesButton bg-green-500 flex gap-x-1 pt-2 p-2' +
                (props.buttonClassName === 'AddMenuItemButton' ? 'font-small' : 'font-medium')
              }
            >
              <MdCreate className="self-center" />
              Save Changes
            </Button>
          </div>
          <Waypoint
            onEnter={() => setIsButtonFloating(false)}
            onLeave={() => setIsButtonFloating(true)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default AddButton;
