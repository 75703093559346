import React, { useEffect, useRef } from 'react';

import JSONEditor, { JSONEditorOptions, JSONEditorMode } from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

const Editor = (props: {
  isResetChanges: boolean;
  json: Object;
  onChangeText: any;
  onEditable: any;
  onValidationError: any;
  resetChanges: any;
}) => {
  const mode: JSONEditorMode = 'code';
  const elRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<JSONEditor>();

  const unmountEditor = () => {
    editorRef?.current?.destroy();
  };

  useEffect(() => {
    const container = elRef?.current;
    const options: JSONEditorOptions = {
      mode: mode,
      onEditable: props.onEditable,
      onChangeText: props.onChangeText,
      onValidationError: props.onValidationError,
    };

    if (container && editorRef) {
      const jsonEditor = new JSONEditor(container, options);
      if (props.isResetChanges) {
        jsonEditor.refresh();
        props.resetChanges();
      }

      jsonEditor.set(props.json);
      editorRef.current = jsonEditor;
    }

    return unmountEditor;
  }, [props]);

  return <div id="jsoneditor" ref={elRef} className="jsoneditor-react-container h-full" />;
};

export default Editor;
