import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';

import Editor from './Editor';
import Alert from '../CompanyComponents/alerts/Alert';
import { JSONEditorError } from '../../interfaces';

const DefaultConfigurationInputDialog = (props: {
  isEdit: boolean;
  setIsEdit: any;
  checkForNoChanges: any;
  checkForDuplicatedAttribute: any;
}) => {
  const [originalConfiguration, setOriginalConfiguration] = useState<Object | null>(null);
  const [isResetChanges, setIsResetChanges] = useState<boolean>(false);

  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [alertHeader, setAlertHeader] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  const ref: any = useRef();

  /**
   * This function is invoked when user updates default configuration and the newly edited configuration is updated to ref.stringifiedEditedConfiguration
   * @param stringifiedJSON - New edited configuration in the form of stringified JSON
   */
  const updateConfiguration = (stringifiedJSON: string) => {
    ref.stringifiedEditedConfiguration = stringifiedJSON;
  };

  /**
   * This function checks if there is syntax error in the input, called by the JSONEditor
   * @param arrError - Array of error captured by the JSONEditor
   */
  const checkForSyntaxError = (arrError: Array<JSONEditorError>) => {
    if (arrError !== null && arrError.length !== 0) {
      ref.hasSyntaxError = true;
    } else {
      ref.hasSyntaxError = false;
    }
  };

  /**
   * This function is invoked when user clicks 'Edit Default Configuration' button. The following actions take place:
   * 1. Fetch the default configuration from Backend through backend route '/device-management/fetch-default-device-configuration'
   *    a. On failure, alert modal will display error message to user and function exits here
   * 2. If successful, set state for originalConfiguration, and save the stringified version of the original configuration to
   * ref.stringifiedEditedConfiguration and also save the ref.hasSyntaxError as false
   */
  const fetchDefaultConfiguration = async () => {
    try {
      const response = await axios.post('device-management/fetch-default-device-configuration', {});
      const { defaultDeviceConfiguration } = response.data;

      setOriginalConfiguration(defaultDeviceConfiguration);
      ref.stringifiedEditedConfiguration = JSON.stringify(defaultDeviceConfiguration);
      ref.hasSyntaxError = false;
    } catch (error) {
      props.setIsEdit(false);

      setArrAlertMessage([
        'Could not retrieve default configuration, please try again later!',
        error,
      ]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  /**
   * This function is invoked when user clicks 'Confirm' button. The following actions take place:
   * 1. Check if there is any existing error (syntax error, duplicated attribute, no changes from original). If there is,
   * alert modal will display error message to user and function exits here.
   * 2. Edited default configuration is posted to backend route '/device-management/update-default-device-configuration'
   *    a. On failure, alert modal will display error message to user and function exits here
   * 3. If successful, alert modal will display sucess message
   */
  const confirmEditDefaultCofiguration = async () => {
    try {
      // Note that the sequence of checking error is important. hasSyntaxError has to be checked before props.checkForDuplicatedAttribute
      // because props.checkForDuplicatedAttribute has a JSON.parse which will throw error if there is syntax error
      if (ref.hasSyntaxError) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage([
          'Syntax Error! Please note that all changes will be reset after you close this Error dialog.',
        ]);
        setIsAlert(true);
        return;
      }

      const isNoChanges = props.checkForNoChanges(
        ref.stringifiedEditedConfiguration,
        originalConfiguration
      );
      if (isNoChanges) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage(['No Change!']);
        setIsAlert(true);
        return;
      }

      const isDuplicated = props.checkForDuplicatedAttribute(ref.stringifiedEditedConfiguration);
      if (isDuplicated) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage([
          'Duplicated attribute! Please note that all changes will be reset after you close this Error dialog.',
        ]);
        setIsAlert(true);
        return;
      }

      const defaultDeviceConfigurationToBeUpdated: Object = JSON.parse(
        ref.stringifiedEditedConfiguration
      );

      await axios.post('/device-management/update-default-device-configuration', {
        defaultDeviceConfigurationToBeUpdated,
      });

      props.setIsEdit(false);

      setArrAlertMessage([]);
      setAlertHeader('Save success!');
      setIsAlert(true);
    } catch (error) {
      props.setIsEdit(false);

      setArrAlertMessage([
        'Could not submit edited default configuration, please try again later!',
        error,
      ]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  useEffect(() => {
    if (props.isEdit) {
      fetchDefaultConfiguration();
    }
  }, [props.isEdit]);

  return (
    originalConfiguration && (
      <React.Fragment>
        {isAlert && (
          <Alert
            arrAlertMessage={arrAlertMessage}
            alertHeader={alertHeader}
            handleOpen={() => {
              setIsAlert(!isAlert);
            }}
            isExpanded={isAlert}
          />
        )}
        <Dialog
          open={props.isEdit}
          handler={() => {}}
          className="min-w-[30rem] lg:min-w-[40rem] max-h-[90%] overflow-auto"
        >
          <DialogHeader className="flex justify-between max-h-[4rem]">
            Edit Default Configuration
            <Button onClick={() => setIsResetChanges(true)} color="amber" className="p-3">
              Reset Changes
            </Button>
          </DialogHeader>
          <DialogBody divider className="flex flex-row min-h-[24rem] max-h-[48rem] gap-3">
            <div className="DefaultConfigurationEditorDiv flex flex-col gap-3 min-h-full w-full overflow-auto">
              <Editor
                isResetChanges={isResetChanges}
                json={originalConfiguration}
                onChangeText={(stringifiedJSON: string) => updateConfiguration(stringifiedJSON)}
                onEditable={() => true}
                onValidationError={(arrError: Array<JSONEditorError>) => {
                  checkForSyntaxError(arrError);
                }}
                resetChanges={() => setIsResetChanges(false)}
              />
            </div>
          </DialogBody>
          <DialogFooter className="p-3 max-h-[4rem]">
            <Button
              variant="text"
              color="red"
              onClick={() => props.setIsEdit(false)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button
              variant="gradient"
              color="green"
              onClick={confirmEditDefaultCofiguration}
              className="DefaultConfigurationConfirmButton"
            >
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </React.Fragment>
    )
  );
};

export default DefaultConfigurationInputDialog;
