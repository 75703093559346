import axios from 'axios';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react';

import InputWithSearchFunction from './InputWithSearchFunction';
import Alert from '../CompanyComponents/alerts/Alert';
import { Device, DeviceCompany } from '../../interfaces';

enum InputType {
  SERIAL_NUMBER = 'serialNumber',
  VERSION = 'version',
}

const DeviceInputDialog = (props: {
  isAdd: boolean;
  setIsAdd: any;
  setSerialNumber: Set<string>;
  setSetSerialNumber: Dispatch<SetStateAction<Set<string>>>;
  arrDeviceCompany: Array<DeviceCompany>;
  setArrDeviceCompany: Dispatch<SetStateAction<Array<DeviceCompany>>>;
  setVersion: Set<string>;
  setSetVersion: Dispatch<SetStateAction<Set<string>>>;
}) => {
  const defaultInput = {
    serialNumber: '',
    version: 'Insight V5.0',
    isError: true,
    serialNumberErrorMessage: 'Please fill up',
    versionErrorMessage: '',
  };

  const [mapDeviceInput, setMapDeviceInput] = useState<
    Map<
      number,
      {
        serialNumber: string;
        version: string;
        isError: boolean;
        serialNumberErrorMessage: string;
        versionErrorMessage: string;
      }
    >
  >(new Map([[-1, defaultInput]]));
  const [lastDeviceId, setLastDeviceId] = useState<number>(-1);
  const [isFormError, setIsFormError] = useState<boolean>(true);

  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [alertHeader, setAlertHeader] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  /**
   * This function is invoked when user updates input element and user input serialNumber is updated to component mapDeviceInput state
   * @param event - Change event providing input serialNumber
   * @param deviceId - Device id of input element currently being changed
   */
  const updateDeviceInput = (valueToBeUpdated: string, deviceId: number, inputType: InputType) => {
    const updatedMapDeviceInput = new Map(mapDeviceInput);
    updatedMapDeviceInput.set(deviceId, {
      ...updatedMapDeviceInput.get(deviceId)!,
      [inputType]: valueToBeUpdated,
    });

    setMapDeviceInput(updatedMapDeviceInput);
  };

  /**
   * This function is invoked on blur of device serial number input element to check for blank and duplicate errors
   * @param deviceId - Device id of input element currently being blurred
   */
  const checkError = (deviceId: number, inputType: InputType) => {
    const updatedMapDeviceInput = new Map(mapDeviceInput);
    const deviceInput = updatedMapDeviceInput.get(deviceId)!;
    // Check for blank errors
    if (deviceInput[inputType].trim() === '') {
      setMapDeviceInput(
        updatedMapDeviceInput.set(deviceId, {
          ...deviceInput,
          isError: true,
          [`${inputType}ErrorMessage`]: 'Please fill up',
        })
      );
      setIsFormError(true);
      return;
    }

    if (inputType === InputType.SERIAL_NUMBER) {
      // Check for duplicate with new serial numbers
      let isDuplicateWithNewDevice = false;
      updatedMapDeviceInput.forEach((mapDeviceValue, mapDeviceId) => {
        if (deviceInput.serialNumber === mapDeviceValue.serialNumber && mapDeviceId !== deviceId) {
          isDuplicateWithNewDevice = true;
        }
      });

      // Check for duplicate with existing serial numbers
      const isDuplicateWithExistingDevice = props.setSerialNumber.has(
        deviceInput.serialNumber.toLowerCase()
      );

      // If any duplicate exists, set device isError to true and set device errorMessage
      if (isDuplicateWithNewDevice || isDuplicateWithExistingDevice) {
        deviceInput.serialNumberErrorMessage = 'Duplicate serial number exists!';
      } else {
        // If no errors for the particular device, set device isError to false and set device errorMessage to blank
        deviceInput.serialNumberErrorMessage = '';
      }
    } else if (inputType === InputType.VERSION) {
      // No checks for version in place, other than checking if field is empty above
      deviceInput.versionErrorMessage = '';
    }
    deviceInput.isError = Object.values(InputType).some(
      (inputType) => deviceInput[`${inputType}ErrorMessage`] !== ''
    );
    setMapDeviceInput(updatedMapDeviceInput);

    // Run through all elements in updatedMapDeviceInput to check for isErrors and set to isFormError state
    let isUpdatedFormError = false;
    updatedMapDeviceInput.forEach((deviceValue) => {
      if (deviceValue.isError) {
        isUpdatedFormError = true;
      }
    });
    setIsFormError(isUpdatedFormError);
  };

  /**
   * This function is invoked when user clicks 'Add Another Device' button. The following actions take place:
   * 1. An additional map entry is created with default input values and errors
   * 2. Increment lastDeviceId state. Reason last device id is not retrieved from mapDeviceInput is to eliminate the need to loop through it
   * 3. Set overall form error to true, as adding an additional device guarantees a 'Please fill up' error to exist
   */
  const addAnotherDevice = () => {
    const updatedMapDeviceInput = new Map(mapDeviceInput);
    const newDeviceId = lastDeviceId - 1;
    setMapDeviceInput(updatedMapDeviceInput.set(newDeviceId, defaultInput));
    setLastDeviceId(newDeviceId);
    setIsFormError(true);
  };

  /**
   * This function is invoked when user clicks 'x' button next to input element to remove the device
   * @param deviceId - Device id of input element currently being removed
   */
  const removeDevice = (deviceId: number) => {
    const updatedMapDeviceInput = new Map(mapDeviceInput);
    updatedMapDeviceInput.delete(deviceId);
    setMapDeviceInput(updatedMapDeviceInput);
  };

  /**
   * This function is invoked when user clicks 'Reset Fields' button. The following actions take place:
   * 1. Reset the mapDeviceInput state to its original value
   * 2. Reset the lastDeviceId state to its orignal value (-1)
   * 3. Reset overall form error to true
   */
  const resetFields = () => {
    setMapDeviceInput(new Map([[-1, defaultInput]]));
    setLastDeviceId(-1);
    setIsFormError(true);
  };

  /**
   * This function is invoked when user clicks 'Confirm' button. The following actions take place:
   * 1. arrDeviceToBeCreated is declared and serialNumbers (input values) are pushed into it
   * 2. arrDeviceToBeCreated is posted to backend route '/device-management/create-device'
   *    a. On failure, alert modal will display error message to user and function exits here
   * 3. If successful, arrDevice is returned with devices created in database. These are updated to parent component's arrDeviceCompany and setSerialNumber
   * 4. Fields are reset and add device dialog is closed
   * 5. Alert modal will display sucess message
   */
  const confirmAddDevice = async () => {
    if (isFormError) {
      setAlertHeader('Save Error!');
      setArrAlertMessage(['Please check for any errors and for any empty fields!']);
      setIsAlert(true);
      return;
    }

    try {
      const arrDeviceToBeCreated: Array<{ serialNumber: string; version: string }> = [];
      mapDeviceInput.forEach((deviceInput) => {
        const { serialNumber, version } = deviceInput;
        arrDeviceToBeCreated.push({
          serialNumber,
          version,
        });
      });
      const response = await axios.post('/device-management/create-device', {
        arrDeviceToBeCreated,
      });
      const { arrDevice } = response.data;
      const arrUpdatedDeviceCompany = [...props.arrDeviceCompany];
      const setUpdatedSerialNumber = new Set(props.setSerialNumber);
      const setUpdatedVersion = new Set(props.setVersion);
      arrDevice.forEach((device: Device) => {
        arrUpdatedDeviceCompany.push({
          ...device,
          arrWaste: [],
        });
        setUpdatedSerialNumber.add(device.serialNumber.toLowerCase());
        setUpdatedVersion.add(device.version);
      });
      // This sorts the arrUpdatedDeviceCompany by its serialNumber in ascending order, case-insensitive
      arrUpdatedDeviceCompany.sort((deviceCompanyA: DeviceCompany, deviceCompanyB: DeviceCompany) =>
        deviceCompanyA.serialNumber.toLowerCase() < deviceCompanyB.serialNumber.toLowerCase()
          ? -1
          : 1
      );
      props.setArrDeviceCompany(arrUpdatedDeviceCompany);
      props.setSetSerialNumber(setUpdatedSerialNumber);
      props.setSetVersion(setUpdatedVersion);

      resetFields();
      props.setIsAdd(false);

      setArrAlertMessage([]);
      setAlertHeader('Save success!');
      setIsAlert(true);
    } catch (error) {
      props.setIsAdd(false);

      setArrAlertMessage(['Could not add data, please try again later!', error]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  return (
    <React.Fragment>
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <Dialog
        open={props.isAdd}
        handler={() => {}}
        className="min-w-[30rem] lg:min-w-[40rem] overflow-visible"
      >
        <DialogHeader className="flex justify-between">
          Add Device(s)
          <Button onClick={resetFields} color="amber" className="p-3">
            Reset Fields
          </Button>
        </DialogHeader>
        <DialogBody divider className="flex-col gap-3 max-h-[32rem] overflow-visible">
          {Array.from(mapDeviceInput).map(([deviceId, deviceInput]) => (
            <div key={deviceId}>
              <div className="flex">
                <div className="flex gap-2 grow">
                  <div className="relative w-full min-w-[200px] h-14">
                    <Input
                      className="DeviceSerialNumberInput"
                      value={deviceInput.serialNumber}
                      onChange={(event) =>
                        updateDeviceInput(event.target.value, deviceId, InputType.SERIAL_NUMBER)
                      }
                      label="Input Serial Number"
                      onBlur={() => checkError(deviceId, InputType.SERIAL_NUMBER)}
                      error={!!deviceInput.serialNumberErrorMessage}
                    />
                    {deviceInput.serialNumberErrorMessage !== '' && (
                      <p className="text-red-500 text-xs mt-1 ml-1 font-normal">
                        {deviceInput.serialNumberErrorMessage}
                      </p>
                    )}
                  </div>
                  <div className="relative w-full min-w-[200px] h-10">
                    <InputWithSearchFunction
                      className="DeviceVersionInput"
                      arrAllOption={Array.from(props.setVersion)}
                      label="Input Version"
                      value={deviceInput.version}
                      errorMessage={deviceInput.versionErrorMessage}
                      onChange={(updatedValue: string) =>
                        updateDeviceInput(updatedValue, deviceId, InputType.VERSION)
                      }
                      onBlur={() => {
                        checkError(deviceId, InputType.VERSION);
                      }}
                    />
                    {deviceInput.versionErrorMessage !== '' && (
                      <p className="text-red-500 text-xs mt-1 ml-1 font-normal">
                        {deviceInput.versionErrorMessage}
                      </p>
                    )}
                  </div>
                </div>
                {mapDeviceInput.size > 1 && (
                  <Button
                    onClick={() => removeDevice(deviceId)}
                    size="sm"
                    className="font-medium m-2 p-2 w-7 h-7 text-center"
                    color="yellow"
                    tabIndex={-1}
                  >
                    <MdOutlineClose />
                  </Button>
                )}
              </div>
            </div>
          ))}
          {mapDeviceInput.size < 5 && (
            <Button
              className="DeviceAddAnotherDeviceButton mt-2 min-h-[2.5rem]"
              color="amber"
              onClick={addAnotherDevice}
            >
              + Add another device
            </Button>
          )}
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={() => props.setIsAdd(false)} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={confirmAddDevice}
            className="DeviceConfirmButton"
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};

export default DeviceInputDialog;
