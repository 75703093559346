import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';

import Editor from './Editor';
import Alert from '../CompanyComponents/alerts/Alert';
import { DeviceCompany, JSONEditorError } from '../../interfaces';

const DEFAULT_CONFIGURATION_TEXT = 'Default Configuration';

const DeviceConfigurationInputDialog = (props: {
  arrDeviceCompany: Array<DeviceCompany>;
  selectedDeviceCompany: DeviceCompany | null;
  checkForNoChanges: any;
  checkForDuplicatedAttribute: any;
  setSelectedDeviceCompany: any;
  updateEditedConfigurationToArrDeviceCompany: any;
}) => {
  const [originalConfiguration, setOriginalConfiguration] = useState<Object | null>(null);
  const [isResetChanges, setIsResetChanges] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [comparisonConfiguration, setComparisonConfiguration] = useState<Object>({});

  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [alertHeader, setAlertHeader] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  const ref: any = useRef();

  /**
   * This function is invoked whenever there is a change in the props.selectedDeviceCompany and props.selectedDeviceCompany is not null
   */
  const setSelectedDeviceOriginalConfiguration = () => {
    const mapDeviceConfigurationBySerialNumber: Map<string, Object> = new Map();
    const { configuration } = props.selectedDeviceCompany!;
    props.arrDeviceCompany.forEach((deviceCompany) => {
      if (deviceCompany.deviceId !== props.selectedDeviceCompany!.deviceId) {
        mapDeviceConfigurationBySerialNumber.set(
          deviceCompany.serialNumber,
          deviceCompany.configuration
        );
      }
    });
    setOriginalConfiguration(configuration);
    ref.stringifiedEditedConfiguration = JSON.stringify(configuration);
    ref.mapDeviceConfigurationBySerialNumber = mapDeviceConfigurationBySerialNumber;
    ref.hasSyntaxError = false;
  };

  /**
   * This function is invoked when user updates device configuration and the newly edited configuration is updated to ref.stringifiedEditedConfiguration
   * @param stringifiedJSON - New edited configuration in the form of stringified JSON
   */
  const updateConfiguration = (stringifiedJSON: string) => {
    ref.stringifiedEditedConfiguration = stringifiedJSON;
  };

  /**
   * This function is invoked when user selects another device's configuration for comparison and set that configuration (finding by
   * the device's serial number) for the comparisonConfiguration state. It is written in a way such that it will check if there is
   * any syntax or duplicated attribute error in the editing device configuration first, before allowing the set state to happen.
   * This is because the state change will cause a rendering which the edited stringified configuration will be parsed to provide
   * the JSON for the editable Editer component. However, if there is error in parsing, the dialog component will not be able to load.
   * Hence, if there is any error in the edited configuration, the configuration that the user selects will not be displayed (do note
   * that user can still select and the newly selected device's serial number will still be displayed but the configuration will remain
   * as the last valid selection).
   * @param selectedSerialNumber - Selected device's serial number, whose configuration is be compared with the currently editing device's configuration
   */
  const updateSelectedComparisonConfiguration = (selectedSerialNumber: string) => {
    // Check for error(s) in the stringifiedEditedConfiguration
    if (ref.hasSyntaxError) {
      return;
    }
    const isDuplicated = props.checkForDuplicatedAttribute(ref.stringifiedEditedConfiguration);
    if (isDuplicated) {
      return;
    }
    const selectedConfiguration =
      ref.mapDeviceConfigurationBySerialNumber.get(selectedSerialNumber);
    if (!selectedConfiguration) {
      setComparisonConfiguration(ref.defaultConfiguration);
    } else {
      setComparisonConfiguration(selectedConfiguration);
    }
  };

  /**
   * This function checks if there is syntax error in the input, called by the JSONEditor
   * @param arrError - Array of error captured by the JSONEditor
   */
  const checkForSyntaxError = (arrError: Array<JSONEditorError>) => {
    if (arrError !== null && arrError.length !== 0) {
      ref.hasSyntaxError = true;
    } else {
      ref.hasSyntaxError = false;
    }
  };

  /**
   * This function is invoked when user clicks 'Edit Default Configuration' button. The following actions take place:
   * 1. Fetch the default configuration from Backend through backend route '/device-management/fetch-default-device-configuration'
   *    a. On failure, alert modal will display error message to user and function exits here
   * 2. If successful, set state for originalConfiguration, and save the stringified version of the original configuration to
   * ref.stringifiedEditedConfiguration and also save the ref.hasSyntaxError as false
   */
  const fetchDefaultConfiguration = async () => {
    try {
      const response = await axios.post('device-management/fetch-default-device-configuration', {});
      const { defaultDeviceConfiguration } = response.data;

      ref.defaultConfiguration = defaultDeviceConfiguration;
    } catch (error) {
      props.setSelectedDeviceCompany(null);

      setArrAlertMessage([
        'Could not retrieve default configuration, please try again later!',
        error,
      ]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  /**
   * This function checks if the edited configuration has non-existent attribute or attribute with wrong data
   * type when compared with the default configuration. Two separate strings containing the attribute names of
   * the respective error will be returned to be printed in the error message to better direct user to the part
   * of configuration that requires rectification.
   * @param editedConfiguration - Edited configuration to be checked for non-existent attribute or attribute with wrong data type when compared with the default configuration
   * @returns Object - Object containing 2 strings of attribute names that are either non-existent or it has the wrong data type when compared with the default configuration.
   */
  const checkForNonExistentAttributeAndWrongDataType = (
    editedConfiguration: Object
  ): {
    nonExistentAttributeNamesInString: string;
    attributesHavingWrongDataTypeInString: string;
  } => {
    const arrNonExistentAttributeName: Array<string> = [];
    const arrAttributeNameThatHasWrongDataType: Array<string> = [];
    const mapDataTypeByAttributeNameOfDefaultConfiguration = new Map();
    Object.entries(ref.defaultConfiguration).forEach(([attributeName, dataValue]) => {
      mapDataTypeByAttributeNameOfDefaultConfiguration.set(attributeName, typeof dataValue);
    });

    Object.entries(editedConfiguration).forEach(([attributeName, dataValue]) => {
      const requiredDataType = mapDataTypeByAttributeNameOfDefaultConfiguration.get(attributeName);
      if (requiredDataType === undefined) {
        arrNonExistentAttributeName.push(attributeName);
      } else if (requiredDataType !== typeof dataValue) {
        arrAttributeNameThatHasWrongDataType.push(attributeName);
      }
    });

    let nonExistentAttributeNamesInString = '-';
    let attributesHavingWrongDataTypeInString = '-';
    if (arrNonExistentAttributeName.length !== 0) {
      nonExistentAttributeNamesInString = arrNonExistentAttributeName.join(', ');
    }
    if (arrAttributeNameThatHasWrongDataType.length !== 0) {
      attributesHavingWrongDataTypeInString = arrAttributeNameThatHasWrongDataType.join(', ');
    }

    return {
      nonExistentAttributeNamesInString,
      attributesHavingWrongDataTypeInString,
    };
  };

  /**
   * This function is invoked when user clicks 'Confirm' button. The following actions take place:
   * 1. Check if there is any existing error (syntax error, duplicated attribute, no changes from original or
   * have data type of an attribute that differs from the default configuration). If there is, alert modal
   * will display error message to user and function exits here.
   * 2. Edited device configuration is posted to backend route '/device-management/update-device'
   *    a. On failure, alert modal will display error message to user and function exits here
   * 3. If successful, alert modal will display sucess message
   */
  const confirmEditDeviceConfiguration = async () => {
    try {
      // Note that the sequence of checking error is important. hasSyntaxError has to be checked before props.checkForDuplicatedAttribute
      // because props.checkForDuplicatedAttribute has a JSON.parse which will throw error if there is syntax error
      if (ref.hasSyntaxError) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage([
          'Syntax Error! Please note that all changes will be reset after you close this Error dialog.',
        ]);
        setIsAlert(true);
        return;
      }
      const isNoChanges = props.checkForNoChanges(
        ref.stringifiedEditedConfiguration,
        originalConfiguration
      );
      if (isNoChanges) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage(['No Change!']);
        setIsAlert(true);
        return;
      }
      const isDuplicated = props.checkForDuplicatedAttribute(ref.stringifiedEditedConfiguration);
      if (isDuplicated) {
        ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
        setAlertHeader('Save Error!');
        setArrAlertMessage([
          'Duplicated attribute! Please note that all changes will be reset after you close this Error dialog.',
        ]);
        setIsAlert(true);
        return;
      }

      const editedConfiguration = JSON.parse(ref.stringifiedEditedConfiguration);
      const { nonExistentAttributeNamesInString, attributesHavingWrongDataTypeInString } =
        checkForNonExistentAttributeAndWrongDataType(editedConfiguration);
      if (
        nonExistentAttributeNamesInString !== '-' ||
        attributesHavingWrongDataTypeInString !== '-'
      ) {
        setAlertHeader('Save Error!');
        setArrAlertMessage([
          `Non-existent attribute(s): ${nonExistentAttributeNamesInString}`,
          `Attribute(s) with wrong data type: ${attributesHavingWrongDataTypeInString}`,
          'Please ensure only attribute(s) that can be found in the default configuration can be used, and they must be of the same data type as per specified in the default configuration.',
        ]);
        setIsAlert(true);
        return;
      }
      const deviceToBeUpdated: Object = {
        deviceId: props.selectedDeviceCompany!.deviceId,
        configuration: editedConfiguration,
      };
      await axios.post('/device-management/update-device', {
        deviceToBeUpdated,
      });
      setIsExpanded(false);
      props.setSelectedDeviceCompany(null);
      props.updateEditedConfigurationToArrDeviceCompany(
        props.selectedDeviceCompany!.deviceId,
        editedConfiguration
      );
      setArrAlertMessage([]);
      setAlertHeader('Save success!');
      setIsAlert(true);
    } catch (error) {
      props.setSelectedDeviceCompany(null);
      setArrAlertMessage([
        'Could not submit edited device configuration, please try again later!',
        error,
      ]);
      setAlertHeader('Save error!');
      setIsAlert(true);
    }
  };

  /**
   * This function opens/closes the part of dialogue to display the other device's configuration for comparison. It is written in
   * a way such that it will check if there is any syntax or duplicated attribute error in the editing device configuration first,
   * before allowing the dialogue to expand. This is because an expansion is caused by a state change and the state change will
   * cause a rendering which the edited stringified configuration will be parsed to provide the JSON for the editable Editer
   * component. However, if there is error in parsing, the dialog component will not be able to load. Hence, if there is any error
   * in the edited configuration, user will not be able to expand/close the dialog.
   * Set default configuration as the default display whenever the dialog expands.
   */
  const expandOrCollapseExtendedDialog = () => {
    // Check for error(s) in the stringifiedEditedConfiguration
    if (ref.hasSyntaxError) {
      return;
    }
    const isDuplicated = props.checkForDuplicatedAttribute(ref.stringifiedEditedConfiguration);
    if (isDuplicated) {
      return;
    }

    const newIsExpanded = !isExpanded;
    if (newIsExpanded) {
      updateSelectedComparisonConfiguration('');
    } else {
      setComparisonConfiguration({});
    }

    setIsExpanded(newIsExpanded);
  };

  /**
   * This function removes all the changes done to the selected configuration to edit
   */
  const resetChanges = () => {
    ref.stringifiedEditedConfiguration = JSON.stringify(originalConfiguration);
    setIsResetChanges(!isResetChanges);
  };

  /**
   * This function closes dialogue when the 'Close' button is clicked. It sets state for setSelectedDeviceCompany to
   * null as that is the parameter that the dialogue checks to open, and also change isExpanded to false
   */
  const closeDialog = () => {
    // To remove the changes when the edit dialog is closed
    ref.stringifiedEditedConfiguration = undefined;
    ref.mapDeviceConfigurationBySerialNumber = undefined;
    ref.hasSyntaxError = undefined;
    setIsExpanded(false);
    props.setSelectedDeviceCompany(null);
  };

  useEffect(() => {
    if (props.selectedDeviceCompany !== null) {
      fetchDefaultConfiguration();
      setSelectedDeviceOriginalConfiguration();
    }
  }, [props.selectedDeviceCompany]);

  // Note that the time taken for the tooltip to appear is a bit slow, so a few methods
  // were tried but it doesnt really work? Maybe it wasnt implemented correctly
  // https://ahmadrosid.com/blog/react-tailwind-tooltip
  // https://stackoverflow.com/questions/9150796/change-how-fast-title-attributes-tooltip-appears
  return (
    <React.Fragment>
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <Dialog
        open={props.selectedDeviceCompany !== null}
        handler={() => {}}
        className={`${
          isExpanded ? 'min-w-[40rem] lg:min-w-[80%]' : 'min-w-[30rem] lg:min-w-[40rem]'
        } max-h-[90%] overflow-auto`}
      >
        <DialogHeader className="flex justify-between max-h-[4rem]">
          {`Edit ${props.selectedDeviceCompany?.serialNumber} Configuration`}
          <Button onClick={() => resetChanges()} color="amber" className="p-3">
            Reset Changes
          </Button>
        </DialogHeader>
        <DialogBody divider className="flex flex-row min-h-[24rem] max-h-[48rem] gap-3">
          <div
            ref={ref}
            className="DeviceConfigurationEditorDiv flex flex-col min-h-full w-full overflow-auto"
          >
            <Editor
              isResetChanges={isResetChanges}
              json={
                (!isResetChanges &&
                  ref.stringifiedEditedConfiguration &&
                  JSON.parse(ref.stringifiedEditedConfiguration)) ||
                originalConfiguration
              }
              onChangeText={(stringifiedJSON: string) => updateConfiguration(stringifiedJSON)}
              onEditable={() => true}
              onValidationError={(arrError: Array<JSONEditorError>) => {
                checkForSyntaxError(arrError);
              }}
              resetChanges={() => resetChanges()}
            />
          </div>
          <Button
            title="Button only works if there is no input error!"
            onClick={() => expandOrCollapseExtendedDialog()}
            className="group px-1 min-w-min bg-transparent shadow-none hover:shadow-none"
          >
            {isExpanded ? (
              <div className="flex flex-row gap-1 min-w-min ">
                <RxDoubleArrowLeft
                  fontSize="28px"
                  className="self-center text-gray-400 group-hover:text-gray-600"
                />
                <p
                  className="text-gray-400 group-hover:text-gray-600"
                  style={{ writingMode: 'vertical-lr' }}
                >
                  Collapse Comparison
                </p>
              </div>
            ) : (
              <div className="flex flex-row gap-1 min-w-min">
                <p
                  className="text-gray-400 group-hover:text-gray-600 rotate-180"
                  style={{ writingMode: 'vertical-rl' }}
                >
                  Show Comparison
                </p>
                <RxDoubleArrowRight
                  fontSize="28px"
                  className="self-center text-gray-400 group-hover:text-gray-600"
                />
              </div>
            )}
          </Button>
          {isExpanded && (
            <div className="flex flex-col gap-3 min-h-full w-full overflow-auto">
              <div className="flex flex-col h-1/5 w-full overflow-visible">
                <p className="font-bold text-black pb-1">Compare With:</p>
                <select
                  name="selection-devices"
                  id="selection-device"
                  defaultValue={DEFAULT_CONFIGURATION_TEXT}
                  className="border border-2 border-gray-300 rounded-md focus:ring-0 focus:border-sky-300 cursor-pointer disabled:opacity-30 disabled:cursor-default"
                  onChange={(event) => updateSelectedComparisonConfiguration(event.target.value)}
                >
                  <option key={-1} className="font-bold text-gray-600">
                    {DEFAULT_CONFIGURATION_TEXT}
                  </option>
                  {Array.from(ref.mapDeviceConfigurationBySerialNumber.keys()).map(
                    (serialNumber: any) => (
                      <option key={serialNumber} className="text-gray-600">
                        {serialNumber}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="flex flex-col h-4/5 w-full overflow-auto">
                <Editor
                  isResetChanges={false}
                  json={comparisonConfiguration || {}}
                  onChangeText={(stringifiedJSON: string) => updateConfiguration(stringifiedJSON)}
                  onEditable={() => false}
                  onValidationError={(arrError: Array<JSONEditorError>) => {
                    checkForSyntaxError(arrError);
                  }}
                  resetChanges={() => resetChanges()}
                />
              </div>
            </div>
          )}
        </DialogBody>
        <DialogFooter className="p-3 max-h-[4rem]">
          <Button variant="text" color="red" onClick={() => closeDialog()} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={confirmEditDeviceConfiguration}
            className="DeviceConfigurationConfirmButton"
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};

export default DeviceConfigurationInputDialog;
