import { useState } from 'react';
import { MdMinimize, MdOpenInFull } from 'react-icons/md';
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  IconButton,
} from '@material-tailwind/react';

import UserRestaurantLocationList from './UserRestaurantLocationList';
import { UserRestaurantLocation } from '../../interfaces';

const UserCard = (props: { userRestaurantLocation: UserRestaurantLocation; deleteUser: any }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const openUserCardOnClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className={`bg-gray-300 rounded-lg p-4 ${isExpanded && 'col-span-full 2xl:col-span-3'}`}>
        <div
          className={`UserCard flex justify-between transition ease-in-out duration-200 relative`}
        >
          <div className="flex flex-col lg:flex-row gap-x-5 gap-y-3 w-full static">
            <div className={`w-5/6 ${isExpanded && `lg:w-1/3`}`}>
              <div className="flex flex-col max-w-sm">
                <p className={`font-semibold text-xl break-all`}>
                  {props.userRestaurantLocation.email}
                </p>
                <p>
                  {props.userRestaurantLocation.firstName} {props.userRestaurantLocation.lastName}
                </p>
                <p>
                  Is Group User:{' '}
                  <span
                    className={`${
                      props.userRestaurantLocation.isCompany ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {props.userRestaurantLocation.isCompany.toString()}
                  </span>
                </p>
                <Button
                  color="red"
                  size="sm"
                  className={`w-min whitespace-nowrap`}
                  onClick={() => setIsDeleteDialogOpened(true)}
                >
                  Delete User
                </Button>
              </div>
            </div>
            {isExpanded && (
              <hr className="h-1 w-full lg:h-full lg:w-1 rounded-xl bg-gray-400 opacity-500 col-span-1 justify-self-center" />
            )}
            {isExpanded && (
              <div className="flex flex-col flex-grow">
                <h1 className="font-semibold text-xl">Properties & Location Access</h1>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-x-9 gap-y-4">
                  {props.userRestaurantLocation.arrRestaurantLocation.map((restaurantLocation) => (
                    <UserRestaurantLocationList
                      restaurantLocation={restaurantLocation}
                      key={restaurantLocation.restaurantId}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="absolute right-0">
            <IconButton
              className="UserButton hover:none"
              variant="text"
              color="teal"
              onClick={openUserCardOnClick}
            >
              {isExpanded ? (
                <MdMinimize className="text-off-black w-4 h-4" />
              ) : (
                <MdOpenInFull className="text-off-black w-4 h-4" />
              )}
            </IconButton>
          </div>
        </div>
      </div>
      <Dialog open={isDeleteDialogOpened} handler={() => {}}>
        <DialogHeader>Confirm User Deletion</DialogHeader>
        <DialogBody divider className={`flex-col`}>
          <p>
            Please confirm to delete user <b>{props.userRestaurantLocation.email}</b>
          </p>
          <br />
          <p>
            *Do note that this action is{' '}
            <u>
              <b>IRREVERSIBLE</b>
            </u>
          </p>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setIsDeleteDialogOpened(false)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => {
              props.deleteUser(
                props.userRestaurantLocation.datavizUserId,
                props.userRestaurantLocation.email
              );
              setIsDeleteDialogOpened(false);
            }}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default UserCard;
