import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import RestaurantServiceInputCard from './RestaurantServiceInputCard';
import Alert from '../alerts/Alert';
import { COMPANY_SERVICE_INPUT_MODE } from '../../../constant/General';
import InputCardsContext from '../../../context/InputCardsContext';
import { Location, Restaurant, Service } from '../../../interfaces';

const RestaurantServiceInputCardsContainer = (props: {
  arrNewRestaurant: Array<Restaurant>;
  companyId: number;
  mapMapNewLocationByLocationIdByRestaurantId: Map<number, Map<number, Location>>;
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>;
  updateMapForNewRestaurant: any;
  updateMapMapNewLocationByLocationIdByRestaurantId: any;
  updateMapMapNewServiceByServiceIdByLocationId: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const MAX_RESTAURANT_PER_COMPANY = 9;
  /**
   * States for this functional component
   */
  const [arrRestaurantId, setArrRestaurantId] = useState<Array<number>>([]); // Array stores key values of all RestaurantServiceInputCard component, for creation of new InputCards

  const [isAlert, setIsAlert] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');

  /**
   * Function updates arrRestaurantId array with a new value to create a new RestaurantServiceInputCard. It will
   * also add an error status true into the inputCardsContext's mapErrorById. If the number of locations
   * to be added already exceeds the maximum number of locations allowed for a restaurant, an alert message
   * will pop up.
   */
  const addRestaurant = () => {
    if (arrRestaurantId.length < MAX_RESTAURANT_PER_COMPANY) {
      let newRestaurantId = props.companyId - arrRestaurantId.length * 100 - 100;
      // This is to cater for the adding new restaurantService in add restaurantService mode, where there is actually a valid companyId
      if (
        inputCardsContext.input.inputModeIndex === COMPANY_SERVICE_INPUT_MODE.addRestaurantService
      ) {
        newRestaurantId = -1000 - arrRestaurantId.length * 100 - 100;
      }
      const newArrRestaurantId = [...arrRestaurantId, newRestaurantId];
      setArrRestaurantId(newArrRestaurantId);
      inputCardsContext.setErrorExist(newRestaurantId, true, 2);
    } else {
      setArrAlertMessage(['Cannot add more than 9 restaurants at one go!']);
      setAlertHeader('Sorry!');
      setIsAlert(true);
    }
  };

  /**
   * Function runs whenever Add Another Restaurant button is clicked, to create a new restaurant card
   */
  const addAnotherRestaurantOnClick = () => {
    addRestaurant();
  };

  /**
   * Function runs whenever Remove Restaurant button is clicked, removes a restaurant from arrRestaurantId and mapNewRestaurantServiceByRestaurantId.
   * Also removes the restaurant's error status and menuInputTag from the inputCardsContext's mapErrorById and mapMenuInputTagByServiceId
   * respectively.
   */
  const removeRestaurantOnClick = () => {
    let newArrRestaurantId = [...arrRestaurantId];
    if (newArrRestaurantId.length > 0) {
      const restaurantToRemoveId = newArrRestaurantId.pop();
      setArrRestaurantId(newArrRestaurantId);
      if (restaurantToRemoveId) {
        props.updateMapForNewRestaurant({
          restaurantId: restaurantToRemoveId,
          companyId: props.companyId,
        });
        inputCardsContext.removeRestaurantIdMenuInputTag(restaurantToRemoveId, -99); // Remove service data from context
        inputCardsContext.deleteErrorForAllIdsWithinRange(restaurantToRemoveId, -99);
      }
    }
  };

  const alertHandler = () => {
    setIsAlert(!isAlert);
  };

  /**
   * useEffect to set up the first restaurant card
   */
  useEffect(() => {
    addRestaurant();
  }, []);

  return (
    <div className="RestaurantServiceInputCardsContainer mt-4 mb-1">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={alertHandler}
          isExpanded={isAlert}
        />
      )}
      <div>
        <div className="flex justify-between gap-x-2 mt-2">
          <div className="text-lg font-medium">Properties Added: {arrRestaurantId.length}</div>
          <div className="flex justify-end gap-x-2 ">
            {arrRestaurantId.length > 1 && (
              <Button
                type="submit"
                color="red"
                size="sm"
                className="RemoveARestaurantButton font-medium flex gap-x-1 pt-2 p-2"
                onClick={removeRestaurantOnClick}
              >
                <MdRemove className="self-center" />
                <span>Remove a Property</span>
              </Button>
            )}
            <Button
              type="submit"
              color="lime"
              size="sm"
              className="AddAnotherRestaurantButton font-medium flex gap-x-1 pt-2 p-2"
              onClick={addAnotherRestaurantOnClick}
            >
              <MdAdd className="self-center" />
              <span>Add another Property</span>
            </Button>
          </div>
        </div>
        {/* )} */}
        <ul className="flex flex-col w-full mt-2 gap-y-1">
          {arrRestaurantId.map((restaurantId: number) => (
            <RestaurantServiceInputCard
              key={restaurantId}
              arrNewRestaurant={props.arrNewRestaurant}
              arrNewLocation={
                props.mapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)
                  ? Array.from(
                      props.mapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)!.values()
                    )
                  : []
              }
              companyId={props.companyId}
              mapMapNewServiceByServiceIdByLocationId={
                props.mapMapNewServiceByServiceIdByLocationId
              }
              restaurantId={restaurantId}
              restaurantService={undefined}
              updateMapForNewRestaurant={props.updateMapForNewRestaurant}
              updateMapMapNewLocationByLocationIdByRestaurantId={
                props.updateMapMapNewLocationByLocationIdByRestaurantId
              }
              updateMapMapNewServiceByServiceIdByLocationId={
                props.updateMapMapNewServiceByServiceIdByLocationId
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RestaurantServiceInputCardsContainer;
