import { useContext, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IconButton } from '@material-tailwind/react';

import LocationServiceInputCardsContainer from './InputComponents/LocationServiceInputCardsContainer';
import RestaurantInputCard from './InputComponents/RestaurantInputCard';
import LocationCard from './LocationCard';
import AddButton from '../Common/Buttons/AddButton';
import UpdateButton from '../Common/Buttons/UpdateButton';
import CompanyContext from '../../context/CompanyContext';
import InputCardsContext from '../../context/InputCardsContext';
import { COMPANY_SERVICE_INPUT_MODE } from '../../constant/General';
import {
  Location,
  NewLocationService,
  NewService,
  Restaurant,
  RestaurantService,
  Service,
} from '../../interfaces';

/**
 * To get an array of new locationService where all the fake IDs are removed
 * @param mapNewLocationByLocationId - Map of key: locationId and value: newLocation
 * @param mapMapNewServiceByServiceIdByLocationId - Map of key: locationId and value: Map of key: serviceId and value: newService
 * @returns arrNewLocationService - Array of newLocationService with the fake IDs removed
 */
const getArrNewLocationService = (
  mapNewLocationByLocationId: Map<number, Location>,
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>
): Array<NewLocationService> => {
  const arrNewLocationService: Array<NewLocationService> = [];
  mapNewLocationByLocationId.forEach((newLocation: Location, locationId: number) => {
    const mapNewServiceByServiceId = mapMapNewServiceByServiceIdByLocationId.get(locationId);
    const arrNewService: Array<NewService> = [];
    mapNewServiceByServiceId?.forEach((newService: Service, serviceId: number) => {
      const newServiceWithoutfakeIds: NewService = { ...newService };
      newServiceWithoutfakeIds.serviceId = undefined;
      newServiceWithoutfakeIds.locationId = undefined;
      newServiceWithoutfakeIds.menuId = undefined;
      newServiceWithoutfakeIds.menu.menuId = undefined;
      arrNewService.push(newServiceWithoutfakeIds);
    });
    const newLocationService: NewLocationService = {
      ...newLocation,
      locationId: undefined,
      arrService: arrNewService,
    };
    arrNewLocationService.push(newLocationService);
  });
  return arrNewLocationService;
};

const RestaurantCard = (props: {
  companyId: number;
  companyName: string;
  restaurantService: RestaurantService;
  saveChangesToExistingCompanyService: any;
}) => {
  const companyContext = useContext(CompanyContext);
  const inputCardsContext = useContext(InputCardsContext);
  /**
   * States for this functional component
   */
  const [componentId, setComponentId] = useState<string>('');
  const [editedRestaurant, setEditedRestaurant] = useState<Restaurant | null>(null);
  const [mapNewLocationByLocationId, setMapNewLocationByLocationId] = useState(new Map());
  const [mapMapNewServiceByServiceIdByLocationId, setMapMapNewServiceByServiceIdByLocationId] =
    useState(new Map());

  /**
   * Function can add the edited restaurant to map, with the locationId as the key, or remove the key-value if there is no change
   * to the restaurant
   * Note: 'restaurantId' is not used because there can only be editing of 1 restaurant in this component. However this function is structured
   * to take in the same set of parameters as the props of LocationInputCard component so as to reduce the number of props for the RestaurantInputCard component.
   * @param idParameters - Object containing restaurantId of the restaurant
   * @param editedRestaurant - Restaurant object containing the edited attribute of an existing retaurant, or undefined if the retaurant is to be removed
   */
  const updateEditedRestaurant = (
    idParameters: { restaurantId: number },
    editedRestaurant?: Restaurant
  ) => {
    if (editedRestaurant) {
      setEditedRestaurant(editedRestaurant);
    } else {
      setEditedRestaurant(null);
    }
  };

  /**
   * Function can add the new location to map, with the locationId as the key, or remove the key-value if the location is to be removed.
   * If the location is to be removed, remove all the new service(s) that belongs to this location.
   * @param idParameters - Object containing locationId of the location
   * @param newLocation - New location object, or undefined if the location is to be removed
   */
  const updateMapNewLocationByLocationId = (
    idParameters: { locationId: number },
    newLocation?: Location
  ) => {
    const { locationId } = idParameters;
    const updatedMapNewLocationByLocationId = new Map(mapNewLocationByLocationId);
    if (newLocation) {
      updatedMapNewLocationByLocationId.set(locationId, newLocation);
    } else {
      updatedMapNewLocationByLocationId.delete(locationId);
      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
    }
    setMapNewLocationByLocationId(updatedMapNewLocationByLocationId);
  };

  /**
   * Function can add the new service to map, with the locationId as the first key and serviceId as the second key, or remove the key-value if the service
   * is to be removed
   * @param idParameters - Object containing serviceId and locationId of the service
   * @param newService - New service object, or undefined if the service is to be removed
   */
  const updateMapMapNewServiceByServiceIdByLocationId = (
    idParameters: { serviceId: number; locationId: number },
    newService?: Location
  ) => {
    const { locationId, serviceId } = idParameters;
    const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
      mapMapNewServiceByServiceIdByLocationId
    );
    if (newService) {
      if (updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)) {
        updatedMapMapNewServiceByServiceIdByLocationId.get(locationId).set(serviceId, newService);
      } else {
        updatedMapMapNewServiceByServiceIdByLocationId.set(
          locationId,
          new Map([[serviceId, newService]])
        );
      }
    } else {
      updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)?.delete(serviceId);
    }
    setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
  };

  /**
   * Function controls dropdown button and isLocationListOpened state which controls if locationServices is shown
   */
  const isLocationListOpened =
    companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId.get(
      props.restaurantService.restaurantId
    )!.isOpened;
  const rotate = isLocationListOpened ? 'rotate(180deg)' : 'rotate(0)';
  const openLocationCard = () => {
    const { isOpened, parentId } =
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId.get(
        props.restaurantService.restaurantId
      )!;
    const newMapIsOpenAndParentIdByRestaurantId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );
    newMapIsOpenAndParentIdByRestaurantId.set(props.restaurantService.restaurantId, {
      isOpened: !isOpened,
      parentId,
    });
    companyContext.updateMapsIsOpenedById({
      mapIsOpenedsByCompanyId: companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: newMapIsOpenAndParentIdByRestaurantId,
    });
  };

  /**
   * Function is called when user clicks on Add Location or Cancel button, enables or disables addition mode by setting the correct input mode
   * in the inputCardsContext. Related RestaurantIdMenuInputTag data saved in the inputCardsContext due to the adding of new locationService will
   * be removed if the Cancel button is clicked so that the data will not be carried forward to the next input mode which can cause incorrect errors.
   */
  const enableAddLocationServiceOnClick = (event: any) => {
    const isAddLocationService = inputCardsContext.checkIfActiveInputMode(
      COMPANY_SERVICE_INPUT_MODE.addLocationService,
      componentId
    );
    if (isAddLocationService === true) {
      inputCardsContext.setInputMode(COMPANY_SERVICE_INPUT_MODE.none, '', props.companyId);
      setComponentId('');
      inputCardsContext.deleteAllError();
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    } else {
      const newComponentId = new Date();
      setComponentId(newComponentId.toString());
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.addLocationService,
        newComponentId.toString(),
        props.companyId
      );
    }
  };

  /**
   * Function is called when user clicks on Update Restaurant or Cancel button, enables or disables update mode by setting the correct input mode
   * in the inputCardsContext
   */
  const enableUpdateRestaurantOnClick = (event: any) => {
    const isUpdateRestaurant = inputCardsContext.checkIfActiveInputMode(
      COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
      componentId
    );
    if (isUpdateRestaurant === true) {
      inputCardsContext.setInputMode(COMPANY_SERVICE_INPUT_MODE.none, '', props.companyId);
      setComponentId('');
      inputCardsContext.deleteAllError();
    } else {
      const newComponentId = new Date();
      setComponentId(newComponentId.toString());
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
        newComponentId.toString(),
        props.companyId
      );
    }
  };

  /**
   * Function is run by save changes button, and calls saveChangesToExistingCompanyService function that is
   * passed down from parent CompanyCard component. Function saves newly added locationService (if any) for the
   * restaurant.
   */
  const saveChangesForAddLocationServiceOnClick = () => {
    const arrNewLocationService = getArrNewLocationService(
      mapNewLocationByLocationId,
      mapMapNewServiceByServiceIdByLocationId
    );
    props.saveChangesToExistingCompanyService(
      { companyId: props.companyId, restaurantId: props.restaurantService.restaurantId },
      {
        arrLocationServiceToBeCreated: arrNewLocationService,
      },
      {}
    );
    // Reset changes
    setMapNewLocationByLocationId(new Map());
    setMapMapNewServiceByServiceIdByLocationId(new Map());
  };

  /**
   * Function is run by save changes button, and calls saveChangesToExistingCompanyService function that is
   * passed down from parent CompanyCard component. Function saves edited retaurant (if any).
   */
  const saveChangesForUpdateRestaurantOnClick = () => {
    props.saveChangesToExistingCompanyService(
      { companyId: props.companyId, restaurantId: props.restaurantService.restaurantId },
      {},
      {
        restaurantToBeUpdated: editedRestaurant !== null ? editedRestaurant : undefined,
      }
    );
    // Reset changes
    setEditedRestaurant(null);
  };

  return (
    <li
      className={`PropertyCard bg-gray-300 rounded-lg flex-col mt-1 ${
        isLocationListOpened ? `p-4` : `p-2`
      }`}
    >
      {inputCardsContext.checkIfActiveInputMode(
        COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
        componentId
      ) ? (
        <div className="flex justify-between">
          <RestaurantInputCard
            arrNewRestaurant={[]}
            companyId={props.companyId}
            restaurantId={props.restaurantService.restaurantId}
            restaurantService={props.restaurantService}
            updateRestaurant={updateEditedRestaurant}
          />
          <UpdateButton
            isEnabled={inputCardsContext.getInputMode(
              COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
              componentId
            )}
            enableUpdateOnClick={enableUpdateRestaurantOnClick}
            saveChangesOnClick={saveChangesForUpdateRestaurantOnClick}
            isUpdate={inputCardsContext.checkIfActiveInputMode(
              COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
              componentId
            )}
            buttonName={'Update Property'}
            buttonClassName={'UpdatePropertyButton'}
            positionType=""
          />
        </div>
      ) : (
        <div
          onClick={() => {
            openLocationCard();
          }}
          className="flex justify-between items-center"
        >
          <div className="font-medium text-lg flex flex-col">
            <div className="text-[0.6rem] text-gray-600 leading-none">
              Property Id: {props.restaurantService.restaurantId}
            </div>
            <div className="flex gap-x-10">
              {props.restaurantService.name}
              <div
                className="flex items-center text-xs "
                onClick={(event) => event.stopPropagation()}
              >
                Slack Channel Id:{' '}
                {props.restaurantService.slackChannelId
                  ? props.restaurantService.slackChannelId
                  : 'None'}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            {isLocationListOpened ? (
              <UpdateButton
                isEnabled={inputCardsContext.getInputMode(
                  COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
                  componentId
                )}
                enableUpdateOnClick={enableUpdateRestaurantOnClick}
                saveChangesOnClick={saveChangesForUpdateRestaurantOnClick}
                isUpdate={inputCardsContext.checkIfActiveInputMode(
                  COMPANY_SERVICE_INPUT_MODE.updateRestaurant,
                  componentId
                )}
                buttonName={'Update Property'}
                buttonClassName={'UpdatePropertyButton'}
                positionType=""
              />
            ) : (
              <p className="text-lg">
                Locations: <span>{props.restaurantService.arrLocationService.length}</span>
              </p>
            )}
            <IconButton variant="text" size="sm" className="LocationButton p-0">
              <MdKeyboardArrowDown
                style={{ transform: rotate, transition: 'all 0.1s linear' }}
                className="h-7 w-7 text-off-black"
              />
            </IconButton>
          </div>
        </div>
      )}
      {isLocationListOpened && (
        <div className="flex-col mt-2">
          <div className="relative flex justify-between">
            <p className="text-lg">
              Locations: <span>{props.restaurantService.arrLocationService.length}</span>
            </p>
            <AddButton
              isEnabled={inputCardsContext.getInputMode(
                COMPANY_SERVICE_INPUT_MODE.addLocationService,
                componentId
              )}
              enableAddOnClick={enableAddLocationServiceOnClick}
              saveChangesOnClick={saveChangesForAddLocationServiceOnClick}
              isAdd={inputCardsContext.checkIfActiveInputMode(
                COMPANY_SERVICE_INPUT_MODE.addLocationService,
                componentId
              )}
              buttonName={'Add Location'}
              buttonClassName={'AddLocationButton'}
            />
          </div>
          {inputCardsContext.checkIfActiveInputMode(
            COMPANY_SERVICE_INPUT_MODE.addLocationService,
            componentId
          ) && (
            <LocationServiceInputCardsContainer
              arrNewLocation={Array.from(mapNewLocationByLocationId.values())}
              arrLocationService={
                props.restaurantService !== undefined
                  ? props.restaurantService!.arrLocationService
                  : []
              }
              mapMapNewServiceByServiceIdByLocationId={mapMapNewServiceByServiceIdByLocationId}
              companyId={props.companyId}
              restaurantId={props.restaurantService.restaurantId}
              updateMapForNewLocation={updateMapNewLocationByLocationId}
              updateMapMapNewServiceByServiceIdByLocationId={
                updateMapMapNewServiceByServiceIdByLocationId
              }
            />
          )}
          <ul>
            {props.restaurantService.arrLocationService.map((locationService) => (
              <LocationCard
                key={locationService.locationId}
                companyId={props.companyId}
                companyName={props.companyName}
                restaurantId={props.restaurantService.restaurantId}
                restaurantName={props.restaurantService.name}
                locationService={locationService}
                arrLocationService={props.restaurantService.arrLocationService}
                saveChangesToExistingCompanyService={props.saveChangesToExistingCompanyService}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default RestaurantCard;
