import { useEffect, useState, useRef } from 'react';
import { Input } from '@material-tailwind/react';

function getOptionClass(index: number, length: number) {
  let className = 'hover:bg-gray-200 px-4 py-2';
  if (index === 0) {
    className += ' rounded-t-lg';
  } else if (index === length - 1) {
    className += ' rounded-b-lg';
  }
  return className;
}

type InputTypeConstantAttributes = {
  inputTypeName: string;
  label: string;
  className: string;
  default: string;
};

const INPUT_TYPE_CONSTANTS: {
  [key: string]: InputTypeConstantAttributes;
} = {
  menu: {
    inputTypeName: 'menu',
    label: 'Menu Name',
    className: 'ServiceMenuInput',
    default: '',
  },
  currency: {
    inputTypeName: 'currency',
    label: 'Currency',
    className: 'ServiceCurrencyInput',
    default: 'USD',
  },
  offset: {
    inputTypeName: 'offset',
    label: 'Offset',
    className: 'ServiceOffsetInput',
    default: '',
  },
  type: {
    inputTypeName: 'type',
    label: 'Type',
    className: 'ServiceTypeInput',
    default: 'Standard',
  },
};

const Autocomplete = (props: {
  arrAllOption: any;
  isEdit: boolean;
  inputType: string;
  currentValue: string;
  originalValue: string;
  updateDropdownInput: any;
  updateManualInput: any;
  errorMessage: string;
}) => {
  const {
    isEdit,
    inputType,
    currentValue,
    originalValue,
    errorMessage,
    updateDropdownInput,
    updateManualInput,
    arrAllOption,
  } = props;
  const [arrFilteredOption, setArrFilteredOption] = useState(arrAllOption);
  const [showOption, setShowOption] = useState(false);
  const ref: any = useRef();

  /**
   * Function to update new input state, and also update the menu object/currency/offset/type in the service input object.
   * Close the dropdown list once a selection is made from the list.
   * @param selectedOption - Menu, currency, offset or type object selected from the dropdown list
   */
  const selectInputFromDropdown = (selectedOption: any) => {
    updateDropdownInput(selectedOption);
    setShowOption(false);
  };

  /**
   * Function attached to input component, takes in search input data for menu, currency, offset or type, and updated updatedService state in the ServiceInputCard.tsx.
   * Filter the arrAllOption by the search input (case insensitive).
   * Note: Currently, for menu, valid input can be manually typed or selected from the dropdown list. But for currency, offset and type, valid input must be selected
   * from the dropdown list.
   * @param event - contains input data from input html
   */
  const manualInputOnChange = (event: any) => {
    if (inputType === INPUT_TYPE_CONSTANTS.menu.inputTypeName) {
      if (isEdit && event.target.value === '') {
        updateDropdownInput({ name: '' });
      } else {
        updateManualInput({ name: event.target.value });
      }
    }

    if (inputType === INPUT_TYPE_CONSTANTS.offset.inputTypeName) {
      if (isEdit && event.target.value === '') {
        updateDropdownInput({ name: '' });
      } else {
        updateManualInput({ name: event.target.value });
      }
    }

    if (inputType === INPUT_TYPE_CONSTANTS.currency.inputTypeName) {
      if (event.target.value === '') {
        updateDropdownInput({ name: '', abbreviation: '' });
      } else {
        updateManualInput({ name: '', abbreviation: event.target.value });
      }
    }

    if (inputType === INPUT_TYPE_CONSTANTS.type.inputTypeName) {
      if (event.target.value === '') {
        updateDropdownInput({ name: '' });
      } else {
        updateManualInput({ name: event.target.value });
      }
    }

    const newManualInputInLowerCase = event.target.value.toLowerCase();
    const arrNewFilteredOption = arrAllOption.filter(
      (option: any) =>
        option.name.toLowerCase().includes(newManualInputInLowerCase) ||
        (option.abbreviation &&
          option.abbreviation.toLowerCase().includes(newManualInputInLowerCase))
    );

    setArrFilteredOption(arrNewFilteredOption);
    if (!showOption) {
      setShowOption(true);
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current.contains(event.target)) {
        setShowOption(false);
      }
    };
    document.addEventListener('click', listener);
    document.addEventListener('focusin', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('focusin', listener);
    };
  }, []);

  /**
   * Function returns a listed item element to display the default selected item for the input component if there
   * is default selection. Currently, currency and service type has default selection.
   * @param inputTypeConstantAttributes - Object containing the attributes of the current input type constant
   * @returns HTML list item element or undefined
   */
  const displayDefaultLineItem = (inputTypeConstantAttributes: InputTypeConstantAttributes) => {
    if (inputTypeConstantAttributes.default !== '') {
      return (
        <li
          className={`pointer-events-none ${getOptionClass(0, arrFilteredOption.length + 1)}`}
          key={`default`}
        >
          <p className="font-bold">Default: {inputTypeConstantAttributes.default}</p>
        </li>
      );
    }
  };

  return (
    <div className="relative" ref={ref}>
      <Input
        error={!!errorMessage}
        variant="static"
        label={INPUT_TYPE_CONSTANTS[inputType].label}
        placeholder={originalValue}
        value={currentValue}
        onFocus={() => setShowOption(true)}
        className={INPUT_TYPE_CONSTANTS[inputType].className}
        onChange={manualInputOnChange}
      />
      {!!errorMessage && <p className="text-red-500 text-xs mt-2">{errorMessage}</p>}
      {showOption && (
        <ul className="absolute bg-gray-100 mt-2 shadow-lg hover:shadow w-full rounded-xl z-50 overflow-auto max-h-80">
          {displayDefaultLineItem(INPUT_TYPE_CONSTANTS[inputType])}
          {arrFilteredOption.map((option: any, index: number) => (
            <li
              className={`${option.name
                .replaceAll(' ', '')
                .replaceAll('+', 'Pos')
                .replaceAll('-', 'Neg')
                .replaceAll(':', '')}OptionSelect ${
                inputType === INPUT_TYPE_CONSTANTS.menu.inputTypeName
                  ? getOptionClass(index, arrFilteredOption.length)
                  : getOptionClass(index + 1, arrFilteredOption.length + 1)
              }`}
              key={`${index} ${option.name}`}
              onClick={() => selectInputFromDropdown(option)}
            >
              {inputType !== INPUT_TYPE_CONSTANTS.currency.inputTypeName
                ? option.name
                : `${option.abbreviation} - ${option.name}`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
