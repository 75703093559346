// Possible offsets as found on:
// https://www.ibm.com/docs/en/cloudpakw3700/2.3.0.0?topic=SS6PD2_2.3.0/doc/psapsys_restapi/time_zone_list.htm
const ARR_OFFSET_OBJECT = [
  {
    name: '-12:00',
  },
  {
    name: '-11:00',
  },
  {
    name: '-10:00',
  },
  {
    name: '-09:30',
  },
  {
    name: '-09:00',
  },
  {
    name: '-08:00',
  },
  {
    name: '-07:00',
  },
  {
    name: '-06:00',
  },
  {
    name: '-05:00',
  },
  {
    name: '-04:30',
  },
  {
    name: '-04:00',
  },
  {
    name: '-03:30',
  },
  {
    name: '-03:00',
  },
  {
    name: '-02:00',
  },
  {
    name: '-01:30',
  },
  {
    name: '-01:00',
  },
  {
    name: '+00:00',
  },
  {
    name: '+01:00',
  },
  {
    name: '+02:00',
  },
  {
    name: '+03:00',
  },
  {
    name: '+03:07',
  },
  {
    name: '+03:30',
  },
  {
    name: '+04:00',
  },
  {
    name: '+04:30',
  },
  {
    name: '+05:00',
  },
  {
    name: '+05:30',
  },
  {
    name: '+05:45',
  },
  {
    name: '+06:00',
  },
  {
    name: '+06:30',
  },
  {
    name: '+07:00',
  },
  {
    name: '+08:00',
  },
  {
    name: '+08:45',
  },
  {
    name: '+09:00',
  },
  {
    name: '+09:30',
  },
  {
    name: '+10:00',
  },
  {
    name: '+10:30',
  },
  {
    name: '+11:00',
  },
  {
    name: '+11:30',
  },
  {
    name: '+12:00',
  },
  {
    name: '+12:45',
  },
  {
    name: '+13:00',
  },
  {
    name: '+14:00',
  },
];

export default ARR_OFFSET_OBJECT;
