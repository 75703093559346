import LocationServiceInputCardsContainer from './LocationServiceInputCardsContainer';
import RestaurantInputCard from './RestaurantInputCard';

import { Location, RestaurantService, Restaurant, Service } from '../../../interfaces';

const RestaurantServiceInputCard = (props: {
  arrNewRestaurant: Array<Restaurant>;
  arrNewLocation: Array<Location>;
  companyId: number;
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>;
  restaurantId: number;
  restaurantService: RestaurantService | undefined;
  updateMapForNewRestaurant: any;
  updateMapMapNewLocationByLocationIdByRestaurantId: any;
  updateMapMapNewServiceByServiceIdByLocationId: any;
}) => {
  return (
    <li className="RestaurantServiceInputCard flex flex-col mt-2 bg-gray-300 p-4 rounded-lg gap-y-1">
      <RestaurantInputCard
        arrNewRestaurant={props.arrNewRestaurant}
        companyId={props.companyId}
        restaurantId={props.restaurantId}
        restaurantService={props.restaurantService}
        updateRestaurant={props.updateMapForNewRestaurant}
      />
      <LocationServiceInputCardsContainer
        arrLocationService={[]}
        arrNewLocation={props.arrNewLocation}
        mapMapNewServiceByServiceIdByLocationId={props.mapMapNewServiceByServiceIdByLocationId}
        companyId={props.companyId}
        restaurantId={props.restaurantId}
        updateMapForNewLocation={props.updateMapMapNewLocationByLocationIdByRestaurantId}
        updateMapMapNewServiceByServiceIdByLocationId={
          props.updateMapMapNewServiceByServiceIdByLocationId
        }
      />
    </li>
  );
};

export default RestaurantServiceInputCard;
