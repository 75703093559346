import { useContext, useState } from 'react';
import { Input } from '@material-tailwind/react';

import CompanyContext from '../../../context/CompanyContext';
import InputCardsContext from '../../../context/InputCardsContext';
import { checkForInvalidNameInput } from '../../../functions/InputChecks';
import { Company, CompanyService } from '../../../interfaces';

const CompanyInputCard = (props: {
  arrNewCompany: Array<Company>;
  companyService: CompanyService | undefined;
  companyId: number;
  updateCompany: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const companyContext = useContext(CompanyContext);

  // If CompanyInputCard is for editing existing company, isNameValid should be true
  const isEdit = !!props.companyService;
  const [isNameValid, setIsNameValid] = useState(isEdit);
  const [nameErrorMessage, setNameErrorMessage] = useState(isEdit ? '' : 'Field cannot be empty!');

  const [companyForInput, setCompanyForInput] = useState<Company>(
    isEdit && props.companyService !== undefined
      ? {
          companyId: props.companyService.companyId,
          isAirline: props.companyService.isAirline,
          name: '',
        }
      : {
          companyId: props.companyId,
          name: '',
          isAirline: false,
        }
  );

  /**
   * Function attached to input component, takes in input data for editing this company's name and updates newCompanyService state.
   * @param event - contains input data from input html
   */
  const updateCompanyNameOnChange = (event: any) => {
    const newCompanyForInput = {
      ...companyForInput,
      name: event.target.value,
    };
    setCompanyForInput(newCompanyForInput);
  };

  /**
   * Function runs whenever onBlur event on company name Input component occurs. Validate the company name
   * - Check for alphanumeric
   * - Cannot duplicate with any new or existing company name
   * Note: If it is for editing, empty string ('') is considered a valid input as it will be treated as no change to the
   * current name. Hence there is slight difference in the handling of input between edit and add.
   */
  const companyNameInputOnBlur = () => {
    if (companyForInput.name.length === 0) {
      if (isEdit) {
        setIsNameValid(true);
        inputCardsContext.deleteErrorForOneId(companyForInput.companyId, 3);
      } else {
        setIsNameValid(false);
        setNameErrorMessage('Field cannot be empty!');
        inputCardsContext.setErrorExist(props.companyId, true, 3);
      }
    } else if (isEdit && companyForInput.name === props.companyService!.name) {
      const newCompany = { ...companyForInput, name: '' };
      setCompanyForInput(newCompany);
      setIsNameValid(true);
      inputCardsContext.deleteErrorForOneId(companyForInput.companyId, 3);
      props.updateCompany(companyForInput.companyId);
    } else {
      props.updateCompany(props.companyId, companyForInput);
      const errorMessage = checkForInvalidNameInput(
        companyForInput.name,
        props.companyId,
        companyContext.arrCompanyService,
        props.arrNewCompany,
        'Group'
      );
      if (errorMessage.length !== 0) {
        setIsNameValid(false);
        setNameErrorMessage(errorMessage);
        inputCardsContext.setErrorExist(companyForInput.companyId, true, 3);
      } else {
        setIsNameValid(true);
        inputCardsContext.deleteErrorForOneId(companyForInput.companyId, 3);
      }
    }
  };

  return (
    <div className="GroupInputCard flex justify-between p-4 gap-y-1">
      <div className="flex flex-col gap-x-1 mt-2">
        <Input
          onBlur={companyNameInputOnBlur}
          size="md"
          onChange={updateCompanyNameOnChange}
          label="Group"
          variant="static"
          value={companyForInput.name}
          placeholder={props.companyService !== undefined ? props.companyService.name : ''}
          className={`GroupNameInput text-off-black`}
          error={!isNameValid}
        />
        {!isNameValid && (
          <p className="GroupNameErrorMessage text-red-500 text-xs mt-2">{nameErrorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default CompanyInputCard;
