const ARR_CURRENCY_OBJECT = [
  {
    name: 'Afghanistan Afghani',
    abbreviation: 'AFN',
  },
  {
    name: 'Albania Lek',
    abbreviation: 'ALL',
  },
  {
    name: 'Algeria Dinar',
    abbreviation: 'DZD',
  },
  {
    name: 'Angola Kwanza',
    abbreviation: 'AOA',
  },
  {
    name: 'Argentina Peso',
    abbreviation: 'ARS',
  },
  {
    name: 'Armenia Dram',
    abbreviation: 'AMD',
  },
  {
    name: 'Aruba Florin',
    abbreviation: 'AWG',
  },
  {
    name: 'Australia Dollar',
    abbreviation: 'AUD',
  },
  {
    name: 'Austria Schilling',
    abbreviation: 'ATS',
  },
  {
    name: 'Azerbaijan New Manat',
    abbreviation: 'AZN',
  },
  {
    name: 'Bahamas Dollar',
    abbreviation: 'BSD',
  },
  {
    name: 'Bahrain Dinar',
    abbreviation: 'BHD',
  },
  {
    name: 'Bangladesh Taka',
    abbreviation: 'BDT',
  },
  {
    name: 'Barbados Dollar',
    abbreviation: 'BBD',
  },
  {
    name: 'Belarus Ruble',
    abbreviation: 'BYR',
  },
  {
    name: 'Belgium Franc',
    abbreviation: 'BEF',
  },
  {
    name: 'Belize Dollar',
    abbreviation: 'BZD',
  },
  {
    name: 'Bermuda Dollar',
    abbreviation: 'BMD',
  },
  {
    name: 'Bhutan Ngultrum',
    abbreviation: 'BTN',
  },
  {
    name: 'Bolivia Boliviano',
    abbreviation: 'BOB',
  },
  {
    name: 'Bosnia Mark',
    abbreviation: 'BAM',
  },
  {
    name: 'Botswana Pula',
    abbreviation: 'BWP',
  },
  {
    name: 'Brazil Real',
    abbreviation: 'BRL',
  },
  {
    name: 'Brunei Dollar',
    abbreviation: 'BND',
  },
  {
    name: 'Bulgaria Lev',
    abbreviation: 'BGN',
  },
  {
    name: 'Burundi Franc',
    abbreviation: 'BIF',
  },
  {
    name: 'Cambodia Riel',
    abbreviation: 'KHR',
  },
  {
    name: 'Canada Dollar',
    abbreviation: 'CAD',
  },
  {
    name: 'Cape Verde Escudo',
    abbreviation: 'CVE',
  },
  {
    name: 'Cayman Islands Dollar',
    abbreviation: 'KYD',
  },
  {
    name: 'CFA Franc BCEAO',
    abbreviation: 'XOF',
  },
  {
    name: 'CFA Franc BEAC',
    abbreviation: 'XAF',
  },
  {
    name: 'CFP Franc',
    abbreviation: 'XPF',
  },
  {
    name: 'Chili Peso',
    abbreviation: 'CLP',
  },
  {
    name: 'China Yuan/Renminbi',
    abbreviation: 'CNY',
  },
  {
    name: 'Colombia Peso',
    abbreviation: 'COP',
  },
  {
    name: 'Comoros Franc',
    abbreviation: 'KMF',
  },
  {
    name: 'Congo Franc',
    abbreviation: 'CDF',
  },
  {
    name: 'Costa Rica Colon',
    abbreviation: 'CRC',
  },
  {
    name: 'Croatia Kuna',
    abbreviation: 'HRK',
  },
  {
    name: 'Cuba Convertible Peso',
    abbreviation: 'CUC',
  },
  {
    name: 'Cuba Peso',
    abbreviation: 'CUP',
  },
  {
    name: 'Cyprus Pound',
    abbreviation: 'CYP',
  },
  {
    name: 'Czech Koruna',
    abbreviation: 'CZK',
  },
  {
    name: 'Denmark Krone',
    abbreviation: 'DKK',
  },
  {
    name: 'Djibouti Franc',
    abbreviation: 'DJF',
  },
  {
    name: 'Dominican Republich Peso',
    abbreviation: 'DOP',
  },
  {
    name: 'East Caribbean Dollar',
    abbreviation: 'XCD',
  },
  {
    name: 'Egypt Pound',
    abbreviation: 'EGP',
  },
  {
    name: 'El Salvador Colon',
    abbreviation: 'SVC',
  },
  {
    name: 'Estonia Kroon',
    abbreviation: 'EEK',
  },
  {
    name: 'Ethiopia Birr',
    abbreviation: 'ETB',
  },
  {
    name: 'Euro',
    abbreviation: 'EUR',
  },
  {
    name: 'Falkland Islands Pound',
    abbreviation: 'FKP',
  },
  {
    name: 'Fiji Dollar',
    abbreviation: 'FJD',
  },
  {
    name: 'Finland Markka',
    abbreviation: 'FIM',
  },
  {
    name: 'Gambia Dalasi',
    abbreviation: 'GMD',
  },
  {
    name: 'Georgia Lari',
    abbreviation: 'GEL',
  },
  {
    name: 'Germany Mark',
    abbreviation: 'DMK',
  },
  {
    name: 'Ghana New Cedi',
    abbreviation: 'GHS',
  },
  {
    name: 'Gibraltar Pound',
    abbreviation: 'GIP',
  },
  {
    name: 'Great Britain Pound',
    abbreviation: 'GBP',
  },
  {
    name: 'Greece Drachma',
    abbreviation: 'GRD',
  },
  {
    name: 'Guatemala Quetzal',
    abbreviation: 'GTQ',
  },
  {
    name: 'Guinea Franc',
    abbreviation: 'GNF',
  },
  {
    name: 'Guyana Dollar',
    abbreviation: 'GYD',
  },
  {
    name: 'Haiti Gourde',
    abbreviation: 'HTG',
  },
  {
    name: 'Honduras Lempira',
    abbreviation: 'HNL',
  },
  {
    name: 'Hong Kong Dollar',
    abbreviation: 'HKD',
  },
  {
    name: 'Hungary Forint',
    abbreviation: 'HUF',
  },
  {
    name: 'Iceland Krona',
    abbreviation: 'ISK',
  },
  {
    name: 'India Rupee',
    abbreviation: 'INR',
  },
  {
    name: 'Indonesia Rupiah',
    abbreviation: 'IDR',
  },
  {
    name: 'Iran Rial',
    abbreviation: 'IRR',
  },
  {
    name: 'Iraq Dinar',
    abbreviation: 'IQD',
  },
  {
    name: 'Ireland Pound',
    abbreviation: 'IED',
  },
  {
    name: 'Israel New Shekel',
    abbreviation: 'ILS',
  },
  {
    name: 'Italy Lira',
    abbreviation: 'ITL',
  },
  {
    name: 'Jamaica Dollar',
    abbreviation: 'JMD',
  },
  {
    name: 'Japan Yen',
    abbreviation: 'JPY',
  },
  {
    name: 'Jordan Dinar',
    abbreviation: 'JOD',
  },
  {
    name: 'Kazakhstan Tenge',
    abbreviation: 'KZT',
  },
  {
    name: 'Kenya Shilling',
    abbreviation: 'KES',
  },
  {
    name: 'Kuwait Dinar',
    abbreviation: 'KWD',
  },
  {
    name: 'Kyrgyzstan Som',
    abbreviation: 'KGS',
  },
  {
    name: 'Laos Kip',
    abbreviation: 'LAK',
  },
  {
    name: 'Latvia Lats',
    abbreviation: 'LVL',
  },
  {
    name: 'Lebanon Pound',
    abbreviation: 'LBP',
  },
  {
    name: 'Lesotho Loti',
    abbreviation: 'LSL',
  },
  {
    name: 'Liberia Dollar',
    abbreviation: 'LRD',
  },
  {
    name: 'Libya Dinar',
    abbreviation: 'LYD',
  },
  {
    name: 'Lithuania Litas',
    abbreviation: 'LTL',
  },
  {
    name: 'Luxembourg Franc',
    abbreviation: 'LUF',
  },
  {
    name: 'Macau Pataca',
    abbreviation: 'MOP',
  },
  {
    name: 'Macedonia Denar',
    abbreviation: 'MKD',
  },
  {
    name: 'Malagasy Ariary',
    abbreviation: 'MGA',
  },
  {
    name: 'Malawi Kwacha',
    abbreviation: 'MWK',
  },
  {
    name: 'Malaysia Ringgit',
    abbreviation: 'MYR',
  },
  {
    name: 'Maldives Rufiyaa',
    abbreviation: 'MVR',
  },
  {
    name: 'Malta Lira',
    abbreviation: 'MTL',
  },
  {
    name: 'Mauritania Ouguiya',
    abbreviation: 'MRO',
  },
  {
    name: 'Mauritius Rupee',
    abbreviation: 'MUR',
  },
  {
    name: 'Mexico Peso',
    abbreviation: 'MXN',
  },
  {
    name: 'Moldova Leu',
    abbreviation: 'MDL',
  },
  {
    name: 'Mongolia Tugrik',
    abbreviation: 'MNT',
  },
  {
    name: 'Morocco Dirham',
    abbreviation: 'MAD',
  },
  {
    name: 'Mozambique New Metical',
    abbreviation: 'MZN',
  },
  {
    name: 'Myanmar Kyat',
    abbreviation: 'MMK',
  },
  {
    name: 'Namibia Dollar',
    abbreviation: 'NAD',
  },
  {
    name: 'Nepal Rupee',
    abbreviation: 'NPR',
  },
  {
    name: 'Netherlands Guilder',
    abbreviation: 'NLG',
  },
  {
    name: 'New Zealand Dollar',
    abbreviation: 'NZD',
  },
  {
    name: 'Nicaragua Cordoba Oro',
    abbreviation: 'NIO',
  },
  {
    name: 'Nigeria Naira',
    abbreviation: 'NGN',
  },
  {
    name: 'NL Antilles Guilder',
    abbreviation: 'ANG',
  },
  {
    name: 'North Korea Won',
    abbreviation: 'KPW',
  },
  {
    name: 'Norway Kroner',
    abbreviation: 'NOK',
  },
  {
    name: 'Oman Rial',
    abbreviation: 'OMR',
  },
  {
    name: 'Pakistan Rupee',
    abbreviation: 'PKR',
  },
  {
    name: 'Panama Balboa',
    abbreviation: 'PAB',
  },
  {
    name: 'Papua New Guinea Kina',
    abbreviation: 'PGK',
  },
  {
    name: 'Paraguay Guarani',
    abbreviation: 'PYG',
  },
  {
    name: 'Peru Nuevo Sol',
    abbreviation: 'PEN',
  },
  {
    name: 'Philippines Peso',
    abbreviation: 'PHP',
  },
  {
    name: 'Poland Zloty',
    abbreviation: 'PLN',
  },
  {
    name: 'Portugal Escudo',
    abbreviation: 'PTE',
  },
  {
    name: 'Qatar Rial',
    abbreviation: 'QAR',
  },
  {
    name: 'Romania New Lei',
    abbreviation: 'RON',
  },
  {
    name: 'Russia Rouble',
    abbreviation: 'RUB',
  },
  {
    name: 'Rwanda Franc',
    abbreviation: 'RWF',
  },
  {
    name: 'Samoa Tala',
    abbreviation: 'WST',
  },
  {
    name: 'Sao Tome/Principe Dobra',
    abbreviation: 'STD',
  },
  {
    name: 'Saudi Arabia Riyal',
    abbreviation: 'SAR',
  },
  {
    name: 'Serbia Dinar',
    abbreviation: 'RSD',
  },
  {
    name: 'Seychelles Rupee',
    abbreviation: 'SCR',
  },
  {
    name: 'Sierra Leone Leone',
    abbreviation: 'SLL',
  },
  {
    name: 'Singapore Dollar',
    abbreviation: 'SGD',
  },
  {
    name: 'Slovakia Koruna',
    abbreviation: 'SKK',
  },
  {
    name: 'Slovenia Tolar',
    abbreviation: 'SIT',
  },
  {
    name: 'Solomon Islands Dollar',
    abbreviation: 'SBD',
  },
  {
    name: 'Somali Shilling',
    abbreviation: 'SOS',
  },
  {
    name: 'South Africa Rand',
    abbreviation: 'ZAR',
  },
  {
    name: 'South Korea Won',
    abbreviation: 'KRW',
  },
  {
    name: 'Spain Peseta',
    abbreviation: 'ESP',
  },
  {
    name: 'Sri Lanka Rupee',
    abbreviation: 'LKR',
  },
  {
    name: 'St Helena Pound',
    abbreviation: 'SHP',
  },
  {
    name: 'Sudan Pound',
    abbreviation: 'SDG',
  },
  {
    name: 'Suriname Dollar',
    abbreviation: 'SRD',
  },
  {
    name: 'Swaziland Lilangeni',
    abbreviation: 'SZL',
  },
  {
    name: 'Sweden Krona',
    abbreviation: 'SEK',
  },
  {
    name: 'Switzerland Franc',
    abbreviation: 'CHF',
  },
  {
    name: 'Syria Pound',
    abbreviation: 'SYP',
  },
  {
    name: 'Taiwan Dollar',
    abbreviation: 'TWD',
  },
  {
    name: 'Tanzania Shilling',
    abbreviation: 'TZS',
  },
  {
    name: 'Thailand Baht',
    abbreviation: 'THB',
  },
  {
    name: "Tonga Pa'anga",
    abbreviation: 'TOP',
  },
  {
    name: 'Trinidad/Tobago Dollar',
    abbreviation: 'TTD',
  },
  {
    name: 'Tunisia Dinar',
    abbreviation: 'TND',
  },
  {
    name: 'Turkish New Lira',
    abbreviation: 'TRY',
  },
  {
    name: 'Turkmenistan Manat',
    abbreviation: 'TMM',
  },
  {
    name: 'Uganda Shilling',
    abbreviation: 'UGX',
  },
  {
    name: 'Ukraine Hryvnia',
    abbreviation: 'UAH',
  },
  {
    name: 'United Arab Emirates Dirham',
    abbreviation: 'AED',
  },
  {
    name: 'Uruguay Peso',
    abbreviation: 'UYU',
  },
  {
    name: 'USA Dollar',
    abbreviation: 'USD',
  },
  {
    name: 'Vanuatu Vatu',
    abbreviation: 'VUV',
  },
  {
    name: 'Venezuela Bolivar',
    abbreviation: 'VEB',
  },
  {
    name: 'Vietnam Dong',
    abbreviation: 'VND',
  },
  {
    name: 'Yemen Rial',
    abbreviation: 'YER',
  },
  {
    name: 'Zambia Kwacha',
    abbreviation: 'ZMK',
  },
  {
    name: 'Zimbabwe Dollar',
    abbreviation: 'ZWD',
  },
];

export default ARR_CURRENCY_OBJECT;
