import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdAdd, MdRefresh, MdEdit, MdEditNote } from 'react-icons/md';
import { Button, Switch } from '@material-tailwind/react';

import Alert from '../CompanyComponents/alerts/Alert';
import { DeviceCompany } from '../../interfaces';
import AddDeviceDialog from './DeviceInputDialog';
import EditDefaultConfigurationDialog from './DefaultConfigurationInputDialog';
import EditDeviceConfigurationDialog from './DeviceConfigurationInputDialog';

const DeviceManagementPage = () => {
  const [arrDeviceCompany, setArrDeviceCompany] = useState<Array<DeviceCompany>>([]);
  const [setSerialNumber, setSetSerialNumber] = useState<Set<string>>(new Set());
  const [setVersion, setSetVersion] = useState<Set<string>>(new Set());
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isShowAllDevices, setIsShowAllDevices] = useState<boolean>(false);
  const [isEditDefaultConfiguration, setIsEditDefaultConfiguration] = useState<boolean>(false);
  const [selectedDeviceCompany, setSelectedDeviceCompany] = useState<DeviceCompany | null>(null);

  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [alertHeader, setAlertHeader] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  /**
   * This function checks if there is duplicated attribute in the configuration input.
   * It is used by both edit default configuration dialog and edit device configuration dialog, and therefore placed
   * in the parent component.
   * Note:
   * Parsed object from a JSON will remove duplicated attribute, taking the last key-value pair of any
   * duplicated attribute. Hence stringifying this object, and comparing its length and the the length of
   * the stringified configuration input can determine if there is duplicated attribute
   * @param stringifiedConfigurationInput - Stringified JSON of updated configuration input
   */
  const checkForDuplicatedAttribute = (stringifiedConfigurationInput: string) => {
    // This is necessary as the stringified configuration input comes with space after colon, and a newline
    // after every key-value pair
    const stringifiedConfigurationInputWithoutSpaceAndNewLine =
      stringifiedConfigurationInput.replaceAll(/[\s | \n]/gm, '');

    const parsedConfigurationInput: Object = JSON.parse(stringifiedConfigurationInput);

    // Need to remove all spaces because stringifiedConfigurationInputWithoutSpaceAndNewLine does not have any
    // space
    const stringifiedEditedConfigurationWithoutSpaceFromParsedInput: string = JSON.stringify(
      parsedConfigurationInput
    ).replaceAll(/[\s]/gm, '');

    if (
      stringifiedEditedConfigurationWithoutSpaceFromParsedInput.length !==
      stringifiedConfigurationInputWithoutSpaceAndNewLine.length
    ) {
      return true;
    }
    return false;
  };

  /**
   * This function checks if the stringified configuration input is the same as the stringified original configuration.
   * It is used by both edit default configuration dialog and edit device configuration dialog, and therefore placed
   * in the parent component.
   * @param stringifiedConfigurationInput - Stringified JSON of updated configuration input
   * @returns Boolean - Indicates if changes has been made to the original configuration
   */
  const checkForNoChanges = (
    stringifiedConfigurationInput: string,
    originalConfiguration: Object
  ) => {
    const editedConfiguration = JSON.parse(stringifiedConfigurationInput);
    const stringifiedEditedConfiguration = JSON.stringify(editedConfiguration);
    const stringifiedOriginalConfiguration: string = JSON.stringify(originalConfiguration);
    if (stringifiedOriginalConfiguration === stringifiedEditedConfiguration) {
      return true;
    }
    return false;
  };

  /**
   * This function updates the edited configuration and the configuration last updated date after the edited configuration
   * has been successfully updated to the database
   * @param deviceId - Id of the device for the edited configuration to be updated to
   * @param editedConfiguration - Edited configuration to be updated to the deviceCompany
   */
  const updateEditedConfigurationToArrDeviceCompany = async (
    deviceId: number,
    editedConfiguration: Object
  ) => {
    const deviceCompanyIndex = arrDeviceCompany.findIndex(
      (deviceCompany) => deviceCompany.deviceId === deviceId
    );
    // Not setState because object is stored by reference, and updating any parameter within does not change the reference
    arrDeviceCompany[deviceCompanyIndex].configuration = editedConfiguration;
    // Update the configuration last updated date. It is done in the Frontend so that it does not have to be returned from
    // the Backend, as the exact datetime is not important, only the date is essential
    arrDeviceCompany[deviceCompanyIndex].configurationLastUpdated = new Date().toISOString();
  };

  /**
   * This function updates the edited isInService after it has been successfully updated to the database
   * @param deviceId - Id of the device for the edited isInService to be updated to
   * @param isInService - Edited isInService to be updated to the deviceCompany
   */
  const updateIsInServiceToArrDeviceCompany = async (
    deviceId: number,
    editedIsInService: boolean
  ) => {
    const deviceCompanyIndex = arrDeviceCompany.findIndex(
      (deviceCompany) => deviceCompany.deviceId === deviceId
    );
    // Not setState because object is stored by reference, and updating any parameter within does not change the reference
    arrDeviceCompany[deviceCompanyIndex].isInService = editedIsInService;
    // This is done because a re-render is required to show the edited isInService
    // Note: This is handled differently from updateEditedConfigurationToArrDeviceCompany because the edited configuration is
    // not displayed in this component.
    setArrDeviceCompany(arrDeviceCompany.slice());
  };

  /**
   * This function fetches all devices details on component mount
   */
  const fetchDeviceDetails = async () => {
    try {
      const response = await axios.post('/device-management/fetch-device-company-details');
      const { arrDeviceCompany } = response.data;
      setArrDeviceCompany(arrDeviceCompany);
      const setSerialNumber: Set<string> = new Set();
      const setVersion: Set<string> = new Set();
      arrDeviceCompany.forEach((deviceCompany: DeviceCompany) => {
        setSerialNumber.add(deviceCompany.serialNumber.toLowerCase());
        setVersion.add(deviceCompany.version);
      });
      setSetSerialNumber(setSerialNumber);
      setSetVersion(setVersion);
    } catch (error) {
      setArrAlertMessage([error]);
      setAlertHeader('Fetch devices error!');
      setIsAlert(true);
    }
  };

  /**
   * This function updates the isInService boolean value in the database
   */
  const updateIsInService = async (deviceCompany: DeviceCompany) => {
    try {
      const deviceToBeUpdated: any = {
        deviceId: deviceCompany.deviceId,
        isInService: !deviceCompany.isInService,
      };
      await axios.post('/device-management/update-device', {
        deviceToBeUpdated,
      });
      updateIsInServiceToArrDeviceCompany(
        deviceToBeUpdated.deviceId,
        deviceToBeUpdated.isInService
      );
    } catch (error) {
      setArrAlertMessage([error]);
      setAlertHeader('Update isInService error!');
      setIsAlert(true);
    }
  };

  /**
   * This function takes in a DeviceCompany object and renders the device's information for the Device table row
   * @param deviceCompany - DeviceCompany object containing the device's serial number and last waste thrown (and if waste has ever been thrown using the device)
   * @returns - Table row element containing table data elements of serial number, last known company location and last used date & time
   */
  const renderDeviceCompanyTableRow = (deviceCompany: DeviceCompany) => {
    const {
      configurationLastUpdated,
      serialNumber,
      arrWaste,
      statusOne,
      statusTwo,
      statusThree,
      isInService,
      version,
    } = deviceCompany;
    let lastUsed = '-';
    let lastKnownCompanyLocation = '-';
    let configurationLastUpdatedDate = moment(configurationLastUpdated).format('DD MMM YYYY');
    if (arrWaste.length !== 0) {
      // Note: lastWaste is taken as last element of arrWaste due to database returning more than one max 'time' from waste table. To be looked into after device management implementation
      const lastWaste = arrWaste[arrWaste.length - 1];
      const { wasteTime, service } = lastWaste;
      const { location } = service;
      const { restaurant, locationName } = location;
      const { company, restaurantName } = restaurant;
      const { companyName } = company;
      lastKnownCompanyLocation = `${companyName} / ${restaurantName} / ${locationName}`;
      lastUsed = moment(wasteTime).format('DD MMM YYYY (HH:mm:ss)');
    }

    return (
      <tr key={serialNumber} className="DeviceTableRow">
        <td className="DeviceRowSerialNumber border px-2 py-2 font-medium whitespace-nowrap sticky left-0 bg-white">
          {serialNumber}
        </td>
        <td className="DeviceRowVersion border px-2 py-2 font-medium whitespace-nowrap sticky left-0 bg-white">
          {version}
        </td>
        <td className="DeviceRowLastKnownCompanyLocation border px-2 py-2 font-medium w-10 whitespace-nowrap">
          {lastKnownCompanyLocation}
        </td>
        <td className="DeviceRowLastUsed border px-2 py-2 font-medium w-[5%]">
          <span className="flex justify-center text-center">{lastUsed}</span>
        </td>
        <td className="border px-2 py-3 whitespace-nowrap">
          <div className="flex justify-center gap-x-3">
            <Button
              onClick={() => setSelectedDeviceCompany(deviceCompany)}
              className={`${serialNumber}ConfigurationButton font-medium flex px-1 py-1 bg-red-400 h-min self-center`}
            >
              <MdEditNote fontSize="20px" />
            </Button>
            <span
              className={`${serialNumber}ConfigurationLastUpdated font-medium whitespace-pre-line min-w-max self-center`}
            >
              {configurationLastUpdatedDate}
            </span>
          </div>
        </td>
        <td className={`border px-2 py-2 font-medium min-w-[8em] whitespace-nowrap`}>
          {statusOne}
        </td>
        <td className={`border px-2 py-2 font-medium min-w-[8em] whitespace-nowrap`}>
          {statusTwo}
        </td>
        <td className={`border px-2 py-2 font-medium min-w-[8em] whitespace-nowrap`}>
          {statusThree}
        </td>
        <td className="border px-2 py-5">
          <div className="flex justify-center">
            <Switch
              color="teal"
              ripple={true}
              id={`${serialNumber}`}
              checked={isInService}
              onChange={() => updateIsInService(deviceCompany)}
            />
          </div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    fetchDeviceDetails();
  }, []);

  const filteredArrDeviceCompany = isShowAllDevices
    ? arrDeviceCompany
    : arrDeviceCompany.filter((deviceCompany) => deviceCompany.isInService);

  return (
    <div className="DeviceManagementPage p-5 pt-3 flex flex-col">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <AddDeviceDialog
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        setSerialNumber={setSerialNumber}
        setSetSerialNumber={setSetSerialNumber}
        arrDeviceCompany={arrDeviceCompany}
        setArrDeviceCompany={setArrDeviceCompany}
        setVersion={setVersion}
        setSetVersion={setSetVersion}
      />

      <EditDefaultConfigurationDialog
        isEdit={isEditDefaultConfiguration}
        setIsEdit={setIsEditDefaultConfiguration}
        checkForNoChanges={checkForNoChanges}
        checkForDuplicatedAttribute={checkForDuplicatedAttribute}
      />

      <EditDeviceConfigurationDialog
        arrDeviceCompany={arrDeviceCompany}
        selectedDeviceCompany={selectedDeviceCompany}
        checkForNoChanges={checkForNoChanges}
        checkForDuplicatedAttribute={checkForDuplicatedAttribute}
        setSelectedDeviceCompany={setSelectedDeviceCompany}
        updateEditedConfigurationToArrDeviceCompany={updateEditedConfigurationToArrDeviceCompany}
      />

      <div className="flex flex-wrap justify-between content-center gap-y-3">
        <div className="flex flex-col">
          <h1 className="font-bold text-[#152445] text-2xl inline-block align-text-bottom">
            Devices (<span>{filteredArrDeviceCompany.length} Total</span>)
          </h1>
          <Switch
            ripple={true}
            id="Display"
            label="Show all devices"
            className="checked:bg-[#152445]"
            checked={isShowAllDevices}
            labelProps={{
              className: 'text-sm',
            }}
            onChange={() => setIsShowAllDevices(!isShowAllDevices)}
          />
        </div>
        <div className="grow flex flex-wrap content-end gap-x-3 gap-y-3 md:justify-end">
          <Button
            onClick={fetchDeviceDetails}
            className="DeviceRefreshButton font-medium flex gap-x-1 pl-3 pr-3 bg-green-600 self-center"
            size="sm"
          >
            <MdRefresh className="self-center" />
            Refresh
          </Button>
          <Button
            onClick={() => setIsEditDefaultConfiguration(true)}
            className="DefaultDeviceConfigurationButton font-medium flex gap-x-1 pl-3 pr-3 self-center"
            color="amber"
            size="sm"
          >
            <MdEdit className="self-center" />
            Edit Default Configuration
          </Button>
          <Button
            onClick={() => setIsAdd(true)}
            className="DeviceAddButton font-medium flex gap-x-1 pl-3 pr-3 bg-regal-blue self-center"
            size="sm"
          >
            <MdAdd className="self-center" />
            Add Device(s)
          </Button>
        </div>
      </div>
      <div className="w-[95vw] 6 border-2 rounded-xl mt-5 self-center max-h-[70vh] overflow-auto overscroll-none">
        <table className="table-auto w-full text-xs">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              <th className="pl-2 border text-left whitespace-nowrap sticky left-0 bg-white">
                Serial Number
              </th>
              <th className="py-3 px-2 border whitespace-nowrap">Version</th>
              <th className="py-3 px-2 border whitespace-nowrap">
                <span className="flex self-center">
                  Last Known Location (Group / Property / Location)
                </span>
              </th>
              <th className="py-3 px-2 border whitespace-nowrap">Last Received</th>
              <th className="py-3 px-2 border whitespace-nowrap">Configuration</th>
              <th className="py-3 px-2 border whitespace-nowrap">Status One</th>
              <th className="py-3 px-2 border whitespace-nowrap">Status Two</th>
              <th className="py-3 px-2 border whitespace-nowrap">Status Three</th>
              <th className="py-3 px-2 border whitespace-nowrap">Is In Service</th>
            </tr>
          </thead>
          <tbody>
            {filteredArrDeviceCompany.map((deviceCompany) =>
              renderDeviceCompanyTableRow(deviceCompany)
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeviceManagementPage;
