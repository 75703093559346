import { useContext, useEffect, useState } from 'react';
import { Input } from '@material-tailwind/react';

import UserRestaurantLocationCheckboxList from './UserRestaurantLocationCheckboxList';
import InputCardsContext from '../../context/InputCardsContext';
import UserContext from '../../context/UserContext';
import { checkIsUserNameValidWithRegEx, isEmailValidCheck } from '../../functions/InputValidation';
import {
  RestaurantLocation,
  RestaurantService,
  User,
  UserRestaurantLocation,
} from '../../interfaces';

const UserInputCard = (props: {
  arrAllRestaurantService: Array<RestaurantService>;
  existingUserRestaurantLocation: UserRestaurantLocation | null;
  updateNewArrRestaurantLocation: any;
  updateNewUser: any;
  updateMapIsErrorByUserId: any;
  updateMapEditedArrRestaurantLocationByUserId: any;
  updateMapEditedUserByUserId: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const userContext = useContext(UserContext);
  const [userForInput, setUserForInput] = useState<User>(
    inputCardsContext.userCardInputModeIndex === 1 && props.existingUserRestaurantLocation !== null
      ? {
          datavizUserId: props.existingUserRestaurantLocation.datavizUserId,
          email: '',
          firstName: '',
          lastName: '',
          isEmailPrimaryRecipient: props.existingUserRestaurantLocation.isEmailPrimaryRecipient,
          isUsingOtp: props.existingUserRestaurantLocation.isUsingOtp,
          isCompany: props.existingUserRestaurantLocation.isCompany,
        }
      : {
          datavizUserId: -1,
          email: '',
          firstName: '',
          lastName: '',
          isEmailPrimaryRecipient: false,
          isUsingOtp: false,
          isCompany: false,
        }
  );

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);

  /**
   * Function updates userForInput object with new email input
   */
  const updateEmailOnChange = (event: any) => {
    setUserForInput({
      ...userForInput,
      email: event.target.value,
    });
  };

  /**
   * Function runs input validation checks on email input whenever user clicks outside of the input box
   */
  const emailInputOnBlur = () => {
    if (inputCardsContext.userCardInputModeIndex === 1) {
      if (
        userForInput.email === '' &&
        userForInput.firstName === '' &&
        userForInput.lastName === ''
      ) {
        setIsEmailValid(true);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, {});
      } else {
        const updatedUserForInput = {
          datavizUserId: props.existingUserRestaurantLocation?.datavizUserId,
          email:
            userForInput.email === ''
              ? props.existingUserRestaurantLocation?.email
              : userForInput.email,
          firstName:
            userForInput.firstName === ''
              ? props.existingUserRestaurantLocation?.firstName
              : userForInput.firstName,
          lastName:
            userForInput.lastName === ''
              ? props.existingUserRestaurantLocation?.lastName
              : userForInput.lastName,
          isEmailPrimaryRecipient: props.existingUserRestaurantLocation?.isEmailPrimaryRecipient,
          isUsingOtp: props.existingUserRestaurantLocation?.isUsingOtp,
          isCompany: props.existingUserRestaurantLocation?.isCompany,
        };
        const isUpdatedEmailValid = isEmailValidCheck(userForInput.email, userContext.arrEmail);
        setIsEmailValid(isUpdatedEmailValid);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, updatedUserForInput);
      }
    } else {
      const isUpdatedEmailValid = isEmailValidCheck(userForInput.email, userContext.arrEmail);
      setIsEmailValid(isUpdatedEmailValid);
      props.updateNewUser(userForInput);
    }
  };

  /**
   * Function updates userForInput object with new first name input
   */
  const updateFirstNameOnChange = (event: any) => {
    setUserForInput({
      ...userForInput,
      firstName: event.target.value,
    });
  };

  /**
   * Function runs input validation checks on first name input whenever user clicks outside of the input box
   */
  const firstNameInputOnBlur = () => {
    if (inputCardsContext.userCardInputModeIndex === 1) {
      if (
        userForInput.email === '' &&
        userForInput.firstName === '' &&
        userForInput.lastName === ''
      ) {
        setIsFirstNameValid(true);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, {});
      } else {
        const updatedUserForInput = {
          datavizUserId: props.existingUserRestaurantLocation?.datavizUserId,
          email:
            userForInput.email === ''
              ? props.existingUserRestaurantLocation?.email
              : userForInput.email,
          firstName:
            userForInput.firstName === ''
              ? props.existingUserRestaurantLocation?.firstName
              : userForInput.firstName,
          lastName:
            userForInput.lastName === ''
              ? props.existingUserRestaurantLocation?.lastName
              : userForInput.lastName,
          isEmailPrimaryRecipient: props.existingUserRestaurantLocation?.isEmailPrimaryRecipient,
          isUsingOtp: props.existingUserRestaurantLocation?.isUsingOtp,
          isCompany: props.existingUserRestaurantLocation?.isCompany,
        };
        const isUpdatedFirstNameValid = checkIsUserNameValidWithRegEx(userForInput.firstName);
        setIsFirstNameValid(isUpdatedFirstNameValid);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, updatedUserForInput);
      }
    } else {
      const isUpdatedFirstNameValid = checkIsUserNameValidWithRegEx(userForInput.firstName);
      setIsFirstNameValid(isUpdatedFirstNameValid);
      props.updateNewUser(userForInput);
    }
  };

  /**
   * Function updates userForInput object with new last name input
   */
  const updateLastNameOnChange = (event: any) => {
    setUserForInput({
      ...userForInput,
      lastName: event.target.value,
    });
  };

  /**
   * Function runs input validation checks on last name input whenever user clicks outside of the input box
   */
  const lastNameInputOnBlur = () => {
    if (inputCardsContext.userCardInputModeIndex === 1) {
      if (
        userForInput.email === '' &&
        userForInput.firstName === '' &&
        userForInput.lastName === ''
      ) {
        setIsLastNameValid(true);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, {});
      } else {
        const updatedUserForInput = {
          datavizUserId: props.existingUserRestaurantLocation?.datavizUserId,
          email:
            userForInput.email === ''
              ? props.existingUserRestaurantLocation?.email
              : userForInput.email,
          firstName:
            userForInput.firstName === ''
              ? props.existingUserRestaurantLocation?.firstName
              : userForInput.firstName,
          lastName:
            userForInput.lastName === ''
              ? props.existingUserRestaurantLocation?.lastName
              : userForInput.lastName,
          isEmailPrimaryRecipient: props.existingUserRestaurantLocation?.isEmailPrimaryRecipient,
          isUsingOtp: props.existingUserRestaurantLocation?.isUsingOtp,
          isCompany: props.existingUserRestaurantLocation?.isCompany,
        };
        const isUpdatedLastNameValid = checkIsUserNameValidWithRegEx(userForInput.lastName);
        setIsLastNameValid(isUpdatedLastNameValid);
        props.updateMapEditedUserByUserId(userForInput.datavizUserId, updatedUserForInput);
      }
    } else {
      const isUpdatedLastNameValid = checkIsUserNameValidWithRegEx(userForInput.lastName);
      setIsLastNameValid(isUpdatedLastNameValid);
      props.updateNewUser(userForInput);
    }
  };

  /**
   * Function runs whenever there is a change in the restaurantLocation(s)/location(s) selected.
   * This function updates mapEditedArrRestaurantLocationByUserId in the parent component.
   * @param arrNewRestaurantLocation - Array of new restaurantLocation(s) selected
   */
  const updateArrRestaurantLocationOnCheck = (
    arrNewRestaurantLocation: Array<RestaurantLocation>
  ) => {
    if (inputCardsContext.userCardInputModeIndex === 1) {
      props.updateMapEditedArrRestaurantLocationByUserId(
        userForInput.datavizUserId,
        arrNewRestaurantLocation
      );
    } else {
      props.updateNewArrRestaurantLocation(arrNewRestaurantLocation);
    }
  };

  /**
   * useEffect is used to update the state mapIsErrorByUserId in the parent component if there is any invalid input for email,
   * first name and last name
   */
  useEffect(() => {
    if (isEmailValid && isFirstNameValid && isLastNameValid) {
      props.updateMapIsErrorByUserId(userForInput.datavizUserId, false);
    } else {
      props.updateMapIsErrorByUserId(userForInput.datavizUserId, true);
    }
  }, [isEmailValid, isFirstNameValid, isLastNameValid]);

  return (
    <div className="flex p-4 mt-3 rounded-lg transition ease-in-out duration-200 bg-gray-300">
      <div className="flex flex-col items-center md:items-start md:flex-row gap-x-5 w-full">
        <div className="flex flex-grow flex-1 flex-col gap-y-8 mt-2 p-3 w-full md:w-1/2">
          <div className="flex flex-col">
            <Input
              onBlur={emailInputOnBlur}
              label="Email"
              variant="static"
              placeholder={
                props.existingUserRestaurantLocation !== null
                  ? props.existingUserRestaurantLocation.email
                  : ''
              }
              className={`UserEmailInput w-max text-off-black ${isFirstNameValid && 'error'}`}
              onChange={updateEmailOnChange}
              error={!isEmailValid}
            />
            {!isEmailValid && (
              <p className="text-red-500 text-xs mt-2">
                Email is either invalid or it already exists.
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <Input
              onBlur={firstNameInputOnBlur}
              label="First Name"
              error={!isFirstNameValid}
              variant="static"
              placeholder={
                props.existingUserRestaurantLocation !== null
                  ? props.existingUserRestaurantLocation.firstName
                  : ''
              }
              className="UserFirstNameInput w-max text-off-black"
              onChange={updateFirstNameOnChange}
            />
            {!isFirstNameValid && (
              <p className="text-red-500 text-xs mt-2">
                Only alphabatical characters and 1 space allowed!
              </p>
            )}
          </div>
          <div>
            <Input
              onBlur={lastNameInputOnBlur}
              label="Last Name"
              error={!isLastNameValid}
              variant="static"
              placeholder={
                props.existingUserRestaurantLocation !== null
                  ? props.existingUserRestaurantLocation.lastName
                  : ''
              }
              className="UserLastNameInput w-max text-off-black"
              onChange={updateLastNameOnChange}
            />
            {!isLastNameValid && (
              <p className="text-red-500 text-xs mt-2">
                Only alphabatical characters and 1 space allowed!
              </p>
            )}
          </div>
        </div>
        <hr className="h-full w-1 rounded-xl bg-gray-400 opacity-500 col-span-1 justify-self-center" />
        <div className="flex flex-col flex-grow mt-1 p-3 w-full md:w-1/3">
          <h1 className="font-semibold text-xl">Properties & Location Access</h1>
          <div className="mt-2 flex-col gap-y-2">
            <UserRestaurantLocationCheckboxList
              key={`UserRestaurantLocationCheckboxList ${userForInput.datavizUserId}`}
              userId={userForInput.datavizUserId!}
              arrAllRestaurantService={props.arrAllRestaurantService}
              arrExistingRestaurantLocation={
                props.existingUserRestaurantLocation !== null
                  ? props.existingUserRestaurantLocation.arrRestaurantLocation
                  : []
              }
              updateArrRestaurantLocationOnCheck={updateArrRestaurantLocationOnCheck}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInputCard;
