import { MdClear, MdCreate } from 'react-icons/md';
import { Waypoint } from 'react-waypoint';
import { Button } from '@material-tailwind/react';

const EditButton = (props: {
  buttonName: string;
  enableEditOnClick: any;
  isButtonFloating: boolean;
  isEdit: boolean;
  isEnabled: boolean;
  saveChangesOnClick: any;
  setIsButtonFloating: any;
}) => {
  const isShowFixedEditButton = props.isButtonFloating && props.isEnabled;
  return (
    <div className="flex gap-x-2 justify-end">
      {props.isEdit && (
        <Button
          onClick={props.enableEditOnClick}
          size="sm"
          className="CancelEditChangesbutton font-medium bg-red-500 flex gap-x-1 pt-2 p-2"
          color="red"
        >
          <MdClear className="self-center" />
          Cancel Edits
        </Button>
      )}
      <>
        {!props.isEdit ? (
          <div className={`${isShowFixedEditButton && 'fixed top-10 z-10'}`}>
            <Button
              onClick={props.enableEditOnClick}
              size="sm"
              className={`EditButton ${
                isShowFixedEditButton
                  ? 'font-medium bg-blue-900 flex gap-x-1 pt-2 p-2 w-48'
                  : 'font-medium bg-blue-600 flex gap-x-1 pt-2 p-2'
              }`}
              color="blue"
              disabled={!props.isEnabled}
            >
              <MdCreate className="self-center" />
              <span className={`self-center ${isShowFixedEditButton && 'w-40'}`}>
                {isShowFixedEditButton ? props.buttonName : 'Edit'}
              </span>
            </Button>
          </div>
        ) : (
          <div className={`${props.isButtonFloating && 'fixed top-10 z-10'}`}>
            <Button
              onClick={props.saveChangesOnClick}
              size="sm"
              className="SaveEditChangesButton font-medium bg-blue-600 flex gap-x-1 pt-2 p-2"
              color="blue"
            >
              <MdCreate className="self-center" />
              {props.buttonName}
            </Button>
          </div>
        )}
        <Waypoint
          onEnter={() => {
            props.setIsButtonFloating(false);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition !== 'below') {
              props.setIsButtonFloating(true);
            }
          }}
        />
      </>
    </div>
  );
};

export default EditButton;
