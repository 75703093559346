import { CgArrowsExpandRight, CgCompressRight } from 'react-icons/cg';
import { Button } from '@material-tailwind/react';

const CollapseAndExpandButton = (props: { type: string; enableCollapseOrExpandOnClick: any }) => {
  return (
    <div className="flex gap-x-2" onClick={(event) => event.stopPropagation()}>
      <Button
        onClick={() => props.enableCollapseOrExpandOnClick(true)}
        size="sm"
        className={
          props.type +
          'CollapseButton flex w-32 gap-x-1 p-2 font-medium justify-center self-center bg-cyan-600'
        }
      >
        <CgArrowsExpandRight className="ExpandButton self-center" />
        Expand All
      </Button>
      <Button
        onClick={() => props.enableCollapseOrExpandOnClick(false)}
        size="sm"
        className={
          props.type +
          'CollapseButton flex w-32 gap-x-1 p-2 font-medium text-black justify-center self-center bg-yellow-600'
        }
      >
        <CgCompressRight className="CollapseButton self-center" />
        Collapse All
      </Button>
    </div>
  );
};

export default CollapseAndExpandButton;
