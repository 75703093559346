import { useContext, useState } from 'react';

import ServiceCard from './ServiceCard';
import UpdateButton from '../Common/Buttons/UpdateButton';
import LocationServiceInputCard from './InputComponents/LocationServiceInputCard';
import { COMPANY_SERVICE_INPUT_MODE } from '../../constant/General';
import InputCardsContext from '../../context/InputCardsContext';
import { NewService, LocationService, Location, Service } from '../../interfaces';

/**
 * To get an array of new service(s) where all the fake IDs are removed
 * @param arrNewServiceWithFakeIds - Array of service to loop through to delete the fake IDs
 * @returns arrNewService - Array of new service(s) where all the fake IDs have been removed
 */
const getArrNewService = (arrNewServiceWithFakeIds: Array<Service>): Array<NewService> => {
  const arrNewService = JSON.parse(JSON.stringify(arrNewServiceWithFakeIds));
  for (let newServiceIndex = 0; newServiceIndex < arrNewService.length; newServiceIndex++) {
    let service = arrNewService[newServiceIndex];
    service.serviceId = undefined;
    service.menu.menuId = undefined;
    service.menuId = undefined;
  }
  return arrNewService;
};

/**
 * Loop through array to delete fake menuuId for existing service(s) where its menu is to be changed to a new one
 * @param arrEditedService - Array of edited service to loop through to the fake menuId
 * @returns arrEditedService - Array of edited service(s) where the fake menuId has been removed
 */
const removeMenuIdFromArrEditedServiceWithNewMenu = (
  arrEditedServiceWithFakeMenuId: Array<Service>
): Array<NewService> => {
  const arrEditedService = JSON.parse(JSON.stringify(arrEditedServiceWithFakeMenuId));
  for (
    let editedServiceIndex = 0;
    editedServiceIndex < arrEditedService.length;
    editedServiceIndex++
  ) {
    if (arrEditedService[editedServiceIndex].menu.menuId === -1) {
      arrEditedService[editedServiceIndex].menu.menuId = undefined;
    }
  }
  return arrEditedService;
};

const LocationCard = (props: {
  companyId: number;
  companyName: string;
  restaurantId: number;
  restaurantName: string;
  locationService: LocationService;
  arrLocationService: Array<LocationService>;
  saveChangesToExistingCompanyService: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);

  const [componentId, setComponentId] = useState<string>('');
  const [editedLocation, setEditedLocation] = useState<Location | null>(null);
  const [mapEditedServiceByServiceId, setMapEditedServiceByServiceId] = useState(new Map());
  const [mapNewServiceByServiceId, setMapNewServiceByServiceId] = useState(new Map());

  /**
   * Function can add the edited location to map, with the locationId as the key, or remove the key-value if there is no change
   * to the location
   * Note: 'locationId' is not used in this function because there can only be editing of 1 location in this component. However this function is structured
   * to take in the same set of parameters as the props of LocationServiceInputCard component so as to reduce the number of props for the LocationServiceInputCard component.
   * @param idParameters - Object containing locationId of the edited location
   * @param editedLocation - Location object containing the edited attribute of an existing location, or undefined if the location is to be removed
   */
  const updateEditedLocation = (
    idParameters: { locationId: number },
    editedLocation?: Location
  ) => {
    if (editedLocation) {
      setEditedLocation(editedLocation);
    } else {
      setEditedLocation(null);
    }
  };

  /**
   * Function can add the edited service to map, with the serviceId as the key, or remove the key-value if there is no change
   * to the service
   * @param idParameters - Object containing serviceId of the edited service
   * @param editedService - Service object containing the edited attribute of an existing service, or undefined if the service is to be removed
   */
  const updateMapEditedServiceByServiceId = (
    idParameters: { serviceId: number },
    editedService?: Service
  ) => {
    const { serviceId } = idParameters;
    const updatedMapEditedServiceByServiceId = new Map(mapEditedServiceByServiceId);
    if (editedService) {
      updatedMapEditedServiceByServiceId.set(serviceId, editedService);
    } else {
      updatedMapEditedServiceByServiceId.delete(serviceId);
    }
    setMapEditedServiceByServiceId(updatedMapEditedServiceByServiceId);
  };

  /**
   * Function can add the new service (or when the new service is edited) to map, with the serviceId as the key, or remove the key-value if the service is to be removed
   * @param idParameters - Object containing serviceId of the new service
   * @param newService - Service object containing the new service, or undefined if the service is to be removed
   */
  const updateMapNewServiceByServiceId = (
    idParameters: { serviceId: number },
    newService?: Service
  ) => {
    const { serviceId } = idParameters;
    const updatedMapNewServiceByServiceId = new Map(mapNewServiceByServiceId);
    if (newService) {
      updatedMapNewServiceByServiceId.set(serviceId, newService);
    } else {
      updatedMapNewServiceByServiceId.delete(serviceId);
    }
    setMapNewServiceByServiceId(updatedMapNewServiceByServiceId);
  };

  /**
   * Function is called when user clicks on Update Location or Cancel button, enables or disables addition and update mode by setting the correct input mode
   * in the inputCardsContext. Related RestaurantIdMenuInputTag data saved in the inputCardsContext due to the adding of new service will be removed if the Cancel
   * button is clicked so that the data will not be carried forward to the next input mode which can cause incorrect errors.
   */
  const enableUpdateLocationServiceOnClick = () => {
    const isAdd = inputCardsContext.checkIfActiveInputMode(
      COMPANY_SERVICE_INPUT_MODE.updateLocationService,
      componentId
    );
    if (isAdd === true) {
      inputCardsContext.setInputMode(COMPANY_SERVICE_INPUT_MODE.none, '', props.companyId);
      setComponentId('');
      inputCardsContext.deleteAllError();
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    } else {
      const newComponentId = new Date();
      setComponentId(newComponentId.toString());
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.updateLocationService,
        newComponentId.toString(),
        props.companyId
      );
    }
  };

  /**
   * Function is run by save changes button, and calls saveChangesToExistingCompanyService function that is passed down from parent CompanyCard component.
   * Function saves edited location (if any), edited services (if any) and newly added services (if any).
   */
  const saveChangesForUpdateLocationServiceOnClick = () => {
    const arrNewService = getArrNewService(Array.from(mapNewServiceByServiceId.values()));
    // If the edited service is to be updated to a new (non-existing) menu, its menu.menuId will have to be set to undefined
    // for
    // 1. Backend - comparison of edited service's menuId and edited service's menu.menuId to register a change in menu,
    // 2. Database-Middleware - edited service's menu.menuId needs to be undefined to trigger a creation of menu
    const arrEditedService = removeMenuIdFromArrEditedServiceWithNewMenu(
      Array.from(mapEditedServiceByServiceId.values())
    );
    props.saveChangesToExistingCompanyService(
      {
        companyId: props.companyId,
        restaurantId: props.restaurantId,
        locationId: props.locationService.locationId,
      },
      {
        arrServiceToBeCreated: arrNewService.length !== 0 ? arrNewService : undefined,
      },
      {
        locationToBeUpdated: editedLocation !== null ? editedLocation : undefined,
        arrServiceToBeUpdated: arrEditedService.length !== 0 ? arrEditedService : undefined,
      }
    );
    // Reset changes
    setEditedLocation(null);
    setMapEditedServiceByServiceId(new Map());
    setMapNewServiceByServiceId(new Map());
  };

  return (
    <li
      className="LocationCard relative flex-col mt-2 bg-gray-200 py-4 px-2 rounded-lg"
      id={`location_${props.locationService.locationId}`}
    >
      <UpdateButton
        isEnabled={inputCardsContext.getInputMode(
          COMPANY_SERVICE_INPUT_MODE.updateLocationService,
          componentId
        )}
        enableUpdateOnClick={enableUpdateLocationServiceOnClick}
        saveChangesOnClick={saveChangesForUpdateLocationServiceOnClick}
        isUpdate={inputCardsContext.checkIfActiveInputMode(
          COMPANY_SERVICE_INPUT_MODE.updateLocationService,
          componentId
        )}
        buttonName={'Update Location'}
        buttonClassName={'UpdateLocationButton'}
        positionType={'absolute'}
      />
      {inputCardsContext.checkIfActiveInputMode(
        COMPANY_SERVICE_INPUT_MODE.updateLocationService,
        componentId
      ) ? (
        <ul>
          <LocationServiceInputCard
            key={props.locationService.locationId}
            arrEditedService={Array.from(mapEditedServiceByServiceId.values())}
            arrLocationService={props.arrLocationService}
            arrNewLocation={[]}
            arrNewService={Array.from(mapNewServiceByServiceId.values())}
            companyId={props.companyId}
            locationId={props.locationService.locationId}
            locationService={props.locationService}
            restaurantId={props.restaurantId}
            updateEditedOrNewLocation={updateEditedLocation}
            updateMapEditedServiceByServiceId={updateMapEditedServiceByServiceId}
            updateMapForNewService={updateMapNewServiceByServiceId}
          />
        </ul>
      ) : (
        <div className="px-2">
          <div className="flex flex-col justify-between">
            <div className="font-medium flex flex-col">
              <div className="text-[0.6rem] p-0 text-gray-600 leading-none">
                Location Id: {props.locationService.locationId}
              </div>
              {props.locationService.name}
            </div>
            <div className="flex mt-2">
              <p>Services: {props.locationService.arrService.length}</p>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-13 gap-x-2 text-xs font-semibold justify-between">
            <p className="col-span-2">Service Name</p>
            <p className="col-span-2">Menu Name</p>
            <p className="col-span-1">Input Tag</p>
            <p className="col-span-1 text-center">Project Start Date</p>
            <p className="col-span-1 text-center">Baseline Start Date</p>
            <p className="col-span-1 text-center">Baseline End Date</p>
            <p className="col-span-1 text-center">Currency</p>
            <p className="col-span-1 text-center">Offset</p>
            <p className="col-span-1 text-center">Type</p>
            <p className="col-span-1 text-center">Group</p>
            <p className="col-span-1 text-center">isValid</p>
          </div>
          <ul className="mt-1 flex flex-col gap-y-1">
            {props.locationService.arrService.map((service) => (
              <ServiceCard
                key={service.serviceId}
                service={service}
                companyId={props.companyId}
                companyName={props.companyName}
                restaurantName={props.restaurantName}
                locationName={props.locationService.name}
              />
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

export default LocationCard;
