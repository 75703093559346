/**
 * Input mode:
 0: No input mode selected
 1: Add Mode for new company service
 2: Add Mode for new restaurant service for an existing company
 3: Add Mode for new location service for an existing restaurant
 4: Update mode for existing company. Only allow editing of existing company.
 5: Update mode for existing restaurant. Only allow editing of existing restaurant.
 4: Update mode for existing location. Allows editing of existing location, existing service(s) of that location, and adding new service(s) to that location.
 */
const COMPANY_SERVICE_INPUT_MODE = {
  none: 0,
  addCompanyService: 1,
  addRestaurantService: 2,
  addLocationService: 3,
  updateCompany: 4,
  updateRestaurant: 5,
  updateLocationService: 6,
};

const POSITION = {
  above: 'above',
};

export { COMPANY_SERVICE_INPUT_MODE, POSITION };
