import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-tailwind/react';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Auth0Provider
    domain="dev-lumitics-vision.eu.auth0.com"
    clientId="OHW8HX6pIMRZEhGPw3a8MCnNwsTc08OW"
    redirectUri={window.location.origin}
  >
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
