import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import MenuItemInputCard from './MenuItemInputCard';
import MenuItemUpload from './MenuItemUpload';
import { checkForInvalidMenuItemName } from '../../../functions/InputChecks';
import { checkIsStationValidWithRegEx } from '../../../functions/InputValidation';
import { MenuItem, NewMenuItem, NewMenuItemError } from '../../../interfaces';

const MenuItemInputList = (props: {
  arrNewMenuItem: Array<NewMenuItem>;
  arrExistingMenuItem: Array<MenuItem>;
  menuId: number;
  updateMapMenuItemByArrMenuItemId: any;
  updateMapMenuItemErrorByArrMenuItemId: any;
}) => {
  const [arrMenuItemIdToBeCreated, setArrMenuItemIdToBeCreated] = useState<Array<number>>([]);
  const [mapMenuItemErrorToBeCreatedByMenuItemId, setMapMenuItemErrorToBeCreatedByMenuItemId] =
    useState<Map<number, NewMenuItemError>>(new Map());

  /**
   * This function adds one menu item and initiates associated menuItemId as a key in parent component's
   * states mapMenuItemErrorByMenuItemId and mapMenuItemByMenuItemId
   */
  const addOneMenuItem = () => {
    const newMenuItem = {
      menuItemId: -1,
      name: '',
      costPerKilogram: '',
      station: '',
      expiryDate: null,
      menuId: props.menuId,
    };
    let newMenuItemId = -1;
    let newArrMenuItemIdToBeCreated = [] as Array<number>;
    if (arrMenuItemIdToBeCreated.length === 0) {
      newArrMenuItemIdToBeCreated = [-1];
    } else {
      const lastMenuItemId = arrMenuItemIdToBeCreated[arrMenuItemIdToBeCreated.length - 1];
      newMenuItemId = lastMenuItemId - 1;
      newArrMenuItemIdToBeCreated = arrMenuItemIdToBeCreated.concat([newMenuItemId]);
      newMenuItem.menuItemId = newMenuItemId;
    }
    setArrMenuItemIdToBeCreated(newArrMenuItemIdToBeCreated);
    setMapMenuItemErrorToBeCreatedByMenuItemId(
      mapMenuItemErrorToBeCreatedByMenuItemId.set(newMenuItemId, {
        isNameValid: false,
        nameErrorMessage: 'Please fill up',
        isStationValid: false,
        stationErrorMessage: 'Please fill up',
        isCostPerKilogramValid: false,
        costPerKilogramErrorMessage: 'Please fill up',
      })
    );
    props.updateMapMenuItemErrorByArrMenuItemId([newMenuItemId], [true], false, false);
    props.updateMapMenuItemByArrMenuItemId([newMenuItemId], [newMenuItem], false, false);
  };

  /**
   * This function checks input name against arrExistingNewMenuItem (menu items that user has input but are not saved yet)
   * and arrExistingMenuItem (menu items that are existing)
   * The following checks are done for menu item name:
   * 1. Empty string
   * 2. Menu item name string validity (see checkIfMenuItemNameValidWithRegEx in InputValidation.ts for detailed explanation)
   * 3. Duplicate check against existing menu items
   * 4. Duplicate check against other new menu items entered
   * @param name - String of menu item name to be checked against
   * @param menuId - Number representing menu id
   * @param arrExistingNewMenuItem - Array of new menu items to be created
   * @returns String - If an error exists, return string of error message, else ''
   */
  const checkNameError = (name: string, menuId: number, arrExistingNewMenuItem: NewMenuItem[]) => {
    let nameErrorMessage = '';
    if (name === '') {
      nameErrorMessage = 'Please fill up';
    } else {
      nameErrorMessage = checkForInvalidMenuItemName(
        name,
        menuId,
        props.arrNewMenuItem.concat(arrExistingNewMenuItem),
        props.arrExistingMenuItem
      );
    }
    return nameErrorMessage;
  };

  /**
   * This function checks input station name if it is valid
   * The following checks are done for station name:
   * 1. Empty string
   * 2. Station name string validity (see checkIsStationValidWithRegEx in InputValidation.ts for detailed explanation)
   * @param station - String of station name to be checked against regex
   * @returns String - If an error exists, return string of error message, else ''
   */
  const checkStationError = (station: string) => {
    let stationErrorMessage = '';
    if (station === '') {
      stationErrorMessage = 'Please fill up';
    } else if (!checkIsStationValidWithRegEx(station)) {
      stationErrorMessage =
        'Must be alphanumeric, or single space in between, and proper capitalized';
    }
    return stationErrorMessage;
  };

  /**
   * This function checks input cost per kilogram if it is valid
   * The following checks are done for cost per kilogram:
   * 1. Empty string
   * 2. Validates if string entered is a valid number string or negative number string
   * @param costPerKilogram - String or number to be checked if is a valid number
   * @returns String - If an error exists, return string of error message, else ''
   */
  const checkCostPerKilogramError = (costPerKilogram: string | number) => {
    let costPerKilogramErrorMessage = '';
    const costPerKilogramInNumberType = Number(costPerKilogram);
    if (costPerKilogram === '') {
      costPerKilogramErrorMessage = 'Please fill up';
    } else if (isNaN(costPerKilogramInNumberType) || costPerKilogramInNumberType < 0) {
      costPerKilogramErrorMessage = 'Invalid number';
    }
    return costPerKilogramErrorMessage;
  };

  /**
   * Function used by MenuItemUpload by CSV. Function takes in the arrMenuItemToBeCreated and converts it
   * into menuItem object to be stored in the frontend for displaying of upload results. Error checking is done
   * in bulk in this function to avoid multiple setStates when error checking
   * @param arrMenuItemToBeCreated - Array of menu items
   */
  const addMultipleMenuItem = (arrMenuItemToBeCreated: MenuItem[]) => {
    const arrNewMenuItemId: Array<number> = [];
    const arrNewMenuItem: Array<NewMenuItem> = [];
    const mapNewMenuItem = new Map(mapMenuItemErrorToBeCreatedByMenuItemId);

    const arrError: Array<boolean> = [];

    for (let menuItemIndex = 0; menuItemIndex < arrMenuItemToBeCreated.length; menuItemIndex += 1) {
      const lastMenuItemId = arrMenuItemIdToBeCreated[arrMenuItemIdToBeCreated.length - 1];
      const currentMenuItem = arrMenuItemToBeCreated[menuItemIndex];
      const currentMenuId = lastMenuItemId - menuItemIndex - 1;
      const newMenuItem = {
        menuItemId: currentMenuId,
        name: currentMenuItem.name,
        costPerKilogram:
          isNaN(Number(currentMenuItem.costPerKilogram)) || currentMenuItem.costPerKilogram === ''
            ? currentMenuItem.costPerKilogram
            : Number(currentMenuItem.costPerKilogram),
        station: currentMenuItem.station,
        expiryDate: null,
        menuId: props.menuId,
      };
      arrNewMenuItemId.push(currentMenuId);
      arrNewMenuItem.push(newMenuItem);

      const nameErrorMessage = checkNameError(currentMenuItem.name, currentMenuId, arrNewMenuItem);
      const stationErrorMessage = checkStationError(currentMenuItem.station);
      const costPerKilogramErrorMessage = checkCostPerKilogramError(
        currentMenuItem.costPerKilogram
      );

      mapNewMenuItem.set(currentMenuId, {
        isNameValid: nameErrorMessage === '',
        nameErrorMessage,
        isStationValid: stationErrorMessage === '',
        stationErrorMessage,
        isCostPerKilogramValid: costPerKilogramErrorMessage === '',
        costPerKilogramErrorMessage,
      });

      arrError.push(
        !(
          nameErrorMessage === '' &&
          stationErrorMessage === '' &&
          costPerKilogramErrorMessage === ''
        )
      );
    }

    let isRemoveFirst = false;
    if (
      arrMenuItemIdToBeCreated.length === 1 &&
      !props.arrNewMenuItem[0].name &&
      !props.arrNewMenuItem[0].station &&
      !props.arrNewMenuItem[0].costPerKilogram
    ) {
      isRemoveFirst = true;
      mapNewMenuItem.delete(-1);
      setArrMenuItemIdToBeCreated(arrNewMenuItemId);
    } else {
      setArrMenuItemIdToBeCreated(arrMenuItemIdToBeCreated.concat(arrNewMenuItemId));
    }

    setMapMenuItemErrorToBeCreatedByMenuItemId(mapNewMenuItem);
    props.updateMapMenuItemByArrMenuItemId(arrNewMenuItemId, arrNewMenuItem, false, isRemoveFirst);
    props.updateMapMenuItemErrorByArrMenuItemId(arrNewMenuItemId, arrError, false, isRemoveFirst);
  };

  /**
   * Function removes the menuItemId from array of menuItemIdToBeCreated when a new menu item row is removed
   * @param menuItemIdToBeRemoved - Id of menu item to be removed from the array of menuItemIdToBeCreated
   */
  const removeMenuItemId = (menuItemIdToBeRemoved: number) => {
    const newArrMenuItemIdToBeCreated: Array<number> = [];
    arrMenuItemIdToBeCreated.forEach((menuItemIdToBeCreated) => {
      if (menuItemIdToBeCreated !== menuItemIdToBeRemoved) {
        newArrMenuItemIdToBeCreated.push(menuItemIdToBeCreated);
      }
    });
    setArrMenuItemIdToBeCreated(newArrMenuItemIdToBeCreated);

    const mapNewMenuItem = new Map(mapMenuItemErrorToBeCreatedByMenuItemId);
    mapNewMenuItem.delete(menuItemIdToBeRemoved);
    setMapMenuItemErrorToBeCreatedByMenuItemId(mapNewMenuItem);
  };

  useEffect(() => {
    // Technically dont have to check for arrMenuItemIdToBeCreated.length, but during development, when save changes while the menu dialogue box
    // is opened, the dialogue box is remounted but the states' are not refreshed, hence always adding another new menu item when saving new
    // to code
    if (arrMenuItemIdToBeCreated.length === 0) {
      addOneMenuItem();
    }
  }, []);

  return (
    <div className="border-2 border-gray-200 rounded-lg text-gray-800 flex flex-col min-w-[53rem]">
      <div className="flex justify-between bg-gray-300 pt-2 pb-2">
        <p className="text-xl font-bold top-0 rounded-t-lg text-left p-2 pl-3 pr-3 border-b-0">
          {'Add New Menu Item(s)'}
        </p>
        <div className="flex gap-2 p-2 pl-3 pr-3">
          <MenuItemUpload addMultipleMenuItem={addMultipleMenuItem} />
        </div>
      </div>
      <div className="p-3 pt-1 pb-1">
        <table className="table-fixed font-normal w-full">
          <thead className="top-10 bg-white">
            <tr className="border-black-500">
              <th className="text-left w-5/12">Name</th>
              <th className="text-center w-3/12">Station</th>
              <th className="text-center w-3/12">Cost Per Kilogram</th>
              <th className="text-center w-1/12" />
            </tr>
          </thead>
          <tbody className="mt-1">
            {props.arrNewMenuItem.map((newMenuItem: NewMenuItem) => (
              <MenuItemInputCard
                key={newMenuItem.menuItemId}
                newMenuItem={newMenuItem}
                arrNewMenuItem={props.arrNewMenuItem}
                arrExistingMenuItem={props.arrExistingMenuItem}
                hasOnlyOneMenuItemToBeCreated={props.arrNewMenuItem.length === 1}
                removeMenuItemId={removeMenuItemId}
                updateMapMenuItemErrorByArrMenuItemId={props.updateMapMenuItemErrorByArrMenuItemId}
                updateMapMenuItemByArrMenuItemId={props.updateMapMenuItemByArrMenuItemId}
                mapMenuItemErrorToBeCreatedByMenuItemId={mapMenuItemErrorToBeCreatedByMenuItemId}
                setMapMenuItemErrorToBeCreatedByMenuItemId={
                  setMapMenuItemErrorToBeCreatedByMenuItemId
                }
                checkNameError={checkNameError}
                checkStationError={checkStationError}
                checkCostPerKilogramError={checkCostPerKilogramError}
              />
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end gap-2 p-2 pl-3 pr-3">
        <Button
          onClick={addOneMenuItem}
          size="sm"
          // variant="outlined"
          className={
            'AddOneMenuItemButton font-medium place-content-center w-full flex gap-x-1 p-2'
          }
          color="yellow"
        >
          <MdAdd className="AddButton self-center" />
          Add Another Menu Item
        </Button>
      </div>
    </div>
  );
};

export default MenuItemInputList;
