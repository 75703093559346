import loader from '../images/Loader.gif';

const LoadingScreen = () => {
  return (
    <div className="flex justify-center items-center h-80vh">
      <img src={loader} alt="Loading..." />
    </div>
  );
};

export default LoadingScreen;
