import { createContext, useState } from 'react';

import {
  Company,
  CompanyService,
  Location,
  LocationService,
  MapsUpdatedIsOpenedById,
  Menu,
  MenuItem,
  Restaurant,
  RestaurantService,
  Service,
  ServiceType,
  ServiceTypeOption,
} from '../interfaces';

/**
 * This function provides sorting algorithm to sort the two elements in ascending order by name
 * @param elementA - Element A to be sorted
 * @param elementB - Element B to be sorted
 * @returns value - Returns 1 if element A should be arranged after element B, else returns -1
 */
const sortInAscendingOrderByName = (
  elementA: Company | LocationService | RestaurantService | Service | Menu,
  elementB: Company | LocationService | RestaurantService | Service | Menu
) => {
  if (elementA.name > elementB.name) {
    return 1;
  }
  return -1;
};

/**
 * To sort array of restaurantService(s) alphabetically in ascending order by restaurant name, location name and service name
 * @param arrRestaurantService - Array of restaurantService(s) to be sorted alphabetically in ascending order by restaurant name, location name and service name
 */
const sortArrRestaurantServiceInAscendingOrderByName = (
  arrRestaurantService: RestaurantService[]
) => {
  arrRestaurantService.forEach((restaurantService) => {
    restaurantService.arrLocationService.forEach((locationService) => {
      locationService.arrService.sort(sortInAscendingOrderByName);
    });
    restaurantService.arrLocationService.sort(sortInAscendingOrderByName);
  });
  arrRestaurantService.sort(sortInAscendingOrderByName);
};

/**
 * Get an array of unique menu that belongs to the company. The array of service is looped through to get
 * the new unique menu object. As this function is used for adding of companyService/restaurantService/
 * locationService/service, there could already be an array of existing menu objects for the company. Thus,
 * the newly created menu objects will be added to the array of existing menu objects and the combined array
 * will be sorted alphabetically in ascending order by name for return.
 * @param arrService - Array of service(s) of a company or array of newly created service(s)
 * @param arrExistingMenu - Array of existing menu that the new menu objects are supposed to be added to
 * @returns arrNewMenu - Array of unique menu, sorted alphabetically in ascending order by name
 */
const getArrUniqueMenu = (arrService: Service[], arrExistingMenu: Menu[]) => {
  let mapMenuByMenuId = new Map();
  if (arrExistingMenu.length !== 0) {
    mapMenuByMenuId = new Map(arrExistingMenu.map((menu) => [menu.menuId, menu]));
  }
  arrService.forEach((service) => {
    const matchedMenu = mapMenuByMenuId.get(service.menuId);
    if (!matchedMenu) {
      mapMenuByMenuId.set(service.menuId, service.menu);
    }
  });
  const arrNewMenu: Menu[] = Array.from(mapMenuByMenuId.values());
  arrNewMenu.sort(sortInAscendingOrderByName);
  return arrNewMenu;
};

const CompanyContext = createContext({
  arrCompanyService: [] as any,
  arrService: [] as any,
  arrServiceTypeOption: [] as any,
  mapArrMenuByRestaurantId: new Map() as Map<number, Array<Menu>>,
  mapArrMenuItemByMenuId: new Map() as Map<number, Array<MenuItem>>,
  mapsIsOpenedById: {} as MapsUpdatedIsOpenedById,
  addArrCompanyService: (arrNewCompanyService: Array<CompanyService>) => {},
  addArrLocationService: (
    companyId: number,
    restaurantId: number,
    arrNewLocationService: Array<LocationService>
  ) => {},
  addArrRestaurantService: (
    companyId: number,
    arrNewRestaurantService: Array<RestaurantService>
  ) => {},
  addArrService: (
    companyId: number,
    restaurantId: number,
    locationId: number,
    arrNewService: Array<Service>
  ) => {},
  addMenuItem: (arrMenuItem: Array<MenuItem>) => {},
  updateArrServiceTypeOption: (arrUpdatedServiceType: Array<ServiceType>) => {},
  updateMapArrMenuByRestaurantId: (updatedMapArrMenuByRestaurantId: Map<number, Array<Menu>>) => {},
  updateCompanyServiceDetails: (
    idParameters: {
      companyId: number;
      restaurantId?: number;
      locationId?: number;
    },
    companyToBeUpdated: Company | undefined,
    restaurantToBeUpdated: Restaurant | undefined,
    locationToBeUpdated: Location | undefined,
    arrServiceToBeUpdated: Array<Service> | undefined
  ) => {},
  updateMapsIsOpenedById: (mapsUpdatedIsOpenedById: MapsUpdatedIsOpenedById) => {},
});

export const CompanyContextProvider = (props: any) => {
  // Store current array of companyService(s) details in the context for the cache and to avoid props drilling
  const [arrCompanyService, setArrCompanyService] = useState<Array<CompanyService>>([]);
  const [arrService, setArrService] = useState<Array<Service>>([]);
  const [arrServiceTypeOption, setArrServiceTypeOption] = useState<Array<ServiceType>>([]);
  const [mapArrMenuByRestaurantId, setMapArrMenuByRestaurantId] = useState<
    Map<number, Array<Menu>>
  >(new Map());
  const [mapArrMenuItemByMenuId, setMapArrMenuItemByMenuId] = useState<
    Map<number, Array<MenuItem>>
  >(new Map());
  const [mapsIsOpenedById, setMapsIsOpenedById] = useState<{
    mapIsOpenedsByCompanyId: Map<number, { isOpened: boolean; isRestaurantListOpened: boolean }>;
    mapIsOpenedAndParentIdByRestaurantId: Map<number, { isOpened: boolean; parentId: number }>;
  }>({
    mapIsOpenedsByCompanyId: new Map(),
    mapIsOpenedAndParentIdByRestaurantId: new Map(),
  });

  /**
   * This function adds the new companyService(s) details to the existing context values, when all the companyService(s)
   * is fetched when the page first loads or when new companyService(s) is created. Sorting is done at the company level
   * (important for creating companyService(s)) as the children arrays are already sorted in the Backend.
   * The following context values are updated:
   * 1. arrCompanyService - Array of all companyService(s)
   * 2. arrService - Array of all service(s). This array is used for validating unique input tag.
   * 3. mapArrMenuByRestaurantId - This map holds the array of unique menu that belongs to a restaurant
   * 4. mapsIsOpenedById - Object containing two maps that hold the isOpened for company card and restaurant list, and location list
   * @param arrNewCompanyService - Array of companyService(s) to be added to the context
   */
  const addArrCompanyService = (arrNewCompanyService: Array<CompanyService>) => {
    const updatedArrCompanyService = arrCompanyService.slice();
    const updatedArrService = arrService.slice();
    const updatedMapArrMenuByRestaurantId = new Map(mapArrMenuByRestaurantId);
    const updatedMapIsOpenedsByCompanyId = new Map(mapsIsOpenedById.mapIsOpenedsByCompanyId);
    const updatedMapIsOpenedAndParentIdByRestaurantId = new Map(
      mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );

    arrNewCompanyService.forEach((companyService: CompanyService) => {
      const { companyId, arrRestaurantService } = companyService;

      arrRestaurantService.forEach((restaurantService: RestaurantService) => {
        const { restaurantId } = restaurantService;
        const arrServiceOfARestaurant: Service[] = [];
        restaurantService.arrLocationService.forEach((locationService: LocationService) => {
          const { arrService } = locationService;
          arrServiceOfARestaurant.push(...arrService);
          updatedArrService.push(...arrService);
        });
        const arrMenu = getArrUniqueMenu(arrServiceOfARestaurant, []);
        updatedMapArrMenuByRestaurantId.set(restaurantId, arrMenu);

        updatedMapIsOpenedAndParentIdByRestaurantId.set(restaurantService.restaurantId, {
          isOpened: false,
          parentId: companyId,
        });
      });

      updatedArrCompanyService.push(companyService);

      updatedMapIsOpenedsByCompanyId.set(companyId, {
        isOpened: false,
        isRestaurantListOpened: false,
      });
    });

    updatedArrCompanyService.sort(sortInAscendingOrderByName);

    setArrCompanyService(updatedArrCompanyService);
    setArrService(updatedArrService);
    setMapArrMenuByRestaurantId(updatedMapArrMenuByRestaurantId);
    setMapsIsOpenedById({
      mapIsOpenedsByCompanyId: updatedMapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: updatedMapIsOpenedAndParentIdByRestaurantId,
    });
  };

  /**
   * Update the array of restaurantService(s) when new restaurantService(s) is added. Sorting is done at the
   * restaurant level as the children arrays are already sorted in the Backend.
   * The following context values are updated:
   * 1. arrCompanyService - Array of all companyService(s) details
   * 2. arrService - Array of all service(s). This array is used for validating unique input tag.
   * 3. mapArrMenuByRestaurantId - This map holds the array of unique menu that belongs to a restaurant
   * 4. mapAllIsOpenedAndParentIdByRestaurantId - This map holds the isOpened for location list
   * @param companyId - Id of company where the new restaurantService(s) is to be added to
   * @param arrNewRestaurantService - Array of restaurantService(s) to be added to the context
   */
  const addArrRestaurantService = (
    companyId: number,
    arrNewRestaurantService: Array<RestaurantService>
  ) => {
    const arrAllCompanyService = arrCompanyService.slice();
    const arrAllService = arrService.slice();
    const mapAllArrMenuByRestaurantId = new Map(mapArrMenuByRestaurantId);
    const mapAllIsOpenedAndParentIdByRestaurantId = new Map(
      mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );

    for (let companyIndex = 0; companyIndex < arrAllCompanyService.length; companyIndex++) {
      const { companyId: currentCompanyId, arrRestaurantService } =
        arrAllCompanyService[companyIndex];

      // Find the correct company, to reduce looping unnecessarily
      if (currentCompanyId === companyId) {
        arrRestaurantService.push(...arrNewRestaurantService);
        arrRestaurantService.sort(sortInAscendingOrderByName);

        arrRestaurantService.forEach((restaurantService: RestaurantService) => {
          const { restaurantId } = restaurantService;
          const arrNewService: Service[] = [];
          restaurantService.arrLocationService.forEach((locationService: LocationService) => {
            const { arrService } = locationService;
            arrNewService.push(...arrService);
            arrAllService.push(...arrService);
          });

          const arrMenu = getArrUniqueMenu(arrNewService, []);
          mapAllArrMenuByRestaurantId.set(restaurantId, arrMenu);

          // The location list of the newly created restaurant should be expanded as the restaurant list is
          // already opened before the adding of new restaurant can happen
          mapAllIsOpenedAndParentIdByRestaurantId.set(restaurantService.restaurantId, {
            isOpened: true,
            parentId: companyId,
          });
        });

        break;
      }
    }

    setArrCompanyService(arrAllCompanyService);
    setArrService(arrAllService);
    setMapArrMenuByRestaurantId(mapAllArrMenuByRestaurantId);
    setMapsIsOpenedById({
      mapIsOpenedsByCompanyId: mapsIsOpenedById.mapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: mapAllIsOpenedAndParentIdByRestaurantId,
    });
  };

  /**
   * Update the array of location(s) when new locationService(s) is added. Sorting is done at the
   * location level as the children arrays are already sorted in the Backend.
   * The following context values are updated:
   * 1. arrCompanyService - Array of all companyService(s) details
   * 2. arrService - Array of all service(s). This array is used for validating unique input tag.
   * 3. mapArrMenuByRestaurantId - This map holds the array of unique menu that belongs to a restaurant
   * @param companyId - Id of company where the new locationService(s) is to be added to
   * @param restaurantId - Id of restaurant where the new locationService(s) is to be added to
   * @param arrNewLocationService - Array of locationService(s) to be added to the context
   */
  const addArrLocationService = (
    companyId: number,
    restaurantId: number,
    arrNewLocationService: Array<LocationService>
  ) => {
    const arrAllCompanyService = arrCompanyService.slice();
    const arrAllService = arrService.slice();
    const mapAllArrMenuByRestaurantId = new Map(mapArrMenuByRestaurantId);

    for (let companyIndex = 0; companyIndex < arrAllCompanyService.length; companyIndex++) {
      const { companyId: currentCompanyId, arrRestaurantService } =
        arrAllCompanyService[companyIndex];
      // Find the correct company, to reduce looping unnecessarily
      if (currentCompanyId === companyId) {
        for (
          let restaurantIndex = 0;
          restaurantIndex < arrRestaurantService.length;
          restaurantIndex++
        ) {
          const { restaurantId: currentRestaurantId, arrLocationService } =
            arrRestaurantService[restaurantIndex];
          // Find the correct restaurant, to reduce looping unnecessarily
          if (currentRestaurantId === restaurantId) {
            arrLocationService.push(...arrNewLocationService);
            arrLocationService.sort(sortInAscendingOrderByName);

            const arrNewService: Service[] = [];
            arrLocationService.forEach((locationService: LocationService) => {
              const { arrService } = locationService;
              arrNewService.push(...arrService);
              arrAllService.push(...arrService);
            });

            const arrMenu = getArrUniqueMenu(
              arrNewService,
              mapArrMenuByRestaurantId.get(restaurantId)!
            );
            mapAllArrMenuByRestaurantId.set(restaurantId, arrMenu);

            break;
          }
        }
        break;
      }
    }

    setArrCompanyService(arrAllCompanyService);
    setArrService(arrAllService);
    setMapArrMenuByRestaurantId(mapAllArrMenuByRestaurantId);
  };

  /**
   * Update the array of service(s) when new service(s) is added and sort the array.
   * The following context values are updated:
   * 1. arrCompanyService - Array of all companyService(s) details
   * 2. arrService - This array is used for validating unique input tag
   * 3. mapArrMenuByRestaurantId - This map holds the array of unique menu that belongs to a restaurant
   * @param companyId - Id of company where the new service(s) is to be added to
   * @param restaurantId - Id of restaurant where the new service(s) is to be added to
   * @param locationId - Id of location where the new service(s) is to be added to
   * @param arrNewService - Array of service(s) to be added to the context
   */
  const addArrService = (
    companyId: number,
    restaurantId: number,
    locationId: number,
    arrNewService: Service[]
  ) => {
    const arrAllCompanyService = arrCompanyService.slice();
    const arrAllService = arrService.slice();
    const mapAllArrMenuByRestaurantId = new Map(mapArrMenuByRestaurantId);

    for (let companyIndex = 0; companyIndex < arrAllCompanyService.length; companyIndex++) {
      const { companyId: currentCompanyId, arrRestaurantService } =
        arrAllCompanyService[companyIndex];
      // Find the correct company, to reduce looping unnecessarily
      if (currentCompanyId === companyId) {
        for (
          let restaurantIndex = 0;
          restaurantIndex < arrRestaurantService.length;
          restaurantIndex++
        ) {
          const { restaurantId: currentRestaurantId, arrLocationService } =
            arrRestaurantService[restaurantIndex];
          // Find the correct restaurant, to reduce looping unnecessarily
          if (currentRestaurantId === restaurantId) {
            for (
              let restaurantIndex = 0;
              restaurantIndex < arrLocationService.length;
              restaurantIndex++
            ) {
              const currentLocation: LocationService = arrLocationService[restaurantIndex];
              // Find the correct location, to reduce looping unnecessarily
              if (currentLocation.locationId === locationId) {
                currentLocation.arrService.push(...arrNewService);
                currentLocation.arrService.sort(sortInAscendingOrderByName);

                arrAllService.push(...arrNewService);

                const arrMenu = getArrUniqueMenu(
                  arrNewService,
                  mapArrMenuByRestaurantId.get(restaurantId)!
                );
                mapAllArrMenuByRestaurantId.set(restaurantId, arrMenu);

                break;
              }
            }
          }
        }
        break;
      }
    }

    setArrCompanyService(arrAllCompanyService);
    setArrService(arrAllService);
    setMapArrMenuByRestaurantId(mapAllArrMenuByRestaurantId);
  };

  /**
   * Update companyService(s) details when company, restaurant, location and/or service is edited. Update the company, restaurant and location, and loop through arrServiceToBeUpdated
   * to update the corresponding object  (if they exist). At the same time, update arrMenu for the restaurant which has service(s) updated.
   * @param idParameters - Object containing companyId, restaurantId (can be undefined) and locationId (can be undefined) to facilitate the finding of the right object to update
   * @param companyToBeUpdated - Company object containing newly edited company data
   * @param restaurantToBeUpdated - Restaurant object containing newly edited restaurant data
   * @param locationToBeUpdated - Location object containing newly edited location data
   * @param arrServiceToBeUpdated - Array of Service objects containing newly edited service data. Note: Current implementation only allows editing of service(s) from the same location.
   */
  const updateCompanyServiceDetails = (
    idParameters: {
      companyId: number;
      restaurantId?: number;
      locationId?: number;
    },
    companyToBeUpdated: Company | undefined,
    restaurantToBeUpdated: Restaurant | undefined,
    locationToBeUpdated: Location | undefined,
    arrServiceToBeUpdated: Array<Service> | undefined
  ) => {
    const arrAllCompanyService = arrCompanyService.slice();
    const arrAllService = arrService.slice();
    const updatedMapArrMenuByRestaurantId = new Map(mapArrMenuByRestaurantId);

    const companyServiceToBeUpdated: CompanyService = arrAllCompanyService.find(
      (companyService) => companyService.companyId === idParameters.companyId
    )!;

    if (companyToBeUpdated) {
      companyServiceToBeUpdated.name = companyToBeUpdated.name;
    }

    if (restaurantToBeUpdated) {
      const restaurantServiceToBeUpdatedIndex: number =
        companyServiceToBeUpdated.arrRestaurantService.findIndex(
          (restaurantService) => restaurantService.restaurantId === idParameters.restaurantId
        )!;
      companyServiceToBeUpdated.arrRestaurantService[restaurantServiceToBeUpdatedIndex] = {
        ...companyServiceToBeUpdated.arrRestaurantService[restaurantServiceToBeUpdatedIndex],
        ...restaurantToBeUpdated,
      };
    }

    if (locationToBeUpdated) {
      const restaurantServiceIndex: number =
        companyServiceToBeUpdated.arrRestaurantService.findIndex(
          (restaurantService) => restaurantService.restaurantId === idParameters.restaurantId
        )!;
      const locationServiceToBeUpdatedIndex: number =
        companyServiceToBeUpdated.arrRestaurantService[
          restaurantServiceIndex
        ].arrLocationService.findIndex(
          (locationService) => locationService.locationId === idParameters.locationId
        )!;
      companyServiceToBeUpdated.arrRestaurantService[restaurantServiceIndex].arrLocationService[
        locationServiceToBeUpdatedIndex
      ] = {
        ...companyServiceToBeUpdated.arrRestaurantService[restaurantServiceIndex]
          .arrLocationService[locationServiceToBeUpdatedIndex],
        ...locationToBeUpdated,
      };
    }

    if (arrServiceToBeUpdated) {
      const restaurantServiceIndex: number =
        companyServiceToBeUpdated.arrRestaurantService.findIndex(
          (restaurantService) => restaurantService.restaurantId === idParameters.restaurantId
        )!;
      const locationServiceIndex: number = companyServiceToBeUpdated.arrRestaurantService[
        restaurantServiceIndex
      ].arrLocationService.findIndex(
        (locationService) => locationService.locationId === idParameters.locationId
      )!;
      const arrService =
        companyServiceToBeUpdated.arrRestaurantService[restaurantServiceIndex].arrLocationService[
          locationServiceIndex
        ].arrService;
      arrServiceToBeUpdated.forEach((serviceToBeUpdated) => {
        const serviceToBeUpdatedIndex = arrService.findIndex(
          (service) => service.serviceId === serviceToBeUpdated.serviceId
        );

        const updatedService = {
          ...arrService[serviceToBeUpdatedIndex],
          ...serviceToBeUpdated,
        };
        companyServiceToBeUpdated.arrRestaurantService[restaurantServiceIndex].arrLocationService[
          locationServiceIndex
        ].arrService[serviceToBeUpdatedIndex] = updatedService;

        // Update for the context value, arrService
        const matchedServiceIndex = arrAllService.findIndex(
          (service) => service.serviceId === serviceToBeUpdated.serviceId
        );
        arrAllService[matchedServiceIndex] = updatedService;
      });

      // To update the array of menu belonging to a restaurant
      const arrServiceOfRestaurant: Array<Service> = [];
      companyServiceToBeUpdated.arrRestaurantService[
        restaurantServiceIndex
      ].arrLocationService.forEach((locationService) => {
        locationService.arrService.forEach((service) => arrServiceOfRestaurant.push(service));
      });
      const arrMenu = getArrUniqueMenu(arrServiceOfRestaurant, []);
      updatedMapArrMenuByRestaurantId.set(idParameters.restaurantId!, arrMenu);

      setMapArrMenuByRestaurantId(updatedMapArrMenuByRestaurantId);
      setArrService(arrAllService);
    }
    sortArrRestaurantServiceInAscendingOrderByName(companyServiceToBeUpdated.arrRestaurantService);

    setArrCompanyService(arrAllCompanyService);
  };

  /**
   * Update the map of restaurantId to arrMenu when new service(s) is added or when existing service(s) is edited
   * @param updatedMapArrMenuByRestaurantId
   */
  const updateMapArrMenuByRestaurantId = (
    updatedMapArrMenuByRestaurantId: Map<number, Array<Menu>>
  ) => {
    setMapArrMenuByRestaurantId(updatedMapArrMenuByRestaurantId);
  };

  /**
   * Update the map of isOpened of companyId or restaurantId when the card/list component is clicked or when a
   * selection is made from the search bar
   * @param mapsUpdatedIsOpenedById
   */
  const updateMapsIsOpenedById = (mapsUpdatedIsOpenedById: MapsUpdatedIsOpenedById) => {
    setMapsIsOpenedById(mapsUpdatedIsOpenedById);
  };

  /**
   * Update the array of service types when it is fetched. Tranform the array of service types to service type
   * options so that this array can be easily used in the InputWithSearchFunction, which uses the attribute
   * 'name' heavily within the component. Hence the only difference between service type object and service type
   * option object is that service type option object has an additional attribute 'name'.
   * @param arrUpdatedServiceType
   */
  const updateArrServiceTypeOption = (arrUpdatedServiceType: Array<ServiceType>) => {
    const arrUpdatedServiceTypeOption: Array<ServiceTypeOption> = [];
    arrUpdatedServiceType.forEach((serviceType) => {
      const updatedServiceTypeOption = {
        ...serviceType,
        name: serviceType.type,
      };
      arrUpdatedServiceTypeOption.push(updatedServiceTypeOption);
    });
    setArrServiceTypeOption(arrUpdatedServiceTypeOption);
  };

  /**
   * This function is called when 1. the menu item(s) of a menu is to be fetched but it does not exist in the
   * mapArrMenuItemByMenuId yet, or 2. new menu items are created
   * @param arrMenuItem - Array of all menu items(s) of a menu that is to be added to the context value, mapArrMenuItemByMenuId
   */
  const addMenuItem = (arrMenuItem: Array<MenuItem>) => {
    const updatedMapArrMenuItemByMenuId = new Map(mapArrMenuItemByMenuId);
    updatedMapArrMenuItemByMenuId.set(arrMenuItem[0].menuId, arrMenuItem);
    setMapArrMenuItemByMenuId(updatedMapArrMenuItemByMenuId);
  };

  return (
    <CompanyContext.Provider
      value={{
        arrCompanyService: arrCompanyService,
        arrService: arrService,
        arrServiceTypeOption: arrServiceTypeOption,
        mapArrMenuByRestaurantId: mapArrMenuByRestaurantId,
        mapArrMenuItemByMenuId: mapArrMenuItemByMenuId,
        mapsIsOpenedById: mapsIsOpenedById,
        addArrCompanyService: addArrCompanyService,
        addArrLocationService: addArrLocationService,
        addArrRestaurantService: addArrRestaurantService,
        addArrService: addArrService,
        addMenuItem: addMenuItem,
        updateArrServiceTypeOption: updateArrServiceTypeOption,
        updateCompanyServiceDetails: updateCompanyServiceDetails,
        updateMapArrMenuByRestaurantId: updateMapArrMenuByRestaurantId,
        updateMapsIsOpenedById: updateMapsIsOpenedById,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
