import React, { useState, useContext } from 'react';
import { Input } from '@material-tailwind/react';

import InputCardsContext from '../../../context/InputCardsContext';
import { checkForInvalidNameInput } from '../../../functions/InputChecks';
import { LocationService, Location } from '../../../interfaces';

const LocationInputCard = (props: {
  arrLocationService: Array<LocationService>;
  arrNewLocation: Array<Location>;
  companyId: number;
  locationId: number;
  locationService: LocationService | undefined;
  restaurantId: number;
  updateEditedOrNewLocation: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);

  const isEdit = !!props.locationService;
  // If LocationInputCard is for editing existing location, isNameValid should be true
  const [isNameValid, setIsNameValid] = useState(isEdit);
  const [nameErrorMessage, setNameErrorMessage] = useState(isEdit ? '' : 'Field cannot be empty!');
  // TODO: To change naming for companyForInput, restaurantForInput, locationForInput and serviceForInput as variable naming is
  // not descriptive enough to convey what the variable is (Ref: #70)
  const [locationForInput, setLocationForInput] = useState<Location>(
    isEdit && props.locationService !== undefined
      ? {
          locationId: props.locationService.locationId,
          name: '',
          restaurantId: props.locationService.restaurantId,
        }
      : {
          locationId: props.locationId,
          name: '',
          restaurantId: props.restaurantId,
        }
  );

  /**
   * Function attached to input component, takes in input data for editing this locations's name and updates newLocationService state.
   * @param event - contains input data from input html
   */
  const updateLocationNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLocationForInput = {
      ...locationForInput,
      name: event.target.value,
    };
    setLocationForInput(newLocationForInput);
  };

  /**
   * Function runs whenever onBlur event on location name Input component occurs. Validate the location name
   * - Check for alphanumeric
   * - Cannot duplicate with any new or existing location name within a restaurant
   * Note: If it is editing an existing location, empty string ('') is considered a valid input as it will be treated as no change to the
   * current name. Hence there is slight difference in the handling of input between edit and add.
   */
  const locationNameInputOnBlur = () => {
    if (isEdit) {
      if (
        locationForInput.name.length === 0 ||
        locationForInput.name === props.locationService!.name
      ) {
        // This setting of locationForInput is because the user might type in the same exact location name as the original,
        // and therefore the serviceForInput's name should be reset to '' to indicate that there is no change.
        const newLocationForInput = { ...locationForInput, name: '' };
        setIsNameValid(true);
        setLocationForInput(newLocationForInput);
        inputCardsContext.deleteErrorForOneId(locationForInput.locationId, 1);
        props.updateEditedOrNewLocation({ locationId: locationForInput.locationId });
      } else {
        const errorMessage = checkForInvalidNameInput(
          locationForInput.name,
          locationForInput.locationId,
          props.arrLocationService,
          [],
          'Location'
        );
        if (errorMessage.length !== 0) {
          setIsNameValid(false);
          setNameErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(locationForInput.locationId, true, 1);
        } else {
          setIsNameValid(true);
          inputCardsContext.deleteErrorForOneId(locationForInput.locationId, 1);
        }
        props.updateEditedOrNewLocation(
          { locationId: locationForInput.locationId },
          locationForInput
        );
      }
    } else {
      props.updateEditedOrNewLocation(
        { locationId: props.locationId, restaurantId: props.restaurantId },
        locationForInput
      );
      if (locationForInput.name.length === 0) {
        setIsNameValid(false);
        setNameErrorMessage('Field cannot be empty!');
        inputCardsContext.setErrorExist(props.locationId, true, 1);
      } else {
        const errorMessage = checkForInvalidNameInput(
          locationForInput.name,
          props.locationId,
          props.arrLocationService,
          props.arrNewLocation,
          'Location'
        );
        if (errorMessage.length !== 0) {
          setIsNameValid(false);
          setNameErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(props.locationId, true, 1);
        } else {
          setIsNameValid(true);
          inputCardsContext.deleteErrorForOneId(props.locationId, 1);
        }
      }
    }
  };

  return (
    <div className="flex justify-between py-4">
      <div className="flex flex-col gap-x-1 mt-2 px-4">
        <Input
          onBlur={locationNameInputOnBlur}
          size="md"
          onChange={updateLocationNameOnChange}
          label="Location"
          variant="static"
          value={locationForInput.name}
          placeholder={props.locationService !== undefined ? props.locationService.name : ''}
          className={`LocationNameInput text-off-black`}
          error={!isNameValid}
        />
        {!isNameValid && (
          <p className="LocationNameErrorMessage text-red-500 text-xs mt-2">{nameErrorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default LocationInputCard;
