import { useContext, useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { Checkbox } from '@material-tailwind/react';

import UserLocationCheckbox from './UserLocationCheckbox';
import InputCardsContext from '../../context/InputCardsContext';
import { RestaurantLocation, RestaurantService, Location } from '../../interfaces';

const UserRestaurantLocationCheckbox = (props: {
  restaurantWithAllLocationService: RestaurantService;
  restaurantWithSelectedLocation: RestaurantLocation | null;
  updateMapSelectedRestaurantLocationByRestaurantId: any;
  userId: number;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const [isRestaurantChecked, setIsRestaurantChecked] = useState(false);
  const [mapIsLocationCheckedByLocationId, setMapIsLocationCheckedByLocationId] = useState(
    new Map<number, boolean>()
  );
  const [mapLocationByLocationId, setMapLocationByLocationId] = useState(new Map());

  /**
   * This function toggles this component's restaurant checked state while also
   * setting the states of it's children location checkbox to false or true depending
   * on the boolean value of this restaurant's checkbox
   */
  const checkIsRestaurantCheckedOnChange = () => {
    const newIsRestaurantChecked = !isRestaurantChecked;
    const newMapIsLocationCheckedByLocationId = new Map(mapIsLocationCheckedByLocationId);
    newMapIsLocationCheckedByLocationId.forEach((isLocationChecked, locationId) => {
      newMapIsLocationCheckedByLocationId.set(locationId, newIsRestaurantChecked);
    });

    setIsRestaurantChecked(newIsRestaurantChecked);
    setMapIsLocationCheckedByLocationId(newMapIsLocationCheckedByLocationId);
    updateParentList(newMapIsLocationCheckedByLocationId);
  };

  /**
   * This function checks if all its location(s) are checked. The restaurant should be checked if all its location(s)
   * are checked, and unchecked if at least one of the location(s) is unchecked
   */
  const checkRestaurantIfAllLocationsAreChecked = (
    mapIsLocationCheckedByLocationIdToCheck: Map<number, boolean>
  ) => {
    let isAllChecked = true;
    mapIsLocationCheckedByLocationIdToCheck.forEach((isChecked: boolean, locationId: number) => {
      if (!isChecked) {
        isAllChecked = false;
      }
    });
    if (isAllChecked) {
      setIsRestaurantChecked(true);
    } else {
      setIsRestaurantChecked(false);
    }
  };
  /**
   * This function is passed down to UserLocationCheckbox component to be run as a callback whenever a
   * UserLocationCheckbox state is changed. Updates mapIsLocationCheckedByLocationId according to locationId
   * to set boolean of checkbox state. Also checks restaurant if all its location(s) are checked.
   */
  const updateIsLocationChecked = (locationId: any, isChecked: boolean) => {
    const newMapIsLocationCheckedByLocationId = new Map(mapIsLocationCheckedByLocationId);
    newMapIsLocationCheckedByLocationId.set(locationId, isChecked);

    checkRestaurantIfAllLocationsAreChecked(newMapIsLocationCheckedByLocationId);
    setMapIsLocationCheckedByLocationId(newMapIsLocationCheckedByLocationId);
    updateParentList(newMapIsLocationCheckedByLocationId);
  };

  /**
   * This function runs on component first render to create a map for boolean states of location
   * checkboxes. For Edit mode, these boolean states are set according to whether these locations
   * are currently associated with the user, while in the Add mode, all the boolean states are false.
   * This function also creates a map for storing all location data which will be used when updating
   * the location(s) associated with the user.
   */
  const createUserLocationCheckBoxList = () => {
    const arrAllLocationService = props.restaurantWithAllLocationService.arrLocationService;
    const newMapIsLocationCheckedByLocationId: Map<number, boolean> = new Map();
    const newMapLocationByLocationId: Map<number, Location> = new Map();
    arrAllLocationService.forEach((locationService) => {
      const location = {
        locationId: locationService.locationId,
        name: locationService.name,
        restaurantId: locationService.restaurantId,
      };
      newMapIsLocationCheckedByLocationId.set(locationService.locationId, false);
      newMapLocationByLocationId.set(locationService.locationId, location);
    });
    if (inputCardsContext.userCardInputModeIndex === 1) {
      props.restaurantWithSelectedLocation?.arrLocation.forEach((location) => {
        newMapIsLocationCheckedByLocationId.set(location.locationId, true);
      });
    }
    checkRestaurantIfAllLocationsAreChecked(newMapIsLocationCheckedByLocationId);
    setMapIsLocationCheckedByLocationId(newMapIsLocationCheckedByLocationId);
    setMapLocationByLocationId(newMapLocationByLocationId);
  };

  /**
   * This function runs whenever a checkbox is checked or unchecked, runs a callback function
   * to pass restaurantLocation object containing object data of all restaurant and locations this
   * new user can access. Passes data to the parent UserRestaurantCheckbox for creation of a new user through
   * the callback function updateMapSelectedRestaurantLocationByRestaurantId.
   */
  const updateParentList = (mapIsLocationCheckedByLocationIdToBeUpdated: Map<number, boolean>) => {
    if (mapIsLocationCheckedByLocationIdToBeUpdated.size !== 0) {
      let newArrLocationChecked: any = [];
      let isAnyLocationChecked: boolean = true;
      mapIsLocationCheckedByLocationIdToBeUpdated.forEach((isChecked, locationId) => {
        if (isChecked) {
          const location = mapLocationByLocationId.get(locationId);
          newArrLocationChecked.push(location);
        }
      });
      const restaurantLocation: RestaurantLocation = {
        restaurantId: props.restaurantWithAllLocationService.restaurantId,
        companyId: props.restaurantWithAllLocationService.companyId,
        name: props.restaurantWithAllLocationService.name,
        arrLocation: newArrLocationChecked,
      };
      if (newArrLocationChecked.length === 0) {
        isAnyLocationChecked = false;
      }
      props.updateMapSelectedRestaurantLocationByRestaurantId(
        restaurantLocation,
        props.restaurantWithAllLocationService.restaurantId,
        isAnyLocationChecked
      );
    }
  };

  /**
   * This useEffect runs createUserLocationCheckBoxList on first render of component only once
   */
  useEffect(() => {
    createUserLocationCheckBoxList();
  }, []);

  return (
    <div className="flex flex-col rounded-xl p-2 bg-gray-200">
      <div className="flex items-center">
        <Checkbox
          className={props.restaurantWithAllLocationService.name + `Checkbox h-5 w-5 rounded-xl`}
          key={`RestaurantCheckbox ${props.userId} ${props.restaurantWithAllLocationService.restaurantId}`}
          id={`RestaurantCheckbox ${props.userId} ${props.restaurantWithAllLocationService.restaurantId}`}
          onChange={checkIsRestaurantCheckedOnChange}
          checked={isRestaurantChecked}
          icon={<MdCheck className="h-3 w-3" />}
        />
        <p className="text-base font-semibold break-words align-middle">
          {props.restaurantWithAllLocationService.name}
        </p>
      </div>
      {props.restaurantWithAllLocationService.arrLocationService.map((locationService) => (
        <UserLocationCheckbox
          key={`UserLocationCheckbox ${props.userId} ${locationService.locationId}`}
          userId={props.userId}
          isChecked={mapIsLocationCheckedByLocationId.get(locationService.locationId) || false}
          locationId={locationService.locationId}
          locationName={locationService.name}
          updateIsLocationChecked={updateIsLocationChecked}
        />
      ))}
    </div>
  );
};

export default UserRestaurantLocationCheckbox;
