import { useAuth0 } from '@auth0/auth0-react';

import LogoutButton from './LoginComponents/LogoutButton';
import logo from '../images/Lumitics-logo-green-0.png';

const Header = () => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <div className="bg-[#152445]">
      <header className="m-1 mt-0 p-6 flex content-center">
        <img src={logo} className="m-auto" alt="logo"></img>
        <p className="text-white ml-3 mt-auto">Management Console</p>
        {isAuthenticated && (
          <p className="flex flex-col text-white grow text-right text-sm items-end">
            <LogoutButton />
            <span className="mt-3">Logged in as: {user?.name}</span>
          </p>
        )}
      </header>
    </div>
  );
};

export default Header;
