import Pptxgen from 'pptxgenjs';
import { DateObject } from 'react-multi-date-picker';
import { User } from '@auth0/auth0-spa-js';

import {
  ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE,
  SLIDE_STYLE,
  SLIDE_TYPE,
} from '../../constant/LocationMonthlyReviewPowerpoint';
import {
  insertGraphForBaseline,
  insertImageSlidesForBaseline,
  insertTableForBaseline,
  initialiseSlide,
  insertBaselineNextStepsBodySlides,
  insertSummaryValues,
} from '../../functions/PowerpointFunctions';
import { Service } from '../../interfaces';

/**
 * Get the number of days within a date range
 * Note: The specified dates are included
 * @param dateRange - Date range to find the number of days it consists
 * @returns number of
 */
const getNumberOfDayWithinDateRange = (dateRange: Array<DateObject>) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const startDate = dateRange[0] as any;
  const endDate = dateRange[1] as any;
  return Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;
};

/**
 * This function holds the logic required to extract the pptData provided by the backend and plots them within
 * a predetermined slide order template structure.
 * The Backend passes back the data in a structure that can be read by Frontend functions as easy as possible without much sorting
 * and calculations.
 * @param fetchBaselineGraphRes - Response from backend containing information required to be plot into the powerpoint slides
 * @param formState - Report form header values used for powerpoint slide headers
 * @param user - User provided by Auth0 SDK to place the user's email address in the closing slide
 */
const downloadBaselineReviewPowerpoint = async (
  fetchBaselineGraphRes: any,
  formState: {
    companyField: string;
    restaurantField: string;
    locationField: string;
    arrSelectedService: Array<Service>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
  },
  user: User | undefined
) => {
  const { baselineReportData, restaurantService, fileName } = fetchBaselineGraphRes;
  const { name: restaurantName, arrLocationService } = restaurantService;
  const { name: locationName } = arrLocationService[0];
  const { dateRange } = formState;
  const numberOfDayWithinDateRange = getNumberOfDayWithinDateRange(dateRange);
  const locationData = baselineReportData[locationName];

  // Powerpoint creation
  const pres = new Pptxgen();
  ARR_SLIDE_ORDER_TEMPLATE_FOR_BASELINE.forEach((slideTemplate) => {
    const { slideStyle } = slideTemplate;
    // Opening Slide
    if (slideStyle === SLIDE_STYLE.opening) {
      const openingText = `${restaurantName}\nBaseline Review (${dateRange[1].format(
        'MMMM-YYYY'
      )})`;
      initialiseSlide(pres, slideStyle, openingText);
      return;
    }
    // Section Slide
    else if (slideStyle === SLIDE_STYLE.section) {
      const { headerText, hasTextToAdd } = slideTemplate;
      if (hasTextToAdd) {
        const sectionText = `${headerText}\n${locationName}`;
        initialiseSlide(pres, slideStyle, sectionText);
      } else {
        initialiseSlide(pres, slideStyle, headerText);
        insertBaselineNextStepsBodySlides(pres, restaurantName);
      }
      return;
    }
    // Closing Slide
    else if (slideStyle === SLIDE_STYLE.closing) {
      initialiseSlide(pres, slideStyle, user?.email);
    }
    // Plain Slide
    else if (slideStyle === SLIDE_STYLE.plain) {
      const { headerText, slideType, dynamic } = slideTemplate;
      if (headerText === 'SUMMARY') {
        let slide = initialiseSlide(pres, slideStyle, headerText);
        insertSummaryValues(
          slide,
          locationData.overview.graph.wasteValue,
          numberOfDayWithinDateRange
        );
      } else if (!dynamic) {
        let slide = initialiseSlide(pres, slideStyle, headerText);
        if (slideType === SLIDE_TYPE.graph) {
          insertGraphForBaseline(slide, locationData.overview.graph.image);
        } else if (slideType === SLIDE_TYPE.table) {
          const {
            arrNameAndWeightPerCoverForGroupedServices,
            arrNameAndWeightPerCoverForServices,
          } = locationData.overview.table;
          insertTableForBaseline(pres, slide, headerText, arrNameAndWeightPerCoverForServices);
          // For grouped service(s)
          const isAllServiceFromSameGroup =
            arrNameAndWeightPerCoverForGroupedServices.length ===
            arrNameAndWeightPerCoverForServices.length;
          if (!isAllServiceFromSameGroup) {
            const headerTextForGroup = `${headerText} (GROUPED)`;
            slide = initialiseSlide(pres, slideStyle, headerTextForGroup);
            insertTableForBaseline(
              pres,
              slide,
              headerTextForGroup,
              arrNameAndWeightPerCoverForGroupedServices
            );
          }
        }
      } else {
        const { arrSlideGraphProp } = slideTemplate;
        locationData.breakdown.forEach((groupedService: any) => {
          const { name, arrImageBase64, arrServiceNameAndTopWasteImageAndTop6WasteItem } =
            groupedService;
          arrSlideGraphProp?.forEach(
            (
              slideProp: {
                headerText: string;
                slideType: string;
                weightCostType?: string;
                isFullWidth?: boolean;
              },
              index: number
            ) => {
              const { headerText, isFullWidth } = slideProp;
              // If service is production, it will only have 1 graph (the first graph of the array)
              if (!arrImageBase64[index]) {
                return;
              }
              let slide = initialiseSlide(
                pres,
                slideStyle,
                `${name.toUpperCase()} - ${headerText}`
              );
              insertGraphForBaseline(
                slide,
                arrImageBase64[index].image,
                arrImageBase64[index].wasteValue,
                {
                  isWeight: index !== arrSlideGraphProp.length - 1,
                  isFullWidth,
                }
              );
            }
          );
          arrServiceNameAndTopWasteImageAndTop6WasteItem.forEach(
            (serviceNameAndTopWasteImageAndTop6WasteItem: any) => {
              const {
                serviceName,
                arrTop3InternalGroupingDetailByWeight,
                arrTopXWasteImageSignedUrlByWeight,
              } = serviceNameAndTopWasteImageAndTop6WasteItem;
              const headerTextForServicePortion = `${name.toUpperCase()}${
                serviceName ? ` - ${serviceName}` : ''
              }`;
              insertImageSlidesForBaseline(
                pres,
                headerTextForServicePortion,
                arrTop3InternalGroupingDetailByWeight,
                arrTopXWasteImageSignedUrlByWeight
              );
            }
          );
        });
      }
    }
  });
  await pres.writeFile({ fileName });
};

export default downloadBaselineReviewPowerpoint;
