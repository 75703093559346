import axios from 'axios';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Switch } from '@material-tailwind/react';

import Alert from './alerts/Alert';
import MenuCard from './MenuCard';
import { Menu, MenuItem, Service } from '../../interfaces';
import CompanyContext from '../../context/CompanyContext';

const ServiceCard = (props: {
  companyId: number;
  companyName: string;
  locationName: string;
  restaurantName: string;
  service: Service;
}) => {
  const companyContext = useContext(CompanyContext);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [arrMenuItem, setArrMenuItem] = useState<MenuItem[]>([]);
  const [arrErrorMessage, setArrErrorMessage] = useState<string[]>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');
  const [isAlert, setIsAlert] = useState<boolean>(false);

  const closeAlertOnClick = () => {
    setIsAlert(!isAlert);
  };

  const openMenuOnClick = async () => {
    if (isMenuOpen === false) {
      await fetchMenuItems(props.service.menu);
    }
    setIsMenuOpen(!isMenuOpen);
  };

  /**
   * Function calls backend to fetch menu items.
   * @param menu - Menu to fetch menu items from
   */
  const fetchMenuItems = async (menu: Menu) => {
    try {
      let arrMenuItemOfService = companyContext.mapArrMenuItemByMenuId.get(menu.menuId) || [];
      if (arrMenuItemOfService.length === 0) {
        const response = await axios.post('/company-management/fetch-menu-items', {
          arrMenu: [menu],
        });
        const { arrArrMenuItem } = response.data;
        if (arrArrMenuItem.length !== 0) {
          arrMenuItemOfService = arrArrMenuItem[0];
          companyContext.addMenuItem(arrMenuItemOfService!);
        }
      }
      setArrMenuItem(arrMenuItemOfService!);
    } catch (error: any) {
      setArrErrorMessage([error]);
      setAlertHeader('Fetch Error');
      setIsAlert(true);
    }
  };

  return (
    <li>
      <Alert
        arrAlertMessage={arrErrorMessage}
        alertHeader={alertHeader}
        isExpanded={isAlert}
        handleOpen={closeAlertOnClick}
      />
      {isMenuOpen && (
        <MenuCard
          companyId={props.companyId}
          menu={props.service.menu}
          service={props.service}
          companyName={props.companyName}
          restaurantName={props.restaurantName}
          locationName={props.locationName}
          arrMenuItem={arrMenuItem}
          isMenuOpen={isMenuOpen}
          openMenuOnClick={openMenuOnClick}
          setArrMenuItem={setArrMenuItem}
        />
      )}
      <div className="ServiceCard mt-1 grid grid-cols-13 gap-x-2 justify-between text-[10px] sm:text-sm">
        <div className="text-off-black underline-offset-2 col-span-2 mr-2 break-all flex flex-col">
          <div className="text-[0.6rem] text-gray-600 leading-none">
            Service Id: {props.service.serviceId}
          </div>
          {props.service.name}
        </div>
        <p
          className="MenuName col-span-2 text-blue-600 cursor-pointer break-all hover:underline mr-2"
          onClick={openMenuOnClick}
        >
          {props.service.menu.name}
        </p>
        <p className="ServiceInputTag text-left col-span-1 mr-2 break-all">
          {props.service.inputTag}
        </p>
        <p className="text-center col-span-1 break-all">
          {moment(props.service.projectStartDate).format('DD MMM YYYY')}
        </p>
        <p className="text-center col-span-1 break-all">
          {props.service.startDate ? moment(props.service.startDate).format('DD MMM YYYY') : 'TBD'}
        </p>
        <p className="text-center col-span-1 break-all">
          {props.service.endDate ? moment(props.service.endDate).format('DD MMM YYYY') : 'TBD'}
        </p>
        <p className="text-center col-span-1 break-all">{props.service.currency}</p>
        <p className="text-center col-span-1 break-all">{props.service.offset}</p>
        <p className="text-center col-span-1 break-all">{props.service.serviceType.type}</p>
        <p className="text-center col-span-1 break-all">{props.service.group}</p>
        <div className="justify-self-center pointer-events-none col-span-1">
          {props.service.isValid ? (
            <Switch
              color="blue"
              id={`${props.service.serviceId}`}
              className="ServiceValid text-off-black font-normal"
              defaultChecked
            />
          ) : (
            <Switch
              color="blue"
              id={`${props.service.serviceId}`}
              className="ServiceInvalid text-off-black font-normal"
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default ServiceCard;
