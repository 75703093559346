import React, { useState } from 'react';
import { MdClear, MdCreate } from 'react-icons/md';
import { Waypoint } from 'react-waypoint';
import { Button } from '@material-tailwind/react';

const UpdateButton = (props: {
  buttonClassName: string;
  buttonName: string;
  enableUpdateOnClick: any;
  isUpdate: boolean;
  isEnabled: boolean;
  positionType: string;
  saveChangesOnClick: any;
}) => {
  const [isButtonFloating, setIsButtonFloating] = useState<boolean>(false);
  return (
    <div
      className={`${props.positionType} flex gap-x-2 right-0 px-4 `}
      onClick={(event) => event.stopPropagation()}
    >
      {!props.isUpdate && (
        <Button
          onClick={props.enableUpdateOnClick}
          size="sm"
          className={
            props.buttonClassName + ' flex gap-x-1 p-2 font-medium self-center bg-blue-600'
          }
          disabled={!props.isEnabled}
        >
          <MdCreate className="UpdateButton self-center" />
          {props.buttonName}
        </Button>
      )}
      {props.isUpdate && (
        <React.Fragment>
          <Button
            onClick={props.enableUpdateOnClick}
            size="sm"
            color="red"
            className={'CancelUpdateButton bg-red-500 flex gap-x-1 pt-2 p-2 font-medium h-fit'}
          >
            <MdClear className="self-center" />
            Cancel Update
          </Button>
          <div className={`${isButtonFloating && 'fixed top-24 z-10'}`}>
            <Button
              onClick={props.saveChangesOnClick}
              size="sm"
              color="green"
              className={'SaveChangesButton bg-blue-600 flex gap-x-1 pt-2 p-2 font-medium h-fit'}
            >
              <MdCreate className="self-center" />
              Save Changes
            </Button>
          </div>
          <Waypoint
            onEnter={() => setIsButtonFloating(false)}
            onLeave={() => setIsButtonFloating(true)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default UpdateButton;
