import React, { useContext, useEffect, useState } from 'react';
import { Input, Switch } from '@material-tailwind/react';
import moment from 'moment';
import { BsFillInfoCircleFill } from 'react-icons/bs';

import InputWithSearchFunction from './InputWithSearchFunction';
import ARR_CURRENCY_OBJECT from '../../../constant/Currency';
import ARR_OFFSET_OBJECT from '../../../constant/Offset';
import CompanyContext from '../../../context/CompanyContext';
import InputCardsContext from '../../../context/InputCardsContext';
import {
  checkForInvalidInputTag,
  checkForInvalidMenuName,
  checkForInvalidNameInput,
} from '../../../functions/InputChecks';
import { checkIsNameValidWithRegEx } from '../../../functions/InputValidation';
import { Menu, NewService, Service, ServiceType, ServiceTypeOption } from '../../../interfaces';

const LUMITICS_COMMENCE_DATE = '2017-05-01';
const INPUT_TYPE = {
  menu: 'menu',
  currency: 'currency',
  offset: 'offset',
  type: 'type',
  group: 'group',
};
const NOT_SELECTED_FROM_DROPDOWN_LIST_ERROR_MESSAGE = 'Please select from dropdown list!';
const CANNOT_BE_EMPTY_ERROR_MESSAGE = 'Field cannot be empty!';
const DEFAULT_CURRENCY = 'USD';
const NEW_ID = -1;

/**
 * This function finds the today of the timezone
 * @returns today - Today in YYYY-MM-DD format
 */
const getTodayDateInYYYYMMDDFormat = () => {
  const utcoffsetInMinutes = moment().utcOffset();
  const today = moment().add(utcoffsetInMinutes, 'minute').toISOString().split('T')[0];

  return today;
};

/**
 * This function finds the start date of the valid date range for baseline end date selection. If there is a selected
 * baseline start date, the baseline end date must be on or after that date. However, if the baseline start date is chosen
 * but before the LUMITICS_COMMENCE_DATE (possible if the date is manually input), the baseline end date should be on or
 * after the LUMITICS_COMMENCE_DATE. If there is no selected baseline start date, check if the service is for edit or create.
 * Default min value is set as today's date if it is for create service as it does not make sense to create a service with
 * a back dated start date.
 * @param todayDate - Today's date
 * @param baselineStartDate - Current baseline start date
 * @param isEdit - Indicates if the ServiceInputCard is for edit or create
 * @returns date - Start date of the valid date range for baseline end date selection
 */
const getMinimumValidBaselineEndDate = (
  todayDate: string,
  baselineStartDate: string | null,
  isEdit: boolean
) => {
  if (baselineStartDate) {
    if (baselineStartDate < LUMITICS_COMMENCE_DATE) {
      return LUMITICS_COMMENCE_DATE;
    }
    return baselineStartDate;
  } else if (isEdit) {
    return LUMITICS_COMMENCE_DATE;
  } else {
    return todayDate;
  }
};

const ServiceInputCard = (props: {
  arrUpdatedService: Array<Service>;
  arrService: Array<Service> | undefined;
  companyId: number;
  restaurantId: number;
  locationId: number;
  service: Service | undefined;
  serviceId: number;
  updateMapForEditedOrNewService: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const companyContext = useContext(CompanyContext);

  const DEFAULT_SERVICE_TYPE = companyContext.arrServiceTypeOption.find(
    (serviceType: ServiceType) => serviceType.type === 'Standard'
  );

  // If ServiceInputCard is for editing existing service, isNameValid, isMenuNameValid,isInputTagValid and isOffsetValid should be true
  const isEdit = !!props.service;
  const [isNameValid, setIsNameValid] = useState(isEdit); // State controls if edited service name is valid

  const [nameErrorMessage, setNameErrorMessage] = useState(
    isEdit ? '' : CANNOT_BE_EMPTY_ERROR_MESSAGE
  ); // State controls error messages for service name

  const [isMenuNameValid, setIsMenuNameValid] = useState(isEdit); // State controls if edited menu name is valid
  const [menuNameErrorMessage, setMenuNameErrorMessage] = useState(
    isEdit ? '' : CANNOT_BE_EMPTY_ERROR_MESSAGE
  );

  const [isInputTagValid, setIsInputTagValid] = useState(isEdit); // State controls if edited tag is valid
  const [inputTagErrorMessage, setInputTagErrorMessage] = useState(
    isEdit ? '' : CANNOT_BE_EMPTY_ERROR_MESSAGE
  );

  const [isProjectStartDateValid, setIsProjectStartDateValid] = useState(isEdit); // If project start date is valid, project start date cannot be null
  const [projectStartDateErrorMessage, setProjectStartDateErrorMessage] = useState(
    isEdit ? '' : CANNOT_BE_EMPTY_ERROR_MESSAGE
  );
  const [isStartDateValid, setIsStartDateValid] = useState(true); // If end date is valid, start date cannot be null
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [isEndDateValid, setIsEndDateValid] = useState(true); // If start date is valid, end date cannot be null
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');

  const [isCurrencyValid, setIsCurrencyValid] = useState(true);
  const [currencyErrorMessage, setCurrencyErrorMessage] = useState('');

  const [isOffsetValid, setIsOffsetValid] = useState(isEdit);
  const [offsetErrorMessage, setOffsetErrorMessage] = useState(
    isEdit ? '' : CANNOT_BE_EMPTY_ERROR_MESSAGE
  );

  const [isServiceTypeValid, setIsServiceTypeValid] = useState(true);
  const [serviceTypeErrorMessage, setServiceTypeErrorMessage] = useState('');

  const [isGroupValid, setIsGroupValid] = useState(true); // State controls if edited group is valid
  const [groupErrorMessage, setGroupErrorMessage] = useState('');

  const [serviceForInput, setServiceForInput] = useState<NewService>(
    isEdit && props.service !== undefined
      ? {
          serviceId: props.service.serviceId,
          name: '',
          inputTag: '',
          locationId: props.service.locationId,
          isValid: props.service.isValid,
          percentageThreshold: props.service.percentageThreshold,
          numDays: props.service.numDays,
          remoteTaggingUserLock: props.service.remoteTaggingUserLock,
          projectStartDate: props.service.projectStartDate,
          startDate: props.service.startDate,
          endDate: props.service.endDate,
          currency: '',
          menuId: props.service.menuId,
          menu: {
            menuId: props.service.menuId,
            name: '',
          },
          offset: '',
          serviceTypeId: props.service.serviceTypeId,
          serviceType: {
            serviceTypeId: props.service.serviceType.serviceTypeId,
            type: '',
          },
          group: props.service.group,
        }
      : {
          serviceId: props.serviceId,
          name: '',
          inputTag: '',
          locationId: props.locationId,
          isValid: true,
          percentageThreshold: 0.2,
          numDays: 30,
          remoteTaggingUserLock: null,
          projectStartDate: '',
          startDate: null,
          endDate: null,
          currency: DEFAULT_CURRENCY,
          menuId: NEW_ID,
          menu: {
            menuId: NEW_ID,
            name: '',
          },
          offset: '',
          serviceTypeId: DEFAULT_SERVICE_TYPE.serviceTypeId,
          serviceType: DEFAULT_SERVICE_TYPE,
          group: null,
        }
  );
  const todayDate = getTodayDateInYYYYMMDDFormat();

  /**
   * Function attached to input component, takes in input data for editing this service's name and updates serviceForInput state
   * @param event - contains input data from input html
   */
  const updateServiceNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceForInput({
      ...serviceForInput,
      name: event.target.value,
    });
  };

  /**
   * Function runs whenever onBlur event on service name Input component occurs. Validate the service name
   * - Check for alphanumeric
   * - Cannot duplicate with any new or existing service name within the same location
   * If the service name is not valid, set isNameValid to false.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current name. Hence there is slight difference in the handling of input between edit and add.
   */
  const checkServiceNameValidOnBlur = () => {
    if (serviceForInput.serviceId !== undefined) {
      if (isEdit) {
        const serviceToBeUpdatedToMap = {
          ...serviceForInput,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };
        if (serviceToBeUpdatedToMap.name === props.service!.name) {
          const isServiceForInputValid =
            serviceForInput.menu.name === '' &&
            serviceForInput.inputTag === '' &&
            serviceForInput.currency === '' &&
            serviceForInput.projectStartDate === props.service!.projectStartDate &&
            serviceForInput.startDate === props.service!.startDate &&
            serviceForInput.endDate === props.service!.endDate &&
            serviceForInput.isValid === props.service!.isValid &&
            serviceForInput.offset === '' &&
            serviceForInput.serviceType.type === '' &&
            isMenuNameValid &&
            isInputTagValid &&
            isCurrencyValid &&
            isProjectStartDateValid &&
            isStartDateValid &&
            isEndDateValid &&
            isOffsetValid &&
            isServiceTypeValid &&
            isGroupValid;
          if (isServiceForInputValid) {
            inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
            props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
          } else {
            props.updateMapForEditedOrNewService(
              { serviceId: serviceForInput.serviceId },
              serviceToBeUpdatedToMap
            );
          }
          // This setting of serviceForInput is because the user might type in the same exact service name as the original,
          // and therefore the serviceForInput's name should be reset to '' to indicate that there is no change.
          const newServiceForInput = { ...serviceForInput, name: '' };
          setServiceForInput(newServiceForInput);
          setIsNameValid(true);
        } else {
          const errorMessage = checkForInvalidNameInput(
            serviceForInput.name,
            serviceForInput.serviceId,
            props.arrService!,
            props.arrUpdatedService,
            'Service'
          );
          if (errorMessage.length !== 0) {
            setIsNameValid(false);
            setNameErrorMessage(errorMessage);
            inputCardsContext.setErrorExist(serviceForInput.serviceId, true, 0);
          } else {
            setIsNameValid(true);
            if (
              isMenuNameValid &&
              isInputTagValid &&
              isCurrencyValid &&
              isOffsetValid &&
              isServiceTypeValid &&
              isProjectStartDateValid &&
              isStartDateValid &&
              isEndDateValid &&
              isGroupValid
            ) {
              inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
            }
          }
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeUpdatedToMap
          );
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          serviceForInput
        );
        if (serviceForInput.name.length === 0) {
          setIsNameValid(false);
          setNameErrorMessage(CANNOT_BE_EMPTY_ERROR_MESSAGE);
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        } else {
          const errorMessage = checkForInvalidNameInput(
            serviceForInput.name,
            props.serviceId,
            props.arrService || [],
            props.arrUpdatedService,
            'Service'
          );

          if (errorMessage.length !== 0) {
            setIsNameValid(false);
            setNameErrorMessage(errorMessage);
            inputCardsContext.setErrorExist(props.serviceId, true, 0);
          } else {
            setIsNameValid(true);
            inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
          }
        }
      }
    }
  };

  /**
   * Function attached to input component, takes in manual input data for editing this service's menu name and updates serviceForInput state
   * @param menu - menu object containing manual input "name" key
   */
  const updateMenuNameOnChangeForManualInput = (menu: Menu) => {
    let updatedMenuId = NEW_ID;
    let updatedName = menu.name;
    if (isEdit && menu.name === '') {
      updatedMenuId = props.service!.menuId;
    }

    setServiceForInput({
      ...serviceForInput,
      menu: {
        menuId: updatedMenuId,
        name: updatedName,
      },
    });
  };

  /**
   * Function attached to menu input component, which takes in the selected menu to update the mapUpdatedServiceByServiceId in the parent component and
   * update the serviceForInput state
   * Note: This function cannot be combined with menuNameInputOnBlur because when a menu is selected from the dropdown list instead of a manual input,
   * menuNameInputOnBlur happens before this function
   * @param menu - Selected menu
   */
  const updateMenuNameOnChangeForDropdown = (menu: Menu) => {
    if (serviceForInput.serviceId !== undefined) {
      const newService = {
        ...serviceForInput,
        menu: {
          menuId: menu.menuId || props.service!.menuId,
          name: menu.name,
        },
      };

      if (
        isNameValid &&
        isInputTagValid &&
        isCurrencyValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isOffsetValid &&
        isServiceTypeValid &&
        isGroupValid
      ) {
        inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
      }

      if (isEdit) {
        const isNoMenuChange = menu.menuId !== NEW_ID && menu.menuId === props.service!.menuId;
        const isNoChangeInNewEditedServiceExcludingMenu =
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.serviceType.type === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;

        const serviceToBeCreatedToEditMap = {
          ...serviceForInput,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: !menu.menuId
            ? props.service!.menu
            : {
                menuId: menu.menuId,
                name: menu.name,
              },
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        if (isNoChangeInNewEditedServiceExcludingMenu && isNoMenuChange) {
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceToBeCreatedToEditMap.serviceId },
            serviceToBeCreatedToEditMap
          );
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsMenuNameValid(true);
      setMenuNameErrorMessage('');
      setServiceForInput(newService);
    }
  };

  /**
   * Function runs whenever onBlur event on menu name Input component occurs. Validate the menu name
   * - Check for alphanumeric
   * - Cannot duplicate with menu name from any other new or existing restaurant
   * If the menu name is not valid, set isMenuNameValid to false.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * existing menu name. Hence there is slight difference in the handling of input between edit and add.
   */
  const menuNameInputOnBlur = () => {
    if (serviceForInput.serviceId !== undefined) {
      const restaurantIdMenuInputTag = {
        restaurantId: props.restaurantId,
        menuName: serviceForInput.menu.name,
        inputTag: serviceForInput.inputTag,
      };

      const { arrAllNewMenuExceptCurrentRestaurant } =
        inputCardsContext.setRestaurantIdMenuInputTag(
          props.restaurantId,
          serviceForInput.serviceId,
          restaurantIdMenuInputTag
        );

      const isOtherFieldsValid =
        isNameValid &&
        isInputTagValid &&
        isCurrencyValid &&
        isOffsetValid &&
        isServiceTypeValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isGroupValid;

      if (isEdit) {
        const isNoChangeInNewEditedServiceExcludingMenu =
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.serviceType.type === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        const isNoChangeInMenu =
          serviceForInput.menu.menuId === props.service!.menuId ||
          serviceForInput.menu.name === props.service!.menu.name;
        if (isNoChangeInNewEditedServiceExcludingMenu && isNoChangeInMenu) {
          setServiceForInput({
            ...serviceForInput,
            menu: {
              menuId: props.service!.menuId,
              name: '',
            },
          });
          setIsMenuNameValid(true);
          setMenuNameErrorMessage('');
          inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          const serviceToBeCreatedToEditMap = {
            ...serviceForInput,
            name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
            inputTag:
              serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
            currency:
              serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
            menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
            offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
            serviceTypeId:
              serviceForInput.serviceType.type === ''
                ? props.service!.serviceTypeId
                : serviceForInput.serviceTypeId,
            serviceType:
              serviceForInput.serviceType.type === ''
                ? props.service!.serviceType
                : serviceForInput.serviceType,
          };
          if (serviceToBeCreatedToEditMap.menu.menuId === NEW_ID) {
            const errorMessage = checkForInvalidMenuName(
              serviceForInput.menu.name,
              companyContext.mapArrMenuByRestaurantId.get(props.restaurantId) || [],
              arrAllNewMenuExceptCurrentRestaurant,
              companyContext.arrService
            );
            if (errorMessage.length !== 0) {
              setIsMenuNameValid(false);
              setMenuNameErrorMessage(errorMessage);
              inputCardsContext.setErrorExist(props.serviceId, true, 0);
            } else {
              setIsMenuNameValid(true);
              setMenuNameErrorMessage('');
              if (isOtherFieldsValid) inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
            }
          } else {
            setIsMenuNameValid(true);
            setMenuNameErrorMessage('');
            if (isOtherFieldsValid) inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
          }
          props.updateMapForEditedOrNewService(
            { serviceId: serviceToBeCreatedToEditMap.serviceId },
            serviceToBeCreatedToEditMap
          );
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          serviceForInput
        );

        // Check if menu name is valid and unique outside of this restaurant
        if (serviceForInput.menu.name.length !== 0) {
          const errorMessage = checkForInvalidMenuName(
            serviceForInput.menu.name,
            companyContext.mapArrMenuByRestaurantId.get(props.restaurantId) || [],
            arrAllNewMenuExceptCurrentRestaurant,
            companyContext.arrService
          );
          if (errorMessage.length !== 0) {
            setIsMenuNameValid(false);
            setMenuNameErrorMessage(errorMessage);
            inputCardsContext.setErrorExist(props.serviceId, true, 0);
          } else {
            setIsMenuNameValid(true);
            setMenuNameErrorMessage('');
            if (isOtherFieldsValid) inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
          }
        } else {
          setIsMenuNameValid(false);
          setMenuNameErrorMessage(CANNOT_BE_EMPTY_ERROR_MESSAGE);
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        }
      }
    }
  };

  /**
   * Function attached to input component, takes in manual input data for editing this service's currency and updates serviceForInput state. At the same time,
   * as currency only take selection from dropdown list as valid input, any manual input will set the currency value to be invalid.
   * @param currencyObject - Object containing name and abbreviation. Note that for this function, name will always be ''.
   */
  const updateCurrencyOnChangeForManualInput = (currencyObject: {
    name: string;
    abbreviation: string;
  }) => {
    setServiceForInput({
      ...serviceForInput,
      currency: currencyObject.abbreviation,
    });
    setIsCurrencyValid(false);
    setCurrencyErrorMessage(NOT_SELECTED_FROM_DROPDOWN_LIST_ERROR_MESSAGE);

    inputCardsContext.setErrorExist(props.serviceId, true, 0);
  };

  /**
   * Function called when a currency is selected from the dropdown list. It takes in input data for updating this service's currency and updated
   * serviceForInput state. New currency is selected from the dropdown list. For editing existing service, if there is no change in any of the field, the service
   * will be removed from mapUpdatedServiceByServiceId. For adding new service, whenever a new currency is selected, it will be updated to mapUpdatedServiceByServiceId.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current name. Hence there is slight difference in the handling of input between edit and add.
   * @param currencyObject - Object containing name and abbreviation
   */
  const updateCurrencyOnChangeForDropdown = (currencyObject: {
    name: string;
    abbreviation: string;
  }) => {
    if (serviceForInput.serviceId !== undefined) {
      const newService = {
        ...serviceForInput,
        currency: currencyObject.abbreviation,
      };

      if (
        isNameValid &&
        isMenuNameValid &&
        isInputTagValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isOffsetValid &&
        isServiceTypeValid &&
        isGroupValid
      ) {
        inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
      }
      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...serviceForInput,
          currency:
            currencyObject.abbreviation === ''
              ? props.service!.currency
              : currencyObject.abbreviation,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        const isNoChangeInServiceForInput =
          serviceToBeAddedToEditMap.currency === props.service!.currency &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.serviceType.type === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (isNoChangeInServiceForInput) {
          newService.currency = '';
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
      } else {
        if (currencyObject.abbreviation === '') {
          newService.currency = DEFAULT_CURRENCY;
        }
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsCurrencyValid(true);
      setCurrencyErrorMessage('');
      setServiceForInput(newService);
    }
  };

  /**
   * Function attached to input component, takes in manual input data for editing this service's service type and updates serviceForInput state. At the same time,
   * as service type only take selection from dropdown list as valid input, any manual input will set the service type value to be invalid.
   * @param typeObject - Object containing serviceTypeId, type and name
   */
  const updateServiceTypeOnChangeForManualInput = (typeObject: ServiceTypeOption) => {
    setServiceForInput({
      ...serviceForInput,
      serviceTypeId: NEW_ID, // Set it to NEW_ID but this value is not important
      serviceType: {
        serviceTypeId: NEW_ID, // Set it to NEW_ID but this value is not important
        type: typeObject.name,
      },
    });
    setIsServiceTypeValid(false);
    setServiceTypeErrorMessage(NOT_SELECTED_FROM_DROPDOWN_LIST_ERROR_MESSAGE);

    inputCardsContext.setErrorExist(props.serviceId, true, 0);
  };

  /**
   * Function called when a service type is selected from the dropdown list. It takes in input data for updating this service's type and updated
   * serviceForInput state. New type is selected from the dropdown list. For editing existing service , if there is no change in any of the field, the service
   * will be removed from mapUpdatedServiceByServiceId. For adding new service, whenever a new type is selected, it will be updated to mapUpdatedServiceByServiceId.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current type. Hence there is slight difference in the handling of input between edit and add.
   * @param typeObject - Object containing serviceTypeId, type and name
   */
  const updateServiceTypeOnChangeForDropdown = (typeObject: ServiceTypeOption) => {
    if (serviceForInput.serviceId !== undefined) {
      const newService = {
        ...serviceForInput,
        serviceTypeId: typeObject.serviceTypeId || props.service!.serviceTypeId,
        serviceType: {
          serviceTypeId: typeObject.serviceTypeId || props.service!.serviceTypeId,
          type: typeObject.type || typeObject.name,
        },
      };

      if (
        isNameValid &&
        isMenuNameValid &&
        isInputTagValid &&
        isCurrencyValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isOffsetValid &&
        isGroupValid
      ) {
        inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
      }
      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...serviceForInput,
          serviceTypeId:
            typeObject.type === '' ? props.service!.serviceTypeId : typeObject.serviceTypeId,
          serviceType:
            typeObject.type === ''
              ? props.service!.serviceType
              : {
                  serviceTypeId: typeObject.serviceTypeId,
                  type: typeObject.type,
                },
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
        };

        const isNoChangeInServiceForInput =
          serviceToBeAddedToEditMap.serviceType.type === props.service!.serviceType!.type &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.currency === '' &&
          serviceForInput.offset === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isGroupValid;
        if (isNoChangeInServiceForInput) {
          newService.serviceType.type = '';
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
      } else {
        if (typeObject.name === '') {
          newService.serviceTypeId = DEFAULT_SERVICE_TYPE.serviceTypeId;
          newService.serviceType = DEFAULT_SERVICE_TYPE;
        }
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsServiceTypeValid(true);
      setServiceTypeErrorMessage('');
      setServiceForInput(newService);
    }
  };

  /**
   * Function attached to input component, takes in manual input data for editing this service's offset and updates serviceForInput state. At the same time,
   * as offset only take selection from dropdown list as valid input, any manual input will set the offset value to be invalid.
   * @param offsetObject - Object containing name of offset
   */
  const updateOffsetOnChangeForManualInput = (offsetObject: { name: string }) => {
    setServiceForInput({
      ...serviceForInput,
      offset: offsetObject.name,
    });
    setIsOffsetValid(false);
    if (!isEdit && offsetObject.name === '') {
      setOffsetErrorMessage(CANNOT_BE_EMPTY_ERROR_MESSAGE);
    } else {
      setOffsetErrorMessage(NOT_SELECTED_FROM_DROPDOWN_LIST_ERROR_MESSAGE);
    }

    inputCardsContext.setErrorExist(props.serviceId, true, 0);
  };

  /**
   * Function called when an offset is selected from the dropdown list. It takes in input data for updating this service's offset and
   * updated serviceForInput state. New offset is selected from the dropdown list. For editing existing service, if there is no change
   * in any of the field, the service will be removed from mapUpdatedServiceByServiceId.
   * For adding new service, whenever a new offset is selected, it will be updated to mapUpdatedServiceByServiceId.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current offset. Hence there is slight difference in the handling of input between edit and add.
   * @param offsetObject - Object containing name of offset
   */
  const updateOffsetOnChangeForDropdown = (offsetObject: { name: string }) => {
    if (serviceForInput.serviceId !== undefined) {
      const newService = {
        ...serviceForInput,
        offset: offsetObject.name,
      };

      if (
        isNameValid &&
        isMenuNameValid &&
        isInputTagValid &&
        isCurrencyValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isServiceTypeValid &&
        isGroupValid
      ) {
        inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
      }
      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...serviceForInput,
          offset: offsetObject.name === '' ? props.service!.offset : offsetObject.name,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        const isNoChangeInServiceForInput =
          serviceToBeAddedToEditMap.offset === props.service!.offset &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.currency === '' &&
          serviceForInput.serviceType.type === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (isNoChangeInServiceForInput) {
          newService.offset = '';
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsOffsetValid(true);
      setOffsetErrorMessage('');
      setServiceForInput(newService);
    }
  };

  /**
   * Function called when a baseline start date is selected from the date picker. When a date is selected from the date picker, the value registered will be
   * a date in YYYY-MM-DD format, and if the end date is still unselected, the end date will be auto-populated to the the 7th day from the selected start date.
   * If any of the date fields, i.e. day, month or month is removed, an empty string will be registered. Whenever that happens, it will be taken as no date is
   * selected, and start date will be assigned a null value.
   * Update the service whenever a change in date is detected.
   * Note:
   * 1. It is considered valid for start date to be undefined (given a null value when it is undefined)
   * 2. There will be no error for start date as it can be undefined.
   * @param event - Change event provided by the input element, provides start date in YYYY-MM-DD format
   */
  const updateStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (serviceForInput.serviceId !== undefined) {
      const newStartDate = event.target.value;
      // Auto-populate the end date to be the 7th day from the start date if a start date is selected and the end date is currently unselected.
      // This is to improve user's experience because most of the services are still having the baseline period of 7 days.
      const newService = {
        ...serviceForInput,
        startDate: newStartDate !== '' ? newStartDate : null,
        endDate:
          newStartDate && !serviceForInput.endDate
            ? moment(newStartDate).add(6, 'days').format('YYYY-MM-DD')
            : serviceForInput.endDate,
      };

      let newIsProjectStartDateValid = true;
      let newIsStartDateValid = true;
      let newIsEndDateValid = true;
      let newProjectStartDateErrorMessage = '';
      let newStartDateErrorMessage = '';
      let newEndDateErrorMessage = '';

      if (
        serviceForInput.projectStartDate &&
        newStartDate &&
        serviceForInput.projectStartDate > newStartDate
      ) {
        newIsStartDateValid = false;
        newIsProjectStartDateValid = false;
        newStartDateErrorMessage = 'Start date must be after or equal project start date!';
        newProjectStartDateErrorMessage = 'Project start date must be before or equal start date!';
      } else if (serviceForInput.projectStartDate === '') {
        newIsProjectStartDateValid = false;
        newProjectStartDateErrorMessage = CANNOT_BE_EMPTY_ERROR_MESSAGE;
      } else if (!newStartDate && newService.endDate) {
        newIsStartDateValid = false;
        newStartDateErrorMessage = 'Must be selected if end date is selected!';
      } else if (newStartDate && newStartDate < LUMITICS_COMMENCE_DATE) {
        newIsStartDateValid = false;
        newStartDateErrorMessage = 'Please select a date from 01/05/2017 onwards !';
      }

      if (newStartDate && !newService.endDate) {
        newIsEndDateValid = false;
        newEndDateErrorMessage = 'Must be selected if start date is selected!';
      } else if (newService.endDate! < LUMITICS_COMMENCE_DATE) {
        newIsEndDateValid = false;
        newEndDateErrorMessage = 'Please select a date from 01/05/2017 onwards !';
      }

      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...newService,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        const isNoChangeInServiceForInput =
          newService.startDate === props.service!.startDate &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (
          isNoChangeInServiceForInput &&
          newIsStartDateValid &&
          newIsEndDateValid &&
          newIsProjectStartDateValid
        ) {
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
        if (
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          newIsProjectStartDateValid &&
          newIsStartDateValid &&
          newIsEndDateValid &&
          isGroupValid
        ) {
          inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
        } else {
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsProjectStartDateValid(newIsProjectStartDateValid);
      setProjectStartDateErrorMessage(newProjectStartDateErrorMessage);
      setIsStartDateValid(newIsStartDateValid);
      setStartDateErrorMessage(newStartDateErrorMessage);
      setIsEndDateValid(newIsEndDateValid);
      setEndDateErrorMessage(newEndDateErrorMessage);
      setServiceForInput(newService);
    }
  };

  /**
   * Function called when a baseline end date is selected from the date picker. When a date is selected from the date picker, the value registered
   * will be a date in YYYY-MM-DD format. If any of the date fields, i.e. day, month or month is removed, an empty string will be registered. Whenever
   * that happens, it will be taken as no date is selected, and end date will be assigned a null value.
   * Update the service whenever a change in date is detected.
   * Note:
   * 1. It is considered valid for end date to be undefined (given a null value when it is undefined)
   * 2. There will be no error for end date as it can be undefined.
   * @param event - Change event provided by the input element, provides end date in YYYY-MM-DD format
   */
  const updateEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (serviceForInput.serviceId !== undefined) {
      const newEndDate = event.target.value;
      const newService = {
        ...serviceForInput,
        endDate: newEndDate !== '' ? newEndDate : null,
      };

      let newIsStartDateValid = true;
      let newIsEndDateValid = true;
      let newStartDateErrorMessage = '';
      let newEndDateErrorMessage = '';

      if (newEndDate && !serviceForInput.startDate) {
        newIsStartDateValid = false;
        newStartDateErrorMessage = 'Must be selected if end date is selected!';
      } else if (
        serviceForInput.projectStartDate &&
        serviceForInput.startDate &&
        serviceForInput.projectStartDate > serviceForInput.startDate
      ) {
        newIsStartDateValid = false;
        newStartDateErrorMessage = 'Start date must be after or equal project start date!';
      } else if (serviceForInput.startDate! < LUMITICS_COMMENCE_DATE) {
        newIsStartDateValid = false;
        newStartDateErrorMessage = 'Please select a date from 01/05/2017 onwards !';
      }

      if (!newEndDate && serviceForInput.startDate) {
        newIsEndDateValid = false;
        newEndDateErrorMessage = 'Must be selected if start date is selected!';
      } else if (newEndDate < serviceForInput.startDate! || newEndDate < LUMITICS_COMMENCE_DATE) {
        newIsEndDateValid = false;
        newEndDateErrorMessage = 'Please select a date from 01/05/2017 onwards !';
      }

      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...newService,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        const isNoChangeInServiceForInput =
          newService.endDate === props.service!.endDate &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (
          isNoChangeInServiceForInput &&
          newIsStartDateValid &&
          newIsEndDateValid &&
          isProjectStartDateValid
        ) {
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
        if (
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isProjectStartDateValid &&
          newIsStartDateValid &&
          newIsEndDateValid &&
          isGroupValid
        ) {
          inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
        } else {
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsStartDateValid(newIsStartDateValid);
      setStartDateErrorMessage(newStartDateErrorMessage);
      setIsEndDateValid(newIsEndDateValid);
      setEndDateErrorMessage(newEndDateErrorMessage);
      setServiceForInput(newService);
    }
  };

  /**
   * Function called when a project start date is selected from the date picker. When a date is selected from the date picker, the value registered
   * will be a date in YYYY-MM-DD format. If any of the date fields, i.e. day, month or month is removed, an empty string will be registered. Whenever
   * that happens, it will be taken as no date is selected, and project start date will be assigned a '' value.
   * Update the service whenever a change in date is detected.
   * @param event - Change event provided by the input element, provides end date in YYYY-MM-DD format
   */
  const updateProjectStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (serviceForInput.serviceId !== undefined) {
      const newProjectStartDate = event.target.value;
      const newService = {
        ...serviceForInput,
        projectStartDate: newProjectStartDate,
      };

      let newIsProjectStartDateValid = true;
      let newIsStartDateValid = true;
      let newProjectStartDateErrorMessage = '';
      let newStartDateErrorMessage = '';
      if (newProjectStartDate === '') {
        newIsProjectStartDateValid = false;
        newProjectStartDateErrorMessage = CANNOT_BE_EMPTY_ERROR_MESSAGE;
      } else if (
        newProjectStartDate &&
        serviceForInput.startDate &&
        newProjectStartDate > serviceForInput.startDate
      ) {
        newIsStartDateValid = false;
        newIsProjectStartDateValid = false;
        newStartDateErrorMessage = 'Start date must be after or equal project start date!';
        newProjectStartDateErrorMessage = 'Project start date must be before or equal start date!';
      }

      if (isEdit) {
        const serviceToBeAddedToEditMap = {
          ...newService,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };

        const isNoChangeInServiceForInput =
          newService.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.name === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.isValid === props.service!.isValid &&
          serviceForInput.offset === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (
          isNoChangeInServiceForInput &&
          newIsStartDateValid &&
          isEndDateValid &&
          newIsProjectStartDateValid
        ) {
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        } else {
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeAddedToEditMap
          );
        }
        if (
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          newIsStartDateValid &&
          newIsProjectStartDateValid &&
          isEndDateValid &&
          isGroupValid
        ) {
          inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
        } else {
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          newService
        );
      }
      setIsStartDateValid(newIsStartDateValid);
      setStartDateErrorMessage(newStartDateErrorMessage);
      setIsProjectStartDateValid(newIsProjectStartDateValid);
      setProjectStartDateErrorMessage(newProjectStartDateErrorMessage);
      setServiceForInput(newService);
    }
  };

  /**
   * Function attached to input component, takes in input data for editing this service's inputTag and updated serviceForInput state
   * @param event - contains input data from input html
   */
  const updateInputTagOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceForInput({
      ...serviceForInput,
      inputTag: event.target.value,
    });
  };

  /**
   * Function runs whenever onBlur event on menu name Input component occurs. Validate the input tag
   * - Check for alphanumeric
   * - Cannot duplicate with any new or existing input tag
   * If the input tag is not valid, set isInputTagValid to false.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current input tag. Hence there is slight difference in the handling of input between edit and add.
   */
  const checkInputTagValidOnBlur = () => {
    if (serviceForInput.serviceId) {
      const restaurantIdMenuInputTag = {
        restaurantId: props.restaurantId,
        menuName: serviceForInput.menu.name,
        inputTag: serviceForInput.inputTag,
      };

      const { arrAllNewInputTagAndServiceIdExceptCurrentService } =
        inputCardsContext.setRestaurantIdMenuInputTag(
          props.restaurantId,
          serviceForInput.serviceId,
          restaurantIdMenuInputTag
        );
      if (isEdit) {
        const serviceToBeCreatedToEditMap = {
          ...serviceForInput,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };
        if (serviceToBeCreatedToEditMap.inputTag === props.service!.inputTag) {
          const isServiceForInputValid =
            serviceForInput.name === '' &&
            serviceForInput.menu.name === '' &&
            serviceForInput.currency === '' &&
            serviceForInput.projectStartDate === props.service!.projectStartDate &&
            serviceForInput.startDate === props.service!.startDate &&
            serviceForInput.endDate === props.service!.endDate &&
            serviceForInput.isValid === props.service!.isValid &&
            serviceForInput.offset === '' &&
            serviceForInput.serviceType.type === '' &&
            isNameValid &&
            isMenuNameValid &&
            isCurrencyValid &&
            isProjectStartDateValid &&
            isStartDateValid &&
            isEndDateValid &&
            isOffsetValid &&
            isServiceTypeValid &&
            isGroupValid;
          if (isServiceForInputValid) {
            inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
            props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
          } else {
            props.updateMapForEditedOrNewService(
              { serviceId: serviceForInput.serviceId },
              serviceToBeCreatedToEditMap
            );
          }
          // This setting of serviceForInput is because the user might type in the same exact service input tag as the original,
          // and therefore the serviceForInput's input tag should be reset to '' to indicate that there is no change.
          const newServiceForInput = { ...serviceForInput, inputTag: '' };
          setServiceForInput(newServiceForInput);
          setIsInputTagValid(true);
        } else {
          const errorMessage = checkForInvalidInputTag(
            serviceToBeCreatedToEditMap.inputTag,
            serviceForInput.serviceId,
            companyContext.arrService,
            arrAllNewInputTagAndServiceIdExceptCurrentService
          );

          if (errorMessage.length !== 0) {
            setIsInputTagValid(false);
            setInputTagErrorMessage(errorMessage);
            inputCardsContext.setErrorExist(serviceForInput.serviceId, true, 0);
            props.updateMapForEditedOrNewService(
              { serviceId: serviceForInput.serviceId },
              serviceToBeCreatedToEditMap
            );
          } else {
            setIsInputTagValid(true);
            if (
              isNameValid &&
              isMenuNameValid &&
              isCurrencyValid &&
              isProjectStartDateValid &&
              isStartDateValid &&
              isEndDateValid &&
              isOffsetValid &&
              isServiceTypeValid &&
              isGroupValid
            ) {
              inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
              if (serviceForInput.isValid !== props.service!.isValid) {
                props.updateMapForEditedOrNewService({
                  serviceId: serviceForInput.serviceId,
                });
              } else {
                props.updateMapForEditedOrNewService(
                  { serviceId: serviceForInput.serviceId },
                  serviceToBeCreatedToEditMap
                );
              }
            } else {
              props.updateMapForEditedOrNewService(
                { serviceId: serviceForInput.serviceId },
                serviceToBeCreatedToEditMap
              );
            }
          }
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          serviceForInput
        );
        // Check if input tag is valid and unique globally
        if (serviceForInput.inputTag.length !== 0) {
          const errorMessage = checkForInvalidInputTag(
            serviceForInput.inputTag,
            serviceForInput.serviceId,
            companyContext.arrService,
            arrAllNewInputTagAndServiceIdExceptCurrentService
          );
          if (errorMessage.length !== 0) {
            setIsInputTagValid(false);
            setInputTagErrorMessage(errorMessage);
          } else {
            setIsInputTagValid(true);
          }
        } else {
          setIsInputTagValid(false);
          setInputTagErrorMessage(CANNOT_BE_EMPTY_ERROR_MESSAGE);
        }
      }
    }
  };

  /**
   * Function attached to input component, takes in input data for editing this service's isValid and updated serviceForInput state
   */
  const updateIsValidOnChange = () => {
    if (isEdit) {
      const newIsValid = !serviceForInput.isValid;
      if (newIsValid !== props.service!.isValid) {
        const serviceToBeCreatedToEditMap = {
          ...serviceForInput,
          isValid: newIsValid,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };
        props.updateMapForEditedOrNewService(
          { serviceId: serviceForInput.serviceId },
          serviceToBeCreatedToEditMap
        );
      } else {
        const isNoChangeInServiceForInput =
          serviceForInput.name === '' &&
          serviceForInput.menu.name === '' &&
          serviceForInput.currency === '' &&
          serviceForInput.inputTag === '' &&
          serviceForInput.projectStartDate === props.service!.projectStartDate &&
          serviceForInput.startDate === props.service!.startDate &&
          serviceForInput.endDate === props.service!.endDate &&
          serviceForInput.offset === '' &&
          serviceForInput.serviceType.type === '' &&
          serviceForInput.group === props.service!.group &&
          isNameValid &&
          isMenuNameValid &&
          isInputTagValid &&
          isCurrencyValid &&
          isProjectStartDateValid &&
          isStartDateValid &&
          isEndDateValid &&
          isOffsetValid &&
          isServiceTypeValid &&
          isGroupValid;
        if (isNoChangeInServiceForInput) {
          props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
        }
      }
      setServiceForInput({
        ...serviceForInput,
        isValid: newIsValid,
      });
    } else {
      const serviceWithNewIsValid = {
        ...serviceForInput,
        isValid: !serviceForInput.isValid,
      };
      setServiceForInput(serviceWithNewIsValid);
      props.updateMapForEditedOrNewService(
        { serviceId: props.serviceId, locationId: props.locationId },
        serviceWithNewIsValid
      );
    }
  };

  /**
   * Function attached to input component, takes in input data for editing this services's group and updates serviceForInput state
   * @param event - contains input data from input html
   */
  const updateGroupOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceForInput({
      ...serviceForInput,
      group: event.target.value,
    });
  };

  /**
   * Function runs whenever onBlur event on service group Input component occurs. Validate the service group
   * - Check for alphanumeric
   * If the service group is not valid, set isGroupValid to false.
   * Note: If it is editing an existing service, empty string ('') is considered a valid input as it will be treated as no change to the
   * current group. Hence there is slight difference in the handling of input between edit and add.
   */
  const checkGroupValidOnBlur = () => {
    if (serviceForInput.serviceId !== undefined) {
      if (serviceForInput.group === '') {
        serviceForInput.group = null;
      }
      if (isEdit) {
        const serviceToBeCreatedToEditMap = {
          ...serviceForInput,
          name: serviceForInput.name === '' ? props.service!.name : serviceForInput.name,
          inputTag:
            serviceForInput.inputTag === '' ? props.service!.inputTag : serviceForInput.inputTag,
          currency:
            serviceForInput.currency === '' ? props.service!.currency : serviceForInput.currency,
          menu: serviceForInput.menu.name === '' ? props.service!.menu : serviceForInput.menu,
          menuId: serviceForInput.menu.name === '' ? props.service!.menuId : serviceForInput.menuId,
          offset: serviceForInput.offset === '' ? props.service!.offset : serviceForInput.offset,
          serviceTypeId:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceTypeId
              : serviceForInput.serviceTypeId,
          serviceType:
            serviceForInput.serviceType.type === ''
              ? props.service!.serviceType
              : serviceForInput.serviceType,
        };
        if (serviceToBeCreatedToEditMap.group === props.service!.group) {
          const isServiceForInputValid =
            serviceForInput.name === '' &&
            serviceForInput.menu.name === '' &&
            serviceForInput.inputTag === '' &&
            serviceForInput.currency === '' &&
            serviceForInput.projectStartDate === props.service!.projectStartDate &&
            serviceForInput.startDate === props.service!.startDate &&
            serviceForInput.endDate === props.service!.endDate &&
            serviceForInput.isValid === props.service!.isValid &&
            serviceForInput.offset === '' &&
            serviceForInput.serviceType.type === '' &&
            isMenuNameValid &&
            isInputTagValid &&
            isCurrencyValid &&
            isStartDateValid &&
            isEndDateValid &&
            isOffsetValid &&
            isServiceTypeValid;
          if (isServiceForInputValid) {
            inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
            props.updateMapForEditedOrNewService({ serviceId: serviceForInput.serviceId });
          } else {
            props.updateMapForEditedOrNewService(
              { serviceId: serviceForInput.serviceId },
              serviceToBeCreatedToEditMap
            );
          }
          setServiceForInput(serviceForInput);
          setIsGroupValid(true);
        } else {
          let errorMessage = '';
          if (serviceForInput.group !== null && !checkIsNameValidWithRegEx(serviceForInput.group)) {
            errorMessage = 'Group does not pass Alphanumeric checks!';
          }
          if (errorMessage.length !== 0) {
            setIsGroupValid(false);
            setGroupErrorMessage(errorMessage);
            inputCardsContext.setErrorExist(serviceForInput.serviceId, true, 0);
          } else {
            setIsGroupValid(true);
            if (
              isNameValid &&
              isMenuNameValid &&
              isInputTagValid &&
              isCurrencyValid &&
              isOffsetValid &&
              isServiceTypeValid &&
              isProjectStartDateValid &&
              isStartDateValid &&
              isEndDateValid
            ) {
              inputCardsContext.deleteErrorForOneId(serviceForInput.serviceId, 0);
            }
          }
          props.updateMapForEditedOrNewService(
            { serviceId: serviceForInput.serviceId },
            serviceToBeCreatedToEditMap
          );
        }
      } else {
        props.updateMapForEditedOrNewService(
          { serviceId: props.serviceId, locationId: props.locationId },
          serviceForInput
        );
        let errorMessage = '';
        if (serviceForInput.group !== null && !checkIsNameValidWithRegEx(serviceForInput.group)) {
          errorMessage = 'Group does not pass Alphanumeric checks!';
        }
        if (errorMessage.length !== 0) {
          setIsGroupValid(false);
          setGroupErrorMessage(errorMessage);
          inputCardsContext.setErrorExist(props.serviceId, true, 0);
        } else {
          setIsGroupValid(true);
          inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
        }
      }
    }
  };

  useEffect(() => {
    if (!isEdit) {
      if (
        isNameValid &&
        isMenuNameValid &&
        isInputTagValid &&
        isCurrencyValid &&
        isProjectStartDateValid &&
        isStartDateValid &&
        isEndDateValid &&
        isOffsetValid &&
        isServiceTypeValid &&
        isGroupValid
      ) {
        inputCardsContext.deleteErrorForOneId(props.serviceId, 0);
      } else {
        inputCardsContext.setErrorExist(props.serviceId, true, 0);
      }
    }
  }, [
    isNameValid,
    isMenuNameValid,
    isInputTagValid,
    isCurrencyValid,
    isOffsetValid,
    isProjectStartDateValid,
    isStartDateValid,
    isEndDateValid,
    isServiceTypeValid,
    isGroupValid,
  ]);

  return (
    <li className="ServiceInputCard px-4 py-1">
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-14 gap-x-4 gap-y-8 mt-3 justify-center">
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-3 mr-3 px-1">
          <Input
            onBlur={checkServiceNameValidOnBlur}
            size="md"
            onChange={updateServiceNameOnChange}
            label="Service"
            variant="static"
            value={serviceForInput.name}
            placeholder={props.service !== undefined ? props.service.name : ''}
            className={`ServiceNameInput text-off-black`}
            error={!isNameValid}
          />
          {!isNameValid && (
            <p className="ServiceNameErrorMessage text-red-500 text-xs mt-2">{nameErrorMessage}</p>
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-3 mr-3 px-1">
          <div className="flex flex-col gap-x-1 flex-1" onBlur={menuNameInputOnBlur}>
            <InputWithSearchFunction
              currentValue={serviceForInput.menu.name}
              originalValue={props.service?.menu.name || ''}
              updateDropdownInput={updateMenuNameOnChangeForDropdown}
              updateManualInput={updateMenuNameOnChangeForManualInput}
              arrAllOption={companyContext.mapArrMenuByRestaurantId.get(props.restaurantId) || []}
              isEdit={isEdit}
              inputType={INPUT_TYPE.menu}
              errorMessage={menuNameErrorMessage}
            />
          </div>
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 col-span-3 mr-3 px-1">
          <Input
            onBlur={checkInputTagValidOnBlur}
            size="md"
            onChange={updateInputTagOnChange}
            label="Input Tag"
            variant="static"
            value={serviceForInput.inputTag}
            placeholder={props.service !== undefined ? props.service.inputTag : ''}
            className={`ServiceInputTagInput text-off-black`}
            error={!isInputTagValid}
          />
          {!isInputTagValid && (
            <p className="InputTagErrorMessage text-red-500 text-xs mt-2">{inputTagErrorMessage}</p>
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-3 mr-3 px-1">
          <InputWithSearchFunction
            currentValue={
              !isEdit && serviceForInput.currency === DEFAULT_CURRENCY
                ? ''
                : serviceForInput.currency
            }
            originalValue={
              props.service !== undefined ? props.service!.currency : serviceForInput.currency
            }
            updateDropdownInput={updateCurrencyOnChangeForDropdown}
            updateManualInput={updateCurrencyOnChangeForManualInput}
            arrAllOption={ARR_CURRENCY_OBJECT}
            isEdit={isEdit}
            inputType={INPUT_TYPE.currency}
            errorMessage={currencyErrorMessage}
          />
        </div>
        <div className="ServiceValidInput col-span-2 scale-90 px-1">
          {isEdit ? (
            <Switch
              color="green"
              label="isValid"
              id={`${serviceForInput.serviceId}`}
              className="text-off-black font-normal"
              checked={serviceForInput.isValid}
              onChange={updateIsValidOnChange}
            />
          ) : (
            <Switch
              color="green"
              label="isValid"
              id={`${serviceForInput.serviceId}`}
              className="text-off-black font-normal"
              defaultChecked
              onChange={updateIsValidOnChange}
            />
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-2 relative mr-3 px-1">
          <input
            type="date"
            className={`ServiceProjectStartDateInput peer block w-full bg-gray-200 outline-0 border-0 border-b pt-4 pb-2 border-blue-gray-200 font-sans font-normal text-sm transition:all focus:ring-0 focus:border-blue-500 focus:border-b-2 focus:outline-0 cursor-pointer ${
              (props.service &&
                props.service.projectStartDate === serviceForInput.projectStartDate) ||
              (!isEdit && serviceForInput.projectStartDate === '')
                ? 'text-gray-500'
                : 'text-blue-gray-700'
            }`}
            // Default min value is set if it is for create service as it does not make sense to create a service with a back dated project start date
            min={props.service !== undefined ? LUMITICS_COMMENCE_DATE : todayDate}
            value={serviceForInput.projectStartDate}
            onChange={updateProjectStartDate}
            title=""
          />
          <label className="select-none pointer-events-none absolute font-normal text-sm -top-3 text-blue-gray-500 peer-focus:text-blue-500">
            Project Start Date
          </label>
          {!isProjectStartDateValid && (
            <p className="ProjectStartDateErrorMessage text-red-500 text-xs mt-2">
              {projectStartDateErrorMessage}
            </p>
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-2 relative mr-3 px-1">
          <input
            type="date"
            className={`ServiceStartDateInput peer block w-full bg-gray-200 outline-0 border-0 border-b pt-4 pb-2 border-blue-gray-200 font-sans font-normal text-sm transition:all focus:ring-0 focus:border-blue-500 focus:border-b-2 focus:outline-0 cursor-pointer ${
              (props.service && props.service.startDate === serviceForInput.startDate) ||
              (!isEdit && serviceForInput.startDate === null)
                ? 'text-gray-500'
                : 'text-blue-gray-700'
            }`}
            // Default min value is set if it is for create service as it does not make sense to create a service with a back dated start date
            min={props.service !== undefined ? LUMITICS_COMMENCE_DATE : todayDate}
            max={
              serviceForInput.endDate !== null && serviceForInput.endDate >= LUMITICS_COMMENCE_DATE
                ? serviceForInput.endDate
                : undefined
            }
            value={serviceForInput.startDate !== null ? serviceForInput.startDate : ''}
            onChange={updateStartDate}
            title=""
          />
          <label className="select-none pointer-events-none absolute font-normal text-sm -top-3 text-blue-gray-500 peer-focus:text-blue-500">
            Baseline Start Date
          </label>
          {!isStartDateValid && (
            <p className="StartDateErrorMessage text-red-500 text-xs mt-2">
              {startDateErrorMessage}
            </p>
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-2 relative mr-3 px-1">
          <input
            type="date"
            className={`ServiceEndDateInput peer block w-full bg-gray-200 outline-0 border-0 border-b pt-4 pb-2 border-blue-gray-200 font-sans font-normal text-sm transition:all focus:ring-0 focus:border-blue-500 focus:border-b-2 focus:outline-0 cursor-pointer ${
              (props.service && props.service.endDate === serviceForInput.endDate) ||
              (!isEdit && serviceForInput.endDate === null)
                ? 'text-gray-500'
                : 'text-blue-gray-700'
            }`}
            // Default min value is set if it is for create service as it does not make sense to create a service with a back dated end date
            min={getMinimumValidBaselineEndDate(todayDate, serviceForInput.startDate, isEdit)}
            value={serviceForInput.endDate !== null ? serviceForInput.endDate : ''}
            onChange={updateEndDate}
            disabled={!serviceForInput.startDate}
            title=""
          />
          <label className="select-none absolute font-normal text-sm -top-3 text-blue-gray-500 peer-focus:text-blue-500">
            <div className="flex flex-row gap-1.5">
              Baseline End Date
              <BsFillInfoCircleFill
                className="group h-4 w-4 self-start text-blue-gray-900"
                title="**Note: Start date has to be selected before end date can be selected"
              />
            </div>
          </label>
          {!isEndDateValid && (
            <p className="EndDateErrorMessage text-red-500 text-xs mt-2">{endDateErrorMessage}</p>
          )}
        </div>
        <div className="flex flex-col shrink gap-x-1 md:col-span-2 lg:col-span-2 mr-3 px-1">
          <InputWithSearchFunction
            currentValue={serviceForInput.offset}
            originalValue={
              props.service !== undefined ? props.service!.offset : serviceForInput.offset
            }
            updateDropdownInput={updateOffsetOnChangeForDropdown}
            updateManualInput={updateOffsetOnChangeForManualInput}
            arrAllOption={ARR_OFFSET_OBJECT}
            isEdit={isEdit}
            inputType={INPUT_TYPE.offset}
            errorMessage={offsetErrorMessage}
          />
        </div>
        <div className="flex flex-col shrink gap-x-1 col-span-2 lg:col-span-3 mr-3 px-1">
          <InputWithSearchFunction
            currentValue={
              !isEdit && serviceForInput.serviceTypeId === DEFAULT_SERVICE_TYPE.serviceTypeId
                ? ''
                : serviceForInput.serviceType!.type
            }
            originalValue={
              props.service !== undefined
                ? props.service!.serviceType!.type
                : serviceForInput.serviceType!.type
            }
            updateDropdownInput={updateServiceTypeOnChangeForDropdown}
            updateManualInput={updateServiceTypeOnChangeForManualInput}
            arrAllOption={companyContext.arrServiceTypeOption}
            isEdit={isEdit}
            inputType={INPUT_TYPE.type}
            errorMessage={serviceTypeErrorMessage}
          />
        </div>
        <div className="flex flex-col shrink gap-x-1 col-span-4 lg:col-span-3 px-1">
          <Input
            onBlur={checkGroupValidOnBlur}
            size="md"
            onChange={updateGroupOnChange}
            label="Group"
            variant="static"
            value={serviceForInput.group !== null ? serviceForInput.group : ''}
            className={`ServiceGroupInput text-off-black`}
            error={!isGroupValid}
          />
          {!isGroupValid && (
            <p className="ServiceNameErrorMessage text-red-500 text-xs mt-2">{groupErrorMessage}</p>
          )}
        </div>
      </div>
      <hr className="border h-2 mt-6 bg-gray-400 rounded-xl lg:hidden" />
    </li>
  );
};

export default ServiceInputCard;
