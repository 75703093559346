import { createContext, useRef, useState } from 'react';

import { RestaurantIdMenuInputTag, SetRestaurantIdMenuInputTagResponse } from '../interfaces';

const InputCardsContext = createContext({
  userCardInputModeIndex: 0 as number,
  userCardInputId: 0 as number,
  updateUserCardInputModeAndId: (
    updatedUserCardInputModeIndex: number,
    updatedUserCardInputId: number
  ) => {},
  setRestaurantIdMenuInputTag: (
    restaurantId: number,
    serviceId: number,
    newRestaurantIdMenuInputTag: RestaurantIdMenuInputTag
  ): any => {},
  removeRestaurantIdMenuInputTag: (idToBeRemoved?: number, rangeValue?: number) => {},
  setErrorExist: (id: number, isErrorExist: boolean, caseNumber: number) => {},
  deleteAllError: () => {},
  deleteErrorForOneId: (id: number, caseNumber: number) => {},
  deleteErrorForAllIdsWithinRange: (id: number, range: number) => {},
  getErrorExist: (): any => {},
  input: {
    inputModeIndex: 0,
    inputId: '',
    companyId: 0,
  },
  setInputMode: (inputModeIndex: number, inputId: string, companyId: number) => {},
  getInputMode: (inputModeIndex: number, inputId: string): any => {},
  checkIfActiveInputMode: (inputModeIndex: number, inputId: string): any => {},
});

export const InputCardsContextProvider = (props: any) => {
  let mapServiceErrorById = useRef(new Map());
  let mapLocationErrorById = useRef(new Map());
  let mapRestaurantErrorById = useRef(new Map());
  let mapCompanyErrorById = useRef(new Map());
  const [input, setInput] = useState({
    inputModeIndex: 0,
    inputId: '',
    companyId: 0,
  });
  // Map that store new restaurantId menu input tag objects for comparison to check for duplicates
  const [mapRestaurantIdMenuInputTagByServiceId, setMapRestaurantIdMenuInputTagByServiceId] =
    useState(new Map());

  const [userCardInputModeIndex, setUserCardInputModeIndex] = useState<number>(0);
  const [userCardInputId, setUserCardInputId] = useState<number>(0);

  /**
   * Function to set the input mode index and the input id of the user list.
   * 1. Input mode index - refers to the mode selected:
   *    0: No mode selected
   *    1: Edit mode selected
   *    2: Add mode selected
   * 2. Input id - is basically the companyId of the user. This is used for the disabling of buttons of
   * other user lists when one of them is selected.
   * @param updatedUserCardInputModeIndex - Input mode of the user card
   * @param updatedUserCardInputId - Id of the company in which the user list's mode has been selected
   */
  const updateUserCardInputModeAndId = (
    updatedUserCardInputModeIndex: number,
    updatedUserCardInputId: number
  ): void => {
    setUserCardInputModeIndex(updatedUserCardInputModeIndex);
    setUserCardInputId(updatedUserCardInputId);
  };

  /**
   * Function to set the new/edited restaurantId menu input tag to a service in the map. There will be two arrays returned:
   * 1. An array of unique menu names, excluding the ones from the restaurant that the service belongs to
   * 2. An array of objects, each containg an unique input tag and its serviceId, excluding the one that belongs to the service
   * Note: The ids will be in the following format: the first digit will be the companyId, the second digit
   * will be the restaurantId, the third digit will be the locationId and the fourth digit will be the serviceId.
   * @param restaurantId - Id of restaurant that the service to be set with new/edited menu input tag
   * @param serviceId - Id of service to be set with the new/edited menu input tag
   * @param newRestaurantIdMenuInputTag - New/edited restaurantId menu input tag object to be set
   * @returns Object
   * @returns Object.arrAllNewMenuExceptCurrentRestaurant - Array of menu name except menu names of the restaurant that the service belongs to
   * @returns Object.arrAllNewInputTagAndServiceIdExceptCurrentService - Array of object containing input tag and its serviceId except that of the service
   */
  const setRestaurantIdMenuInputTag = (
    restaurantId: number,
    serviceId: number,
    newRestaurantIdMenuInputTag: RestaurantIdMenuInputTag
  ): SetRestaurantIdMenuInputTagResponse => {
    let newMapRestaurantIdMenuInputTagByServiceId = new Map(mapRestaurantIdMenuInputTagByServiceId);
    newMapRestaurantIdMenuInputTagByServiceId.set(serviceId, newRestaurantIdMenuInputTag);

    let allNewMenuExceptCurrentRestaurantSet = new Set<string>();
    let arrAllNewInputTagAndServiceIdExceptCurrentService: Array<{
      serviceId: number;
      inputTag: string;
    }> = [];
    let arrAllNewMenuExceptCurrentRestaurant: Array<any> = [];

    newMapRestaurantIdMenuInputTagByServiceId.forEach(
      (restaurantIdMenuInputTag: RestaurantIdMenuInputTag, currentServiceId: number) => {
        if (restaurantIdMenuInputTag.restaurantId !== restaurantId) {
          allNewMenuExceptCurrentRestaurantSet.add(restaurantIdMenuInputTag.menuName);
        }
        if (currentServiceId !== serviceId) {
          arrAllNewInputTagAndServiceIdExceptCurrentService.push({
            inputTag: restaurantIdMenuInputTag.inputTag,
            serviceId: currentServiceId,
          });
        }
      }
    );
    arrAllNewMenuExceptCurrentRestaurant = Array.from(allNewMenuExceptCurrentRestaurantSet);

    setMapRestaurantIdMenuInputTagByServiceId(newMapRestaurantIdMenuInputTagByServiceId);

    return {
      arrAllNewMenuExceptCurrentRestaurant,
      arrAllNewInputTagAndServiceIdExceptCurrentService,
    };
  };

  /**
   * If idToBeRemoved and rangeValue are both present, remove the key-value pair in mapRestaurantIdMenuInputTagByServiceId if the key lies between the lower limit of the range (derived) and the upper
   * limit of the range (idToBeRemoved).Else, remove all elements in mapRestaurantIdMenuInputTagByServiceId.
   * Note: Both the idToBeRemoved and the rangeValue are negative numbers
   * @param idToBeRemoved - Id of company/restaurant/location/service where the key is to be removed from the map
   * @param rangeValue - A number to be added to the idToBeRemoved where it provides the lower limit of the range of key-values to be removed from the map.
   *                     It will be -9 if the idToBeRemoved is a locationId, -99 if idToBeRemoved is a restaurantId, and -999 if idToBeRemoved is a companyId.
   */
  const removeRestaurantIdMenuInputTag = (idToBeRemoved?: number, rangeValue?: number) => {
    if (idToBeRemoved && rangeValue) {
      const lowerLimitOfRange = idToBeRemoved + rangeValue;
      let newMapRestaurantIdMenuInputTagByServiceId = new Map(
        mapRestaurantIdMenuInputTagByServiceId
      );
      newMapRestaurantIdMenuInputTagByServiceId.forEach(
        (restaurantIdMenuInputTag: RestaurantIdMenuInputTag, serviceId: number) => {
          if (serviceId >= lowerLimitOfRange && serviceId <= idToBeRemoved) {
            newMapRestaurantIdMenuInputTagByServiceId.delete(serviceId);
          }
        }
      );
      setMapRestaurantIdMenuInputTagByServiceId(newMapRestaurantIdMenuInputTagByServiceId);
    } else {
      setMapRestaurantIdMenuInputTagByServiceId(new Map());
    }
  };

  // const resetError = () => {};

  const setErrorExist = (id: number, isErrorExist: boolean, caseNumber: number) => {
    switch (caseNumber) {
      case 3: {
        let newMapCompanyErrorById = new Map(mapCompanyErrorById.current);
        newMapCompanyErrorById.set(id, isErrorExist);
        mapCompanyErrorById.current = newMapCompanyErrorById;
        break;
      }

      case 2: {
        let newMapRestaurantErrorById = new Map(mapRestaurantErrorById.current);
        newMapRestaurantErrorById.set(id, isErrorExist);
        mapRestaurantErrorById.current = newMapRestaurantErrorById;
        break;
      }

      case 1: {
        let newMapLocationErrorById = new Map(mapLocationErrorById.current);
        newMapLocationErrorById.set(id, isErrorExist);
        mapLocationErrorById.current = newMapLocationErrorById;
        break;
      }

      case 0: {
        let newMapServiceErrorById = new Map(mapServiceErrorById.current);
        newMapServiceErrorById.set(id, isErrorExist);
        mapServiceErrorById.current = newMapServiceErrorById;
        break;
      }

      default: {
        break;
      }
    }
  };

  /**
   * Function resets maps to original state for when user exits any input mode (add or edit)
   */
  const deleteAllError = () => {
    mapServiceErrorById.current = new Map();
    mapLocationErrorById.current = new Map();
    mapRestaurantErrorById.current = new Map();
    mapCompanyErrorById.current = new Map();
  };

  /**
   * Function stores isErrorExist boolean in map for checks later on to see if there are any errors
   * in the edit component tree
   * @param idToRemove - id of object error boolean to remove from map
   * @param objectType - Object type to indicate which map to remove isErrorExist from according to idToRemove
   */
  const deleteErrorForOneId = (idToRemove: number, caseNumber: number) => {
    switch (caseNumber) {
      case 3: {
        let newMapCompanyErrorById = new Map(mapCompanyErrorById.current);
        newMapCompanyErrorById.delete(idToRemove);
        mapCompanyErrorById.current = newMapCompanyErrorById;
        break;
      }

      case 2: {
        let newMapRestaurantErrorById = new Map(mapRestaurantErrorById.current);
        newMapRestaurantErrorById.delete(idToRemove);
        mapRestaurantErrorById.current = newMapRestaurantErrorById;
        break;
      }

      case 1: {
        let newMapLocationErrorById = new Map(mapLocationErrorById.current);
        newMapLocationErrorById.delete(idToRemove);
        mapLocationErrorById.current = newMapLocationErrorById;
        break;
      }

      case 0: {
        let newMapServiceErrorById = new Map(mapServiceErrorById.current);
        newMapServiceErrorById.delete(idToRemove);
        mapServiceErrorById.current = newMapServiceErrorById;
        break;
      }

      default: {
        break;
      }
    }
  };

  const deleteErrorForAllIdsWithinRange = (idToRemove: number, range: number) => {
    const lowerLimitOfRange = idToRemove + range;
    let newMapCompanyErrorById = new Map(mapCompanyErrorById.current);
    let newMapRestaurantErrorById = new Map(mapRestaurantErrorById.current);
    let newMapLocationErrorById = new Map(mapLocationErrorById.current);
    let newMapServiceErrorById = new Map(mapServiceErrorById.current);

    newMapCompanyErrorById.forEach((isErrorExist: boolean, id: number) => {
      if (id >= lowerLimitOfRange && id <= idToRemove) {
        newMapCompanyErrorById.delete(id);
      }
    });
    newMapRestaurantErrorById.forEach((isErrorExist: boolean, id: number) => {
      if (id >= lowerLimitOfRange && id <= idToRemove) {
        newMapRestaurantErrorById.delete(id);
      }
    });
    newMapLocationErrorById.forEach((isErrorExist: boolean, id: number) => {
      if (id >= lowerLimitOfRange && id <= idToRemove) {
        newMapLocationErrorById.delete(id);
      }
    });
    newMapServiceErrorById.forEach((isErrorExist: boolean, id: number) => {
      if (id >= lowerLimitOfRange && id <= idToRemove) {
        newMapServiceErrorById.delete(id);
      }
    });
    mapCompanyErrorById.current = newMapCompanyErrorById;
    mapRestaurantErrorById.current = newMapRestaurantErrorById;
    mapLocationErrorById.current = newMapLocationErrorById;
    mapServiceErrorById.current = newMapServiceErrorById;
  };

  const getErrorExist = () => {
    const arrCompanyError = Array.from(mapCompanyErrorById.current.values());
    const arrRestaurantError = Array.from(mapRestaurantErrorById.current.values());
    const arrLocationError = Array.from(mapLocationErrorById.current.values());
    const arrServiceError = Array.from(mapServiceErrorById.current.values());
    if (
      arrCompanyError.length === 0 &&
      arrRestaurantError.length === 0 &&
      arrLocationError.length === 0 &&
      arrServiceError.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Update the input mode whenever an edit action or add action is reqested by user.
   * Please refer to ../constant.General.tsx for more information on the different types of input modes.
   * @param inputMode - Sets the current input mode of management console
   * @param inputId - Id of component setting the input (To differentiate components of the same type)
   * @param companyId - Id of company that component belongs to
   */
  const setInputMode = (inputModeIndex: number, inputId: string, companyId: number) => {
    const newInput = {
      inputModeIndex: inputModeIndex,
      inputId: inputId,
      companyId: companyId,
    };
    setInput(newInput);
  };

  /**
   * Returns a boolean value to determine if a component should be enabled or disabled by checking
   * component's inputModeIndex and inputId against the currently stored inputModeIndex and InputId.
   * If currently stored inputId is not an empty string, it signifies that either a update or create input
   * is currently opened, and will return false if the id does not match the currently stored id of
   * component that is currently opened
   * @param inputModeIndex - Sets the current input mode of management console
   * @param inputId - Id of component setting the input (To differentiate components of the same type)
   * @returns boolean - Determines component should be enabled or disabled
   */
  const getInputMode = (inputModeIndex: number, inputId: string) => {
    if (input.inputId === '') {
      return true;
    } else if (input.inputId === inputId) {
      return inputModeIndex === input.inputModeIndex;
    } else {
      return false;
    }
  };

  /**
   * Function checks if there is a create or update input component currently active.
   * Used for a variety of components that needs to determine if it should be rendered or
   * not according to the old isAdd and isEdit booleans.
   * @param inputModeIndex - Sets the current input mode of management console
   * @param inputId - Id of component setting the input (To differentiate components of the same type)
   * @returns boolean - Returns a boolean that determines if a component in the component tree has a create or update component input active
   */
  const checkIfActiveInputMode = (inputModeIndex: number, inputId: string) => {
    return inputModeIndex === input.inputModeIndex && inputId === input.inputId;
  };

  return (
    <InputCardsContext.Provider
      value={{
        userCardInputModeIndex: userCardInputModeIndex,
        userCardInputId: userCardInputId,
        updateUserCardInputModeAndId: updateUserCardInputModeAndId,
        setRestaurantIdMenuInputTag: setRestaurantIdMenuInputTag,
        removeRestaurantIdMenuInputTag: removeRestaurantIdMenuInputTag,
        setErrorExist: setErrorExist,
        deleteAllError: deleteAllError,
        deleteErrorForOneId: deleteErrorForOneId,
        deleteErrorForAllIdsWithinRange: deleteErrorForAllIdsWithinRange,
        getErrorExist: getErrorExist,
        input: input,
        setInputMode: setInputMode,
        getInputMode: getInputMode,
        checkIfActiveInputMode: checkIfActiveInputMode,
      }}
    >
      {props.children}
    </InputCardsContext.Provider>
  );
};

export default InputCardsContext;
