import { useEffect, useState } from 'react';

import UserRestaurantLocationCheckbox from './UserRestaurantLocationCheckbox';
import { RestaurantLocation, RestaurantService } from '../../interfaces';

const UserRestaurantLocationCheckboxList = (props: {
  arrAllRestaurantService: Array<RestaurantService>;
  arrExistingRestaurantLocation: Array<RestaurantLocation> | [];
  updateArrRestaurantLocationOnCheck: any;
  userId: number;
}) => {
  const [
    mapSelectedRestaurantLocationByRestaurantId,
    setMapSelectedRestaurantLocationByRestaurantId,
  ] = useState(new Map<number, object>());

  /**
   * This function initialises the original array of restaurantLocation(s) into the map so as to capture the original restaurantLocation(s) will
   * will be in the array if new location(s) are added.
   */
  const initialiseOriginalArrRestaurantLocationToMap = () => {
    const newMapSelectedRestaurantLocationByRestaurantId = new Map(
      props.arrExistingRestaurantLocation.map((restaurantLocation) => {
        return [restaurantLocation.restaurantId, restaurantLocation];
      })
    );
    const arrNewRestaurantLocation = Array.from(
      newMapSelectedRestaurantLocationByRestaurantId.values()
    );
    props.updateArrRestaurantLocationOnCheck(arrNewRestaurantLocation);
    setMapSelectedRestaurantLocationByRestaurantId(newMapSelectedRestaurantLocationByRestaurantId);
  };

  /**
   * This function is passed down to UserRestaurantLocationCheckbox as a callback function for said component to
   * update if the checked/unchecked restaurantLocation object. Stores checked data in a map with restaurantIDs
   * as its key values. If none of the location(s) of the restaurant is checked, the restaurantLocation should be
   * removed.
   * @param newSelectedRestaurantLocation - Object containing all checked locations, location data and restaurant data
   * @param restaurantId - Number to identify what restaurant is to be updated in mapRestaurantLocationCheckedByRestaurantId state
   * @param isAnyLocationChecked - Boolean value used to identify if restaurant is to be updated or removed from mapRestaurantLocationCheckedByRestaurantId
   */
  const updateMapSelectedRestaurantLocationByRestaurantId = (
    newSelectedRestaurantLocation: RestaurantLocation,
    restaurantId: number,
    isAnyLocationChecked: boolean
  ) => {
    const newMapSelectedRestaurantLocationByRestaurantId = new Map(
      mapSelectedRestaurantLocationByRestaurantId
    );
    if (isAnyLocationChecked) {
      newMapSelectedRestaurantLocationByRestaurantId.set(
        restaurantId,
        newSelectedRestaurantLocation
      );
    } else {
      newMapSelectedRestaurantLocationByRestaurantId.delete(restaurantId);
    }
    const arrNewRestaurantLocation = Array.from(
      newMapSelectedRestaurantLocationByRestaurantId.values()
    );
    props.updateArrRestaurantLocationOnCheck(arrNewRestaurantLocation);

    setMapSelectedRestaurantLocationByRestaurantId(newMapSelectedRestaurantLocationByRestaurantId);
  };

  useEffect(() => {
    initialiseOriginalArrRestaurantLocationToMap();
  }, []);

  return (
    <div className="mt-2 grid xl:grid-cols-2 2xl:grid-cols-3 gap-x-9 gap-y-4">
      {props.arrAllRestaurantService.map((restaurantService: any) => (
        <UserRestaurantLocationCheckbox
          key={`UserRestaurantLocationCheckbox ${props.userId} ${restaurantService.restaurantId}`}
          userId={props.userId}
          restaurantWithAllLocationService={restaurantService}
          restaurantWithSelectedLocation={
            props.arrExistingRestaurantLocation.find(
              (existingRestaurantLocation) =>
                existingRestaurantLocation.restaurantId === restaurantService.restaurantId
            ) || null
          }
          updateMapSelectedRestaurantLocationByRestaurantId={
            updateMapSelectedRestaurantLocationByRestaurantId
          }
        />
      ))}
    </div>
  );
};

export default UserRestaurantLocationCheckboxList;
