import Pptxgen from 'pptxgenjs';

import {
  ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT,
  ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT,
  ARR_SERVICE_ORDER,
  SLIDE_STYLE,
} from '../constant/LocationMonthlyReviewPowerpoint';
import {
  TopMenuItemSummary,
  TopMenuItemSummaryWithImageDetail,
  ReductionInCostSummaryForLocation,
  ReductionInWeightSummaryForLocation,
  WastePerCoverSummary,
  WastePerCoverSummaryForLocation,
} from '../interfaces';

const LUMITICS_DASHBOARD_URL = 'https://dashboard.lumitics.com/';

/**
 * This function as created to convert large numbers (with no. of digits greater than 6) into an abbreviated form with 2dp.
 * This is to ensure that numbers with number of digits larger than 6 do not end up creating a extremely long and hard to
 * read number that is not user friendly in terms of UX for the end user. For numbers >= 10 and below 1M, it is rounded off
 * to the nearest whole number and every 3 digits is separated by a comma. For numbers below 10, it is rounded off to 2dp.
 * Look at "arrAbbreviatedSymbolReference" to see abbreviations created for different numbers of digits.
 * @param value - Number to be formatted
 * @returns abbreviatedValueInString - Number in string form that has been formatted accordingly
 */
const convertToAbbreviatedNumber = (value: number) => {
  const arrAbbreviatedSymbolReference = [
    { value: 1e9, divisor: 1e9, symbol: 'B' },
    { value: 1e6, divisor: 1e6, symbol: 'M' },
    { value: 1e3, divisor: 1e3, symbol: '' },
    { value: 1e1, divisor: 1, symbol: '' },
    { value: 0, divisor: 0, symbol: '' },
  ];
  // Number between 1000 and 1000000 is handled differently as every 3 digits before the decimal point is
  // to be separated by a comma
  const matchedAbbreviatedSymbolReference = arrAbbreviatedSymbolReference.find(
    (abbreviatedSymbolReference) => value >= abbreviatedSymbolReference.value
  );
  // No abbreviation for number between 1000 and 1000000
  if (matchedAbbreviatedSymbolReference!.divisor === 1e3) {
    return Math.round(value).toLocaleString('en-US');
  }
  if (matchedAbbreviatedSymbolReference!.divisor === 1) {
    return Math.round(value);
  }
  if (matchedAbbreviatedSymbolReference!.divisor === 0) {
    return value.toFixed(2);
  }
  return (
    (value / matchedAbbreviatedSymbolReference!.divisor).toFixed(2) +
    matchedAbbreviatedSymbolReference!.symbol
  );
};

/**
 * This function adds and initialises a slide depending on the slide type passed in
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * @param type - Type of slide to be initialised - 'opening', 'section', 'closing' and 'plain'
 * @param text - Text to be placed on slide upon initialisation (i.e. header text, opening text, closing email address, etc.)
 */
const initialiseSlide = (pres: Pptxgen, type: string, text?: string): Pptxgen.Slide => {
  const slide = pres.addSlide();
  switch (type) {
    case SLIDE_STYLE.opening: {
      slide.background = { path: 'openingBackground.png' };
      slide.addText(text!, {
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 16,
        align: 'center',
        x: 1.06,
        y: 3.8,
        h: 1,
        w: 4,
      });
      break;
    }
    case SLIDE_STYLE.section: {
      slide.background = { path: 'sectionBackground.png' };
      slide.addText('', {
        shape: pres.ShapeType.rect,
        fill: { color: '#102547' },
        bold: true,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 12,
        x: 0,
        y: 0.4,
        w: 5.5,
        h: 0.35,
      });
      slide.addText(text!, {
        bold: true,
        fontFace: 'Dosis',
        align: 'center',
        color: '#e4745e',
        fontSize: 14,
        x: 3.5,
        y: 2.6,
        w: 3,
        h: 0.35,
      });
      break;
    }
    case SLIDE_STYLE.closing: {
      slide.background = { path: 'closingBackground.png' };
      slide.addText('lumitics.com/', {
        x: 2,
        y: 3.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText(text!, {
        x: 6.07,
        y: 3.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText('facebook.com/lumitics/', {
        x: 2,
        y: 4.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      slide.addText('71 Ayer Rajah Crescent, #07-10\nSingapore 139951', {
        x: 6.07,
        y: 4.78,
        w: 3.5,
        h: 0.5,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 18,
      });
      break;
    }
    case SLIDE_STYLE.plain: {
      slide.background = { path: 'plainBackground.png' };
      slide.addText(text!, {
        shape: pres.ShapeType.rect,
        fill: { color: '#102547' },
        bold: true,
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 12,
        x: 0,
        y: 0.4,
        w: 5.5,
        h: 0.35,
      });
      break;
    }
  }
  return slide;
};

/**
 * This function inserts graph images to the current working slide
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image
 * @param headerText - Header text used for discriminating the content to be placed beside the graphs
 * @param isFullWidth - Indicates if the graph is to fill up the entire width of the slide
 * @param wastePerCoverSummary - Waste per cover values required for graph subtexts
 */
const insertGraph = (
  slide: Pptxgen.Slide,
  graphImage: string,
  headerText: string,
  isFullWidth?: boolean,
  wastePerCoverSummary?: WastePerCoverSummary
) => {
  if (graphImage !== undefined) {
    const widthOfGraphImage = isFullWidth ? 9.8 : 7;
    slide.addImage({
      data: `image/png;base64,${graphImage}`,
      y: 0.97,
      w: widthOfGraphImage,
      h: 4.5,
      x: 0.1,
    });
  }

  // For graph slides with subtext
  let arrGraphSubtext = [];
  if (wastePerCoverSummary) {
    const {
      weightPerCoverForPreviousMonth,
      weightPerCoverForFinalMonth,
      weightPerCoverForBaseline,
    } = wastePerCoverSummary;
    const weightPerCoverForFinalMonthDisplayValue =
      weightPerCoverForFinalMonth === '-' ? '-' : Math.round(weightPerCoverForFinalMonth);
    const weightPerCoverForPreviousMonthDisplayValue =
      weightPerCoverForPreviousMonth === '-' ? '-' : Math.round(weightPerCoverForPreviousMonth);
    if (headerText === 'LEFTOVER PER COVER (BY MONTHS)') {
      let differenceInPercentageFromPreviousMonthDisplayValue: string | number = '-';
      if (
        weightPerCoverForPreviousMonthDisplayValue !== 0 &&
        weightPerCoverForPreviousMonthDisplayValue !== '-' &&
        weightPerCoverForFinalMonthDisplayValue !== '-'
      ) {
        differenceInPercentageFromPreviousMonthDisplayValue = Math.round(
          ((weightPerCoverForPreviousMonthDisplayValue - weightPerCoverForFinalMonthDisplayValue) /
            weightPerCoverForPreviousMonthDisplayValue) *
            100
        );
      }
      arrGraphSubtext.push('Leftover per cover for the month');
      arrGraphSubtext.push(`${formatDataString(weightPerCoverForFinalMonthDisplayValue, 'grams')}`);
      arrGraphSubtext.push('As compared to last month');
      arrGraphSubtext.push(
        typeof differenceInPercentageFromPreviousMonthDisplayValue === 'number'
          ? `${Math.abs(differenceInPercentageFromPreviousMonthDisplayValue)}% ${
              differenceInPercentageFromPreviousMonthDisplayValue >= 0 ? 'reduction' : 'increase'
            }`
          : '-'
      );
    } else if (headerText === 'LEFTOVER PER COVER (BY LAST 8 WEEKS)') {
      const weightPerCoverForBaselineDisplayValue =
        weightPerCoverForBaseline === '-' ? '-' : Math.round(weightPerCoverForBaseline);
      let differenceInPercentageFromBaselineDisplayValue: string | number = '-';
      if (
        weightPerCoverForBaselineDisplayValue !== 0 &&
        weightPerCoverForBaselineDisplayValue !== '-' &&
        weightPerCoverForFinalMonthDisplayValue !== '-'
      ) {
        differenceInPercentageFromBaselineDisplayValue = Math.round(
          ((weightPerCoverForBaselineDisplayValue - weightPerCoverForFinalMonthDisplayValue) /
            weightPerCoverForBaselineDisplayValue) *
            100
        );
      }
      arrGraphSubtext.push('Leftover per cover for the month');
      arrGraphSubtext.push(`${formatDataString(weightPerCoverForFinalMonthDisplayValue, 'grams')}`);
      arrGraphSubtext.push('As compared to baseline');
      arrGraphSubtext.push(
        typeof differenceInPercentageFromBaselineDisplayValue === 'number'
          ? `${Math.abs(differenceInPercentageFromBaselineDisplayValue)}% ${
              differenceInPercentageFromBaselineDisplayValue >= 0 ? 'reduction' : 'increase'
            }`
          : '-'
      );
    } else if (headerText === 'LEFTOVER IN TERMS OF COST (BY MONTHS)') {
      slide.addText(
        [
          { text: 'Note:' },
          {
            text: 'Data shown here is based on the menu items that have been mapped.',
            options: { bullet: true },
          },
          {
            text: 'Cost may change as more menu items are mapped and validated.',
            options: { bullet: true },
          },
        ],
        {
          fontFace: 'Dosis',
          fontSize: 8,
          color: '#102547',
          x: 7.7,
          y: 4.6,
          w: 2.2,
          h: 1,
        }
      );
    }
  }

  if (arrGraphSubtext.length > 0) {
    const arrTextProps: Array<Pptxgen.TextProps> = [];
    for (let subtextIndex = 0; subtextIndex < arrGraphSubtext.length; subtextIndex += 2) {
      const textHeader = arrGraphSubtext[subtextIndex];
      arrTextProps.push({
        text: textHeader,
        options: {
          fontSize: 14,
          color: '#102547',
          align: 'center',
          breakLine: true,
          fontFace: 'Dosis',
          bold: true,
        },
      });
      const textBody = arrGraphSubtext[subtextIndex + 1];
      arrTextProps.push({
        text: `${textBody}\n\n`,
        options: {
          fontSize: 14,
          color: '#e4745e',
          align: 'center',
          fontFace: 'Dosis',
          bold: true,
        },
      });
    }
    slide.addText(arrTextProps, { x: 7.3, y: 2, w: 2.5, h: 3 });
  }
};

/**
 * This function inserts waste images with text overlay to the current working slide
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * waste images exceeding 3 rows
 * @param slide - Variable referencing the current working slide
 * @param arrTopMenuItemSummaryWithImageDetail - Array of top menu items with their waste images
 * @param headerText - Header's text to be used when adding new slides
 */
const insertImage = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  arrTopMenuItemSummaryWithImageDetail: Array<TopMenuItemSummaryWithImageDetail>,
  headerText: string
) => {
  let currentSlide = slide;
  for (
    let imageRowIndex = 0;
    imageRowIndex < arrTopMenuItemSummaryWithImageDetail.length;
    imageRowIndex += 1
  ) {
    let yValue = imageRowIndex % 3;
    const { menuItemName, weight, numberOfThrows, cost, arrImageDetail, currency } =
      arrTopMenuItemSummaryWithImageDetail[imageRowIndex];
    currentSlide.addText(
      `${menuItemName}\n${weight.toFixed(2)} KG\n${Math.round(cost).toLocaleString(
        'en-us'
      )} ${currency}\n${numberOfThrows} throws`,
      {
        bold: true,
        fontFace: 'Dosis',
        color: '#e4745e',
        fontSize: 12,
        w: 2,
        h: 1.3,
        x: 0.2,
        y: 1.1 + yValue * 1.5,
        align: 'center',
      }
    );
    let xValue = 0;
    for (
      let imageColumnIndex = 0;
      imageColumnIndex < arrImageDetail.length;
      imageColumnIndex += 1
    ) {
      const {
        image,
        thrownDate,
        weight: imageWeight,
        cost: imageCost,
      } = arrImageDetail[imageColumnIndex];
      if (image !== undefined) {
        currentSlide.addImage({
          data: `image/png;base64,${image}`,
          x: 2.3 + xValue * 1.8,
          y: 1 + yValue * 1.5,
          h: 1.4,
          w: 1.7,
        });
      }
      let imageText = thrownDate;
      if (imageWeight) {
        imageText += ` ${imageWeight.toFixed(2)} KG`;
      } else if (imageCost) {
        imageText += ` ${currency} ${Math.round(imageCost).toLocaleString('en-us')}`;
      }
      currentSlide.addText(imageText, {
        fontFace: 'Dosis',
        color: '#FFFFFF',
        fontSize: 10,
        align: 'center',
        y: 2.1 + yValue * 1.5,
        x: 2.5 + xValue * 1.8,
        h: 0.3,
        w: 1.3,
      });
      // This is to cater for plate waste where it has 12 images to be displayed
      if ((imageColumnIndex + 1) % 4 === 0) {
        yValue += 1;
        xValue = 0;
      } else {
        xValue += 1;
      }
    }
    if (imageRowIndex === 2) {
      currentSlide = initialiseSlide(pres, SLIDE_STYLE.plain, headerText);
    }
  }
};

/**
 * This function inserts station graph to the current working slide
 * Station graphs are slightly thinner in width compared to other graphs
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image
 */
const insertStationGraph = (slide: Pptxgen.Slide, graphImage: string) => {
  if (graphImage !== undefined) {
    slide.addImage({ data: `image/png;base64,${graphImage}`, y: 0.97, w: 5.5, h: 4.5, x: 0.1 });
  }
};

/**
 * This function inserts 3 rows x 2 columns of waste images for each station slide
 * Additional text is placed to the left of each row giving the total weight or cost for the station
 * @param slide - Variable referencing the current working slide
 * @param arrMenuItemDetailByStation - Array of menu item detail for each station
 */
const insertStationImage = (
  slide: Pptxgen.Slide,
  arrMenuItemDetailByStation: Array<{
    currency?: string;
    station: string;
    weight?: number;
    cost?: number;
    arrMenuItemDetail: Array<{
      menuItemName: string;
      weight?: number;
      cost?: number;
      image: string;
    }>;
  }>
) => {
  slide.addText('Top Two Items Per Station', {
    fontFace: 'Dosis',
    fontSize: 10,
    align: 'center',
    bold: true,
    x: 7.23,
    y: 0.6,
    w: 2,
    h: 0.22,
  });
  arrMenuItemDetailByStation.forEach((menuItemDetailByStation, stationIndex) => {
    const {
      station,
      weight: stationWeight,
      cost: stationCost,
      currency,
      arrMenuItemDetail,
    } = menuItemDetailByStation;
    let stationText = station;
    if (stationWeight) {
      stationText += `\n${Math.round(stationWeight).toLocaleString('en-us')} KG`;
    } else if (stationCost) {
      stationText += `\n${currency} ${Math.round(stationCost).toLocaleString('en-us')}`;
    }
    slide.addText(stationText, {
      fontFace: 'Dosis',
      fontSize: 10,
      align: 'center',
      bold: true,
      x: 5.65,
      y: 1.33 + stationIndex * 1.55,
      w: 1.05,
      h: 0.6,
    });

    arrMenuItemDetail.forEach((menuItemDetail, menuItemIndex) => {
      const { menuItemName, weight: menuItemWeight, cost: menuItemCost, image } = menuItemDetail;
      // The check for image is because Backend will not provide a graph image if there is no data
      if (image !== undefined) {
        slide.addImage({
          data: `image/png;base64,${image}`,
          y: 0.95 + stationIndex * 1.55,
          x: 6.7 + menuItemIndex * 1.6,
          h: 1.2,
          w: 1.45,
        });
      }
      let menuItemText = menuItemName;
      if (menuItemWeight) {
        menuItemText += `\n${Math.round(menuItemWeight).toLocaleString('en-us')} KG`;
      } else if (menuItemCost) {
        menuItemText += `\n${currency} ${Math.round(menuItemCost).toLocaleString('en-us')}`;
      }
      slide.addText(menuItemText, {
        fontFace: 'Dosis',
        fontSize: 6,
        align: 'center',
        x: 6.7 + menuItemIndex * 1.6,
        y: 2.12 + stationIndex * 1.55,
        w: 1.45,
        h: 0.3,
        valign: 'top',
      });
    });
  });
};

/**
 * This function formats the column explanation row on each summary table
 * Of the inner arrays, text is split by its line breaks
 * Only the first 2 lines of each cell is bolded
 * @param arrArrSummaryRowText - Array of array of text to be displayed
 * @returns Object - Pptxgen.TableRow format to be inserted as a row to a Pptxgen Table object
 */
const formatSummaryRow = (
  arrArrSummaryRowText: Array<Array<string>>,
  isOnly1ColumnSpan?: true
): Pptxgen.TableRow => {
  return arrArrSummaryRowText.map((arrCellText, arrCellTextIndex) => {
    if (arrCellTextIndex === 0)
      return {
        text: arrCellText[0],
        options: {
          bold: true,
          valign: 'top',
          colspan: isOnly1ColumnSpan ? 1 : 2,
        },
      };
    return {
      text: arrCellText.map((cellText, cellTextIndex) => {
        const cell: { text: string; options?: { bold: boolean } } = {
          text: cellText,
        };
        if (cellTextIndex < 2) {
          cell.options = {
            bold: true,
          };
        }
        return cell;
      }),
      options: {
        valign: 'top',
      },
    };
  });
};

/**
 * This function formats the calculation explanation row on each summary table
 * Of the inner arrays, text is split by its line breaks
 * Only the first column is to be bolded
 * @param arrArrCalculationRowText - Array of array of text to be displayed
 * @returns Object - Pptxgen.TableRow format to be appended as a row to a Pptxgen Table object
 */
const formatCalculationRow = (arrArrCalculationRowText: Array<Array<string>>): Pptxgen.TableRow => {
  return arrArrCalculationRowText.map((arrCellText, arrCellTextIndex) => {
    if (arrCellTextIndex === 0)
      return {
        text: arrCellText[0],
        options: {
          bold: true,
          colspan: 2,
        },
      };
    return {
      text: arrCellText.map((cellText) => ({
        text: cellText,
      })),
    };
  });
};

/**
 * This function formats the data value by prepending or appending the unit passed in
 * @param data - Data value requiring unit to be prepended or appended
 * @param unit - Unit to be prepended or appended
 * @returns String of data with unit to be displayed
 */
const formatDataString = (data: number | string, unit: string) => {
  if (unit === 'KG' || unit === 'grams per cover' || unit === 'grams') {
    return `${data.toLocaleString('en-us')} ${unit}`;
  }

  return `${unit} ${data.toLocaleString('en-us')}`;
};

/**
 * This function formats summary table row cells based on data values
 * Only third column and above will have its cell fill colored with green if positive, red if negative and transparent if neither
 * @param arrDataRow - Array of numbers to be displayed as a row in summary table
 * @param unit - Unit to be prepended or appended to value (e.g. 'KG', 'USD', 'grams per cover', etc.)
 * @param summaryTableRowType - 'weight', 'waste per cover' or 'cost' as they have different requirements
 * for their table display. For now, 'cost' does not have a strict requirement, but keeping this parameter
 * optional in view of future development
 * @param skipFirstCol - Used for 'waste per cover' table where its first col for its second row is skipped to
 * due to merging of the first and second rows of the table displaying baseline waste per cover
 * @returns Object - Pptxgen.TableRow format to be appended as a row to a Pptxgen Table object
 */
const formatSummaryTableRow = (
  arrDataRow: Array<number | '-'>,
  unit: string,
  summaryTableRowType?: string,
  skipFirstCol?: boolean
): [Pptxgen.TableRow, Array<number | string>] => {
  const arrDataRowDisplayValue = arrDataRow.map((data) =>
    typeof data === 'number' ? Math.round(data) : data
  );
  const [dataWithoutReductionDisplayValue, dataDisplayValue] = arrDataRowDisplayValue;

  const arrDataRowCell: Pptxgen.TableCell[] = [];
  [dataWithoutReductionDisplayValue, dataDisplayValue].forEach((cellData, cellIndex) => {
    // First and Second Column
    if (skipFirstCol && cellIndex === 0) {
      return;
    }
    const dataRowCell = {
      text: `${formatDataString(
        typeof cellData === 'number' ? Math.round(cellData) : cellData,
        unit
      )}`,
      options: {
        rowspan: summaryTableRowType === 'waste per cover' && cellIndex === 0 ? 2 : 1,
        color: '#e4745e',
        bold: true,
      },
    };
    arrDataRowCell.push(dataRowCell);
  });

  // Third Column: Reduction value
  const reductionValue =
    typeof dataWithoutReductionDisplayValue === 'number' && typeof dataDisplayValue === 'number'
      ? dataWithoutReductionDisplayValue - dataDisplayValue
      : '-';
  const reductionPercentage =
    typeof dataWithoutReductionDisplayValue === 'number' &&
    typeof dataDisplayValue === 'number' &&
    reductionValue !== '-' &&
    dataWithoutReductionDisplayValue !== 0
      ? Math.abs(Math.round((reductionValue / dataWithoutReductionDisplayValue) * 100))
      : '-';
  let increaseOrReductionString = '';
  if (typeof reductionValue === 'number' && reductionValue > 0) {
    increaseOrReductionString = ' reduction';
  } else if (typeof reductionValue === 'number' && reductionValue < 0) {
    increaseOrReductionString = ' increase';
  }
  arrDataRowCell.push({
    text: `${formatDataString(
      typeof reductionValue === 'number' ? Math.abs(reductionValue) : reductionValue,
      unit
    )} (${reductionPercentage}%)${increaseOrReductionString}`,
    options: {
      color: '#e4745e',
      bold: true,
      fill:
        reductionValue === '-' || reductionPercentage === '-' || reductionValue === 0
          ? undefined
          : reductionValue > 0
          ? { color: '#d9ead3' }
          : {
              color: '#f5cbcc',
            },
    },
  });
  if (summaryTableRowType === 'weight') {
    // Fourth Column: (Weight summary only) Number of meals saved
    const numberOfMealsSaved =
      typeof reductionValue === 'number' ? Math.round(reductionValue / 0.25) : '-';
    arrDataRowCell.push({
      text: numberOfMealsSaved.toLocaleString('en-us'),
      options: {
        color: '#e4745e',
        bold: true,
        fill:
          typeof numberOfMealsSaved === 'number' && numberOfMealsSaved > 0
            ? { color: '#d9ead3' }
            : typeof numberOfMealsSaved === 'number' && numberOfMealsSaved < 0
            ? { color: '#f5cbcc' }
            : undefined,
      },
    });

    // Fifth Column: (Weight summary only) CO2 Emissions Avoided
    const cO2EmissionsAvoided =
      typeof reductionValue === 'number' ? Math.round(reductionValue * 2.5) : '-';
    arrDataRowCell.push({
      text: formatDataString(cO2EmissionsAvoided, unit),
      options: {
        color: '#e4745e',
        bold: true,
        fill:
          typeof cO2EmissionsAvoided === 'number' && cO2EmissionsAvoided > 0
            ? { color: '#d9ead3' }
            : typeof cO2EmissionsAvoided === 'number' && cO2EmissionsAvoided < 0
            ? { color: '#f5cbcc' }
            : undefined,
      },
    });
  }
  return [arrDataRowCell, arrDataRowDisplayValue];
};

/**
 * This function inserts the waste summary tables to the current working slide
 * For all slides, headers are values without intervention, actual values and reduction values
 * For weight slides, there are two additional headers for number of meals saved and carbon emissions avoided
 * 2 rows will be created per service representing current month and whole period, with additional 2 rows appended
 * at the back for total values. All values represented on the table are calculated based on display value (not
 * actual value) for ease of calculation by clients. This is also true for weight per cover slides, only if all the service
 * belong to the same group.
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * required for exceeding 4 services per slide
 * @param slide - Variable referencing the current working slide
 * @param headerText - Header text used for discriminating the type and content to be placed in the tables
 * @param tableData - Object containing values required to populate table values
 * @param tableData.currency - Currency to be prefixed in cost values
 * @param isAllServiceFromSameGroup - Indicates if all the service belong to the same group. Only used for generating SUMMARY - TOTAL LEFTOVER PER COVER table.
 */
const insertTable = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  headerText: string,
  tableData: {
    currency: string;
    reductionInWeightSummaryForLocation: ReductionInWeightSummaryForLocation;
    wastePerCoverSummaryForLocation: WastePerCoverSummaryForLocation;
    reductionInCostSummaryForLocation: ReductionInCostSummaryForLocation;
  },
  isAllServiceFromSameGroup?: boolean
) => {
  let arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  const NUMBER_OF_SERVICE_PER_SLIDE = 4;
  const tableProp: Pptxgen.TableProps = {
    x: 0.125,
    y: 0.85,
    w: 9.75,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  };
  const {
    currency,
    reductionInWeightSummaryForLocation,
    wastePerCoverSummaryForLocation,
    reductionInCostSummaryForLocation,
  } = tableData;
  if (
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF WEIGHT (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT));
    arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT));
    const { arrReductionInWeightSummaryForService } = reductionInWeightSummaryForLocation;
    arrReductionInWeightSummaryForService.sort(
      (reductionInWeightSummaryA, reductionInWeightSummaryB) => {
        const serviceAIndex = ARR_SERVICE_ORDER.indexOf(
          reductionInWeightSummaryA.serviceName!.toLowerCase()
        );
        const serviceBIndex = ARR_SERVICE_ORDER.indexOf(
          reductionInWeightSummaryB.serviceName!.toLowerCase()
        );
        return (
          (serviceAIndex > -1 ? serviceAIndex : Infinity) -
          (serviceBIndex > -1 ? serviceBIndex : Infinity)
        );
      }
    );
    let totalWeightWithoutReductionForFinalMonthForLocation = 0;
    let totalWeightForFinalMonthForLocation = 0;
    let totalWeightWithoutReductionForWholePeriodForLocation = 0;
    let totalWeightForWholePeriodForLocation = 0;
    arrReductionInWeightSummaryForService.forEach(
      (reductionInWeightSummary, reductionInWeightSummaryIndex) => {
        const {
          totalWeightWithoutReductionForFinalMonth,
          totalWeightForFinalMonth,
          totalWeightWithoutReductionForWholePeriod,
          totalWeightForWholePeriod,
          serviceName,
        } = reductionInWeightSummary;
        const [
          arrReductionInWeightValueForServiceForTheMonthText,
          [
            totalWeightWithoutReductionForFinalMonthDisplayValue,
            totalWeightForFinalMonthDisplayValue,
          ],
        ] = formatSummaryTableRow(
          [totalWeightWithoutReductionForFinalMonth, totalWeightForFinalMonth],
          'KG',
          'weight'
        );
        arrPPTTableRow.push([
          { text: serviceName, options: { rowspan: 2, bold: true } },
          { text: 'For The Month', options: { bold: true } },
          ...arrReductionInWeightValueForServiceForTheMonthText,
        ]);
        const [
          arrReductionInWeightValueForServiceSinceProjectStartedText,
          [
            totalWeightWithoutReductionForWholePeriodDisplayValue,
            totalWeightForWholePeriodDisplayValue,
          ],
        ] = formatSummaryTableRow(
          [totalWeightWithoutReductionForWholePeriod, totalWeightForWholePeriod],
          'KG',
          'weight'
        );
        arrPPTTableRow.push([
          { text: 'Since Project Started', options: { bold: true } },
          ...arrReductionInWeightValueForServiceSinceProjectStartedText,
        ]);
        totalWeightWithoutReductionForFinalMonthForLocation +=
          typeof totalWeightWithoutReductionForFinalMonthDisplayValue === 'number'
            ? totalWeightWithoutReductionForFinalMonthDisplayValue
            : 0;
        totalWeightForFinalMonthForLocation +=
          typeof totalWeightForFinalMonthDisplayValue === 'number'
            ? totalWeightForFinalMonthDisplayValue
            : 0;
        totalWeightWithoutReductionForWholePeriodForLocation +=
          typeof totalWeightWithoutReductionForWholePeriodDisplayValue === 'number'
            ? totalWeightWithoutReductionForWholePeriodDisplayValue
            : 0;
        totalWeightForWholePeriodForLocation +=
          typeof totalWeightForWholePeriodDisplayValue === 'number'
            ? totalWeightForWholePeriodDisplayValue
            : 0;
        if (
          (reductionInWeightSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
          reductionInWeightSummaryIndex + 1 !== arrReductionInWeightSummaryForService.length
        ) {
          slide.addTable(arrPPTTableRow, tableProp);
          slide = initialiseSlide(pres, 'plain', headerText);
          arrPPTTableRow = [];
          arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_WEIGHT_SUMMARY_ROW_TEXT));
          arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_WEIGHT_CALCULATION_ROW_TEXT));
        }
      }
    );
    const [arrReductionInWeightValueForLocationForTheMonthText] = formatSummaryTableRow(
      [totalWeightWithoutReductionForFinalMonthForLocation, totalWeightForFinalMonthForLocation],
      'KG',
      'weight'
    );
    arrPPTTableRow.push([
      { text: 'Total', options: { rowspan: 2, bold: true } },
      { text: 'For The Month', options: { bold: true } },
      ...arrReductionInWeightValueForLocationForTheMonthText,
    ]);
    const [arrReductionInWeightValueForLocationSinceProjectStartedText] = formatSummaryTableRow(
      [totalWeightWithoutReductionForWholePeriodForLocation, totalWeightForWholePeriodForLocation],
      'KG',
      'weight'
    );
    arrPPTTableRow.push([
      { text: 'Since Project Started', options: { bold: true } },
      ...arrReductionInWeightValueForLocationSinceProjectStartedText,
    ]);
    slide.addTable(arrPPTTableRow, tableProp);
  } else if (
    headerText === 'SUMMARY - TOTAL LEFTOVER PER COVER' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER PER COVER (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT));
    arrPPTTableRow.push(
      formatCalculationRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT)
    );
    const {
      arrWastePerCoverSummaryForService,
      weightPerCoverForBaseline: weightPerCoverForBaselineForLocation,
      weightPerCoverForFinalMonth: weightPerCoverForFinalMonthForLocation,
      weightPerCoverForWholePeriod: weightPerCoverForWholePeriodForLocation,
    } = wastePerCoverSummaryForLocation;
    arrWastePerCoverSummaryForService.sort((wastePerCoverSummaryA, wastePerCoverSummaryB) => {
      const serviceAIndex = ARR_SERVICE_ORDER.indexOf(
        wastePerCoverSummaryA.serviceName!.toLowerCase()
      );
      const serviceBIndex = ARR_SERVICE_ORDER.indexOf(
        wastePerCoverSummaryB.serviceName!.toLowerCase()
      );
      return (
        (serviceAIndex > -1 ? serviceAIndex : Infinity) -
        (serviceBIndex > -1 ? serviceBIndex : Infinity)
      );
    });
    // The variables totalWeightPerCoverForBaselineForLocation, totalWeightPerCoverForFinalMonthForLocation and totalWeightPerCoverForWholePeriodForLocation
    // are necessary to cater for the case where all the services belong to the same group, and therefore all total values should be the sum of the rounded
    // off values of each service
    let totalWeightPerCoverForBaselineForLocation = 0;
    let totalWeightPerCoverForFinalMonthForLocation = 0;
    let totalWeightPerCoverForWholePeriodForLocation = 0;
    arrWastePerCoverSummaryForService.forEach((wastePerCoverSummary, wastePerCoverSummaryIndex) => {
      const {
        weightPerCoverForBaseline,
        weightPerCoverForFinalMonth,
        weightPerCoverForWholePeriod,
        serviceName,
      } = wastePerCoverSummary;
      const [
        arrReductionInWeightPerCoverValueForServiceForTheMonthText,
        [totalWeightPerCoverForBaselineDisplayValue, totalWeightPerCoverForFinalMonthDisplayValue],
      ] = formatSummaryTableRow(
        [weightPerCoverForBaseline, weightPerCoverForFinalMonth],
        'grams per cover',
        'waste per cover'
      );
      arrPPTTableRow.push([
        { text: serviceName, options: { rowspan: 2, bold: true } },
        { text: 'For The Month', options: { bold: true } },
        ...arrReductionInWeightPerCoverValueForServiceForTheMonthText,
      ]);
      const [
        arrReductionInWeightPerCoverValueForServiceSinceProjectStartedText,
        [_, totalWeightPerCoverForWholePeriodDisplayValue],
      ] = formatSummaryTableRow(
        [weightPerCoverForBaseline, weightPerCoverForWholePeriod],
        'grams per cover',
        'waste per cover',
        true
      );
      arrPPTTableRow.push([
        { text: 'Since Project Started', options: { bold: true } },
        ...arrReductionInWeightPerCoverValueForServiceSinceProjectStartedText,
      ]);
      if (isAllServiceFromSameGroup) {
        totalWeightPerCoverForBaselineForLocation +=
          typeof totalWeightPerCoverForBaselineDisplayValue === 'number'
            ? totalWeightPerCoverForBaselineDisplayValue
            : 0;
        totalWeightPerCoverForFinalMonthForLocation +=
          typeof totalWeightPerCoverForFinalMonthDisplayValue === 'number'
            ? totalWeightPerCoverForFinalMonthDisplayValue
            : 0;
        totalWeightPerCoverForWholePeriodForLocation +=
          typeof totalWeightPerCoverForWholePeriodDisplayValue === 'number'
            ? totalWeightPerCoverForWholePeriodDisplayValue
            : 0;
      }
      if (
        (wastePerCoverSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
        wastePerCoverSummaryIndex + 1 !== arrWastePerCoverSummaryForService.length
      ) {
        slide.addTable(arrPPTTableRow, tableProp);
        slide = initialiseSlide(pres, 'plain', headerText);
        arrPPTTableRow = [];
        arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT));
        arrPPTTableRow.push(
          formatCalculationRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_CALCULATION_ROW_TEXT)
        );
      }
    });
    if (!isAllServiceFromSameGroup) {
      totalWeightPerCoverForBaselineForLocation =
        typeof weightPerCoverForBaselineForLocation === 'number'
          ? weightPerCoverForBaselineForLocation
          : 0;
      totalWeightPerCoverForFinalMonthForLocation =
        typeof weightPerCoverForFinalMonthForLocation === 'number'
          ? weightPerCoverForFinalMonthForLocation
          : 0;
      totalWeightPerCoverForWholePeriodForLocation =
        typeof weightPerCoverForWholePeriodForLocation === 'number'
          ? weightPerCoverForWholePeriodForLocation
          : 0;
    }
    const [arrWastePerCoverValueForLocationForTheMonthText] = formatSummaryTableRow(
      [totalWeightPerCoverForBaselineForLocation, totalWeightPerCoverForFinalMonthForLocation],
      'grams per cover',
      'waste per cover'
    );
    arrPPTTableRow.push([
      { text: 'Total', options: { rowspan: 2, bold: true } },
      { text: 'For The Month', options: { bold: true } },
      ...arrWastePerCoverValueForLocationForTheMonthText,
    ]);
    const [arrWastePerCoverValueForLocationSinceProjectStartedText] = formatSummaryTableRow(
      [totalWeightPerCoverForBaselineForLocation, totalWeightPerCoverForWholePeriodForLocation],
      'grams per cover',
      'waste per cover',
      true
    );
    arrPPTTableRow.push([
      { text: 'Since Project Started', options: { bold: true } },
      ...arrWastePerCoverValueForLocationSinceProjectStartedText,
    ]);
    slide.addImage({
      path: 'lightbulb.png',
      x: 2.2,
      y: 4.98,
      h: 0.5,
      w: 0.5,
    });
    slide.addText(
      [
        {
          text: 'Reduction in leftovers per cover (grams) is the key.',
          options: { bullet: true },
        },
        {
          text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
          options: { bullet: true },
        },
      ],
      {
        x: 2.8,
        y: 4.98,
        h: 0.6,
        w: 5,
        bold: true,
        fontFace: 'Dosis',
        fontSize: 8,
        color: '#102547',
      }
    );
    slide.addTable(arrPPTTableRow, tableProp);
  } else if (
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST' ||
    headerText === 'SUMMARY - TOTAL LEFTOVER IN TERMS OF COST (GROUPED)'
  ) {
    arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT(currency)));
    arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT));
    const { arrReductionInCostSummaryForService } = reductionInCostSummaryForLocation;
    arrReductionInCostSummaryForService.sort((reductionInCostSummaryA, reductionInCostSummaryB) => {
      const serviceAIndex = ARR_SERVICE_ORDER.indexOf(
        reductionInCostSummaryA.serviceName!.toLowerCase()
      );
      const serviceBIndex = ARR_SERVICE_ORDER.indexOf(
        reductionInCostSummaryB.serviceName!.toLowerCase()
      );
      return (
        (serviceAIndex > -1 ? serviceAIndex : Infinity) -
        (serviceBIndex > -1 ? serviceBIndex : Infinity)
      );
    });
    let totalCostWithoutReductionForFinalMonthForLocation = 0;
    let totalCostForFinalMonthForLocation = 0;
    let totalCostWithoutReductionForWholePeriodForLocation = 0;
    let totalCostForWholePeriodForLocation = 0;
    arrReductionInCostSummaryForService.forEach(
      (reductionInCostSummary, reductionInCostSummaryIndex) => {
        const {
          totalCostWithoutReductionForFinalMonth,
          totalCostForFinalMonth,
          totalCostWithoutReductionForWholePeriod,
          totalCostForWholePeriod,
          serviceName,
        } = reductionInCostSummary;
        const [
          arrReductionInCostValueForServiceForTheMonthText,
          [totalCostWithoutReductionForFinalMonthDisplayValue, totalCostForFinalMonthDisplayValue],
        ] = formatSummaryTableRow(
          [totalCostWithoutReductionForFinalMonth, totalCostForFinalMonth],
          currency
        );
        arrPPTTableRow.push([
          { text: serviceName, options: { rowspan: 2, bold: true } },
          { text: 'For The Month', options: { bold: true } },
          ...arrReductionInCostValueForServiceForTheMonthText,
        ]);
        const [
          arrReductionInCostValueForServiceSinceProjectStartedText,
          [
            totalCostWithoutReductionForWholePeriodDisplayValue,
            totalCostForWholePeriodDisplayValue,
          ],
        ] = formatSummaryTableRow(
          [totalCostWithoutReductionForWholePeriod, totalCostForWholePeriod],
          currency
        );
        arrPPTTableRow.push([
          { text: 'Since Project Started', options: { bold: true } },
          ...arrReductionInCostValueForServiceSinceProjectStartedText,
        ]);
        totalCostWithoutReductionForFinalMonthForLocation +=
          typeof totalCostWithoutReductionForFinalMonthDisplayValue === 'number'
            ? totalCostWithoutReductionForFinalMonthDisplayValue
            : 0;
        totalCostForFinalMonthForLocation +=
          typeof totalCostForFinalMonthDisplayValue === 'number'
            ? totalCostForFinalMonthDisplayValue
            : 0;
        totalCostWithoutReductionForWholePeriodForLocation +=
          typeof totalCostWithoutReductionForWholePeriodDisplayValue === 'number'
            ? totalCostWithoutReductionForWholePeriodDisplayValue
            : 0;
        totalCostForWholePeriodForLocation +=
          typeof totalCostForWholePeriodDisplayValue === 'number'
            ? totalCostForWholePeriodDisplayValue
            : 0;
        if (
          (reductionInCostSummaryIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
          reductionInCostSummaryIndex + 1 !== arrReductionInCostSummaryForService.length
        ) {
          slide.addTable(arrPPTTableRow, tableProp);
          slide = initialiseSlide(pres, 'plain', headerText);
          arrPPTTableRow = [];
          arrPPTTableRow.push(formatSummaryRow(ARR_ARR_TOTAL_COST_SUMMARY_ROW_TEXT(currency)));
          arrPPTTableRow.push(formatCalculationRow(ARR_ARR_TOTAL_COST_CALCULATION_ROW_TEXT));
        }
      }
    );

    const [arrCostValueForLocationForTheMonthText] = formatSummaryTableRow(
      [totalCostWithoutReductionForFinalMonthForLocation, totalCostForFinalMonthForLocation],
      currency
    );
    arrPPTTableRow.push([
      { text: 'Total', options: { bold: true, rowspan: 2 } },
      { text: 'For The Month', options: { bold: true } },
      ...arrCostValueForLocationForTheMonthText,
    ]);
    const [arrCostValueForLocationSinceProjectStartedText] = formatSummaryTableRow(
      [totalCostWithoutReductionForWholePeriodForLocation, totalCostForWholePeriodForLocation],
      currency
    );
    arrPPTTableRow.push([
      { text: 'Since Project Started', options: { bold: true } },
      ...arrCostValueForLocationSinceProjectStartedText,
    ]);
    slide.addText(
      [
        { text: 'Note:' },
        {
          text: 'Data shown here is based on the menu items that have been mapped.',
          options: { bullet: true },
        },
        {
          text: 'Cost may change as more menu items are mapped and validated.',
          options: { bullet: true },
        },
      ],
      {
        fontFace: 'Dosis',
        fontSize: 10,
        color: '#102547',
        x: 0.5,
        y: 4.72,
        w: 6,
        h: 0.7,
      }
    );
    slide.addTable(arrPPTTableRow, tableProp);
  }
};

/**
 * This function appends the ordinal suffix to a number (e.g. 1 -> 1st, 2 -> 2nd, etc.)
 * @param number - Number to be suffixed
 * @returns - Number with ordinal suffix
 */
const appendOrdinalSuffixToNumber = (number: number) => {
  var lastDigit = number % 10;
  if (lastDigit === 1) {
    return number + 'st';
  }
  if (lastDigit === 2) {
    return number + 'nd';
  }
  if (lastDigit === 3) {
    return number + 'rd';
  }
  return number + 'th';
};

/**
 * This function inserts the service's top 6 menu item table to the current working slide
 * The table headers include 'Top 6 High Volume Items'/'Top 6 High Value Items', 'This Month' and 'Last Month'
 * Comparison between 'This Month' and 'Last Month' menu item trend is done and increases are highlighted in red,
 * while decreases are highlighted in green for 'This Month'. If there is no change, they are highlighted in yellow.
 * @param slide - Variable referencing the current working slide
 * @param tableHeaderText - Table header 'Top 6 High Volume Items' or 'Top 6 High Value Items'
 * @param currency - Currency to be prefixed in cost values
 * @param arrTop6MenuItem - Array of menu items sorted in order of display
 * @param arrTop6MenuItemForPreviousMonth - Array of menu items from previous period sorted in order of display
 * @param isProductionService - Indicate if this table belongs to a production service. It is used to disable the highlighting of cells.
 */
const insertServiceTop6MenuItemTable = (
  slide: Pptxgen.Slide,
  tableHeaderText: string,
  currency: string,
  arrTop6MenuItem: Array<TopMenuItemSummary>,
  arrTop6MenuItemForPreviousMonth: Array<TopMenuItemSummary>,
  isProductionService: boolean
) => {
  // Trend comparison
  const mapMenuItemSummaryByMenuItemName = new Map(
    arrTop6MenuItem.map((topMenuItem) => {
      const { menuItemName } = topMenuItem;
      return [menuItemName, topMenuItem];
    })
  );
  const comparisonKey =
    tableHeaderText === 'Top 6 High Volume Items' ? 'weightPerCover' : 'costPerCover';
  const mapTrendByMenuItemName = new Map();
  if (!isProductionService) {
    arrTop6MenuItemForPreviousMonth.forEach((menuItemSummaryForPreviousMonth) => {
      const { menuItemName } = menuItemSummaryForPreviousMonth;
      const menuItemSummary = mapMenuItemSummaryByMenuItemName.get(menuItemName);
      if (menuItemSummary) {
        let trend = 'unchanged';
        const valueDifference =
          Number(Number(menuItemSummary[comparisonKey]).toFixed(2)) -
          Number(Number(menuItemSummaryForPreviousMonth[comparisonKey]).toFixed(2));
        if (valueDifference < 0) {
          trend = 'decrease';
        } else if (valueDifference > 0) {
          trend = 'increase';
        }
        mapTrendByMenuItemName.set(menuItemName, trend);
      }
    });
  }

  const arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  arrPPTTableRow.push([
    { text: tableHeaderText, options: { bold: true } },
    { text: 'This Month', options: { bold: true } },
    { text: 'Last Month', options: { bold: true } },
  ]);
  for (
    let menuItemIndex = 0;
    menuItemIndex < Math.max(arrTop6MenuItem.length, arrTop6MenuItemForPreviousMonth.length);
    menuItemIndex += 1
  ) {
    const menuItem = arrTop6MenuItem[menuItemIndex];
    const menuItemForPreviousMonth = arrTop6MenuItemForPreviousMonth[menuItemIndex];

    const currentPPTTableRow: Pptxgen.TableRow = [
      { text: appendOrdinalSuffixToNumber(menuItemIndex + 1), options: { bold: true } },
    ];
    if (menuItem) {
      const { menuItemName, weight, weightPerCover, cost, numberOfThrows, costPerCover } = menuItem;
      let fill;
      const trend = mapTrendByMenuItemName.get(menuItemName);
      if (trend === 'increase') {
        fill = { color: '#f4cccc' };
      } else if (trend === 'decrease') {
        fill = { color: '#d9ead3' };
      } else if (trend === 'unchanged') {
        fill = { color: '#fff2cc' };
      }
      currentPPTTableRow.push({
        text: `${menuItemName}\n${weight.toFixed(2)} KG${
          weightPerCover
            ? ` (${
                typeof weightPerCover === 'number' && weightPerCover !== 0
                  ? weightPerCover.toFixed(2)
                  : weightPerCover
              } grams per cover)`
            : ''
        }\n${currency} ${Math.round(cost).toLocaleString('en-us')}${
          costPerCover
            ? ` (${
                typeof costPerCover === 'number' && costPerCover !== 0
                  ? `${currency} ${convertToAbbreviatedNumber(costPerCover)}`
                  : costPerCover
              } per cover)`
            : ''
        }\n${numberOfThrows} throws`,
        options: {
          fill,
        },
      });
    } else {
      currentPPTTableRow.push({
        text: '',
      });
    }
    if (menuItemForPreviousMonth) {
      const { menuItemName, weight, weightPerCover, cost, numberOfThrows, costPerCover } =
        menuItemForPreviousMonth;
      currentPPTTableRow.push({
        text: `${menuItemName}\n${weight.toFixed(2)} KG${
          weightPerCover
            ? ` (${
                typeof weightPerCover === 'number' && weightPerCover !== 0
                  ? weightPerCover.toFixed(2)
                  : weightPerCover
              } grams per cover)`
            : ''
        }\n${currency} ${Math.round(cost).toLocaleString('en-us')}${
          costPerCover
            ? ` (${
                typeof costPerCover === 'number' && costPerCover !== 0
                  ? `${currency} ${convertToAbbreviatedNumber(costPerCover)}`
                  : costPerCover
              } per cover)`
            : ''
        }\n${numberOfThrows} throws`,
      });
    } else {
      currentPPTTableRow.push({
        text: '',
      });
    }
    arrPPTTableRow.push(currentPPTTableRow);
  }
  slide.addTable(arrPPTTableRow, {
    x: 0.5,
    y: 1,
    w: 9,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  });
};

/**
 * This function inserts graph images to the current working slide for baseline report
 * @param slide - Variable referencing the current working slide
 * @param graphImage - Base 64 string of the graph image
 * @param wasteValue - Waste per cover values required for graph subtexts
 * @param options - Options object
 * @param options.isWeight - Indicates if the graph is for weight
 * @param options.isFullWidth - Indicates if the graph is to fill up the entire width of the slide
 */
const insertGraphForBaseline = (
  slide: Pptxgen.Slide,
  graphImage: string,
  wasteValue?: number | string,
  options?: {
    isWeight?: boolean;
    isFullWidth?: boolean;
  }
) => {
  if (graphImage !== undefined) {
    const widthOfGraphImage = options?.isFullWidth ? 9.8 : 7;
    slide.addImage({
      data: `image/png;base64,${graphImage}`,
      y: 0.97,
      w: widthOfGraphImage,
      h: 4.5,
      x: 0.1,
    });
  }

  if (wasteValue !== null && wasteValue !== undefined) {
    const summaryText = options?.isWeight ? 'Total Weight' : 'Total LPC';
    const unit = options?.isWeight ? 'KG' : 'grams';
    slide.addText(
      [
        { text: summaryText },
        { text: `${wasteValue} ${unit}`, options: { softBreakBefore: true } },
      ],
      {
        fontFace: 'Dosis',
        fontSize: 8,
        bold: true,
        color: '#e4745e',
        x: 9.2,
        y: 5,
        w: 0.8,
        h: 0.5,
        align: 'center',
      }
    );
  }
};

/**
 * This function inserts table for baseline report. It only presents weight per cover values
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide
 * required for exceeding 4 services per slide
 * @param slide - Variable referencing the current working slide
 * @param headerText - Header text used for discriminating the type and content to be placed in the tables
 * @param arrNameAndWeightPerCover - Array of object containing name and weight per cover (name can the group name or the service name)
 */
const insertTableForBaseline = (
  pres: Pptxgen,
  slide: Pptxgen.Slide,
  headerText: string,
  arrNameAndWeightPerCover: Array<{ name: string; weightPerCover: number | string }>
) => {
  let arrPPTTableRow: Array<Pptxgen.TableRow> = [];
  const NUMBER_OF_SERVICE_PER_SLIDE = 9;
  const tableProp: Pptxgen.TableProps = {
    x: 2,
    y: 1.2,
    w: 6,
    h: 1,
    fontFace: 'Dosis',
    fontSize: 8,
    align: 'center',
    border: { type: 'solid', pt: 1, color: '#102547' },
    color: '#102547',
    valign: 'middle',
  };

  arrPPTTableRow.push(
    formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT.slice(0, 2), true)
  );
  arrNameAndWeightPerCover
    .slice(0, arrNameAndWeightPerCover.length - 1)
    .forEach((nameAndWeightPerCover, nameAndWeightPerCoverIndex) => {
      const { name, weightPerCover } = nameAndWeightPerCover;
      arrPPTTableRow.push([
        { text: name, options: { bold: true } },
        { text: `${weightPerCover} grams per cover`, options: { color: '#e4745e', bold: true } },
      ]);

      if (
        (nameAndWeightPerCoverIndex + 1) % NUMBER_OF_SERVICE_PER_SLIDE === 0 &&
        nameAndWeightPerCoverIndex + 1 !== arrNameAndWeightPerCover.length - 1
      ) {
        slide.addTable(arrPPTTableRow, tableProp);
        slide = initialiseSlide(pres, 'plain', headerText);
        arrPPTTableRow = [];
        arrPPTTableRow.push(
          formatSummaryRow(ARR_ARR_TOTAL_LEFTOVER_PER_COVER_SUMMARY_ROW_TEXT.slice(0, 2), true)
        );
      }
    });
  arrPPTTableRow.push([
    {
      text: arrNameAndWeightPerCover[arrNameAndWeightPerCover.length - 1].name,
      options: { bold: true, fill: { color: '#fff2cc' } },
    },
    {
      text: `${
        arrNameAndWeightPerCover[arrNameAndWeightPerCover.length - 1].weightPerCover
      } grams per cover`,
      options: { color: '#e4745e', bold: true, fill: { color: '#fff2cc' } },
    },
  ]);
  slide.addImage({
    path: 'lightbulb.png',
    x: 2.2,
    y: 4.98,
    h: 0.5,
    w: 0.5,
  });
  slide.addText(
    [
      {
        text: 'Reduction in leftovers per cover (grams) is the key.',
        options: { bullet: true },
      },
      {
        text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
        options: { bullet: true },
      },
    ],
    {
      x: 2.8,
      y: 4.98,
      h: 0.6,
      w: 5,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 8,
      color: '#102547',
    }
  );
  slide.addTable(arrPPTTableRow, tableProp);
};

/**
 * This function inserts waste images into a slide. Two slides will be created - one is for showing the top wasted images, and the other is
 * for showing the top 4 wasted images of the top 3 internal groupings.
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * waste images exceeding 3 rows
 * @param headerText - Header's text to be used when adding new slides. Note that this text only contain the group/service information
 * @param arrTopMenuItemSummaryWithImageDetail - Array of top menu items with their waste images

 */
const insertImageSlidesForBaseline = (
  pres: Pptxgen,
  headerTextForServicePortion: string,
  arrTop3InternalGroupingDetailByWeight: Array<{
    weight: number;
    arrImageSignedURL: Array<string>;
  }>,
  arrTopXWasteImageSignedUrlByWeight: Array<string>
) => {
  let slide = initialiseSlide(
    pres,
    SLIDE_STYLE.plain,
    `${headerTextForServicePortion} - TOP WASTED THROWS`
  );
  const arrTop24ImageSignedUrl = arrTopXWasteImageSignedUrlByWeight.slice(0, 24);
  let yValue = 0;
  for (let imageIndex = 0; imageIndex < arrTop24ImageSignedUrl.length; imageIndex += 1) {
    let xValue = imageIndex % 6;
    slide.addImage({
      path: arrTop24ImageSignedUrl[imageIndex],
      x: 0.6 + xValue * 1.48,
      y: 0.85 + yValue * 1.18,
      h: 1.1,
      w: 1.34,
    });
    if (xValue === 5) {
      yValue += 1;
    }
  }

  slide = initialiseSlide(
    pres,
    SLIDE_STYLE.plain,
    `${headerTextForServicePortion} - TOP 3 LEFTOVER IN TERMS OF WEIGHT`
  );
  for (
    let imageRowIndex = 0;
    imageRowIndex < arrTop3InternalGroupingDetailByWeight.length;
    imageRowIndex += 1
  ) {
    let yValue = imageRowIndex % 3;
    const { weight, arrImageSignedURL } = arrTop3InternalGroupingDetailByWeight[imageRowIndex];
    slide.addText(`${weight} KG`, {
      bold: true,
      fontFace: 'Dosis',
      color: '#102547',
      fontSize: 12,
      w: 2,
      h: 1.3,
      x: 0.2,
      y: 1.1 + yValue * 1.5,
      align: 'center',
    });
    let xValue = 0;
    for (
      let imageColumnIndex = 0;
      imageColumnIndex < arrImageSignedURL.length;
      imageColumnIndex += 1
    ) {
      slide.addImage({
        path: arrImageSignedURL[imageColumnIndex],
        x: 2.3 + xValue * 1.8,
        y: 1 + yValue * 1.5,
        h: 1.4,
        w: 1.7,
      });
      xValue += 1;
    }
  }
};

/**
 * This function inserts 6 Next Steps body slides that contain information about location monthly report and Lumitics Dashboard features
 * @param pres - Variable referencing the current working presentation. This is required to add a new slide, required for
 * @param restaurantName - Restaurant name to be used in one of the paragraphs of one of the slide
 */
const insertBaselineNextStepsBodySlides = (pres: Pptxgen, restaurantName: string) => {
  const slideStyle = SLIDE_STYLE.plain;
  let slide = initialiseSlide(pres, slideStyle, 'NEXT STEPS');
  slide.addText(
    [
      { text: 'Lumitics', options: { bold: true } },
      {
        text: 'Check data collected and share in the group chat should there be any device usage issues',
        options: { bullet: true },
      },
      {
        text: 'Schedule monthly meetings to go through the past month’s data and identify opportunities for food waste reduction by highlighting the top wasted items',
        options: { bullet: true },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 1,
      y: 1.5,
      w: 8,
      h: 1,
    }
  );
  slide.addText(
    [
      { text: restaurantName, options: { bold: true } },
      {
        text: 'Input the daily covers on ',
        options: { bullet: true },
      },
      {
        text: 'Lumitics Dashboard',
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
      {
        text: ' - latest by the 3rd of every month',
        options: {
          breakLine: false,
        },
      },
      {
        text: 'Complete the menu item mapping on ',
        options: { bullet: true },
      },
      {
        text: 'Lumitics Dashboard',
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 1,
      y: 2.7,
      w: 8,
      h: 1,
    }
  );

  // Create sample slide for Top 3 items in terms of volume from the location monthly report
  slide = initialiseSlide(
    pres,
    slideStyle,
    'SAMPLE REPORT - TOP THREE ITEMS IN TERMS OF VOLUME (WEIGHT)'
  );
  slide.addImage({
    path: 'sampleTop3ByWeight.png',
    x: 0,
    y: 0.36,
    h: '88%',
    w: '100%',
  });

  // Create sample slide for Top 3 items in terms of value from the location monthly report
  slide = initialiseSlide(
    pres,
    slideStyle,
    'SAMPLE REPORT - TOP THREE ITEMS IN TERMS OF VALUE (COST) '
  );
  slide.addImage({
    path: 'sampleTop3ByCost.png',
    x: 0,
    y: 0.36,
    h: '88%',
    w: '100%',
  });

  // Create sample slide for Dashboard - Link
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - LINK');
  slide.addImage({
    path: 'sampleDashboardLink.png',
    x: 2,
    y: 1.1,
    h: '65%',
    w: '64%',
  });
  slide.addText(
    [
      { text: 'Link: ', options: { bold: true } },
      {
        text: LUMITICS_DASHBOARD_URL,
        options: {
          breakLine: false,
          hyperlink: { url: LUMITICS_DASHBOARD_URL, tooltip: 'Visit Lumitics' },
        },
      },
    ],
    {
      fontFace: 'Dosis',
      fontSize: 14,
      color: '#102547',
      x: 3.5,
      y: 5,
      w: 3.5,
      h: 0.5,
    }
  );

  // Create sample slide for Dashboard - Cover Input Mapping
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - COVER INPUT');
  slide.addImage({
    path: 'sampleDashboardCoverInput.png',
    x: 0.2,
    y: 1.4,
    h: '61%',
    w: '96%',
  });

  // Create sample slide for Dashboard - Menu Item Mapping
  slide = initialiseSlide(pres, slideStyle, 'DASHBOARD - MENU ITEM MAPPING');
  slide.addImage({
    path: 'sampleDashboardMenuItemMapping.png',
    x: 0.1,
    y: 1.3,
    h: '60%',
    w: '96%',
  });
};

/**
 * This function inserts the summary values for a location - total weight, number of meals wasted and the amount of CO2 emitted for annual
 * @param slide - Variable referencing the current working slide
 * @param weight - Total weight in KG rounded to whole number
 * @param numberOfDayWithinDateRange - Number of days that the weight is summed from
 */
const insertSummaryValues = (
  slide: Pptxgen.Slide,
  weight: number,
  numberOfDayWithinDateRange: number
) => {
  const annualWasteValue = Math.round((weight / numberOfDayWithinDateRange) * 365);
  slide.addImage({
    path: 'foodWasteIcon.png',
    x: 1.4,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Annual food waste outlook without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${annualWasteValue} KG`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `(${weight} KG / ${numberOfDayWithinDateRange} days x 365 days)`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 0.8,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  const numberOfMealsWasted = Math.round(annualWasteValue * 4);
  slide.addImage({
    path: 'mealIcon.png',
    x: 4.3,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Number of meals wasted without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${numberOfMealsWasted}`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: '(1 KG of food waste = 4 meals)',
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 3.7,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  const amountOfCo2Emissions = Math.round(annualWasteValue * 2.5);
  slide.addImage({
    path: 'co2Icon.png',
    x: 7.2,
    y: 1.2,
    h: 1.2,
    w: 1.2,
  });
  slide.addText(
    [
      {
        text: 'Amount of CO2 emissions without Lumitics',
        options: { color: '#102547' },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: `${amountOfCo2Emissions} KG`,
        options: { color: '#e4745e', softBreakBefore: true },
      },
      {
        text: ' ',
        options: {
          softBreakBefore: true,
        },
      },
      {
        text: '(1 KG of food waste = 2.5 KG of CO2)',
        options: { color: '#e4745e', softBreakBefore: true },
      },
    ],
    {
      x: 6.6,
      y: 2.8,
      h: 1.2,
      w: 2.4,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 11,
      color: '#102547',
      align: 'center',
    }
  );

  slide.addImage({
    path: 'lightbulb.png',
    x: 2.2,
    y: 4.98,
    h: 0.5,
    w: 0.5,
  });
  slide.addText(
    [
      {
        text: 'Reduction in leftovers per cover (grams) is the key.',
        options: { bullet: true },
      },
      {
        text: 'Based on our historical data, hotels can achieve 20-30% food waste reduction in a year by consistently managing food waste and taking corrective actions.',
        options: { bullet: true },
      },
    ],
    {
      x: 2.8,
      y: 4.98,
      h: 0.6,
      w: 5,
      bold: true,
      fontFace: 'Dosis',
      fontSize: 8,
      color: '#102547',
    }
  );
};

export {
  insertGraph,
  insertGraphForBaseline,
  insertImage,
  insertImageSlidesForBaseline,
  insertBaselineNextStepsBodySlides,
  insertSummaryValues,
  insertTableForBaseline,
  insertTable,
  insertStationGraph,
  insertStationImage,
  insertServiceTop6MenuItemTable,
  initialiseSlide,
};
