import { useContext, useState } from 'react';

import RestaurantServiceInputCardsContainer from './InputComponents/RestaurantServiceInputCardsContainer';
import RestaurantCard from './RestaurantCard';
import AddButton from '../Common/Buttons/AddButton';
import { COMPANY_SERVICE_INPUT_MODE } from '../../constant/General';
import InputCardsContext from '../../context/InputCardsContext';
import {
  CompanyService,
  Location,
  NewLocationService,
  NewRestaurantService,
  NewService,
  Restaurant,
  Service,
} from '../../interfaces';

/**
 * To get an array of new restaurantService to when the fake IDs are removed
 * @param mapNewFRestaurantByRestaurantId - Map of key: restaurantId and value: newRestaurant
 * @param mapMapNewLocationByLocationIdByRestaurantId - Map of key: restaurantId and value: Map of key: locationId and value: newLocation
 * @param mapMapNewServiceByServiceIdByLocationId - Map of key: locationId and value: Map of key: serviceId and value: newService
 * @returns arrNewRestaurantService - Array of newRestaurantService with the fake IDs removed
 */
const getArrNewRestaurantService = (
  mapNewRestaurantByRestaurantId: Map<number, Restaurant>,
  mapMapNewLocationByLocationIdByRestaurantId: Map<number, Map<number, Location>>,
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>
): Array<NewRestaurantService> => {
  const arrNewRestaurantService: Array<NewRestaurantService> = [];
  mapNewRestaurantByRestaurantId.forEach((newRestaurant: Restaurant, restaurantId: number) => {
    const mapNewLocationByLocationId =
      mapMapNewLocationByLocationIdByRestaurantId.get(restaurantId);
    const arrNewLocationService: Array<NewLocationService> = [];
    mapNewLocationByLocationId?.forEach((newLocation: Location, locationId: number) => {
      const mapNewServiceByServiceId = mapMapNewServiceByServiceIdByLocationId.get(locationId);
      const arrNewService: Array<NewService> = [];
      mapNewServiceByServiceId?.forEach((newService: Service, serviceId: number) => {
        const newServiceWithoutfakeIds: NewService = { ...newService };
        newServiceWithoutfakeIds.serviceId = undefined;
        newServiceWithoutfakeIds.locationId = undefined;
        newServiceWithoutfakeIds.menuId = undefined;
        newServiceWithoutfakeIds.menu.menuId = undefined;
        arrNewService.push(newServiceWithoutfakeIds);
      });
      const newLocationService: NewLocationService = {
        ...newLocation,
        locationId: undefined,
        restaurantId: undefined,
        arrService: arrNewService,
      };
      arrNewLocationService.push(newLocationService);
    });
    const newRestaurantService: NewRestaurantService = {
      ...newRestaurant,
      restaurantId: undefined,
      arrLocationService: arrNewLocationService,
    };
    arrNewRestaurantService.push(newRestaurantService);
  });
  return arrNewRestaurantService;
};

const RestaurantList = (props: {
  companyService: CompanyService;
  saveChangesToExistingCompanyService: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);

  const [componentId, setComponentId] = useState('');
  const [mapNewRestaurantByRestaurantId, setMapNewRestaurantByRestaurantId] = useState(new Map());
  const [
    mapMapNewLocationByLocationIdByRestaurantId,
    setMapMapNewLocationByLocationIdByRestaurantId,
  ] = useState(new Map());
  const [mapMapNewServiceByServiceIdByLocationId, setMapMapNewServiceByServiceIdByLocationId] =
    useState(new Map());

  /**
   * Function is called when user clicks on Add Restaurant or Cancel button, and enables or disables addition mode by setting the correct input mode
   * in the inputCardsContext. Related RestaurantIdMenuInputTag data saved in the inputCardsContext due to the adding of new restaurantService will be
   * removed if the Cancel button is clicked so that the data will not be carried forward to the next input mode which can cause incorrect errors.
   */
  const enableAddRestaurantServiceOnClick = (event: any) => {
    const isAddRestaurantService = inputCardsContext.checkIfActiveInputMode(
      COMPANY_SERVICE_INPUT_MODE.addRestaurantService,
      componentId
    );
    if (isAddRestaurantService === true) {
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.none,
        '',
        props.companyService.companyId
      );
      setComponentId('');
      inputCardsContext.deleteAllError();
      inputCardsContext.removeRestaurantIdMenuInputTag(); // Remove service data (specifically edited/new menu) from context
    } else {
      const newComponentId = new Date();
      setComponentId(newComponentId.toString());
      inputCardsContext.setInputMode(
        COMPANY_SERVICE_INPUT_MODE.addRestaurantService,
        newComponentId.toString(),
        props.companyService.companyId
      );
    }
  };

  /**
   * Function can add the new restaurant to map, with the restaurantId as the key, or remove the key-value if the restaurant is to be removed.
   * If the restaurant is to be removed, remove all the new location(s) and service(s) that belongs to this restaurant.
   * @param idParameters - Object containing the restaurantId of the restaurant
   * @param newRestaurant - New restaurant object, or undefined if the restaurant is to be removed
   */
  const updateMapNewRestaurantByRestaurantId = (
    idParameters: { restaurantId: number },
    newRestaurant?: Restaurant
  ) => {
    const { restaurantId } = idParameters;
    const updatedMapNewRestaurantByRestaurantId = new Map(mapNewRestaurantByRestaurantId);
    if (newRestaurant) {
      updatedMapNewRestaurantByRestaurantId.set(restaurantId, newRestaurant);
    } else {
      updatedMapNewRestaurantByRestaurantId.delete(restaurantId);

      const updatedMapMapNewLocationByLocationIdByRestaurantId = new Map(
        mapMapNewLocationByLocationIdByRestaurantId
      );
      updatedMapMapNewLocationByLocationIdByRestaurantId.delete(restaurantId);

      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      const lowerLimitOfRange = restaurantId - 999;
      const upperLimitOfRange = restaurantId;
      updatedMapMapNewServiceByServiceIdByLocationId.forEach(
        (mapNewServiceByService, locationId) => {
          if (locationId >= lowerLimitOfRange && locationId <= upperLimitOfRange) {
            updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
          }
        }
      );
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
      setMapMapNewLocationByLocationIdByRestaurantId(
        updatedMapMapNewLocationByLocationIdByRestaurantId
      );
    }
    setMapNewRestaurantByRestaurantId(updatedMapNewRestaurantByRestaurantId);
  };

  /**
   * Function can add the new location to map, with the restaurantId as the first key and locationId as the second key, or remove the key-value if the location is
   * to be removed.
   * If the location is to be removed, remove all the new service(s) that belongs to this location.
   * @param idParameters - Object containing the locationId and the restaurantId of the location
   * @param newLocation - New location object, or undefined if the location is to be removed
   */
  const updateMapMapNewLocationByLocationIdByRestaurantId = (
    idParameters: { locationId: number; restaurantId: number },
    newLocation?: Location
  ) => {
    const { locationId, restaurantId } = idParameters;
    const updatedMapMapNewLocationByLocationIdByRestaurantId = new Map(
      mapMapNewLocationByLocationIdByRestaurantId
    );
    if (newLocation) {
      if (updatedMapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)) {
        updatedMapMapNewLocationByLocationIdByRestaurantId
          .get(restaurantId)
          .set(locationId, newLocation);
      } else {
        updatedMapMapNewLocationByLocationIdByRestaurantId.set(
          restaurantId,
          new Map([[locationId, newLocation]])
        );
      }
    } else {
      updatedMapMapNewLocationByLocationIdByRestaurantId.get(restaurantId)?.delete(locationId);
      const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
        mapMapNewServiceByServiceIdByLocationId
      );
      updatedMapMapNewServiceByServiceIdByLocationId.delete(locationId);
      setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
    }
    setMapMapNewLocationByLocationIdByRestaurantId(
      updatedMapMapNewLocationByLocationIdByRestaurantId
    );
  };

  /**
   * Function can add the new service to map, with the locationId as the first key and serviceId as the second key, or remove the key-value if the service
   * is to be removed
   * @param idParameters - Object containing the serviceId and the locationId of the service
   * @param newService - New service object, or undefined if the service is to be removed
   */
  const updateMapMapNewServiceByServiceIdByLocationId = (
    idParameters: { serviceId: number; locationId: number },
    newService?: Location
  ) => {
    const { locationId, serviceId } = idParameters;
    const updatedMapMapNewServiceByServiceIdByLocationId = new Map(
      mapMapNewServiceByServiceIdByLocationId
    );
    if (newService) {
      if (updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)) {
        updatedMapMapNewServiceByServiceIdByLocationId.get(locationId).set(serviceId, newService);
      } else {
        updatedMapMapNewServiceByServiceIdByLocationId.set(
          locationId,
          new Map([[serviceId, newService]])
        );
      }
    } else {
      updatedMapMapNewServiceByServiceIdByLocationId.get(locationId)?.delete(serviceId);
    }
    setMapMapNewServiceByServiceIdByLocationId(updatedMapMapNewServiceByServiceIdByLocationId);
  };

  /**
   * Function is run by save changes button, and calls saveChangesToExistingCompanyService function that is
   * passed down from parent CompanyCard component. Function saves newly added restaurantServices for the
   * company.
   */
  const saveChangesForAddRestaurantServiceOnClick = () => {
    const arrNewRestaurantService = getArrNewRestaurantService(
      mapNewRestaurantByRestaurantId,
      mapMapNewLocationByLocationIdByRestaurantId,
      mapMapNewServiceByServiceIdByLocationId
    );
    props.saveChangesToExistingCompanyService(
      { companyId: props.companyService.companyId },
      {
        arrRestaurantServiceToBeCreated: arrNewRestaurantService,
      },
      {}
    );
    // Reset changes
    setMapNewRestaurantByRestaurantId(new Map());
    setMapMapNewLocationByLocationIdByRestaurantId(new Map());
    setMapMapNewServiceByServiceIdByLocationId(new Map());
  };

  return (
    <div className="RestaurantList">
      <div>
        <div className="mt-3 mb-2 flex gap-x-1 flex-nowrap justify-between content-center">
          <p className="font-medium text-xl">
            Properties: <span>{props.companyService.arrRestaurantService.length}</span>
          </p>
          <div>
            <AddButton
              isEnabled={inputCardsContext.getInputMode(
                COMPANY_SERVICE_INPUT_MODE.addRestaurantService,
                componentId
              )}
              enableAddOnClick={enableAddRestaurantServiceOnClick}
              saveChangesOnClick={saveChangesForAddRestaurantServiceOnClick}
              isAdd={inputCardsContext.checkIfActiveInputMode(
                COMPANY_SERVICE_INPUT_MODE.addRestaurantService,
                componentId
              )}
              buttonName={'Add Property'}
              buttonClassName={'AddPropertyButton'}
            />
          </div>
        </div>
        {inputCardsContext.checkIfActiveInputMode(
          COMPANY_SERVICE_INPUT_MODE.addRestaurantService,
          componentId
        ) && (
          <RestaurantServiceInputCardsContainer
            arrNewRestaurant={Array.from(mapNewRestaurantByRestaurantId.values())}
            companyId={props.companyService.companyId}
            mapMapNewLocationByLocationIdByRestaurantId={
              mapMapNewLocationByLocationIdByRestaurantId
            }
            mapMapNewServiceByServiceIdByLocationId={mapMapNewServiceByServiceIdByLocationId}
            updateMapForNewRestaurant={updateMapNewRestaurantByRestaurantId}
            updateMapMapNewLocationByLocationIdByRestaurantId={
              updateMapMapNewLocationByLocationIdByRestaurantId
            }
            updateMapMapNewServiceByServiceIdByLocationId={
              updateMapMapNewServiceByServiceIdByLocationId
            }
          />
        )}
        <ul className="flex flex-col gap-y-1">
          {props.companyService.arrRestaurantService.map((restaurantService) => (
            <RestaurantCard
              key={restaurantService.restaurantId}
              companyId={props.companyService.companyId}
              companyName={props.companyService.name}
              restaurantService={restaurantService}
              saveChangesToExistingCompanyService={props.saveChangesToExistingCompanyService}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RestaurantList;
