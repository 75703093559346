import React, { useState, useContext } from 'react';
import { MdAdd } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import { COMPANY_SERVICE_INPUT_MODE } from '../../../constant/General';
import InputCardsContext from '../../../context/InputCardsContext';

const FAKE_COMPANY_ID = -1;

const AddCompanyButton = () => {
  const inputCardsContext = useContext(InputCardsContext);
  const [componentId, setComponentId] = useState<string>('');

  const addButtonClick = () => {
    const newComponentId = new Date();
    setComponentId(newComponentId.toString());
    inputCardsContext.setInputMode(
      COMPANY_SERVICE_INPUT_MODE.addCompanyService,
      newComponentId.toString(),
      FAKE_COMPANY_ID
    );
  };

  const cancelButtonClick = () => {
    inputCardsContext.setInputMode(COMPANY_SERVICE_INPUT_MODE.none, '', FAKE_COMPANY_ID);
    setComponentId('');
  };

  return (
    <React.Fragment>
      {inputCardsContext.input.inputModeIndex === COMPANY_SERVICE_INPUT_MODE.addCompanyService ? (
        <Button
          onClick={cancelButtonClick}
          color="red"
          className="CancelAddButton font-medium flex gap-x-1 pl-3 pr-3"
        >
          <MdAdd className="self-center" />
          Cancel Add
        </Button>
      ) : (
        <Button
          onClick={addButtonClick}
          className="font-medium flex gap-x-1 pl-3 pr-3 bg-regal-blue"
          disabled={
            !inputCardsContext.getInputMode(
              COMPANY_SERVICE_INPUT_MODE.addCompanyService,
              componentId
            )
          }
        >
          <MdAdd className="self-center" />
          Add a Group
        </Button>
      )}
    </React.Fragment>
  );
};

export default AddCompanyButton;
