import { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { Checkbox } from '@material-tailwind/react';

const UserLocationCheckbox = (props: {
  isChecked: boolean;
  locationId: number;
  locationName: string;
  updateIsLocationChecked: any;
  userId: number;
}) => {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  /**
   * This useEffect updates this component's isChecked state whenever
   * a new isChecked state is set from the parent RestaurantCheckbox (this is to
   * allow for users to check all locations by just checking the parent restaurant)
   */
  useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);

  /**
   * This function toggles this component's isChecked state
   */
  const toggleLocationCheckOnChange = () => {
    const updatedIsChecked = !isChecked;

    setIsChecked(updatedIsChecked);
    props.updateIsLocationChecked(props.locationId, updatedIsChecked);
  };

  return (
    <div className="flex items-center ml-5 mt-0">
      <Checkbox
        className={props.locationName + 'Checkbox h-4 w-4 rounded-xl'}
        checked={isChecked}
        key={`UserLocationCheckbox ${props.userId} ${props.locationId}`}
        id={`UserLocationCheckbox ${props.userId} ${props.locationId}`}
        onChange={toggleLocationCheckOnChange}
        icon={<MdCheck className="h-2.5 w-2.5" />}
      />
      <p className="text-sm break-all align-middle">{props.locationName}</p>
    </div>
  );
};

export default UserLocationCheckbox;
