import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';

import './App.css';
import Alert from './components/CompanyComponents/alerts/Alert';
import CompanyPage from './components/CompanyComponents/CompanyManagementPage';
import DeviceManagementPage from './components/DeviceComponents/DeviceManagementPage';
import Header from './components/Header';
import LoadingScreen from './components/LoadingScreen';
import ReportInputCard from './components/ReportComponents/ReportInputCard';
import { CompanyContextProvider } from './context/CompanyContext';
import { InputCardsContextProvider } from './context/InputCardsContext';
import { UserContextProvider } from './context/UserContext';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, error, logout } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated || error !== undefined) {
      return;
    }
    const redirectToLoginPage = async () => {
      await loginWithRedirect();
    };
    redirectToLoginPage();
  }, [isLoading, isAuthenticated]);

  return (
    <React.Fragment>
      <Header />
      <CompanyContextProvider>
        <InputCardsContextProvider>
          <UserContextProvider>
            {(isLoading || !isAuthenticated) && <LoadingScreen />}
            {error && (
              <Alert
                arrAlertMessage={[`${error}`]}
                alertHeader={`Login Error`}
                handleOpen={() => {
                  logout();
                }}
                isExpanded
              />
            )}
            {!isLoading && isAuthenticated && (
              <Tabs value="companies">
                <TabsHeader className="mx-28 mt-2 xl:mx-96">
                  <Tab key="companies" value="companies">
                    Groups
                  </Tab>
                  <Tab key="reports" value="reports" className="Reports">
                    Reports
                  </Tab>
                  <Tab key="devices" value="devices" className="Devices">
                    Devices
                  </Tab>
                </TabsHeader>
                <TabsBody>
                  <TabPanel key="companies" value="companies" className="text-black font-normal">
                    <CompanyPage />
                  </TabPanel>
                  <TabPanel key="reports" value="reports" className="text-black font-normal">
                    <ReportInputCard />
                  </TabPanel>
                  <TabPanel key="devices" value="devices" className="text-black font-normal">
                    <DeviceManagementPage />
                  </TabPanel>
                </TabsBody>
              </Tabs>
            )}
          </UserContextProvider>
        </InputCardsContextProvider>
      </CompanyContextProvider>
    </React.Fragment>
  );
}

export default App;
