import { useEffect, useState } from 'react';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';

const Alert = (props: {
  arrAlertMessage: Array<any>;
  alertHeader: string;
  handleOpen: any;
  isExpanded: boolean;
}) => {
  const [isMessageExist, setIsMessageExist] = useState(false);
  let count: number = 0;

  useEffect(() => {
    if (props.arrAlertMessage.length !== 0) {
      if (props.arrAlertMessage[0] !== '') {
        setIsMessageExist(true);
      }
    }
  }, []);

  return (
    <Dialog className="Alert" open={props.isExpanded} handler={props.handleOpen}>
      <DialogHeader className="AlertHeader">{props.alertHeader}</DialogHeader>
      {isMessageExist && (
        <DialogBody className="AlertBody" divider>
          <div className="flex flex-col font-normal text-off-black">
            {props.arrAlertMessage.map((message) => (
              <div key={(count += 1)} className="whitespace-pre-wrap">
                {message.toString()}
              </div>
            ))}
          </div>
        </DialogBody>
      )}
      <DialogFooter>
        <Button
          variant="gradient"
          color="green"
          onClick={props.handleOpen}
          className="ConfirmAlertButton"
        >
          <span>Confirm</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Alert;
