import { useContext } from 'react';

import CompanyCard from './CompanyCard';
import CompanyContext from '../../context/CompanyContext';
import { CompanyService } from '../../interfaces';

const CompanyList = () => {
  const companyContext = useContext(CompanyContext);

  return (
    <ul className="mt-3 grid gap-x-3 grid-flow-dense-y gap-y-1 lg:grid-cols-2">
      {companyContext.arrCompanyService.map((companyService: CompanyService) => (
        <CompanyCard
          key={`companyCard_${companyService.companyId}`}
          companyService={companyService}
        />
      ))}
    </ul>
  );
};

export default CompanyList;
