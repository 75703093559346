import { createContext, useState } from 'react';

import {
  CompanyService,
  Location,
  RestaurantLocation,
  RestaurantService,
  UserLocation,
  UserRestaurantLocation,
} from '../interfaces';

/**
 * Sort algorithm to sort array of userRestaurantLocation alphabetically in ascending order by email
 * @param userRestaurantLocationA - userRestaurantLocation object A
 * @param userRestaurantLocationB - userRestaurantLocation object B
 * @returns Boolean - Indicate if the positions of userRestaurantLocationA and userRestaurantLocationB should be swapped
 */
const sortUserRestaurantLocationInAscendingOrderByEmail = (
  userRestaurantLocationA: UserRestaurantLocation,
  userRestaurantLocationB: UserRestaurantLocation
) => {
  if (userRestaurantLocationA.email > userRestaurantLocationB.email) {
    return 1;
  }
  return -1;
};

/**
 * Identify the company that the user belongs to by comparing the restaurant Id, returning the whole companyService
 * @param arrAllCompanyServiceWithMenu - Array of all companyService(s)
 * @param restaurantIdToCompare - Id of restaurant to match with the restaurant within the arrCompanyService
 * @returns companyService - companyService that the user belongs to
 */
const findCompanyServiceThatUserBelongsTo = (
  arrCompanyService: CompanyService[],
  restaurantIdToCompareWith: number
): any => {
  const numberOfCompany = arrCompanyService.length;
  for (let companyIndex = 0; companyIndex < numberOfCompany; companyIndex++) {
    const numberOfRestaurant = arrCompanyService[companyIndex].arrRestaurantService.length;
    for (let restaurantIndex = 0; restaurantIndex < numberOfRestaurant; restaurantIndex++) {
      if (
        arrCompanyService[companyIndex].arrRestaurantService[restaurantIndex].restaurantId ===
        restaurantIdToCompareWith
      ) {
        return arrCompanyService[companyIndex];
      }
    }
  }
};

/**
 * Get an array of restaurantLocation(s) that the user has access to
 * @param arrRestaurantService - Array of restaurantLocation(s) belonging to the company that the user belongs to
 * @param arrLocation - Array of location(s) that the user has access to
 * @returns arrRestaurantLocation - Array of restaurantLocation(s) that the user has access to. Will be an empty array if array of location is empty
 */
const getArrRestaurantLocationForUser = (
  arrRestaurantService: RestaurantService[],
  arrLocation: Location[]
): RestaurantLocation[] => {
  const arrRestaurantLocation: RestaurantLocation[] = [];
  arrLocation.forEach((location) => {
    const matchedRestaurantLocationIndex = arrRestaurantLocation.findIndex(
      (restaurantLocation) => restaurantLocation.restaurantId === location.restaurantId
    );
    if (matchedRestaurantLocationIndex === -1) {
      const matchedRestaurantService = arrRestaurantService.find(
        (restaurantService: RestaurantService) =>
          restaurantService.restaurantId === location.restaurantId
      );
      if (matchedRestaurantService !== undefined) {
        const restaurantLocation = {
          restaurantId: matchedRestaurantService.restaurantId,
          name: matchedRestaurantService.name,
          companyId: matchedRestaurantService.companyId,
          arrLocation: [location],
        };
        arrRestaurantLocation.push(restaurantLocation);
      }
    } else {
      arrRestaurantLocation[matchedRestaurantLocationIndex].arrLocation.push(location);
    }
  });
  return arrRestaurantLocation;
};

const UserContext = createContext({
  mapArrUserRestaurantLocationByCompanyId: new Map() as Map<number, UserRestaurantLocation[]>,
  arrEmail: [] as any,
  addLocationAccessToUser: (companyId: number, restaurantId: number, arrLocation: Location[]) => {},
  addUser: (userRestaurantLocationCreated: UserRestaurantLocation) => {},
  deleteUser: (companyId: number, datavizUserId: number, datavizUserEmail: string) => {},
  initialiseAllUserDetails: (
    arrUserLocation: UserLocation[],
    arrCompanyService: CompanyService[]
  ) => {},
  updateUser: (arrUpdatedUserRestaurantLocation: UserRestaurantLocation[]) => {},
});

export const UserContextProvider = (props: any) => {
  // Store current array of user details in the context for the cache and to avoid props drilling
  const [mapArrUserRestaurantLocationByCompanyId, setMapArrUserRestaurantLocationByCompanyId] =
    useState<Map<number, UserRestaurantLocation[]>>(new Map());

  const [arrEmail, setArrEmail] = useState<string[]>([]);

  /**
   * This function intialises all the user details and construct the data in a way that is grouped by companyId
   * for easy retrieval of the data. This is called only once when the user first click on a user bar (any user).
   * The reason why it is not written in a way where only the specific arrUserRestaurantLocation of the company is
   * fetched is because an array of all emails needs to be retrieved for validation if the app user decides to create
   * or edit Dashboard user.
   * @param arrUserLocation - Array of all userLocation(s)
   * @param arrCompanyService - Array of all companyService(s)
   */
  const initialiseAllUserDetails = (
    arrUserLocation: UserLocation[],
    arrCompanyService: CompanyService[]
  ) => {
    const updatedMapArrUserRestaurantLocationByCompanyId = new Map();
    const updatedArrEmail: string[] = [];

    arrUserLocation.forEach((userLocation) => {
      const { arrLocation } = userLocation;
      if (arrLocation && arrLocation.length !== 0) {
        const { arrLocation } = userLocation;

        const companyService = findCompanyServiceThatUserBelongsTo(
          arrCompanyService,
          arrLocation[0].restaurantId
        );
        const arrRestaurantLocationOfUser = getArrRestaurantLocationForUser(
          companyService.arrRestaurantService,
          arrLocation
        );

        const userRestaurantLocation: UserRestaurantLocation = {
          datavizUserId: userLocation.datavizUserId,
          email: userLocation.email,
          firstName: userLocation.firstName,
          lastName: userLocation.lastName,
          isEmailPrimaryRecipient: userLocation.isEmailPrimaryRecipient,
          isUsingOtp: userLocation.isUsingOtp,
          isCompany: userLocation.isCompany,
          arrRestaurantLocation: arrRestaurantLocationOfUser,
        };

        if (updatedMapArrUserRestaurantLocationByCompanyId.get(companyService.companyId)) {
          const arrUserRestaurantLocation = updatedMapArrUserRestaurantLocationByCompanyId.get(
            companyService.companyId
          );
          arrUserRestaurantLocation.push(userRestaurantLocation);
        } else {
          updatedMapArrUserRestaurantLocationByCompanyId.set(companyService.companyId, [
            userRestaurantLocation,
          ]);
        }

        updatedArrEmail.push(userRestaurantLocation.email);
      }
    });
    // To sort user(s) within a company by email
    updatedMapArrUserRestaurantLocationByCompanyId.forEach((arrUserRestaurantLocation) =>
      arrUserRestaurantLocation.sort(sortUserRestaurantLocationInAscendingOrderByEmail)
    );

    setMapArrUserRestaurantLocationByCompanyId(updatedMapArrUserRestaurantLocationByCompanyId);
    setArrEmail(updatedArrEmail);
  };

  /**
   * This function removes the user's details from the context values as the user is deleted
   * @param companyId - Id of company that the dataviz user that has been deleted belongs to
   * @param datavizUserId - Id of dataviz user that has been deleted
   * @param datavizUserEmail - Email of dataviz user that has been deleted
   */
  const deleteUser = (companyId: number, datavizUserId: number, datavizUserEmail: string) => {
    const updatedMapArrUserRestaurantLocationByCompanyId = new Map(
      mapArrUserRestaurantLocationByCompanyId
    );
    let updatedArrEmail: string[] = arrEmail.slice();
    updatedMapArrUserRestaurantLocationByCompanyId.forEach(
      (arrUserRestaurantLocation, currentCompanyId) => {
        if (currentCompanyId === companyId) {
          let updatedArrUserRestaurantLocation = arrUserRestaurantLocation.slice();
          updatedArrUserRestaurantLocation = updatedArrUserRestaurantLocation.filter(
            (userRestaurantLocation) => userRestaurantLocation.datavizUserId !== datavizUserId
          );
          updatedMapArrUserRestaurantLocationByCompanyId.set(
            companyId,
            updatedArrUserRestaurantLocation
          );
        }
      }
    );
    updatedArrEmail = updatedArrEmail.filter((email) => email !== datavizUserEmail);
    setMapArrUserRestaurantLocationByCompanyId(updatedMapArrUserRestaurantLocationByCompanyId);
    setArrEmail(updatedArrEmail);
  };

  /**
   * This function adds the newly created userRestaurantLocation to the context values and sort the array of
   * userRestaurantLocation(s) that it is added to
   * @param userRestaurantLocationCreated - userRestaurantLocation that is newly created
   */
  const addUser = (userRestaurantLocationCreated: UserRestaurantLocation) => {
    const { companyId } = userRestaurantLocationCreated.arrRestaurantLocation[0];
    const updatedMapArrUserRestaurantLocationByCompanyId = new Map(
      mapArrUserRestaurantLocationByCompanyId
    );
    const updatedArrEmail: string[] = arrEmail.slice();

    const updatedArrUserRestaurantLocation =
      updatedMapArrUserRestaurantLocationByCompanyId.get(companyId) || [];
    updatedArrUserRestaurantLocation.push(userRestaurantLocationCreated);
    updatedArrUserRestaurantLocation.sort(sortUserRestaurantLocationInAscendingOrderByEmail);
    updatedMapArrUserRestaurantLocationByCompanyId.set(companyId, updatedArrUserRestaurantLocation);

    updatedArrEmail.push(userRestaurantLocationCreated.email);
    setMapArrUserRestaurantLocationByCompanyId(updatedMapArrUserRestaurantLocationByCompanyId);
    setArrEmail(updatedArrEmail);
  };

  /**
   * This function updates an array of userRestaurantLocation(s) to the context values and sorts the array of
   * userRestaurantLocation(s) that it is updated
   * @param arrUpdatedUserRestaurantLocation - Array of userRestaurantLocation(s) that is updated
   */
  const updateUser = (arrUpdatedUserRestaurantLocation: UserRestaurantLocation[]) => {
    const { companyId } = arrUpdatedUserRestaurantLocation[0].arrRestaurantLocation[0];
    const updatedMapArrUserRestaurantLocationByCompanyId = new Map(
      mapArrUserRestaurantLocationByCompanyId
    );
    const updatedArrEmail: string[] = [];

    // The replacing of userRestaurantLocation with the update one is written in the way such that it minimizes the
    // the number of times arrUpdatedUserRestaurantLocation is looped through
    const arrUserRestaurantLocation =
      updatedMapArrUserRestaurantLocationByCompanyId.get(companyId)!;
    const setUpdatedUserId = new Set();
    const updatedArrUserRestaurantLocation = [];
    arrUpdatedUserRestaurantLocation.forEach((userRestaurantLocation) => {
      setUpdatedUserId.add(userRestaurantLocation.datavizUserId);
    });
    arrUserRestaurantLocation.forEach((userRestaurantLocation) => {
      if (!setUpdatedUserId.has(userRestaurantLocation.datavizUserId)) {
        updatedArrUserRestaurantLocation.push(userRestaurantLocation);
      }
    });
    updatedArrUserRestaurantLocation.push(...arrUpdatedUserRestaurantLocation);
    updatedArrUserRestaurantLocation.sort(sortUserRestaurantLocationInAscendingOrderByEmail);
    updatedMapArrUserRestaurantLocationByCompanyId.set(companyId, updatedArrUserRestaurantLocation);

    // To get an array of email from the updated arrAllCompanyUserRestaurantLocation as the changes made can possibly be on email
    updatedMapArrUserRestaurantLocationByCompanyId.forEach((arrUserRestaurantLocation) => {
      arrUserRestaurantLocation.forEach((userRestaurantLocation) => {
        updatedArrEmail.push(userRestaurantLocation.email);
      });
    });

    setMapArrUserRestaurantLocationByCompanyId(updatedMapArrUserRestaurantLocationByCompanyId);
    setArrEmail(updatedArrEmail);
  };

  /**
   * This function adds an array of newly created location(s) to the user(s) who has access to the property that the newly created location(s) belongs to
   * @param companyId - Id of company of array of location(s)
   * @param restaurantId - Id of restaurant of array of location(s)
   * @param arrLocation - Array of newly created location(s) that is to be added to the respective user(s) in the context value mapArrUserRestaurantLocationByCompanyId
   */
  const addLocationAccessToUser = (
    companyId: number,
    restaurantId: number,
    arrLocation: Location[]
  ) => {
    const updatedMapArrUserRestaurantLocationByCompanyId = new Map(
      mapArrUserRestaurantLocationByCompanyId
    );

    const arrUserRestaurantLocation =
      updatedMapArrUserRestaurantLocationByCompanyId.get(companyId)!;

    arrUserRestaurantLocation.forEach((userRestaurantLocation: UserRestaurantLocation) => {
      const matchedRestaurantLocation = userRestaurantLocation.arrRestaurantLocation.find(
        (restaurantLocation: RestaurantLocation) => restaurantLocation.restaurantId === restaurantId
      );
      if (matchedRestaurantLocation) {
        matchedRestaurantLocation.arrLocation.push(...arrLocation);
      }
    });

    setMapArrUserRestaurantLocationByCompanyId(updatedMapArrUserRestaurantLocationByCompanyId);
  };

  return (
    <UserContext.Provider
      value={{
        mapArrUserRestaurantLocationByCompanyId: mapArrUserRestaurantLocationByCompanyId,
        arrEmail: arrEmail,
        addLocationAccessToUser: addLocationAccessToUser,
        addUser: addUser,
        deleteUser: deleteUser,
        initialiseAllUserDetails: initialiseAllUserDetails,
        updateUser: updateUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
