import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import { useContext, useEffect, useState, useRef } from 'react';
import { MdArrowUpward, MdClear, MdDownload } from 'react-icons/md';
import { Button, Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';

import Alert from './alerts/Alert';
import MenuItemInputList from './InputComponents/MenuItemInputList';
import StationCard from './StationCard';
import AddButton from '../Common/Buttons/AddButton';
import CompanyContext from '../../context/CompanyContext';
import { Menu, MenuItem, NewMenuItem, Service } from '../../interfaces';

/**
 * This function provides sorting algorithm to sort the two elements in ascending order by firstly, station, and secondly, name.
 * @param elementA - Element A to be sorted
 * @param elementB - Element B to be sorted
 * @returns value - Returns 1 if element A should be arranged after element B, else returns -1
 */
const sortMenuItem = (elementA: MenuItem, elementB: MenuItem) => {
  if (elementA.station > elementB.station) {
    return 1;
  }
  if (elementA.station < elementB.station) {
    return -1;
  }
  if (elementA.name > elementB.name) {
    return 1;
  }
  if (elementA.name < elementB.name) {
    return -1;
  }
  return 0;
};

const MenuCard = (props: {
  arrMenuItem: Array<MenuItem>;
  companyId: number;
  companyName: string;
  isMenuOpen: boolean;
  locationName: string;
  menu: Menu;
  openMenuOnClick: any;
  restaurantName: string;
  service: Service;
  setArrMenuItem: any;
}) => {
  useEffect(() => {
    if (props.arrMenuItem !== undefined) {
      if (props.arrMenuItem.length !== 0) {
        groupMenuItemsByStation(props.arrMenuItem);
      }
    }
  }, [props.arrMenuItem]);

  const companyContext = useContext(CompanyContext);

  const [arrArrMenuItemOfStation, setArrArrMenuItemOfStation] = useState<MenuItem[][]>([]);
  const [isAddMenuItem, setIsAddMenuItem] = useState<boolean>(false);
  const [mapMenuItemErrorByMenuItemId, setMapMenuItemErrorByMenuItemId] = useState<
    Map<number, boolean>
  >(new Map());
  const [mapMenuItemByMenuItemId, setMapMenuItemByMenuItemId] = useState<Map<number, NewMenuItem>>(
    new Map()
  );
  const [isMenuItemExist, setIsMenuItemExist] = useState<boolean>(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');

  const topRef: any = useRef();

  /**
   * Function scrolls overflowed list of menu items to the top
   * @param isSmooth - Boolean determines if scrolling to top has a smooth transition or an instant scroll
   */
  const scrollToTop = (isSmooth: boolean) => {
    if (isSmooth) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  /**
   * Onclick function for button to run
   */
  const scrollToTopOnClick = () => {
    scrollToTop(true);
  };

  /**
   * Function resets all states related to add menu items. This function is needed so that whenever the menu dialogue box reopens,
   * if the MenuItemInputList is opened previously, it will be close, so as to start afresh.
   */
  const resetAllAddMenuItemStates = () => {
    setIsAddMenuItem(false);
    setMapMenuItemErrorByMenuItemId(new Map());
    setMapMenuItemByMenuItemId(new Map());
  };

  /**
   * Function sets isError boolean for each menuItem. mapMenuItemErrorByMenuItem is used to store
   * the current isError values for newly added menu items. This value is used to determine if the user
   * needs to check their inputs and prevents the frontend from posting new menu items that have
   * invalid inputs.
   * @param arrMenuItemId - Array of id of menu item
   * @param arrIsError - Array of booleans determine if error for menuItem should be true or false
   * @param isRemove - Boolean determines if menuItem should be deleted from map or not
   * @param isRemoveFirst - Boolean determines if first menuItem should be deleted from map or not
   */
  const updateMapMenuItemErrorByArrMenuItemId = (
    arrMenuItemId: Array<number>,
    arrIsError: Array<boolean>,
    isRemove: boolean,
    isRemoveFirst: boolean
  ) => {
    const newMapMenuItemErrorByMenuItemId = new Map(mapMenuItemErrorByMenuItemId);
    arrMenuItemId.forEach((menuItemId, index) => {
      if (isRemove && mapMenuItemByMenuItemId.size !== 0) {
        newMapMenuItemErrorByMenuItemId.delete(menuItemId);
      } else {
        newMapMenuItemErrorByMenuItemId.set(menuItemId, arrIsError[index]);
      }
    });
    if (isRemoveFirst) {
      newMapMenuItemErrorByMenuItemId.delete(-1);
    }
    setMapMenuItemErrorByMenuItemId(newMapMenuItemErrorByMenuItemId);
  };

  /**
   * Function sets isError boolean for each menuItem. mapMenuItemErrorByMenuItem is used to store
   * the current isError values for newly added menu items. This value is used to determine if the user
   * needs to check their inputs and prevents the frontend from posting new menu items that have
   * invalid inputs.
   * @param arrMenuItemId - Id of menu item
   * @param arrNewMenuItem - Array of new menu items created from InputWithSearchFunction component
   * @param isRemove - Boolean determines if arrMenuItem should be deleted from map or not
   * @param isRemoveFirst - Boolean determines if first menuItem should be deleted from map or not
   */
  const updateMapMenuItemByArrMenuItemId = (
    arrMenuItemId: Array<number>,
    arrNewMenuItem: Array<NewMenuItem>,
    isRemove: boolean,
    isRemoveFirst: boolean
  ) => {
    const newMapMenuItemByMenuItemId = new Map(mapMenuItemByMenuItemId);
    for (let menuItemIndex = 0; menuItemIndex < arrMenuItemId.length; menuItemIndex += 1) {
      const currentMenuId = arrMenuItemId[menuItemIndex];
      if (isRemove) {
        newMapMenuItemByMenuItemId.delete(currentMenuId);
      } else {
        newMapMenuItemByMenuItemId.set(currentMenuId, arrNewMenuItem[menuItemIndex]);
      }
    }
    if (isRemoveFirst) {
      newMapMenuItemByMenuItemId.delete(-1);
    }
    setMapMenuItemByMenuItemId(newMapMenuItemByMenuItemId);
  };

  /**
   * Function runs when user clicks on save changes button. Saves newly added menu items into the database.
   */
  const saveChangesOnClick = async () => {
    let hasAnyError = false;
    mapMenuItemErrorByMenuItemId.forEach((isError, menuItemId) => {
      if (isError) {
        hasAnyError = true;
      }
    });
    if (hasAnyError) {
      setAlertHeader('Save Error!');
      setArrAlertMessage(['Please check for any errors and for any empty fields!']);
      setIsAlert(true);
    } else {
      const arrMenuItemToBeCreated: Array<NewMenuItem> = [];
      mapMenuItemByMenuItemId.forEach((menuItem, menuItemId) => {
        const menuItemToBeCreated = {
          ...menuItem,
          menuItemId: undefined,
        };
        arrMenuItemToBeCreated.push(menuItemToBeCreated);
      });
      try {
        const response = await axios.post('/company-management/create-menu-item', {
          menuId: props.menu.menuId,
          arrMenuItemToBeCreated,
        });
        const { arrMenuItem } = response.data;
        const arrUpdatedMenuItem = companyContext.mapArrMenuItemByMenuId.get(props.menu.menuId)
          ? companyContext.mapArrMenuItemByMenuId.get(props.menu.menuId)!.slice()
          : [];
        arrUpdatedMenuItem.push(...arrMenuItem);
        companyContext.addMenuItem(arrUpdatedMenuItem);
        props.setArrMenuItem(arrUpdatedMenuItem);
        setIsSaveSuccess(true);
        setArrAlertMessage([]);
        setAlertHeader('Save success!');
        setIsAlert(true);
      } catch (error: any) {
        setArrAlertMessage(['Could not add menu item(s)!', error]);
        setAlertHeader('Save error!');
        setIsAlert(true);
      }
    }
  };

  /**
   * Function is run whenever a new array of all menu items is retrieved from database. Function loops through
   * entire array to filter for current menu items only. The menu items are further grouped by its station and are
   * arranged in alphabetical order, both by station and also for menu items within the same station.
   * @param arrMenuItem - Array of all menu items retrieved for this menu id
   */
  const groupMenuItemsByStation = (arrMenuItem: MenuItem[]) => {
    // To filter for current menu items only
    const arrCurrentMenuItem = [] as MenuItem[];
    arrMenuItem.forEach((menuItem: MenuItem) => {
      if (menuItem.expiryDate === null) {
        arrCurrentMenuItem.push(menuItem);
      }
    });

    // Sort menu item by station and then menu item name
    arrCurrentMenuItem.sort(sortMenuItem);

    // To arrange the menu items which have already been sorted by station, followed by menu item name
    // into an array of array of menu items grouped by station
    let previousStation = '';
    const newArrArrMenuItemOfStation = [] as MenuItem[][];
    arrCurrentMenuItem.forEach((menuItem) => {
      if (menuItem.station !== previousStation) {
        newArrArrMenuItemOfStation.push([menuItem]);
      } else {
        newArrArrMenuItemOfStation[newArrArrMenuItemOfStation.length - 1].push(menuItem);
      }
      previousStation = menuItem.station;
    });

    if (newArrArrMenuItemOfStation.length === 0) {
      setIsMenuItemExist(false);
    } else {
      setIsMenuItemExist(true);
    }
    setArrArrMenuItemOfStation(newArrArrMenuItemOfStation);
  };

  const closeMenuOnClick = () => {
    resetAllAddMenuItemStates();
    props.openMenuOnClick();
  };

  /**
   * This function set isAddMenuItem to true or false to open or close the menu dialogue box. If the dialogue box is to be closed (currently opened),
   * reset all variables for adding menu item(s).
   */
  const enableAddOnClick = () => {
    if (isAddMenuItem) {
      setMapMenuItemErrorByMenuItemId(new Map());
      setMapMenuItemByMenuItemId(new Map());
    } else {
      scrollToTop(false);
    }
    setIsAddMenuItem(!isAddMenuItem);
  };

  const alertHandler = () => {
    if (isSaveSuccess === true) {
      resetAllAddMenuItemStates();
      setIsSaveSuccess(false);
    }
    setIsAlert(!isAlert);
  };

  /**
   * This function downloads .csv file of current menu items, comma-separated by name, station and cost per kilogram
   */
  const downloadMenu = () => {
    const arrMenuItemToBeDownloaded: Array<{
      name: string;
      station: string;
      costPerKilogram: string | number;
    }> = [];
    arrArrMenuItemOfStation.forEach((arrMenuItemOfStation) => {
      arrMenuItemOfStation.forEach((arrMenuItem) => {
        arrMenuItemToBeDownloaded.push({
          name: arrMenuItem.name,
          station: arrMenuItem.station,
          costPerKilogram: arrMenuItem.costPerKilogram,
        });
      });
    });
    const options = {
      filename: `${props.companyName.replaceAll(' ', '')}_${props.restaurantName.replaceAll(
        ' ',
        ''
      )}_${props.locationName.replaceAll(' ', '')}_${props.service.name.replaceAll(' ', '')}`,
      quoteStrings: '',
      showLabels: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(arrMenuItemToBeDownloaded);
  };

  return (
    <div>
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={alertHandler}
          isExpanded={isAlert}
        />
      )}
      <Dialog
        size={'xl'}
        className="MenuCard flex flex-col max-h-[95%]"
        open={props.isMenuOpen}
        handler={() => {}}
      >
        <DialogHeader className="MenuCardHeader flex flex-col w-full">
          <div className="text-left w-full font-body py-2 text-lg">
            {`${props.companyName} / ${props.restaurantName} / ${props.locationName} / ${props.service.name}`}
          </div>
          <div className="absolute right-5">
            <IconButton
              className="CloseMenuButton rounded-full"
              variant="text"
              onClick={closeMenuOnClick}
            >
              <MdClear className="text-black h-7 w-7" />
            </IconButton>
          </div>
        </DialogHeader>
        <DialogBody
          className="MenuCardBody overflow-y-scroll h-5/6 flex flex-col"
          id="dialogBody"
          divider
        >
          <div className="sticky top-[91%] left-0 z-40 ml-auto mr-3">
            <IconButton className="rounded-full" onClick={scrollToTopOnClick}>
              <MdArrowUpward />
            </IconButton>
          </div>
          <div className="relative p-0 m-auto -top-14" ref={topRef}>
            <div className="flex flex-col max-w-4xl min-w-[26rem] mt-5 gap-y-4">
              <div className="flex justify-end gap-2">
                {!isAddMenuItem && isMenuItemExist && (
                  <Button
                    onClick={downloadMenu}
                    className="MenuDownloadButton flex gap-x-1 p-2"
                    color="amber"
                  >
                    <MdDownload className="self-center" />
                    Download Menu
                  </Button>
                )}
                <AddButton
                  isEnabled={true}
                  enableAddOnClick={enableAddOnClick}
                  saveChangesOnClick={saveChangesOnClick}
                  isAdd={isAddMenuItem}
                  buttonName={'Add Menu Item'}
                  buttonClassName={'AddMenuItemButton'}
                />
              </div>
              {isAddMenuItem && (
                <MenuItemInputList
                  menuId={props.menu.menuId}
                  arrNewMenuItem={Array.from(mapMenuItemByMenuItemId.values())}
                  arrExistingMenuItem={props.arrMenuItem}
                  updateMapMenuItemErrorByArrMenuItemId={updateMapMenuItemErrorByArrMenuItemId}
                  updateMapMenuItemByArrMenuItemId={updateMapMenuItemByArrMenuItemId}
                />
              )}
              {!isMenuItemExist ? (
                <div className="h-96 flex">
                  <p className="m-auto font-normal text-off-black">No Menu Items exists</p>
                </div>
              ) : (
                <div className="flex flex-col gap-y-5">
                  {arrArrMenuItemOfStation.map((arrMenuItem, index) => (
                    <StationCard arrMenuItem={arrMenuItem} key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default MenuCard;
