import { RestaurantLocation } from '../../interfaces';

const UserRestaurantLocationList = (props: { restaurantLocation: RestaurantLocation }) => {
  return (
    <div className={`OnePropertyLocationList flex flex-col bg-gray-200 rounded-lg p-3`}>
      <p className="text-base font-semibold break-words">{props.restaurantLocation.name}</p>
      <ul>
        {props.restaurantLocation.arrLocation.map((location) => (
          <li className="break-words" key={location.locationId}>
            {location.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserRestaurantLocationList;
