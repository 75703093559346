import { useContext, useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Button } from '@material-tailwind/react';

import Alert from '../alerts/Alert';
import LocationServiceInputCard from './LocationServiceInputCard';
import { COMPANY_SERVICE_INPUT_MODE } from '../../../constant/General';
import InputCardsContext from '../../../context/InputCardsContext';
import { Location, LocationService, Service } from '../../../interfaces';

const LocationServiceInputCardsContainer = (props: {
  arrLocationService: Array<LocationService>;
  arrNewLocation: Array<Location>;
  mapMapNewServiceByServiceIdByLocationId: Map<number, Map<number, Service>>;
  companyId: number;
  restaurantId: number;
  updateMapForNewLocation: any;
  updateMapMapNewServiceByServiceIdByLocationId: any;
}) => {
  const inputCardsContext = useContext(InputCardsContext);
  const MAX_LOCATION_PER_RESTAURANT = 9;
  /**
   * States for this functional component
   */
  const [arrLocationId, setArrLocationId] = useState<Array<number>>([]); // Array stores key values of all LocationServiceInputCard component, for creation of new InputCards

  const [isAlert, setIsAlert] = useState(false);
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<string>>([]);
  const [alertHeader, setAlertHeader] = useState<string>('');

  /**
   * Function updates arrLocationId array with a new value to create a new LocationServiceInputCard. It will
   * also add an error status true into the inputCardsContext's mapErrorById. If the number of locations
   * to be added already exceeds the maximum number of locations allowed for a restaurant, an alert message
   * will pop up.
   */
  const addLocation = () => {
    if (arrLocationId.length < MAX_LOCATION_PER_RESTAURANT) {
      let newLocationId = props.restaurantId - arrLocationId.length * 10 - 10;
      // This is to cater for the adding new locationService in add locationService mode, where there is actually a valid restaurantId
      if (
        inputCardsContext.input.inputModeIndex === COMPANY_SERVICE_INPUT_MODE.addLocationService
      ) {
        newLocationId = -1100 - arrLocationId.length * 10 - 10;
      }
      const newArrLocationId = [...arrLocationId, newLocationId];
      setArrLocationId(newArrLocationId);
      inputCardsContext.setErrorExist(newLocationId, true, 1);
    } else {
      setArrAlertMessage(['Cannot add more than 9 locations at one go!']);
      setAlertHeader('Sorry!');
      setIsAlert(true);
    }
  };

  /**
   * Function runs whenever Add Another Location button is clicked, to create a new location card
   */
  const addAnotherLocationOnClick = () => {
    addLocation();
  };

  /**
   * Function runs whenever Remove Location button is clicked, removes a location from arrLocationId and mapNewLocationServiceByLocationId.
   * Also removes the location's error status and menuInputTag from the inputCardsContext's mapErrorById and mapMenuInputTagByServiceId
   * respectively.
   */
  const removeLocationOnClick = () => {
    let newArrLocationId = [...arrLocationId];
    if (newArrLocationId.length !== 0) {
      const locationToRemoveId = newArrLocationId.pop();
      setArrLocationId(newArrLocationId);
      if (locationToRemoveId) {
        props.updateMapForNewLocation({
          locationId: locationToRemoveId,
          restaurantId: props.restaurantId,
        });
        inputCardsContext.removeRestaurantIdMenuInputTag(locationToRemoveId, -9); // Remove service data from context
        inputCardsContext.deleteErrorForAllIdsWithinRange(locationToRemoveId, -9);
      }
    }
  };

  const alertHandler = () => {
    setIsAlert(!isAlert);
  };

  /**
   * useEffect to set up the first location card
   */
  useEffect(() => {
    addLocation();
  }, []);

  return (
    <div className="LocationServiceInputCardsContainer">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={alertHandler}
          isExpanded={isAlert}
        />
      )}
      <div className="flex justify-between gap-x-2 mt-2">
        <div className="text-lg font-medium">Locations Added: {arrLocationId.length}</div>
        <div className="flex justify-end gap-x-2 ">
          {arrLocationId.length > 1 && (
            <Button
              type="submit"
              color="red"
              size="sm"
              className="RemoveALocationButton font-medium flex gap-x-1 pt-2 p-2"
              onClick={removeLocationOnClick}
            >
              <MdRemove className="self-center" />
              <span>Remove a Location</span>
            </Button>
          )}
          <Button
            type="submit"
            color="lime"
            size="sm"
            className="AddAnotherLocationButton font-medium flex gap-x-1 pt-2 p-2"
            onClick={addAnotherLocationOnClick}
          >
            <MdAdd className="self-center" />
            <span>Add another Location</span>
          </Button>
        </div>
      </div>
      <ul className="flex flex-col w-full mt-2 gap-y-1">
        {arrLocationId.map((locationId: number) => (
          <LocationServiceInputCard
            key={locationId}
            arrEditedService={[]}
            arrLocationService={props.arrLocationService}
            arrNewLocation={props.arrNewLocation}
            arrNewService={
              props.mapMapNewServiceByServiceIdByLocationId.get(locationId)
                ? Array.from(
                    props.mapMapNewServiceByServiceIdByLocationId.get(locationId)!.values()
                  )
                : []
            }
            companyId={props.companyId}
            locationId={locationId}
            locationService={undefined}
            restaurantId={props.restaurantId}
            updateEditedOrNewLocation={props.updateMapForNewLocation}
            updateMapEditedServiceByServiceId={() => {}}
            updateMapForNewService={props.updateMapMapNewServiceByServiceIdByLocationId}
          />
        ))}
      </ul>
    </div>
  );
};

export default LocationServiceInputCardsContainer;
